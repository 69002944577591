import aberlourpng from "./aberlour.png";
import absolutelyxpng from "./absolut-elyx.png";
import absolutpng from "./absolut.png";
import altospng from "./altos.png";
import araratpng from "./ararat.png";
import augierpng from "./augier.png";
import avionpng from "./avion.png";
import ballantinespng from "./ballantines.png";
import becherovkapng from "./becherovka.png";
import beefeaterpng from "./beefeater.png";
import blenderspridepng from "./blenders-pride.png";
import brancottestatepng from "./brancott-estate.png";
import campoviejopng from "./campo-viejo.png";
import cederspng from "./ceders.png";
import chivaspng from "./chivas.png";
import churchroadpng from "./church-road.png";
import clancampbellpng from "./clan-campbell.png";
import delmagueypng from "./delmaguey.png";
import georgewyndhampng from "./george-wyndham.png";
import greenspotpng from "./greenspot.png";
import havanaclubpng from "./havana_club.png";
import imperialbluepng from "./imperial-blue.png";
import imperialpng from "./imperial.png";
import italicuspng from "./italicus.png";
import jacobscreekpng from "./jacobs-creek.png";
import jamesonpng from "./jameson.png";
import jeffersonspng from "./jeffersons.png";
import jpwiserspng from "./jp-wisers.png";
import kahluapng from "./kahlua.png";
import kenwoodpng from "./kenwood.png";
import kinobipng from "./kinobi.png";
import lilletpng from "./lillet.png";
import longjohnpng from "./long-john.png";
import lorbepng from "./lorbe.png";
import lotno40png from "./lotno40.png";
import malfypng from "./malfy.png";
import malibupng from "./malibu.png";
import martellpng from "./martell.png";
import methodandmadnesspng from "./method-and-madness.png";
import midletonveryrarepng from "./midleton-very-rare.png";
import minttupng from "./minttu.png";
import monkey47png from "./monkey-47.png";
import mummpng from "./mumm.png";
import olmecapng from "./olmeca.png";
import ostoyapng from "./ostoya.png";
import passportscotchpng from "./passport-scotch.png";
import pastis51png from "./pastis_51.png";
import pernodpng from "./pernod.png";
import perrierjouetpng from "./perrier-jouet.png";
import pipers100png from "./100-pipers.png";
import plymouth_ginpng from "./plymouth_gin.png";
import powerspng from "./powers.png";
import rabbitholepng from "./rabbit-hole.png";
import ramazzottipng from "./ramazzotti.png";
import redbreastpng from "./redbreast.png";
import ricardpng from "./ricard.png";
import royalsalutepng from "./royal-salute.png";
import royalstagpng from "./royal-stag.png";
import scapapng from "./scapa.png";
import seagramsginpng from "./seagrams-gin.png";
import secretspeysidepng from "./secret-speyside.png";
import smoothamblerpng from "./smooth-ambler.png";
import somethingspecialpng from "./something-special.png";
import sthugopng from "./st-hugo_2.png";
import stoneleighpng from "./stoneleigh.png";
import suzepng from "./suze.png";
import theglenlivetpng from "./the-glenlivet.png";
import txpng from "./tx.png";
import ungavapng from "./ungava.png";
import wyborowapng from "./wyborowa.png";
import ysiospng from "./ysios.png";

import * as React from "react";
import {Flex, Image, ImageProps} from "@fluentui/react-northstar";
import {Brand} from "interfaces/Brand/Brand";

const renderBrandIcon = (imagePath: string) => (props: ImageProps & { width?: number, height?: number }): JSX.Element => {
    const imageProps: ImageProps = {...props};
    imageProps.src = imagePath;
    const width = (props.width ?? 20) + "px";
    const height = (props.height ?? 20) + "px";
    return (
        <Flex styles={{width, height}} vAlign={"center"} hAlign={"center"}>
            <Image fluid className={"brand"} {...imageProps}/>
        </Flex>
    );
}

export const BrandPaths: Record<Brand, string> = {
    [Brand.Aberlour]: aberlourpng,
    [Brand.Absolut_Elyx]: absolutelyxpng,
    [Brand.Absolut]: absolutpng,
    [Brand.Altos]: altospng,
    [Brand.Ararat]: araratpng,
    [Brand.Augier]: augierpng,
    [Brand.Avion]: avionpng,
    [Brand.Ballantine__s]: ballantinespng,
    [Brand.Becherovka]: becherovkapng,
    [Brand.Beefeater]: beefeaterpng,
    [Brand.Blenders_Pride]: blenderspridepng,
    [Brand.Brancott_Estate]: brancottestatepng,
    [Brand.Campo_Viejo]: campoviejopng,
    [Brand.Ceder__s]: cederspng,
    [Brand.Chivas_Regal]: chivaspng,
    [Brand.Church_Road]: churchroadpng,
    [Brand.Clan_Campbell]: clancampbellpng,
    [Brand.Del_Maguey]: delmagueypng,
    [Brand.George_Wyndham]: georgewyndhampng,
    [Brand.Green_Spot]: greenspotpng,
    [Brand.Havana_Club]: havanaclubpng,
    [Brand.Imperial_Blue]: imperialbluepng,
    [Brand.Imperial]: imperialpng,
    [Brand.Italicus]: italicuspng,
    [Brand.Jacob__s_Creek]: jacobscreekpng,
    [Brand.Jameson]: jamesonpng,
    [Brand.Jefferson__s]: jeffersonspng,
    [Brand.JP_Wiser]: jpwiserspng,
    [Brand.Kahlua]: kahluapng,
    [Brand.Kenwood]: kenwoodpng,
    [Brand.KI_NO_BI]: kinobipng,
    [Brand.Lillet]: lilletpng,
    [Brand.Long_John]: longjohnpng,
    [Brand.Lorbe]: lorbepng,
    [Brand.Lot_no_40]: lotno40png,
    [Brand.Malfy]: malfypng,
    [Brand.Malibu]: malibupng,
    [Brand.Martell]: martellpng,
    [Brand.Method_And_Madness]: methodandmadnesspng,
    [Brand.Midleton_Very_Rare]: midletonveryrarepng,
    [Brand.Minttu]: minttupng,
    [Brand.Monkey_47]: monkey47png,
    [Brand.GH_Mumm]: mummpng,
    [Brand.Olmeca]: olmecapng,
    [Brand.Ostoya]: ostoyapng,
    [Brand.Passport_Scotch]: passportscotchpng,
    [Brand.Pastis_51]: pastis51png,
    [Brand.Pernod]: pernodpng,
    [Brand.Perrier_Jouet]: perrierjouetpng,
    [Brand.Pipers_100]: pipers100png,
    [Brand.Plymouth_gin]: plymouth_ginpng,
    [Brand.Powers]: powerspng,
    [Brand.Rabbit_Hole]: rabbitholepng,
    [Brand.Ramazzotti]: ramazzottipng,
    [Brand.Redbreast]: redbreastpng,
    [Brand.Ricard]: ricardpng,
    [Brand.Royal_Salute]: royalsalutepng,
    [Brand.Royal_Stag]: royalstagpng,
    [Brand.Scapa]: scapapng,
    [Brand.Seagrams_Gin]: seagramsginpng,
    [Brand.Secret_Speyside]: secretspeysidepng,
    [Brand.Smooth_Ambler]: smoothamblerpng,
    [Brand.Something_Special]: somethingspecialpng,
    [Brand.St_Hugo]: sthugopng,
    [Brand.Stoneleigh]: stoneleighpng,
    [Brand.Suze]: suzepng,
    [Brand.The_Glenlivet]: theglenlivetpng,
    [Brand.Tx]: txpng,
    [Brand.Ungava]: ungavapng,
    [Brand.Wyborowa_Wodka]: wyborowapng,
    [Brand.Ysios]: ysiospng,
}

export const BrandIcons: Record<Brand, (props: ImageProps & { width?: number, height?: number }) => JSX.Element> = {
    [Brand.Aberlour]: renderBrandIcon(BrandPaths[Brand.Aberlour]),
    [Brand.Absolut_Elyx]: renderBrandIcon(BrandPaths[Brand.Absolut_Elyx]),
    [Brand.Absolut]: renderBrandIcon(BrandPaths[Brand.Absolut]),
    [Brand.Altos]: renderBrandIcon(BrandPaths[Brand.Altos]),
    [Brand.Ararat]: renderBrandIcon(BrandPaths[Brand.Ararat]),
    [Brand.Augier]: renderBrandIcon(BrandPaths[Brand.Augier]),
    [Brand.Avion]: renderBrandIcon(BrandPaths[Brand.Avion]),
    [Brand.Ballantine__s]: renderBrandIcon(BrandPaths[Brand.Ballantine__s]),
    [Brand.Becherovka]: renderBrandIcon(BrandPaths[Brand.Becherovka]),
    [Brand.Beefeater]: renderBrandIcon(BrandPaths[Brand.Beefeater]),
    [Brand.Blenders_Pride]: renderBrandIcon(BrandPaths[Brand.Blenders_Pride]),
    [Brand.Brancott_Estate]: renderBrandIcon(BrandPaths[Brand.Brancott_Estate]),
    [Brand.Campo_Viejo]: renderBrandIcon(BrandPaths[Brand.Campo_Viejo]),
    [Brand.Ceder__s]: renderBrandIcon(BrandPaths[Brand.Ceder__s]),
    [Brand.Chivas_Regal]: renderBrandIcon(BrandPaths[Brand.Chivas_Regal]),
    [Brand.Church_Road]: renderBrandIcon(BrandPaths[Brand.Church_Road]),
    [Brand.Clan_Campbell]: renderBrandIcon(BrandPaths[Brand.Clan_Campbell]),
    [Brand.Del_Maguey]: renderBrandIcon(BrandPaths[Brand.Del_Maguey]),
    [Brand.George_Wyndham]: renderBrandIcon(BrandPaths[Brand.George_Wyndham]),
    [Brand.Green_Spot]: renderBrandIcon(BrandPaths[Brand.Green_Spot]),
    [Brand.Havana_Club]: renderBrandIcon(BrandPaths[Brand.Havana_Club]),
    [Brand.Imperial_Blue]: renderBrandIcon(BrandPaths[Brand.Imperial_Blue]),
    [Brand.Imperial]: renderBrandIcon(BrandPaths[Brand.Imperial]),
    [Brand.Italicus]: renderBrandIcon(BrandPaths[Brand.Italicus]),
    [Brand.Jacob__s_Creek]: renderBrandIcon(BrandPaths[Brand.Jacob__s_Creek]),
    [Brand.Jameson]: renderBrandIcon(BrandPaths[Brand.Jameson]),
    [Brand.Jefferson__s]: renderBrandIcon(BrandPaths[Brand.Jefferson__s]),
    [Brand.JP_Wiser]: renderBrandIcon(BrandPaths[Brand.JP_Wiser]),
    [Brand.Kahlua]: renderBrandIcon(BrandPaths[Brand.Kahlua]),
    [Brand.Kenwood]: renderBrandIcon(BrandPaths[Brand.Kenwood]),
    [Brand.KI_NO_BI]: renderBrandIcon(BrandPaths[Brand.KI_NO_BI]),
    [Brand.Lillet]: renderBrandIcon(BrandPaths[Brand.Lillet]),
    [Brand.Long_John]: renderBrandIcon(BrandPaths[Brand.Long_John]),
    [Brand.Lorbe]: renderBrandIcon(BrandPaths[Brand.Lorbe]),
    [Brand.Lot_no_40]: renderBrandIcon(BrandPaths[Brand.Lot_no_40]),
    [Brand.Malfy]: renderBrandIcon(BrandPaths[Brand.Malfy]),
    [Brand.Malibu]: renderBrandIcon(BrandPaths[Brand.Malibu]),
    [Brand.Martell]: renderBrandIcon(BrandPaths[Brand.Martell]),
    [Brand.Method_And_Madness]: renderBrandIcon(BrandPaths[Brand.Method_And_Madness]),
    [Brand.Midleton_Very_Rare]: renderBrandIcon(BrandPaths[Brand.Midleton_Very_Rare]),
    [Brand.Minttu]: renderBrandIcon(BrandPaths[Brand.Minttu]),
    [Brand.Monkey_47]: renderBrandIcon(BrandPaths[Brand.Monkey_47]),
    [Brand.GH_Mumm]: renderBrandIcon(BrandPaths[Brand.GH_Mumm]),
    [Brand.Olmeca]: renderBrandIcon(BrandPaths[Brand.Olmeca]),
    [Brand.Ostoya]: renderBrandIcon(BrandPaths[Brand.Ostoya]),
    [Brand.Passport_Scotch]: renderBrandIcon(BrandPaths[Brand.Passport_Scotch]),
    [Brand.Pastis_51]: renderBrandIcon(BrandPaths[Brand.Pastis_51]),
    [Brand.Pernod]: renderBrandIcon(BrandPaths[Brand.Pernod]),
    [Brand.Perrier_Jouet]: renderBrandIcon(BrandPaths[Brand.Perrier_Jouet]),
    [Brand.Pipers_100]: renderBrandIcon(BrandPaths[Brand.Pipers_100]),
    [Brand.Plymouth_gin]: renderBrandIcon(BrandPaths[Brand.Plymouth_gin]),
    [Brand.Powers]: renderBrandIcon(BrandPaths[Brand.Powers]),
    [Brand.Rabbit_Hole]: renderBrandIcon(BrandPaths[Brand.Rabbit_Hole]),
    [Brand.Ramazzotti]: renderBrandIcon(BrandPaths[Brand.Ramazzotti]),
    [Brand.Redbreast]: renderBrandIcon(BrandPaths[Brand.Redbreast]),
    [Brand.Ricard]: renderBrandIcon(BrandPaths[Brand.Ricard]),
    [Brand.Royal_Salute]: renderBrandIcon(BrandPaths[Brand.Royal_Salute]),
    [Brand.Royal_Stag]: renderBrandIcon(BrandPaths[Brand.Royal_Stag]),
    [Brand.Scapa]: renderBrandIcon(BrandPaths[Brand.Scapa]),
    [Brand.Seagrams_Gin]: renderBrandIcon(BrandPaths[Brand.Seagrams_Gin]),
    [Brand.Secret_Speyside]: renderBrandIcon(BrandPaths[Brand.Secret_Speyside]),
    [Brand.Smooth_Ambler]: renderBrandIcon(BrandPaths[Brand.Smooth_Ambler]),
    [Brand.Something_Special]: renderBrandIcon(BrandPaths[Brand.Something_Special]),
    [Brand.St_Hugo]: renderBrandIcon(BrandPaths[Brand.St_Hugo]),
    [Brand.Stoneleigh]: renderBrandIcon(BrandPaths[Brand.Stoneleigh]),
    [Brand.Suze]: renderBrandIcon(BrandPaths[Brand.Suze]),
    [Brand.The_Glenlivet]: renderBrandIcon(BrandPaths[Brand.The_Glenlivet]),
    [Brand.Tx]: renderBrandIcon(BrandPaths[Brand.Tx]),
    [Brand.Ungava]: renderBrandIcon(BrandPaths[Brand.Ungava]),
    [Brand.Wyborowa_Wodka]: renderBrandIcon(BrandPaths[Brand.Wyborowa_Wodka]),
    [Brand.Ysios]: renderBrandIcon(BrandPaths[Brand.Ysios]),
}