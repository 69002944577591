import {useCallback, useContext, useEffect, useMemo, useReducer} from "react";
import {AppDispatchMessageType} from "./App.interfaces";
import {AppView, IAppView} from "interfaces";
import {AppViews} from "const";
import {initialState, reducer} from "./App.reducer";
import {ITeamsContext} from "../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../services/TeamsContext";
import {IUserContext, UserContext} from "../services/UserContext";
import {UserRole} from "../interfaces/UserRole";
import {useNavigate} from "react-router-dom";
import {ILocationContext} from "../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../services/LocationContext/LocationContext";

export const useApp = () => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const [state, dispatch] = useReducer(reducer, initialState(userContext.currentUser?.applicationSettings.homepage));
    const navigate = useNavigate();

    useEffect(function onMount() {
        navigate(state.currentView.path);
    }, []);

    useEffect(function onPathChange() {
        const view = Object.values(AppViews).find(v => v.path === window.location.pathname);
        if (!view || state.currentView.id === view.id) return;
        locationContext.clearLocations();
        dispatch({type: AppDispatchMessageType.ChangeAppView, view});
    }, [window.location.pathname]);

    const handleChangeView = useCallback((view: IAppView) => {
        dispatch({type: AppDispatchMessageType.ChangeAppView, view});
    }, []);

    const handleToggleOpenMenu = useCallback(() => {
        dispatch({type: AppDispatchMessageType.ToggleOpenMenu});
    }, []);

    const hiddenViews = useMemo(() => {
        if (userContext.currentUser?.role !== UserRole.Basic) return [];
        return [AppView.Buildings, AppView.Dashboard];
    }, []);

    const userName = userContext.currentUser?.displayName ?? "";

    const userId = teamsContext.userId;

    return {
        ...state,
        handleChangeView,
        views: Object.values(AppViews),
        handleToggleOpenMenu,
        isOnMobile: teamsContext.isOnMobile,
        hiddenViews,
        userName,
        userId
    }
}