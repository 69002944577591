import React, {memo} from "react";
import {Flex} from "@fluentui/react-northstar";
import "./FlagshipBadge.styles.scss";
import {IFlagshipBadgeProps} from "./FlagshipBadge.interfaces";
import {useFlagshipBadge} from "./FlagshipBadge.hook";
import {FlagshipIcon} from "../../assets/icons";

const propsAreEqual = (prevProps: Readonly<IFlagshipBadgeProps>, nextProps: Readonly<IFlagshipBadgeProps>) => {
    return prevProps.brand === nextProps.brand;
}

export const FlagshipBadge = memo((props: IFlagshipBadgeProps) => {
    const logic = useFlagshipBadge(props);

    const brandIcon = <logic.brand.element height={25} width={25} className={"flagship-brand-icon"}/>

    return (
        <Flex className={"pos-relative"} title={logic.brand.formattedName}>
            <FlagshipIcon className={"flagship"}/>
            <Flex fill hAlign={"center"} vAlign={"center"} className={"fill-absolute"}>
                {brandIcon}
            </Flex>
        </Flex>
    )
}, propsAreEqual)