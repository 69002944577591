import React from "react";
import {KpiLogic} from "./Kpi.logic";
import {KpiRendering} from "./Kpi.rendering";
import {KpiPropsUnion} from "./Kpi.interfaces";

const Kpi = (props: KpiPropsUnion): JSX.Element => {
    return (
        <KpiLogic {...props}>
            {logic => <KpiRendering {...logic}/>}
        </KpiLogic>
    )
}

export default Kpi;