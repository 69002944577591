import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, Payload, State} from "./BuildingKpiDetails.interfaces";

const reducer = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof State;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const getInitialState = (): State => {
    const state: State = {
        loaded: false,
        kpis: undefined,
        showPublicReviewsSideView: false,
        showPrivateReviewsSideView: false,
    };
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(), []);
    const stateRef = useRef<Readonly<State>>(initialState);
    const propsRef = useRef<Readonly<LogicProps>>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reducer(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef};
}