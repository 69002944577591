import * as React from "react";
import {useCallback, useContext} from "react";
import moment from "moment";
import "./ActivityCard.styles.scss";
import {useNavigate} from "react-router-dom";
import {Card, Flex, Text, TextArea} from "@fluentui/react-northstar";
import {IActivityCardProps} from "./ActivityCard.interfaces";
import {ActivityType, IActivity} from "interfaces";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {IUserContext, UserContext} from "../../services/UserContext";
import {UserPreview} from "../UserPreview/UserPreview";
import {PopupPicture} from "../PopupPicture/PopupPicture";
import languageObject from "../../translations";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import MarkdownView from "react-showdown";

export const ActivityCard = (props: IActivityCardProps) => {
    const userContext = useContext<IUserContext>(UserContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const navigate = useNavigate();
    const translate = useTranslation();
    const {type, userId, id, date, picture} = props.activity;

    const buildingName = props.activity.buildingName ?? "?";

    const user = userContext.list.find(u => u.id === userId);

    const time = <Text color={"brand"} weight={"semibold"} truncated content={moment(date).calendar()}/>;

    const avatar = <UserPreview userId={userId}/>

    const userName = <Text weight={"bold"} content={user?.displayName}/>;

    const locationName = <Text styles={{color: "gray"}} content={buildingName}/>;

    const renderHeader = (type: ActivityType): JSX.Element | null => {
        switch (type) {
            case ActivityType.Review:
                return (
                    <Flex gap={"gap.small"} space={"between"} vAlign={"start"}>
                        <Flex fill gap={"gap.small"} vAlign={"center"}>
                            <Flex>
                                {avatar}
                            </Flex>
                            <Flex fill column>
                                {userName}
                                {locationName}
                            </Flex>
                        </Flex>
                        <Flex>
                            {time}
                        </Flex>
                    </Flex>
                )
            case ActivityType.EventCreated:
                return (
                    <Flex gap={"gap.small"} space={"between"} vAlign={"start"}>
                        <Flex className={"overflow-hidden"} fill gap={"gap.small"} vAlign={"center"}>
                            <Text truncated weight={"bold"} content={languageObject.translate("NewEvent")}/>
                        </Flex>
                        <Flex>
                            {time}
                        </Flex>
                    </Flex>
                )
            default:
                return null;
        }
    }

    const renderContent = (type: ActivityType): JSX.Element | null => {
        switch (type) {
            case ActivityType.Review:
                if (!user) return null;
                const review = props.activity.review ?? "";
                if (!review) return null;
                const popupPicture = !picture ? null :
                    <PopupPicture src={picture} maxHeightDesktop={150} maxHeightMobile={100}/>;
                const translationParameters: Record<string, string | number> = {
                    userName: user?.displayName,
                    locationName: buildingName
                }
                return (
                    <Flex column gap={"gap.small"}>
                        <Text content={translate("UserSharedPrivateReviewOn", translationParameters)}/>
                        <TextArea fluid readOnly value={review}/>
                        {popupPicture}
                    </Flex>
                );
            case ActivityType.EventCreated:
                const eventDescription = props.activity.review ?? "";
                const eventPicture = !picture ? null :
                    <PopupPicture src={picture} maxHeightDesktop={150} maxHeightMobile={100}/>;
                return (
                    <Flex column gap={"gap.small"}>
                        <Text content={buildingName}/>
                        {!eventDescription ? null :
                            <MarkdownView
                                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                                className={"notes-viewer"}
                                markdown={eventDescription}
                            />
                        }
                        {eventPicture}
                    </Flex>
                );
            default:
                return null;
        }
    }

    const handleClick = useCallback((activity: IActivity) => () => {
        if (activity.type !== ActivityType.EventCreated) return;
        navigate("/events");
    }, []);

    const content = renderContent(type);
    if (content === null) return null;

    const header = renderHeader(type);

    const className = [
        "activity-card",
        props.activity.type === ActivityType.EventCreated ? "cursor-pointer" : "",
    ].join(" ");

    return (
        <Flex key={"activity-item-" + id}>
            <Card fluid elevated className={className} onClick={handleClick(props.activity)}>
                <Flex column gap={"gap.small"}>
                    {header}
                    {content}
                </Flex>
            </Card>
        </Flex>
    )
}