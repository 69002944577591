import "./App.styles.scss";
import {Route, Routes} from 'react-router-dom';
import {Banner, ErrorBoundary} from "components";
import {Menu} from "components/Menu/Menu";
import {useApp} from "./App.hook";
import React, {useCallback, useMemo} from "react";
import {Flex} from "@fluentui/react-northstar";
import TelemetryProvider from "../services/TelemetryProvider/TelemetryProvider";

const App = () => {
    const logic = useApp();

    const withMenu = useCallback((children: JSX.Element) => (
        <Flex fill>
            <Menu
                isOnMobile={logic.isOnMobile}
                open={logic.openMenu}
                onClose={logic.handleToggleOpenMenu}
                onChangeView={logic.handleChangeView}
                currentView={logic.currentView.id}
                hiddenViews={logic.hiddenViews}
                userName={logic.userName}
            />
            <Flex fill column className={"overflow-scroll"}>
                <Flex className={"y-padding x-padding"}>
                    <Banner
                        titleTranslationId={logic.currentView.translationId}
                        handleToggleOpenMenu={logic.handleToggleOpenMenu}
                    />
                </Flex>
                <Flex fill>
                    {children}
                </Flex>
            </Flex>
        </Flex>
    ), [logic.currentView, logic.openMenu, logic.hiddenViews, logic.userName])

    const routes = useMemo(() => (
        <Routes>
            {logic.views.map(view => <Route key={view.path} path={view.path} element={withMenu(<view.element/>)}/>)}
        </Routes>
    ), [logic.views, withMenu])

    return (
        <TelemetryProvider userId={logic.userId}>
            <ErrorBoundary>
                {routes}
            </ErrorBoundary>
        </TelemetryProvider>
    );
}

export default App;