import {Alert, BuildingIcon, Button, EditIcon, Flex, MenuIcon, Text} from "@fluentui/react-northstar";
import React, {memo} from "react";
import "./Banner.style.scss";
import {IBannerProps} from "./Banner.interfaces";
import {ActivityModal} from "components";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {useBanner} from "./Banner.hook";
import translations from "../../translations";

const propsAreEqual = (prevProps: Readonly<IBannerProps>, nextProps: Readonly<IBannerProps>) => {
    return prevProps.titleTranslationId === nextProps.titleTranslationId;
}

export const Banner = memo((props: IBannerProps) => {
    const logic = useBanner(props);
    const translate = useTranslation();

    const menuButton = (
        <Button
            className={"menu-button"}
            icon={<MenuIcon className={"cursor-pointer"} size={"large"}/>}
            iconOnly
            text
        />
    )

    const title = (
        <Text
            styles={{whiteSpace: "nowrap"}}
            size={"large"}
            weight={"semibold"}
            content={translate(props.titleTranslationId)}
        />
    )

    const activityTrigger = logic.isOnMobile ? false : (
        <Flex>
            <ActivityModal/>
        </Flex>
    )

    const sectorManagerNotification = !logic.showSectorManagerNotification ?
        false : renderSectorManagerNotification(logic.incompleteBuildingsCount, logic.isOnMobile, logic.handleNavigateToIncompleteBuildings);

    const classNames = [
        "x-padding",
        "banner" + (logic.isOnMobile ? "-mobile" : ""),
        "no-select"
    ];

    return (
        <Flex gap={"gap.small"} space={"between"} className={classNames.join(" ")}>
            <Flex vAlign={"center"} {...(!logic.isOnMobile && {gap: "gap.smaller"})}>
                <Flex vAlign={"center"} onClick={props.handleToggleOpenMenu}>
                    {menuButton}
                    {title}
                </Flex>
            </Flex>
            <Flex gap={"gap.smaller"}>
                {sectorManagerNotification}
                {activityTrigger}
            </Flex>
        </Flex>
    )
}, propsAreEqual);

const renderSectorManagerNotification = (
    incompleteBuildingsCount: number,
    isOnMobile: boolean,
    onClick: () => void,
) => {
    const translationId = incompleteBuildingsCount > 1 ? "XBuildingsToComplete" : "XBuildingToComplete";
    const text = isOnMobile ? incompleteBuildingsCount + "" : translations.translate(translationId, {count: incompleteBuildingsCount});
    return (
        <Flex>
            <Alert
                visible
                className={"cursor-pointer"}
                content={
                    <Flex gap={"gap.smaller"} vAlign={"center"}>
                        <EditIcon outline/>
                        {!isOnMobile ? null : <BuildingIcon outline/>}
                        <Text content={text}/>
                    </Flex>
                }
                warning
                onClick={onClick}
            />
        </Flex>
    )
}