import * as React from "react";
import {ReactComponent as CallIconSvg} from "./call.svg";
import {ReactComponent as ItineraryIconSvg} from "./itinerary.svg";
import {ReactComponent as StarIconSvg} from "./star.svg";
import {ReactComponent as MedalIconSvg} from "./medal.svg";
import {ReactComponent as CocktailIconSvg} from "./cocktail.svg";
import {ReactComponent as CutleryIconSvg} from "./cutlery.svg";
import {ReactComponent as LocationIconSvg} from "./location.svg";
import {ReactComponent as MusicIconSvg} from "./music.svg";
import {ReactComponent as WineIconSvg} from "./wine.svg";
import {ReactComponent as BuildingsIconSvg} from "./buildings.svg";
import {ReactComponent as CalendarIconSvg} from "./calendar.svg";
import {ReactComponent as EyeIconSvg} from "./eye.svg";
import {ReactComponent as ListIconSvg} from "./list.svg";
import {ReactComponent as MapIconSvg} from "./map.svg";
import {ReactComponent as CloseIconSvg} from "./close.svg";
import {ReactComponent as SettingsIconSvg} from "./settings.svg";
import {ReactComponent as BellIconSvg} from "./bell.svg";
import {ReactComponent as CheckCircleSvg} from "./check_circle.svg";
import {ReactComponent as EditSvg} from "./edit.svg";
import {ReactComponent as SmileyEditSvg} from "./smiley_edit.svg";
import {ReactComponent as LocationPinSvg} from "./location_pin.svg";
import {ReactComponent as CameraSvg} from "./camera.svg";
import {ReactComponent as ShareSvg} from "./share.svg";
import {ReactComponent as FlagshipSvg} from "./flagship.svg";
import {ReactComponent as GoogleSvg} from "./google.svg";
import {ReactComponent as GoogleMapsSvg} from "./google_maps.svg";
import {ReactComponent as CoffeeSvg} from "./coffee.svg";
import {ReactComponent as EndArrowSvg} from "./endArrow.svg";

export type IIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
export type IconProps = React.SVGProps<SVGSVGElement> & { title?: string };

const renderSvgIcon = (Icon: IIcon) => (props: IconProps): JSX.Element => {
    const propsClone = {...props};
    propsClone.width = propsClone.width ?? 20;
    propsClone.height = propsClone.height ?? 20;
    if (!propsClone.fill) propsClone.className += " icon-auto-fill";
    return <Icon {...propsClone}/>;
}

export const CallIcon = renderSvgIcon(CallIconSvg);
export const ItineraryIcon = renderSvgIcon(ItineraryIconSvg);
export const StarIcon = renderSvgIcon(StarIconSvg);
export const MedalIcon = renderSvgIcon(MedalIconSvg);
export const CocktailIcon = renderSvgIcon(CocktailIconSvg);
export const CutleryIcon = renderSvgIcon(CutleryIconSvg);
export const LocationIcon = renderSvgIcon(LocationIconSvg);
export const MusicIcon = renderSvgIcon(MusicIconSvg);
export const WineIcon = renderSvgIcon(WineIconSvg);
export const BuildingsIcon = renderSvgIcon(BuildingsIconSvg);
export const CalendarIcon = renderSvgIcon(CalendarIconSvg);
export const EyeIcon = renderSvgIcon(EyeIconSvg);
export const ListIcon = renderSvgIcon(ListIconSvg);
export const MapIcon = renderSvgIcon(MapIconSvg);
export const CloseIcon = renderSvgIcon(CloseIconSvg);
export const SettingsIcon = renderSvgIcon(SettingsIconSvg);
export const BellIcon = renderSvgIcon(BellIconSvg);
export const CheckCircleIcon = renderSvgIcon(CheckCircleSvg);
export const EditIcon = renderSvgIcon(EditSvg);
export const SmileyEditIcon = renderSvgIcon(SmileyEditSvg);
export const LocationPinIcon = renderSvgIcon(LocationPinSvg);
export const CameraIcon = renderSvgIcon(CameraSvg);
export const ShareIcon = renderSvgIcon(ShareSvg);
export const FlagshipIcon = renderSvgIcon(FlagshipSvg);
export const GoogleIcon = renderSvgIcon(GoogleSvg);
export const GoogleMapsIcon = renderSvgIcon(GoogleMapsSvg);
export const CoffeeIcon = renderSvgIcon(CoffeeSvg);
export const EndArrowIcon = renderSvgIcon(EndArrowSvg);