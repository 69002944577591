import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {ShorthandCollection, ShorthandValue} from "@fluentui/react-northstar/dist/es/types";
import {Button, Checkbox, Flex, Input, MenuItemProps, PopupProps, RetryIcon, Text} from "@fluentui/react-northstar";
import {FilterDispatchMessageType, IFilterItem, IFilterLogic, IFilterProps} from "./Filter.interfaces";
import {initialState, reducer} from "./Filter.reducer";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {Immutable} from "../../interfaces/Immutable";

export const useFilter = (props: IFilterProps): IFilterLogic => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const translate = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const propsRef = useRef<IFilterProps>(props);

    propsRef.current = {...props};

    const {isOnMobile} = teamsContext;

    useEffect(function handleCloseOnOutsideClick() {
        if (isOnMobile || !state.open) return;
        document.body.addEventListener("click", handleClose);
        return () => document.body.removeEventListener("click", handleClose);
    }, [state.open]);

    const handleOpen = useCallback(() => {
        dispatch({type: FilterDispatchMessageType.Open});
    }, [])

    const handleClose = useCallback(() => {
        dispatch({type: FilterDispatchMessageType.Close});
    }, [])

    const handleToggleOpen = useCallback(() => {
        dispatch({type: FilterDispatchMessageType.ToggleOpen});
    }, [])

    const handlePopupHoverOpenChange = useCallback((e: React.SyntheticEvent, data?: PopupProps) => {
        if (e.type !== "mouseleave") return;
        if (data?.open) return;
        handleClose();
    }, []);

    const findItemInList = useCallback((items: Immutable<Array<IFilterItem>>, itemKey: string): Immutable<IFilterItem | undefined> => {
        let foundItem: Immutable<IFilterItem | undefined> = undefined;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (!item) continue;
            if (!item.menu && item.key === itemKey) foundItem = item;
            else if (!!item.menu) {
                const menuItem = findItemInList(item.menu, itemKey);
                if (!!menuItem) foundItem = menuItem;
            }
            if (!!foundItem) break;
        }
        return foundItem;
    }, [])

    const clearFilters = useCallback((items: Immutable<Array<IFilterItem>>): Array<IFilterItem> => {
        return cloneItems(items).map(item => {
            if (!item.menu) {
                item.isChecked = false;
                return item;
            }
            item.menu = clearFilters(item.menu);
            return item;
        });
    }, []);

    const handleClearFilters: IFilterLogic["handleClearFilters"] = useCallback((e) => {
        e.stopPropagation();
        propsRef.current.onUpdateItems(clearFilters([...propsRef.current.items]));
    }, []);

    const handleToggleItem: IFilterLogic["handleToggleItem"] = useCallback((itemKey) => (e) => {
        e.stopPropagation();
        const newItems = cloneItems(propsRef.current.items);
        const item = findItemInList(newItems, itemKey) as IFilterItem;
        if (!item) throw new Error("Can't find filter item with key : " + itemKey);
        item.isChecked = !item.isChecked;
        propsRef.current.onUpdateItems(newItems);
    }, []);

    const handleFilterItemInputValueChange: IFilterLogic["handleFilterItemInputValueChange"] = useCallback((itemKey) => (e, data) => {
        const value = data?.value ?? "";
        const newItems = cloneItems(propsRef.current.items);
        const item = findItemInList(newItems, itemKey) as IFilterItem;
        if (!item) throw new Error("Can't find filter item with key : " + itemKey);
        if (!item.input) throw new Error("Can't update filter item input value with key : " + itemKey);
        if (!!item.input.validate && !item.input.validate(value)) return;
        item.input.value = value;
        propsRef.current.onUpdateItems(newItems);
    }, []);

    const formatItem = useCallback((item: Immutable<IFilterItem>) => {
        const formattedItem: ShorthandValue<MenuItemProps> = {key: item.key};
        if (!!item.menu) {
            formattedItem.content = item.label;
            formattedItem.icon = item.icon;
            formattedItem.on = "hover";
            formattedItem.menu = {
                items: item.menu.map(formatItem),
                ...(teamsContext.isOnMobile && {
                    popper: {align: "end", position: "below"}
                })
            };
            return formattedItem;
        }
        const unitWidth = (!!item.input?.unit ? item.input.unit.width : 0) + 15;
        const inputMaxWidth = unitWidth + 80;
        formattedItem.onClick = handleToggleItem(item.key);
        formattedItem.children = (
            <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    {item.icon}
                    <Text content={item.label}/>
                </Flex>
                {!item.input ? null :
                    <Flex
                        fill className={"filter-input-container"}
                        styles={{maxWidth: inputMaxWidth + "px"}}
                        onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => e.stopPropagation()}>
                        <Input
                            type={item.input.type ?? ""}
                            fluid
                            value={item.input.value}
                            input={{styles: {paddingRight: unitWidth + "px"}}}
                            className={"filter-input"}
                            icon={<Text styles={{color: "grey"}} content={item.input.unit.label}/>}
                            onChange={handleFilterItemInputValueChange(item.key)}
                        />
                    </Flex>
                }
                <Checkbox labelPosition={"start"} checked={item.isChecked}/>
            </Flex>
        )
        return formattedItem;
    }, []);

    const formattedItems = useMemo((): ShorthandCollection<MenuItemProps> => {
        const items: ShorthandCollection<MenuItemProps> = [
            {
                key: "header",
                disabled: true,
                className: "filter-header",
                children: (
                    <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
                        <Text className={"header-title"} weight={"semibold"} content={translate("Filters")}/>
                        <Button
                            styles={{marginRight: "-5px"}}
                            primary
                            text
                            content={translate("Reset")}
                            onClick={handleClearFilters}
                            icon={<RetryIcon/>}
                        />
                    </Flex>
                ),
            },
            {
                key: "divider-1",
                kind: "divider",
                styles: {margin: "5px 0"}
            },
        ];
        items.push(...propsRef.current.items.map(formatItem));
        return items;
    }, [propsRef.current.items]);

    return {
        open: state.open,
        handleOpen,
        handleClose,
        items: formattedItems,
        handleToggleItem,
        handleFilterItemInputValueChange,
        handleClearFilters,
        handleToggleOpen,
        handlePopupHoverOpenChange,
        isOnMobile,
    }
}

export const cloneItems = (items: Immutable<Array<IFilterItem>>): Array<IFilterItem> => {
    return items.map(item => {
        const newItem: IFilterItem = {
            key: item.key,
            input: !item.input ? undefined : {
                value: item.input.value,
                type: item.input.type,
                unit: {
                    label: item.input.unit.label,
                    width: item.input.unit.width
                },
                validate: item.input.validate,
            },
            isChecked: item.isChecked,
            value: item.value,
            icon: item.icon,
            label: item.label,
            menu: !item.menu ? undefined : cloneItems(item.menu),
        }
        return newItem;
    })
}