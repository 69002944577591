import React, {useContext} from "react";
import {LocationViewLogic} from "./LocationView.logic";
import {LocationViewRendering} from "./LocationView.rendering";
import {Props} from "./LocationView.interfaces";
import {IUserContext, UserContext} from "../../services/UserContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

const LocationView = (props: Props): JSX.Element => {
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    return (
        <LocationViewLogic {...props} userContext={userContext} locationContext={locationContext}
                           teamsContext={teamsContext}>
            {logic => <LocationViewRendering {...logic}/>}
        </LocationViewLogic>
    )
}

export default LocationView;