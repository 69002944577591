import React from "react";
import {IAppContextProps} from "./AppContext.interfaces";
import {useConfigurationService} from "../ConfigurationService/ConfigurationService.hook";
import {TeamsContextProvider} from "../TeamsContext/TeamsContextProvider";
import {TeamsContext} from "services/TeamsContext/TeamsContext";
import {UserContextProvider} from "../UserContext/UserContextProvider";
import {UserContext} from "services/UserContext/UserContext";
import {LocationContextProvider} from "../LocationContext/LocationContextProvider";
import {LocationContext} from "services/LocationContext/LocationContext";
import {EventContextProvider} from "../EventContext/EventContextProvider";
import {EventContext} from "services/EventContext/EventContext";
import {ITeamsContext} from "../TeamsContext/TeamsContext.interfaces";
import {IUserContext} from "../UserContext";
import {ILocationContext} from "../LocationContext/LocationContext.interfaces";
import {IEventContext} from "../EventContext";
import {ActivityContextProvider} from "../ActivityContext/ActivityContext.provider";
import {Loader} from "@fluentui/react-northstar";

export const AppContext = (props: IAppContextProps) => {
    const configuration = useConfigurationService();

    const renderChildren = (
        teamsContext: ITeamsContext,
        userContext: IUserContext,
        locationContext: ILocationContext,
        eventContext: IEventContext
    ) => {
        const waitForData = teamsContext.isInTaskModule;
        const isDataLoaded = locationContext.loaded && userContext.loaded && locationContext.loaded && eventContext.loaded;
        if (waitForData && !isDataLoaded) return <Loader styles={{height: "100vh"}}/>;
        return props.children;
    }

    return (
        <TeamsContextProvider configuration={configuration}>
            <TeamsContext.Consumer>
                {teamsContext => !teamsContext.loaded ? null : (
                    <UserContextProvider teamsContext={teamsContext} configuration={configuration.data}>
                        <UserContext.Consumer>
                            {userContext => (
                                <LocationContextProvider teamsContext={teamsContext} userContext={userContext}
                                                         configuration={configuration}>
                                    <LocationContext.Consumer>
                                        {locationContext => (
                                            <EventContextProvider teamsContext={teamsContext}
                                                                  locationContext={locationContext}>
                                                <EventContext.Consumer>
                                                    {eventContext => (
                                                        <ActivityContextProvider teamsContext={teamsContext}
                                                                                 userContext={userContext}>
                                                            {renderChildren(teamsContext, userContext, locationContext, eventContext)}
                                                        </ActivityContextProvider>
                                                    )}
                                                </EventContext.Consumer>
                                            </EventContextProvider>
                                        )}
                                    </LocationContext.Consumer>
                                </LocationContextProvider>
                            )}
                        </UserContext.Consumer>
                    </UserContextProvider>
                )}
            </TeamsContext.Consumer>
        </TeamsContextProvider>
    )
}