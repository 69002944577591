import {ILocationMarkerPreviewProps} from "./LocationMarkerPreview.interfaces";
import "./LocationMarkerPreview.styles.scss";
import {Tooltip} from "@fluentui/react-northstar";
import {LocationPreview} from "../LocationPreview/LocationPreview";
import * as React from "react";
import {memo} from "react";
import {useLocationMarkerPreview} from "./LocationMarkerPreview.hook";

const propsAreEqual = (prevProps: Readonly<ILocationMarkerPreviewProps>, nextProps: Readonly<ILocationMarkerPreviewProps>) => {
    return prevProps.locationId === nextProps.locationId && prevProps.target === nextProps.target;
}

export const LocationMarkerPreview = memo((props: ILocationMarkerPreviewProps) => {
    const logic = useLocationMarkerPreview(props);

    if (!logic.location) return null;

    const preview = (
        <LocationPreview location={logic.location} showDetails={props.showDetails}/>
    )

    const tooltip = !props.target ? null : (
        <Tooltip
            key={props.locationId}
            open
            offset={[0, -3]}
            pointing
            content={{
                className: "location-pin-container",
                content: preview,
            }}
            target={props.target}
        />
    )

    return tooltip;
}, propsAreEqual)