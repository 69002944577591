import {Logic, LogicProps, Props} from "./SuperAdminDashboard.interfaces";
import moment from "moment";
import {CountBuildingData} from "../../../interfaces/Kpis/SuperAdminKpis";

export const SuperAdminDashboardLogic = (props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}

export const useLogic = (props: LogicProps) => {

    const buildingsClicks = props.kpis?.buildingsClicks ?? 0;

    const recommendedBuildingsClicks = props.kpis?.recommendedBuildingsClicks ?? 0;

    const buildingsInFavoritesCount = props.kpis?.buildingsInFavoritesCount ?? 0;

    const createdEventsCount = props.kpis?.createdEventsCount ?? 0;

    const reviewsCount = props.kpis?.reviewsCount ?? 0;

    const listViewClicks = props.kpis?.listViewClicks ?? 0;

    const topBuildingClicksChartData = {
        labels: props.kpis?.topBuildingClicks.map(i => i.name) ?? [],
        values: props.kpis?.topBuildingClicks.map(i => i.count) ?? [],
    }

    const topRecommendedBuildingClicksChartData = {
        labels: props.kpis?.topRecommendedBuildingClicks.map(i => i.name) ?? [],
        values: props.kpis?.topRecommendedBuildingClicks.map(i => i.count) ?? [],
    }

    const topBuildingInFavoritesChartData = {
        labels: props.kpis?.topBuildingInFavorites.map(i => i.name) ?? [],
        values: props.kpis?.topBuildingInFavorites.map(i => i.count) ?? [],
    }

    const topKeywords = props.kpis?.topKeywords ?? [];

    const connectionsCount = props.kpis?.connections.reduce((count, c) => count + c.count, 0) ?? 0;

    const connectionsChartData = {
        labels: props.kpis?.connections.map(i => new Date(i.key).toLocaleDateString(moment.locale(), {
            day: 'numeric',
            month: 'short'
        })) ?? [],
        values: props.kpis?.connections.map(i => i.count) ?? [],
    }

    return {
        ...props,
        buildingsClicks,
        recommendedBuildingsClicks,
        buildingsInFavoritesCount,
        createdEventsCount,
        reviewsCount,
        listViewClicks,
        topBuildingClicksChartData,
        topRecommendedBuildingClicksChartData,
        topBuildingInFavoritesChartData,
        topKeywords,
        connectionsChartData,
        connectionsCount,
        handleClickOnBuildingFromBarChart: handleClickOnBuildingFromBarChart(props.onSelectBuilding),
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleClickOnBuildingFromBarChart = (onSelectBuilding: Props["onSelectBuilding"]) => (data: Array<CountBuildingData> | undefined) => (index: number) => {
    if (!data || index < 0) return;
    const selectedItem = data[index];
    if (!selectedItem) return;
    onSelectBuilding(selectedItem.id, selectedItem.name);
}