import {useCallback, useReducer as ReactReducer, useRef} from "react";
import {Payload, State} from "./Kpi.interfaces";

const initialState: State = {
    selectedInterval: 7,
}

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            default:
                throw new Error("Payload type " + payloadType + " not implemented");
        }
    });
}

export const useReducer = () => {
    const stateRef = useRef<Readonly<State>>({...initialState});
    const [, render] = ReactReducer(() => [], []);

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch};
}