import {IUser} from "interfaces/IUser";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {useCallback, useEffect, useRef, useState} from "react";
import {IApplicationSettings} from "../../views/Settings/Settings.interfaces";
import {AppView, IConfiguration, IGeoPosition} from "../../interfaces";
import {IUserContext, IUserContextState} from "./UserContext.interfaces";
import {GraphService} from "../GraphService";
import {GuidModule} from "../../modules/Guid.module";
import {UserRole} from "../../interfaces/UserRole";
import {UserSettingsApi} from "../../apis/UserSettings/UserSettingsApi";
import {LocationApi} from "../../apis/Location/LocationApi";

const initialState: IUserContextState = {
    list: new Array<IUser>(),
    loaded: false,
    currentUser: undefined,
    isFetchingDataFromParent: false,
    showPermissionPage: false,
}

export const useUserContext = (teamsContext: ITeamsContext, configuration: IConfiguration): IUserContext => {
    const [state, setState] = useState<IUserContextState>(initialState);
    const applicationSettingsRef = useRef<IApplicationSettings>();

    useEffect(function onMount() {
        (async () => {
            if (!teamsContext.loaded) return;
            await fetchCurrentUserData();
        })()
    }, [teamsContext.loaded]);

    const getCurrentUserRole = useCallback((settings: IApplicationSettings) => {
        if (settings.isSuperAdmin) return UserRole.SuperAdmin;
        if (settings.isAdmin) return UserRole.Admin;
        if (settings.isBrandAdmin) return UserRole.BrandAmbassador;
        if (configuration.isSectorManager) return UserRole.SectorManager;
        return UserRole.Basic;
    }, []);

    const getCurrentUserGeoPosition = useCallback(async () => new Promise<IGeoPosition | null>(async resolve => {
        const defaultLocation: IGeoPosition = {lat: 48.852943, lng: 2.337160};
        const isOnDesktop = !teamsContext.isOnMobile;
        const isLocationAvailable = !!window.navigator.geolocation;
        if (isOnDesktop || !isLocationAvailable) {
            const geoPosition = await LocationApi.getGeoPositionFromProxy();
            if (!geoPosition) return resolve(defaultLocation);
            return resolve(geoPosition);
        }
        window.navigator.geolocation.getCurrentPosition(function (position) {
            console.error("Can't get user geolocation");
            if (!("coords" in position)) return resolve(null);
            return resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        }, () => {
            console.error("Can't get user geolocation");
            return resolve(null);
        }, {
            enableHighAccuracy: true,
        });
    }), []);

    const fetchCurrentUserData = async () => {
        const graphData = await GraphService.getUsersDataAsync([teamsContext.userId]);
        if (graphData.length === 0) throw new Error("Can't get connected user data : " + teamsContext.userId);
        const applicationSettings: IApplicationSettings = await UserSettingsApi.getUserSettings(teamsContext.userId);
        applicationSettingsRef.current = {...applicationSettings};
        if (applicationSettings.homepage === AppView.Activity) applicationSettings.homepage = AppView.AroundMe;
        const currentUser: IUser = {
            ...graphData[0]!,
            applicationSettings: applicationSettings,
            role: getCurrentUserRole(applicationSettings),
        }
        const currentUserLocation = await getCurrentUserGeoPosition();
        if (currentUserLocation) currentUser.geoLocation = currentUserLocation;
        setState((prevState): IUserContextState => ({
            ...prevState,
            currentUser,
            loaded: !!currentUserLocation,
            showPermissionPage: !currentUserLocation,
            isFetchingDataFromParent: false,
        }));
    }

    const toggleLocationInFavorite = useCallback((id: string) => {
        return new Promise<void>(async resolve => {
            if (!applicationSettingsRef.current) throw new Error("Application settings are undefined");
            const newSettings: IApplicationSettings = {...applicationSettingsRef.current};
            if (!newSettings) throw new Error("Can't get current user settings");
            let favoriteBuildings = [...(newSettings.favoriteBuildings ?? [])];
            if (favoriteBuildings.includes(id))
                favoriteBuildings = favoriteBuildings.filter(flid => flid !== id);
            else favoriteBuildings.push(id);
            newSettings.favoriteBuildings = favoriteBuildings;
            setState((prevState): IUserContextState => {
                if (!prevState.currentUser) return prevState;
                return ({
                    ...prevState,
                    currentUser: {
                        ...prevState.currentUser,
                        applicationSettings: newSettings,
                    }
                })
            });
            applicationSettingsRef.current = {...newSettings};
            resolve();
            await UserSettingsApi.updateUserSettings(newSettings);
        })
    }, []);

    const updateSettings = useCallback((settings: Partial<IApplicationSettings>) => {
        return new Promise<void>(async resolve => {
            if (!applicationSettingsRef.current) throw new Error("Can't find application settings");
            let newSettings = {...applicationSettingsRef.current};
            if (!newSettings) throw new Error("Can't get current user settings");
            newSettings = {
                ...newSettings,
                ...settings,
            }
            setState((prevState): IUserContextState => {
                if (!prevState.currentUser) throw new Error("Can't find current user");
                const user: IUser = {
                    ...prevState.currentUser,
                    applicationSettings: newSettings,
                };
                return {...prevState, currentUser: user}
            });
            applicationSettingsRef.current = {...newSettings};
            resolve();
            await UserSettingsApi.updateUserSettings(newSettings);
        });
    }, []);

    const fetchUsers = useCallback(async (usersIds: Array<string>) => {
        if (!teamsContext.loaded) return;
        const ids = Array.from(new Set<string>(usersIds)).filter(id => GuidModule.isValidGuid(id));
        if (ids.length === 0) return;
        const users = await GraphService.getUsersDataAsync(ids) as Array<IUser>;
        if (users.length === 0) return;
        setState((prevState): IUserContextState => {
            const newList = [...prevState.list];
            const prevUsersIds = prevState.list.map(u => u.id);
            users.forEach(u => !prevUsersIds.includes(u.id) && newList.push(u));
            return {
                ...prevState,
                list: newList,
            }
        });
    }, [teamsContext.loaded]);

    return {
        ...state,
        updateSettings,
        toggleLocationInFavorite,
        fetchUsers,
    }
}