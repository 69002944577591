import React from "react";
import {SuggestionsDropdownLogic} from "./SuggestionsDropdown.logic";
import {SuggestionsDropdownRendering} from "./SuggestionsDropdown.rendering";
import {Props} from "./SuggestionsDropdown.interfaces";

const SuggestionsDropdown = (props: Props): JSX.Element => {
    return (
        <SuggestionsDropdownLogic {...props}>
            {logic => <SuggestionsDropdownRendering {...logic}/>}
        </SuggestionsDropdownLogic>
    )
}

export default SuggestionsDropdown;