import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, Payload, Props, State} from "./LocationTable.interfaces";
import {Immutable} from "../../interfaces/Immutable";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "toggleSortAscending":
                state.sortAscending = !state.sortAscending;
                break;
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof State;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const updateExternalRef = (externalRef: Props["externalRef"], state: Immutable<State>) => {
    if (!externalRef) return;
    externalRef.current = {...state};
}

const getInitialState = (props: LogicProps): State => {
    const state: State = {
        initialized: false,
        columnSort: "distance",
        sortAscending: true,
        allowMultipleSelection: false,
        visibleBrandsCount: 0,
        visibleItemsCount: 0,
        visibleRange: [0, 0],
        currentPage: 0,
    };
    updateExternalRef(props.externalRef, state);
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Immutable<State>>(initialState);
    const propsRef = useRef<LogicProps>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current as State, Array.isArray(payload) ? payload : [payload]);
        updateExternalRef(props.externalRef, stateRef.current);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef, externalRef: propsRef.current.externalRef};
}