import React, {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, OpenHoursFormRef, OpenHoursRange, Payload, State} from "./OpenHoursForm.interfaces";
import {ILocation, ILocationHours, WeekDay} from "../../../interfaces";
import {getHoursItems} from "./OpenHoursForm.logic";
import {Immutable} from "../../../interfaces/Immutable";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "setRanges":
                state.ranges = payload.value;
                break;
            case "toggleRangeOpenState":
                state.rangesOpenState = {...state.rangesOpenState};
                if (!(payload.rangeId in state.rangesOpenState)) state.rangesOpenState[payload.rangeId] = false;
                state.rangesOpenState[payload.rangeId] = !state.rangesOpenState[payload.rangeId];
                break;
            case "setRangeSelectedDays":
                state.ranges = [...state.ranges];
                const rangeIndex = state.ranges.findIndex(r => r.id === payload.rangeId);
                const previousSelectedDays = state.ranges[rangeIndex]?.selectedDays ?? [];
                if (previousSelectedDays.length > payload.selectedDays.length) {
                    const deletedDays = previousSelectedDays.filter(d => !payload.selectedDays.includes(d));
                    if (state.ranges[rangeIndex]!.timeSlots.length > 0) {
                        let closedDaysRange = state.ranges.find(r => r.timeSlots.length === 0);
                        if (!closedDaysRange) {
                            state.ranges.push({id: state.ranges.length + "", timeSlots: [], selectedDays: []});
                            closedDaysRange = state.ranges.find(r => r.timeSlots.length === 0);
                        }
                        closedDaysRange!.selectedDays.push(...deletedDays);
                    } else {
                        const openDaysRange = [...state.ranges].reverse().find(r => r.timeSlots.length > 0);
                        openDaysRange!.selectedDays.push(...deletedDays);
                    }
                }
                if (payload.selectedDays.length === 0) {
                    state.ranges.splice(rangeIndex, 1);
                    break;
                }
                if (rangeIndex < 0) break;
                state.ranges.forEach(r => r.selectedDays = r.selectedDays.filter(d => !payload.selectedDays.includes(d)));
                state.ranges[rangeIndex]!.selectedDays = payload.selectedDays;
                break;
            case "addRange":
                state.ranges = [...state.ranges];
                const existingRange = state.ranges
                    .find(r => r.timeSlots.map(ts => ts.hours).join(",") === payload.timeSlots.map(ts => ts.hours).join(","));
                if (existingRange) {
                    existingRange.selectedDays.push(...payload.selectedDays.filter(d => !existingRange.selectedDays.includes(d)));
                } else {
                    state.ranges.push({
                        id: state.ranges.length + "",
                        timeSlots: payload.timeSlots,
                        selectedDays: payload.selectedDays
                    });
                }
                break;
            case "removeRange":
                state.ranges = state.ranges.filter(r => r.id !== payload.rangeId);
                break;
            case "addTimeSlot":
                state.ranges = [...state.ranges];
                const rangeToAddTimeSlot = state.ranges.find(r => r.id === payload.rangeId);
                if (rangeToAddTimeSlot) rangeToAddTimeSlot.timeSlots.push({
                    id: rangeToAddTimeSlot.timeSlots.length + "",
                    hours: [undefined, undefined]
                });
                break;
            case "removeTimeSlot":
                state.ranges = [...state.ranges];
                const rangeToRemoveTimeSlot = state.ranges.find(r => r.id === payload.rangeId);
                if (rangeToRemoveTimeSlot)
                    rangeToRemoveTimeSlot.timeSlots = rangeToRemoveTimeSlot.timeSlots.filter(ts => ts.id !== payload.timeSlotId);
                break;
            case "setTimeSlotHour":
                state.ranges = [...state.ranges];
                const rangeToUpdateTimeSlot = state.ranges.find(r => r.id === payload.rangeId);
                if (rangeToUpdateTimeSlot) {
                    const timeSlotToUpdate = rangeToUpdateTimeSlot.timeSlots.find(t => t.id === payload.timeSlotId);
                    if (timeSlotToUpdate) {
                        timeSlotToUpdate.hours[payload.isStart ? 0 : 1] = payload.hour;
                    }
                }
                break;
            default:
                throw new Error("Payload type " + payloadType + " not implemented");
        }
    });
}

const generateRangesFromOpenHours = (openHours: Immutable<ILocation["hours"]>): Array<OpenHoursRange> => {
    const ranges = new Array<OpenHoursRange>();
    const hours = getHoursItems();
    Object.keys(openHours).forEach(key => {
        const day = Number(key) as WeekDay;
        const dayOpenHoursString = openHours[day].flatMap(h => [hours[h[0]!], hours[h[1]!]]).join(",");
        const selectedRange = ranges.find(r => r.timeSlots.map(ts => ts.hours).join(",") === dayOpenHoursString);
        if (!selectedRange) {
            ranges.push({
                id: "range-" + ranges.length,
                selectedDays: [day],
                timeSlots: openHours[day].map((timeSlot, index) => ({
                    id: "timeSlot-" + index,
                    hours: [hours[timeSlot[0]!], hours[timeSlot[1]!]]
                })),
            });
        } else selectedRange.selectedDays.push(day);
    });
    return ranges;
}

const convertRangesToOpenHours = (ranges: State["ranges"]): ILocationHours => {
    const hoursItems = getHoursItems();
    const openHours: ILocationHours = {
        [WeekDay.Monday]: [],
        [WeekDay.Tuesday]: [],
        [WeekDay.Wednesday]: [],
        [WeekDay.Thursday]: [],
        [WeekDay.Friday]: [],
        [WeekDay.Saturday]: [],
        [WeekDay.Sunday]: [],
    };
    Object.values(WeekDay).forEach(day => {
        const rangeWhereDayIn = ranges.find(r => r.selectedDays.includes(day as WeekDay));
        if (rangeWhereDayIn) {
            const hours = rangeWhereDayIn?.timeSlots.map((t): [string, string] | null => {
                const startHour = Object.keys(hoursItems).find(key => hoursItems[key] === t.hours[0]);
                const endHour = Object.keys(hoursItems).find(key => hoursItems[key] === t.hours[1]);
                if (!startHour || !endHour) return null;
                return [startHour, endHour];
            }).filter(i => i) as Array<[string, string]>;
            openHours[day as WeekDay] = hours;
        }
    });
    return openHours;
}

const updateExternalRef = (externalRef: React.MutableRefObject<OpenHoursFormRef | undefined>, state: State) => {
    if (externalRef.current === undefined)
        externalRef.current = {data: undefined};
    externalRef.current!.data = convertRangesToOpenHours(state.ranges);
}

const getInitialState = (props: LogicProps): State => {
    const state: State = {
        ranges: generateRangesFromOpenHours(props.initialHours),
        rangesOpenState: {},
    };
    updateExternalRef(props.externalRef, state);
    return state;
}

export const useReducer = (props: LogicProps) => {
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Readonly<State>>(initialState);
    const [, render] = ReactReducer(() => [], []);

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        updateExternalRef(props.externalRef, stateRef.current);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch};
}