import {ILocationType, LocationType} from "interfaces";
import {CocktailIcon, CoffeeIcon, CutleryIcon, MusicIcon, WineIcon} from "assets/icons";

const nightClub: ILocationType = {
    id: LocationType.NightClub,
    element: MusicIcon,
    translationId: "NightClub",
    shortLabel: "NC",
}

const highEnergyBar: ILocationType = {
    id: LocationType.HighEnergyBar,
    element: CocktailIcon,
    translationId: "HighEnergyBar",
    shortLabel: "HEB",
}

const lowEnergyBar: ILocationType = {
    id: LocationType.LowEnergyBar,
    element: CoffeeIcon,
    translationId: "LowEnergyBar",
    shortLabel: "LEB",
}

const restaurant: ILocationType = {
    id: LocationType.Restaurant,
    element: CutleryIcon,
    translationId: "Restaurant",
    shortLabel: "RES",
}

const wineMerchant: ILocationType = {
    id: LocationType.WineMerchant,
    element: WineIcon,
    translationId: "WineMerchant",
    shortLabel: "WM",
}

export const LocationTypes: Record<LocationType, ILocationType> = {
    [LocationType.NightClub]: nightClub,
    [LocationType.LowEnergyBar]: lowEnergyBar,
    [LocationType.HighEnergyBar]: highEnergyBar,
    [LocationType.Restaurant]: restaurant,
    [LocationType.WineMerchant]: wineMerchant,
}