import {FilterDispatchMessage, FilterDispatchMessageType, IFilterItem, IFilterState} from "./Filter.interfaces";
import {Immutable} from "../../interfaces/Immutable";
import {cloneItems} from "./Filter.hook";

export const initialState: IFilterState = {
    items: [],
    open: false,
}

export const reducer = (prevState: IFilterState, payload: FilterDispatchMessage): IFilterState => {
    const newState: IFilterState = {...prevState};
    switch (payload.type) {
        case FilterDispatchMessageType.InputValueChange:
            newState.items = updateItemValue(prevState.items, payload.itemKey, payload.value);
            return newState;
        case FilterDispatchMessageType.ToggleCheck:
            newState.items = toggleItemCheck(prevState.items, payload.itemKey);
            return newState;
        case FilterDispatchMessageType.Open:
            newState.open = true;
            return newState;
        case FilterDispatchMessageType.Close:
            newState.open = false;
            return newState;
        case FilterDispatchMessageType.ToggleOpen:
            newState.open = !prevState.open;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}

const toggleItemCheck = (prevItems: Immutable<Array<IFilterItem>>, itemKey: string): Array<IFilterItem> => {
    const newItems = cloneItems(prevItems);
    const item = newItems.find(i => i.key === itemKey);
    if (!item) throw new Error("Can't find filter item with key : " + itemKey);
    item.isChecked = !item.isChecked;
    return newItems;
}

const updateItemValue = (prevItems: Immutable<Array<IFilterItem>>, itemKey: string, value: string | number) => {
    const newItems = cloneItems(prevItems);
    const item = newItems.find(i => i.key === itemKey);
    if (!item) throw new Error("Can't find filter item with key : " + itemKey);
    if (!item.input) throw new Error("Can't update filter item input value with key : " + itemKey);
    item.input.value = value;
    return newItems;
}