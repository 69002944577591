import {Logic} from "./SuggestionsDropdown.interfaces";
import "./SuggestionsDropdown.styles.scss";
import {Dropdown, Flex, Loader, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {LocationTypes} from "../../const";
import {GoogleMapsIcon} from "../../assets/icons";

export const SuggestionsDropdownRendering = (logic: Logic) => {

    const searchSuggestionsItems = useMemo(() => logic.suggestions.map(sug => {
        const LocationTypeIconElement = sug.location === null ? null : LocationTypes[sug.location]?.element;
        return {
            key: sug.id,
            content: (
                <Flex fill gap={"gap.smaller"} styles={{padding: "5px 0"}}>
                    {!LocationTypeIconElement ? <GoogleMapsIcon height={20}/> : <LocationTypeIconElement/>}
                    <Text truncated content={sug.name} size={"medium"}/>
                </Flex>
            ),
            value: sug,
        }
    }), [logic.suggestions]);

    return (
        <Dropdown
            fluid
            disabled={logic.disabled}
            styles={{width: "100%"}}
            inverted={logic.inverted}
            clearable={logic.clearable}
            onBlur={logic.handleSearchInputBlur}
            onSearchQueryChange={logic.handleChangeSearchValue}
            onChange={logic.handleSelectSuggestion}
            placeholder={logic.placeholder}
            search={() => searchSuggestionsItems}
            items={searchSuggestionsItems}
            searchQuery={logic.filter}
            {...(searchSuggestionsItems.length === 0 && {open: false})}
            toggleIndicator={logic.isSearching ?
                <Loader styles={{marginTop: "1px"}} size={"small"}/>
                :
                (logic.rightIcon ?? (searchSuggestionsItems.length === 0 ? null : undefined))
            }
            searchInput={{inputRef: logic.handleSetSearchInputRef, disabled: logic.disabled}}
        />
    )
}