import {Logic} from "./LocationView.interfaces";
import "./LocationView.styles.scss";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import React, {useMemo} from "react";
import {Recommendations} from "../Recommendations/Recommendations";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {Toolbar} from "../Toolbar/Toolbar";
import {LocationViewType} from "../../interfaces";
import {LocationPreview} from "../LocationPreview/LocationPreview";
import Map from "../Map/Map";
import {ItemDetails} from "../ItemDetails/ItemDetails";
import {ItemType} from "../ItemDetails/ItemDetails.interfaces";
import LocationTable from "../LocationTable/LocationTable";

export const LocationViewRendering = (logic: Logic) => {
    const translate = useTranslation();

    const recommendations = useMemo(() => !logic.showRecommendations ? null : (
        <Recommendations
            visibleLocations={logic.recommendedLocations}
            showSkeletons={false}
            showLocationDetails={logic.handleShowLocationDetails}
        />
    ), [logic.recommendedLocations, logic.showRecommendations]);

    const toolbar = (
        <Flex className={"x-padding"}>
            <Toolbar
                onFiltersChange={logic.handleUpdateFilterItems}
                filterItems={logic.filterItems}
                locationView={logic.locationView}
                onSearchChange={logic.handleSearchChange}
                onToggleLocationView={logic.handleToggleLocationView}
                searchBarPlaceholder={translate("SearchLocation")}
                onSelectSuggestion={logic.handleSelectSuggestion}
                isSearching={logic.isSearching}
                disableToggleViewButton={logic.disableToggleViewButton}
                fetchSuggestions={!logic.isShowingIncompleteBuildings ? logic.fetchSuggestions : undefined}
                totalCount={logic.totalCount}
            />
        </Flex>
    )

    const locationTable = (logic.isOnMobile || logic.locationView === LocationViewType.Map) ? null : (
        <Flex fill className={"x-padding"}>
            <LocationTable
                externalRef={logic.tableRef}
                show={logic.locationView === LocationViewType.List}
                locations={logic.tableLocations}
                showSkeletons={!logic.areLocationsLoaded}
                onShowDetails={logic.handleShowLocationDetails}
                view={logic.view}
                fetchLocations={logic.handleFetchLocations}
                onInitialized={logic.handleFetchLocations}
            />
        </Flex>
    )

    const locationMobileList = useMemo(() => {
        if (!logic.isOnMobile || logic.locationView === LocationViewType.Map) return null;
        if (!logic.areLocationsLoaded) return (
            <Flex fill vAlign={"center"} hAlign={"center"}>
                <Loader/>
            </Flex>
        )
        const isListEmpty = logic.tableLocations.length === 0;
        return (
            <Flex fill={isListEmpty} column styles={{gap: "20px"}} className={"x-padding"}>
                {isListEmpty ?
                    <Flex fill vAlign={"center"} hAlign={"center"}>
                        <Text content={translate("NoResult")}/>
                    </Flex>
                    :
                    logic.tableLocations.map(loc => (
                        <LocationPreview key={loc.id} location={loc} showDetails={logic.handleShowLocationDetails}/>
                    ))
                }
            </Flex>
        )
    }, [logic.locationView, logic.tableLocations]);

    const map = (
        <Map
            show={logic.locationView === LocationViewType.Map}
            filteredLocations={logic.mapLocations}
            showLocationDetails={logic.handleShowLocationDetails}
            externalRef={logic.mapRef}
            blockFetch={logic.isShowingIncompleteBuildings}
            fetchLocations={logic.handleFetchLocations}
            onToggleFullscreen={logic.render}
        />
    );

    const locationDetails = useMemo(() => (
        <ItemDetails
            itemId={logic.selectedLocationId}
            itemType={ItemType.Location}
            onHide={logic.handleClearSelectedLocation}
        />
    ), [logic.selectedLocationId]);

    return (
        <Flex fill column>
            <Flex fill column gap={"gap.medium"}>
                <Flex column gap={"gap.medium"}>
                    {recommendations}
                    {toolbar}
                </Flex>
                <Flex fill column className={logic.isMapFullscreen ? "" : "pos-relative"}>
                    {locationTable}
                    {locationMobileList}
                    {map}
                </Flex>
            </Flex>
            {locationDetails}
        </Flex>
    )
}