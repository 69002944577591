import {IAppView} from "interfaces";

export interface IAppState {
    currentView: IAppView,
    openMenu: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum AppDispatchMessageType {
    ChangeAppView,
    ToggleOpenMenu,
}

type ChangeAppView = {
    type: AppDispatchMessageType.ChangeAppView,
    view: IAppView,
};

type ToggleOpenMenu = {
    type: AppDispatchMessageType.ToggleOpenMenu,
};

export type AppDispatchMessage = ChangeAppView | ToggleOpenMenu;
