import React, {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, MapRef, Payload, State} from "./Map.interfaces";
import {Immutable} from "../../interfaces/Immutable";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "toggleFullScreen":
                state.showFullscreen = !state.showFullscreen;
                break;
            case "addMapListeners":
                state.mapListeners = [...state.mapListeners];
                state.mapListeners.push(...payload.value);
                break;
            case "addVisibleLocation":
                state.visibleLocations = [...state.visibleLocations];
                state.visibleLocations.push(payload.value);
                break;
            case "addMarkerListeners":
                state.markerListeners = [...state.markerListeners];
                state.markerListeners.push(...payload.value);
                break;
            case "bindMarker":
                state.markers = new Map<string, google.maps.Marker>(state.markers);
                state.markers.set(payload.id, payload.marker);
                break;
            case "clearMarkers":
                state.markers = new Map<string, google.maps.Marker>();
                break;
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof State;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const updateExternalRef = (externalRef: React.MutableRefObject<MapRef | undefined>, state: Immutable<State>) => {
    if (!externalRef.current) {
        externalRef.current = {
            centerToUserPosition: () => 0,
            centerMapToLocations: () => 0,
            clearSelectedSuggestionZoneCircle: () => 0,
            goToGeoPosition: () => 0,
            hideLocationPreview: () => 0,
            isFullscreen: false,
            refreshLocations: () => 0,
            visibleLocationIds: [],
            getMapCenter: () => ({lat: 0, lng: 0}),
            getMapBounds: () => undefined,
            getMapRadius: () => undefined,
        }
    }
    externalRef.current.isFullscreen = state.showFullscreen;
    externalRef.current.visibleLocationIds = state.visibleLocations;
}

const getInitialState = (props: LogicProps): State => {
    const state: State = {
        loaded: false,
        showFullscreen: false,
        visibleLocationPreviewId: "",
        hideLocationPreview: false,
        disableZoomOutButton: false,
        mapListeners: [],
        markers: new Map<string, google.maps.Marker>(),
        markerListeners: [],
        clusterer: undefined,
        clusterMarkers: new Map<string, google.maps.Marker>(),
        visibleLocations: [],
        prevLocationPreviewId: "",
        markerPreviewPosition: [0, 0],
        isDefaultBuildingAlreadyShown: false,
        prevZoom: 0,
        prevBounds: undefined,
        selectedSuggestionBuildingData: undefined,
        selectedSuggestionZoneCircle: undefined,
    };
    updateExternalRef(props.externalRef, state);
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Immutable<State>>(initialState);
    const propsRef = useRef<LogicProps>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current as State, Array.isArray(payload) ? payload : [payload]);
        updateExternalRef(props.externalRef, stateRef.current);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef, externalRef: propsRef.current.externalRef};
}