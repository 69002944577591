import {IFilterItem, IFilterLogic} from "../Filter.interfaces";
import {ShorthandCollection} from "@fluentui/react-northstar/dist/es/types";
import {MenuItemProps} from "@fluentui/react-northstar";
import {Immutable} from "../../../interfaces/Immutable";

export interface IFilterMobileDialogState {
    openMenuIds: Array<string>
}

export interface IFilterMobileDialogProps {
    items: Immutable<Array<IFilterItem>>,
    menuItems: ShorthandCollection<MenuItemProps>,
    show: boolean,
    onHide: () => void,
    handleClearFilters: IFilterLogic["handleClearFilters"],
    handleToggleItem: IFilterLogic["handleToggleItem"],
    handleFilterItemInputValueChange: IFilterLogic["handleFilterItemInputValueChange"]
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum FilterMobileDialogDispatchMessageType {
    ToggleMenu, ClearOpenMenus
}

type ToggleMenu = {
    type: FilterMobileDialogDispatchMessageType.ToggleMenu,
    menuId: string,
};

type ClearOpenMenus = {
    type: FilterMobileDialogDispatchMessageType.ClearOpenMenus
}

export type FilterMobileDialogDispatchMessage = ToggleMenu | ClearOpenMenus;