import {Logic} from "./LineChartKpi.interfaces";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import translations from "../../../../translations";
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {brandColors} from "../../../../const/ThemeCustomizations";
import {Line} from "react-chartjs-2";
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
);

export const LineChartKpiRendering = (logic: Logic) => {
    return (
        <Flex column fill>
            {!logic.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={logic.title}/>
                </Flex>
            )}
            {logic.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill className={"pos-relative"} styles={{marginTop: "20px"}}>
                    <Flex className={"fill-absolute"} vAlign={"center"} hAlign={"center"}>
                        {renderLineChart(logic)}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

const renderLineChart = (logic: Logic) => {
    if (logic.showSkeletons) return null;
    if (logic.noDataAvailable) return (
        <Text content={translations.translate("NoDataAvailable")}/>
    )
    return renderLineChartContent({
        xLabels: logic.labels,
        data: logic.values,
        isLightTheme: logic.isLightTheme,
    });
}

const renderLineChartContent = (data: { xLabels: Array<string>, data: Array<number>, isLightTheme: boolean }) => {
    const chartTextColor = data.isLightTheme ? "#252423" : "white";
    const chartGridColor = data.isLightTheme ? "rgb(237, 235, 233)" : "rgb(91, 91, 91)";
    const chartLabels = data.xLabels;
    const chartOptions: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 6,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 5,
                    stepSize: 1,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
        }
    };
    const chartData: ChartData<"line"> = {
        labels: chartLabels,
        datasets: [{
            data: data.data,
            borderColor: brandColors[800],
            backgroundColor: brandColors[800],
            pointRadius: 5,
        }]
    };
    return <Line options={chartOptions} data={chartData}/>;
}