import React, {memo, useMemo} from "react";
import {
    Alert,
    Button,
    CheckmarkCircleIcon,
    Divider,
    ExclamationTriangleIcon,
    Flex,
    FormDropdown,
    FormLabel,
    Image,
    SaveIcon,
    Text,
    TrashCanIcon,
    UndoIcon
} from "@fluentui/react-northstar";
import "./EditLocationForm.styles.scss";
import {IEditLocationFormProps} from "./EditLocationForm.interfaces";
import {useEditLocationForm} from "./EditLocationForm.hook";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {Carousel} from "../Carousel/Carousel";
import {NotificationDialog} from "../NotificationDialog/NotificationDialog";
import {brandColors} from "../../const/ThemeCustomizations";
import OpenHoursForm from "./OpenHoursForm/OpenHoursForm";
import SuggestionsDropdown from "../SuggestionsDropdown/SuggestionsDropdown";
import {LocationModule} from "../../modules";

const propsAreEqual = (prevProps: Readonly<IEditLocationFormProps>, nextProps: Readonly<IEditLocationFormProps>) => {
    return prevProps.location.id === nextProps.location.id
}

export const EditLocationForm = memo((props: IEditLocationFormProps) => {
    const translate = useTranslation();
    const logic = useEditLocationForm(props);

    const saveButton = useMemo(() => (
        <Flex column gap={"gap.small"} className={"x-padding bottom-padding w-100"}>
            {(logic.isLocationComplete || !logic.geoPosition) ? null :
                <Alert
                    className={"w-100"}
                    warning
                    content={
                        <Flex gap={"gap.small"} vAlign={"center"} hAlign={"center"}>
                            <ExclamationTriangleIcon outline/>
                            <Text content={translate("CantEditBuildingAfterAddressCompleteMessage")}/>
                        </Flex>
                    }
                />
            }
            <Button
                disabled={!logic.canEditBrandAmbassadorFields || !logic.isValid || logic.isUpdatingLocation}
                loading={logic.isUpdatingLocation}
                fluid primary
                icon={<SaveIcon size={"large"} outline/>}
                content={translate("Save")}
                onClick={logic.handleSave}
            />
        </Flex>
    ), [logic.isValid, logic.handleSave, logic.isUpdatingLocation, logic.geoPosition, logic.isLocationComplete]);

    const pictures = useMemo(() => {
        if (!logic.canEditAdminFields || !LocationModule.isLocationComplete(props.location)) return [];
        const pictures = props.location.pictures;
        return pictures.map(url => {
            const isPictureRemoved = logic.removedPictureUrls.includes(url);
            const hideButton = !logic.canEditAdminFields || pictures.length <= 1 || (!isPictureRemoved && logic.removedPictureUrls.length === pictures.length - 1);
            const buttonIcon = isPictureRemoved ? <UndoIcon outline/> : <TrashCanIcon outline/>;
            const buttonAction = isPictureRemoved ? logic.handleRestorePicture(url) : logic.handleRemovePicture(url);
            return (
                <Flex key={"picture-" + url} fill className={"w-100 pos-relative"} styles={{maxHeight: "300px"}}>
                    {hideButton ? null :
                        <Button className={"remove-picture-btn"} icon={buttonIcon} onClick={buttonAction}/>
                    }
                    <Flex fill className={"cursor-pointer"} onClick={logic.handleShowImageFullscreen(url)}>
                        <Image className={isPictureRemoved ? "removed-picture" : ""} fluid src={url}/>
                    </Flex>
                </Flex>
            )
        })
    }, [props.location.pictures, logic.removedPictureUrls]);

    const picturesCarousel = useMemo(() => pictures.length === 0 ? null : (
        <Flex className={"pictures-container"}>
            <Carousel
                key={"pictures-carousel"}
                labelId={"Pictures"}
                items={pictures}
                slidesPerView={1}
                pushMoveButtons
            />
        </Flex>
    ), [pictures]);

    const locationBrandsDropdown = useMemo(() => {
        const value = logic.brandsItems.filter(i => logic.selectedBrands.includes(i.value));
        return (
            <FormDropdown
                fluid
                multiple
                disabled={logic.isUpdatingLocation}
                className={"edit-location-form-brands-dropdown"}
                label={translate("Brands")}
                items={logic.brandsItems}
                value={value}
                placeholder={translate("SelectBrands")}
                onChange={logic.handleChangeSelectedBrands}
            />
        )
    }, [logic.selectedBrands, logic.isUpdatingLocation]);

    const addressDropdown = useMemo(() => (
        <Flex column className={"w-100"} styles={{marginTop: "-10px"}} gap={"gap.smaller"}>
            <Flex column>
                <FormLabel content={<Text>{translate("Address")} <Text color={"red"}>*</Text></Text>}/>
                <SuggestionsDropdown
                    disabled={logic.isUpdatingLocation}
                    placeholder={translate("EnterFullAddress")}
                    fetchSuggestions={logic.handleFetchAddressSuggestions}
                    onSelectSuggestion={logic.handleSelectAddressSuggestion}
                    onSearchChange={logic.handleClearSelectedAddress}
                    initialSearchValue={logic.fullAddress}
                />
            </Flex>
            {!logic.eliteAddress ? null :
                <Flex>
                    <Alert visible info content={translate("EliteAddress") + " : " + logic.eliteAddress}/>
                </Flex>
            }
        </Flex>
    ), [logic.isUpdatingLocation]);

    const notificationDialog = useMemo(() => (
        <NotificationDialog
            show={logic.showNotificationDialog}
            onHide={() => 0}
            title={translate("LocationUpdated")}
            icon={
                <CheckmarkCircleIcon
                    size={"largest"}
                    styles={{color: brandColors[500], transform: "scale(2)", padding: "30px"}}
                />
            }
        />
    ), [logic.showNotificationDialog])

    const detailsForm = logic.hideBuildingDetailsForm ? null : (
        <>
            {logic.buildingDetailsForm.formItems["building"]}
            {logic.buildingDetailsForm.formItems["name"]}
            {logic.buildingDetailsForm.formItems["type"]}
            {logic.buildingDetailsForm.formItems["imageLevel"]}
            {logic.buildingDetailsForm.formItems["priceRange"]}
            {addressDropdown}
            {logic.buildingDetailsForm.formItems["phone"]}
            {logic.buildingDetailsForm.formItems["website"]}
            {logic.buildingDetailsForm.formItems["picture"]}
            {locationBrandsDropdown}
            <Divider/>
            <Text size={"large"} content={translate("OpenHours")}/>
            <OpenHoursForm externalRef={logic.openHoursFormRef} initialHours={logic.initialHours}
                           disabled={logic.isUpdatingLocation}/>
            <Divider/>
        </>
    )

    const customForm = (
        <>
            {logic.buildingCustomForm.formItems["show-section"]}
            {logic.buildingCustomForm.formItems["show"]}
            {logic.geoPosition ? false :
                <Flex>
                    <Alert
                        visible
                        warning
                        icon={<ExclamationTriangleIcon outline/>}
                        content={translate("BuildingVisibilityDisabledMessage")}
                    />
                </Flex>
            }
            {logic.buildingCustomForm.formItems["divider-show"]}
            {logic.buildingCustomForm.formItems["promotion-section"]}
            {logic.buildingCustomForm.formItems["recommendNote"]}
            {logic.buildingCustomForm.formItems["flagship"]}
            {logic.buildingCustomForm.formItems["divider-2"]}
            {logic.buildingCustomForm.formItems["sector-manager-section"]}
            {logic.buildingCustomForm.formItems["emailCds"]}
            {logic.buildingCustomForm.formItems["sectorManagerReview"]}
            {!picturesCarousel ? null : <Divider/>}
        </>
    )

    return (
        <Flex fill column className={"edit-location-form"}>
            <Flex fill className={"pos-relative overflow-scroll"}>
                <Flex fill column className={"fill-absolute"}>
                    <Flex className={"x-padding y-padding"}>
                        <Flex fill column gap={"gap.medium"} className={"form-container"}>
                            {detailsForm}
                            {customForm}
                        </Flex>
                    </Flex>
                    {picturesCarousel}
                </Flex>
            </Flex>
            <Flex column gap={"gap.small"}>
                <Divider styles={{paddingTop: 0}}/>
                {saveButton}
            </Flex>
            {notificationDialog}
        </Flex>
    )
}, propsAreEqual)