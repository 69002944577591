import {memo} from "react";
import {Logic, LogicProps} from "./KeywordsKpi.interfaces";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const KeywordsKpiLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {

    const sortedKeywords = [...props.keywords].sort((a, b) => b.count - a.count);

    return {
        ...props,
        sortedKeywords,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////