import React, {useContext} from "react";
import {DashboardLogic} from "./Dashboard.logic";
import {DashboardRendering} from "./Dashboard.rendering";
import {IUserContext, UserContext} from "../../services/UserContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const Dashboard = (): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);

    return (
        <DashboardLogic userContext={userContext} teamsContext={teamsContext}>
            {logic => <DashboardRendering {...logic}/>}
        </DashboardLogic>
    )
}