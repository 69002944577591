import {DropdownProps, Flex, Text} from "@fluentui/react-northstar";
import * as React from "react";
import {useCallback, useContext, useMemo, useReducer, useRef} from "react";
import {IToolbarProps, ToolbarDispatchMessageType} from "./Toolbar.interfaces";
import {AppView} from "interfaces";
import {AppModule} from "modules";
import {initialState, reducer} from "./Toolbar.reducer";
import {IFilterItem} from "components/Filter/Filter.interfaces";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

let searchTimeout: NodeJS.Timeout | null = null;

export const useToolbar = (props: IToolbarProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const translate = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const searchInputRef = useRef<HTMLInputElement>();
    const cancelOnSearchChangeRef = useRef<boolean>(false);

    const handleUpdateFilterItems = useCallback((items: Array<IFilterItem>) => {
        if (props.onFiltersChange) props.onFiltersChange(items);
    }, []);

    const handleToggleLocationView = useCallback(() => {
        if (props.onToggleLocationView) props.onToggleLocationView();
    }, []);

    const handleChangeSearchValue = useCallback((e: React.SyntheticEvent | null, data: DropdownProps) => {
        if (data.highlightedIndex !== undefined) return;
        const searchFilter = data?.searchQuery + "";
        dispatch({type: ToolbarDispatchMessageType.ChangeSearchFilter, searchFilter});
        if (!props.onSearchChange) return;
        if (!!searchTimeout) clearTimeout(searchTimeout);
        const timeout = !searchFilter ? 0 : 600;
        searchTimeout = setTimeout(() => {
            if (props.onSearchChange) props.onSearchChange(searchFilter);
        }, timeout);
    }, [props.onSearchChange]);

    const handleSelectSuggestion = useCallback((suggestion: ILocationSuggestion) => {
        cancelOnSearchChangeRef.current = true;
        dispatch({type: ToolbarDispatchMessageType.ChangeSearchFilter, searchFilter: suggestion.name});
        if (!!props.onSelectSuggestion) props.onSelectSuggestion(suggestion);
        if (!!searchInputRef.current) searchInputRef.current?.blur();
    }, [props.onSelectSuggestion]);

    const handleSetSearchInputRef = useCallback((ref: HTMLInputElement) => {
        searchInputRef.current = ref;
    }, []);

    const handleSearchInputBlur = useCallback(() => {
        // Fix issue on IOS after blur scroll is stuck
        document.documentElement.scrollTop = 0;
    }, []);

    const handleMoveForward = useCallback(() => {
        if (props.handleMoveForward) props.handleMoveForward();
    }, [props.handleMoveForward])

    const handleMoveBackward = useCallback(() => {
        if (props.handleMoveBackward) props.handleMoveBackward();
    }, [props.handleMoveBackward])

    const appViewDropdownItems = useMemo(() => {
        let views = Object.keys(AppView).filter((key: any) => !isNaN(Number(key))).map(key => Number(key) as AppView);
        const excludedViews = [AppView.Activity, AppView.Settings];
        views = views.filter(key => !excludedViews.includes(key));
        return views.map((key) => {
            const icon = AppModule.getViewIcon(key, 15);
            return ({
                key: "app-view-" + key,
                content: (
                    <Flex gap={"gap.small"} vAlign={"center"}>
                        {icon}
                        <Text size={"medium"} content={translate(AppView[key] ?? "")}/>
                    </Flex>
                ),
                value: key,
            })
        })
    }, []);

    const disableLocationViewButton = props.disableToggleViewButton ?? false;

    const isSearching = state.searchFilter.length > 0 && (props.isSearching ?? false);

    const fetchSuggestions: (filter: string) => Promise<Array<ILocationSuggestion>> = props.fetchSuggestions ?? returnEmptySuggestions;

    return {
        filterItems: props.filterItems,
        appViewDropdownItems,
        searchFilter: state.searchFilter,
        handleChangeSearchValue,
        isSynchronizing: !locationContext.loaded,
        handleUpdateFilterItems,
        showFilterButton: !!props.filterItems && !!props.onFiltersChange,
        showSearchBar: !!props.onSearchChange,
        showLocationViewButton: !!props.onToggleLocationView,
        handleToggleLocationView,
        locationView: props.locationView,
        searchBarPlaceholder: props.searchBarPlaceholder ?? translate("Search"),
        handleMoveForward,
        handleMoveBackward,
        showMoveButtons: !!props.handleMoveBackward && !!props.handleMoveForward,
        disableNextButton: props.disableNextButton ?? false,
        disablePrevButton: props.disablePrevButton ?? false,
        handleSelectSuggestion,
        handleSetSearchInputRef,
        handleSearchInputBlur,
        isSearching,
        disableLocationViewButton,
        fetchSuggestions,
        handleSearchChange: props.onSearchChange,
        totalCount: props.totalCount,
        align: props.align,
        dateRange: props.dateRange,
        handleDateRangeChange: props.onDateRangeChange,
    }
}

const returnEmptySuggestions = () => new Promise<Array<ILocationSuggestion>>(resolve => resolve(new Array<ILocationSuggestion>()));