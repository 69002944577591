import {useLocationContext} from "./LocationContext.hook";
import {LocationContext} from "./LocationContext";
import {ILocationContextProps} from "./LocationContext.interfaces";

export const LocationContextProvider = (props: ILocationContextProps) => {
    const value = useLocationContext(props.teamsContext, props.userContext, props.configuration);

    return (
        <LocationContext.Provider value={value}>
            {props.children}
        </LocationContext.Provider>
    )
}