import {Logic, OpenHoursRange} from "./OpenHoursForm.interfaces";
import "./OpenHoursForm.styles.scss";
import React, {useCallback} from "react";
import {
    AddIcon,
    Button,
    ChevronDownIcon,
    ChevronEndIcon,
    Flex,
    FormDropdown,
    Text,
    TrashCanIcon
} from "@fluentui/react-northstar";
import translations from "../../../translations";
import {WeekDay} from "../../../interfaces";

export const OpenHoursFormRendering = (logic: Logic) => {

    const renderRangeHeader = useCallback((range: OpenHoursRange, isOpen: boolean, disabled: boolean) => {
        const selectedDays = logic.weekDaysItems.filter(d => range.selectedDays.includes(d.value));
        const label = selectedDays.length === logic.weekDaysItems.length ?
            translations.translate("Everyday") :
            selectedDays.map(d => translations.translate(WeekDay[d.value] ?? "")
                .slice(0, logic.isOnMobile ? 3 : 999)).join(", ");
        return (
            <Flex
                className={"cursor-pointer"}
                gap={"gap.smaller"}
                onClick={logic.handleToggleRangeOpenState(range.id)}
                vAlign={"center"}>
                <Button
                    text
                    disabled={disabled}
                    iconOnly
                    icon={isOpen ? <ChevronDownIcon/> : <ChevronEndIcon/>}
                    styles={{marginLeft: "-10px"}}
                />
                <Text weight={"semibold"}
                      content={translations.translate(range.timeSlots.length === 0 ? "Closed" : "Open") + ": "}/>
                <Text content={label}/>
            </Flex>
        )
    }, []);

    const renderRangeSelectedDaysDropdown = useCallback((range: OpenHoursRange) => (
        <FormDropdown
            fluid
            multiple
            className={"open-hours-week-days-dropdown"}
            label={translations.translate("WeekDays")}
            items={logic.weekDaysItems}
            value={logic.weekDaysItems.filter(d => range.selectedDays.includes(d.value))}
            placeholder={translations.translate("SelectWeekDays")}
            onChange={logic.handleChangeRangeSelectedDays(range)}
        />
    ), []);

    const renderRangeTimeSlot = useCallback((range: OpenHoursRange, timeSlot: OpenHoursRange["timeSlots"][0], index: number) => {
        const timeSlotItems = logic.getFilteredTimeSlotItems(range, timeSlot);
        return (
            <Flex column className={"w-100"} key={timeSlot.id}>
                <Text content={translations.translate("TimeSlot") + " n°" + (index + 1)}/>
                <Flex className={"w-100"} gap={"gap.small"} vAlign={"start"}>
                    <FormDropdown
                        fluid
                        className={"w-100"}
                        items={timeSlotItems.startHourItems}
                        value={timeSlotItems.startHourItems.find(h => h.value === timeSlot.hours[0])}
                        placeholder={logic.isOnMobile ? "" : translations.translate("SelectStartHour")}
                        onChange={logic.handleChangeTimeSlotHour(range.id, timeSlot.id, true)}
                    />
                    <FormDropdown
                        fluid
                        className={"w-100"}
                        items={timeSlotItems.endHourItems}
                        value={timeSlotItems.endHourItems.find(h => h.value === timeSlot.hours[1])}
                        placeholder={logic.isOnMobile ? "" : translations.translate("SelectEndHour")}
                        onChange={logic.handleChangeTimeSlotHour(range.id, timeSlot.id, false)}
                    />
                    <Flex>
                        <Button
                            iconOnly text icon={<TrashCanIcon/>}
                            onClick={logic.handleRemoveTimeSlot(range.id, timeSlot.id)}
                        />
                    </Flex>
                </Flex>
            </Flex>
        )
    }, []);

    const renderRangeContent = useCallback((range: OpenHoursRange) => (
        <Flex styles={{paddingLeft: "15px", marginBottom: "20px"}}>
            <Flex fill column gap={"gap.medium"}>
                {renderRangeSelectedDaysDropdown(range)}
                {range.timeSlots.map((timeSlot, index) => renderRangeTimeSlot(range, timeSlot, index))}
                {!logic.canAddTimeSlot(range) ? null :
                    <Button
                        fluid icon={<AddIcon outline/>}
                        content={translations.translate("AddTimeSlot")}
                        onClick={logic.handleAddTimeSlot(range.id)}
                    />
                }
            </Flex>
        </Flex>
    ), []);

    return (
        <Flex fill column className={"no-select"}>
            {logic.ranges.map(range => (
                <Flex key={range.id} column gap={"gap.smaller"} className={"w-100"}>
                    {renderRangeHeader(range, logic.rangesOpenState[range.id] ?? false, logic.disabled)}
                    {!logic.rangesOpenState[range.id] ? null : renderRangeContent(range)}
                </Flex>
            ))}
        </Flex>
    )
}