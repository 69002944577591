import {IGeoPosition, ILocation} from "interfaces";
import {Brand} from "../../interfaces/Brand/Brand";
import {Immutable} from "../../interfaces/Immutable";

export interface IEditLocationFormProps {
    location: Immutable<ILocation>,
    onSaveDone: () => void,
}

export interface IEditLocationFormState {
    isRecommended: boolean,
    hasFlagship: boolean,
    removedPictureUrls: Array<string>,
    isUpdatingLocation: boolean,
    showNotificationDialog: boolean,
    selectedBrands: Array<Brand>,
    fullAddress: string,
    geoPosition: IGeoPosition | null | undefined,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum EditLocationFormDispatchMessageType {
    ToggleRecommendedByPernodRicard,
    ToggleFlagship,
    RemovePicture,
    RestorePicture,
    SetNotificationDialogVisibility,
    SetIsUpdatingLocation,
    SetSelectedBrands,
    SetAddress,
}

type ToggleRecommendedByPernodRicard = {
    type: EditLocationFormDispatchMessageType.ToggleRecommendedByPernodRicard,
};

type ToggleFlagship = {
    type: EditLocationFormDispatchMessageType.ToggleFlagship,
}

type RemovePicture = {
    type: EditLocationFormDispatchMessageType.RemovePicture,
    url: string,
}

type RestorePicture = {
    type: EditLocationFormDispatchMessageType.RestorePicture,
    url: string,
}

type SetNotificationDialogVisibility = {
    type: EditLocationFormDispatchMessageType.SetNotificationDialogVisibility,
    show: boolean,
};

type SetIsUpdatingLocation = {
    type: EditLocationFormDispatchMessageType.SetIsUpdatingLocation,
    value: boolean,
}

type SetSelectedBrands = {
    type: EditLocationFormDispatchMessageType.SetSelectedBrands,
    value: Array<Brand>,
}

type SetAddress = {
    type: EditLocationFormDispatchMessageType.SetAddress,
    fullAdress: string,
    geoPosition: IGeoPosition | null | undefined,
}


export type EditLocationFormDispatchMessage =
    ToggleRecommendedByPernodRicard
    | ToggleFlagship
    | RemovePicture
    | RestorePicture
    | SetNotificationDialogVisibility
    | SetIsUpdatingLocation
    | SetSelectedBrands
    | SetAddress