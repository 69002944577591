import {useCallback, useContext, useReducer} from "react";
import {IPopupPictureProps, PopupPictureDispatchMessageType} from "./PopupPicture.interfaces";
import {initialState, reducer} from "./PopupPicture.reducer";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const usePopupPicture = (props: IPopupPictureProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const {isOnMobile} = teamsContext;

    const handleToggleShowPictureFullscreen = useCallback(() => {
        dispatch({type: PopupPictureDispatchMessageType.ToggleShowPictureFullscreen});
    }, [])

    return {
        ...props,
        ...state,
        handleToggleShowPictureFullscreen,
        isOnMobile
    }
}