import {memo} from "react";
import {Logic, LogicProps} from "./LineChartKpi.interfaces";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const LineChartKpiLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {

    const noDataAvailable = props.values.length === 0;

    return {
        ...props,
        noDataAvailable,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////