import React, {memo, useMemo} from "react";
import {Button, CheckmarkCircleIcon, Divider, Flex, FormDropdown, SaveIcon, Text} from "@fluentui/react-northstar";
import "./EventForm.styles.scss";
import {IEventFormProps} from "./EventForm.interfaces";
import {useEventForm} from "./EventForm.hook";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {NotificationDialog} from "../NotificationDialog/NotificationDialog";
import {brandColors} from "../../const/ThemeCustomizations";

const propsAreEqual = (prevProps: Readonly<IEventFormProps>, nextProps: Readonly<IEventFormProps>) => {
    return prevProps.locationId === nextProps.locationId;
}

export const EventForm = memo((props: IEventFormProps) => {
    const translate = useTranslation();
    const logic = useEventForm(props);

    const saveButton = useMemo(() => (
        <Flex className={"w-100"}>
            <Button
                disabled={!logic.isFormValid || logic.isSendingEvent || !logic.areDatesValid}
                loading={logic.isSendingEvent}
                fluid
                primary
                icon={<SaveIcon size={"large"} outline/>}
                content={translate("Save")}
                onClick={logic.handleSubmit}
            />
        </Flex>
    ), [logic.isFormValid, logic.isSendingEvent, logic.areDatesValid]);

    const favoriteBrandsDropdown = useMemo(() => {
        return (
            <FormDropdown
                fluid
                disabled={logic.isSendingEvent}
                multiple
                label={translate("PartnerBrands")}
                items={logic.brandsItems}
                onChange={logic.handleChangeLinkedBrands}
                placeholder={translate("SelectBrands")}
                defaultValue={logic.defaultSelectedLinkedBrands}
            />
        )
    }, [logic.handleChangeLinkedBrands, logic.isSendingEvent])

    const form = (
        <Flex fill column gap={"gap.medium"} className={"form-container"}>
            {logic.formItems["name"]}
            <Flex gap={"gap.small"} vAlign={"start"}>
                {logic.formItems["startDate"]}
                {logic.formItems["startHour"]}
            </Flex>
            <Flex column>
                <Flex gap={"gap.small"} vAlign={"start"}>
                    {logic.formItems["endDate"]}
                    {logic.formItems["endHour"]}
                </Flex>
                {logic.areDatesValid ? null :
                    <Text color={"red"} content={translate("StartDateGreaterThanEndDate")}/>
                }
            </Flex>
            {favoriteBrandsDropdown}
            {logic.formItems["coverPictureUrl"]}
            <Flex fill>
                {logic.formItems["notes"]}
            </Flex>
            {/* Just for bottom space */}
            <Flex styles={{minHeight: "1px"}}/>
        </Flex>
    )

    const notificationDialog = useMemo(() => (
        <NotificationDialog
            show={logic.showNotificationDialog}
            onHide={() => 0}
            title={translate(!logic.event ? "EventPublished" : "EventUpdated")}
            icon={
                <CheckmarkCircleIcon
                    size={"largest"}
                    styles={{color: brandColors[500], transform: "scale(2)", padding: "30px"}}
                />
            }
        />
    ), [logic.showNotificationDialog])

    return (
        <Flex fill column>
            <Flex fill className={"pos-relative overflow-scroll"}>
                <Flex fill className={"fill-absolute"}>
                    {form}
                </Flex>
            </Flex>
            <Flex column gap={"gap.small"}>
                <Divider styles={{padding: 0}}/>
                {saveButton}
            </Flex>
            {notificationDialog}
        </Flex>
    )
}, propsAreEqual)