import {useCallback, useContext, useRef} from "react";
import {ILocation, LocationViewType} from "interfaces";
import {IUserContext, UserContext} from "services/UserContext";
import {UserRole} from "../../interfaces/UserRole";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {Immutable} from "../../interfaces/Immutable";
import {InRangeRequest} from "../../interfaces/InRangeRequest";
import {ILocationViewRef} from "../../components/LocationView/LocationView.interfaces";

export const useBuildings = () => {
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const locationViewRef = useRef<Immutable<ILocationViewRef>>();

    const userRole = userContext.currentUser?.role;

    const handleFilterLocations = useCallback((locations: Immutable<Array<ILocation>>, view: "map" | "table") => {
        const userId = userContext.currentUser?.id;
        const userRole = userContext.currentUser?.role ?? UserRole.Basic;
        if ([UserRole.SuperAdmin, UserRole.Admin, UserRole.BrandAmbassador].includes(userRole)) return locations;
        if (userRole === UserRole.SectorManager) {
            if (view === "table") return locations.filter(l => l.sectorManagerId === userId);
            return locations;
        }
        return [];
    }, []);

    const handleFetchLocations = useCallback(async (request: InRangeRequest) => {
        const incompleteBuildingsFilter = locationViewRef.current?.filterItems.find(i => i.key === "incomplete-buildings");
        const isShowingIncompleteBuildings = incompleteBuildingsFilter?.isChecked ?? false;
        if (isShowingIncompleteBuildings) {
            locationViewRef.current?.setLocationView(LocationViewType.List);
            return await locationContext.fetchIncompleteLocations(request);
        }
        if (locationViewRef.current?.locationView === LocationViewType.Map)
            return await locationContext.fetchLocations(request);
        return await locationContext.fetchCurrentUserBuildings(request);
    }, []);

    return {
        showAllLocationsInTable: userRole === UserRole.SectorManager,
        handleFilterLocations,
        handleFetchLocations,
        locationViewRef
    }
}