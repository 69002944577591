import {useCallback, useRef, useState} from "react";
import {IEventContextState} from "./EventContext.interfaces";
import {IEvent} from "interfaces/Event/IEvent";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {EventsApi} from "../../apis/Events/EventsApi";
import {v4 as uuidv4} from "uuid";
import {ILocationContext} from "../LocationContext/LocationContext.interfaces";

const initialState: IEventContextState = {
    loaded: false,
    list: new Array<IEvent>(),
    isFetchingDataFromParent: false,
}

export const useEventContext = (teamsContext: ITeamsContext, locationContext: ILocationContext) => {
    const [state, setState] = useState<IEventContextState>(initialState);
    const fetchedBuildingIdsRef = useRef<Array<string>>([]);

    const createEvent = useCallback(async (event: IEvent) => {
        return new Promise<void>(async resolve => {
            const eventWithId = {...event, id: uuidv4()};
            setState((prevState): IEventContextState => ({
                ...prevState,
                list: [...prevState.list, eventWithId],
            }));
            await EventsApi.createEvent(eventWithId);
            resolve();
        });
    }, []);

    const updateEvent = useCallback(async (event: IEvent) => {
        return new Promise<void>(async resolve => {
            setState((prevState): IEventContextState => {
                let list = [...prevState.list];
                list = list.filter(e => e.id !== event.id);
                list.push(event);
                return {...prevState, list};
            });
            await EventsApi.updateEvent(event);
            resolve();
        });
    }, []);

    const areBuildingEventsFetched = useCallback((buildingId: string) => {
        return fetchedBuildingIdsRef.current.includes(buildingId);
    }, []);

    const fetchBuildingEvents = useCallback(async (buildingId: string) => {
        if (fetchedBuildingIdsRef.current.includes(buildingId)) return;
        fetchedBuildingIdsRef.current.push(buildingId);
        const events = await EventsApi.getByBuilding(buildingId);
        setState((prevState): IEventContextState => {
            const newEvents = events.filter(newEvent => !prevState.list.find(existingEvent => existingEvent.id === newEvent.id));
            return {
                ...prevState,
                list: [...prevState.list, ...newEvents],
                loaded: true
            }
        });
    }, []);

    const fetchEventsFromGeoLocationAndDateRange = useCallback(async (settings: {
        lat: number,
        lng: number,
        radius: number,
        start: string,
        end: string
    }) => {
        const data = await EventsApi.getFromGeoPositionAndDateRange(settings.lat, settings.lng, settings.radius, settings.start, settings.end);
        locationContext.setLocationsList(data.buildings);
        setState((prevState): IEventContextState => {
            const newEvents = data.events.filter(newEvent => !prevState.list.find(existingEvent => existingEvent.id === newEvent.id));
            return {
                ...prevState,
                list: [...prevState.list, ...newEvents],
                loaded: true
            }
        });
    }, []);

    return {
        ...state,
        createEvent,
        fetchBuildingEvents,
        fetchEventsFromGeoLocationAndDateRange,
        areBuildingEventsFetched,
        updateEvent
    };
}