import React, {useCallback, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {EventFormDispatchMessageType, IEventFormProps} from "./EventForm.interfaces";
import {DropdownItemProps} from "@fluentui/react-northstar";
import {Brand} from "../../interfaces/Brand/Brand";
import {Brands} from "../../const";
import {CameraIcon} from "../../assets/icons";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {IFormItemValue, useForm} from "@witivio_teamspro/northstar-form";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {EventContext, IEventContext} from "../../services/EventContext";
import {IEvent} from "../../interfaces/Event/IEvent";
import moment from "moment";
import {initialState, reducer} from "./EventForm.reducer";

export const useEventForm = (props: IEventFormProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const eventContext = useContext<IEventContext>(EventContext);
    const translate = useTranslation();
    const linkedBrandsRef = useRef<Array<Brand> | undefined>();
    const prevDataRef = useRef<Record<string, IFormItemValue>>({});
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(function onEventIdChange() {
        reset();
    }, [props.eventId])

    const event = useMemo(() => {
        if (!props.eventId) return;
        return eventContext.list.find(e => e.id === props.eventId);
    }, [props.eventId]);

    const hoursItems = useMemo(() => {
        const items: Record<string, number> = {};
        Array.from({length: 24}).forEach((_, index) => {
            items[moment({hour: index}).format('LT')] = index;
            items[moment({hour: index, minute: 30}).format('LT')] = index + 0.5;
        });
        return items;
    }, []);

    const brandsItems = useMemo((): Array<DropdownItemProps & { value: Brand }> => {
        return Object.values(Brands).map(brand => ({
            key: "brand-" + brand.id,
            value: brand.id,
            header: brand.formattedName,
            className: "brand-dropdown-item",
            image: brand.iconPath
        }));
    }, []);

    const defaultSelectedLinkedBrands = useMemo(() => {
        const brands = Array.from(brandsItems.values());
        return event?.linkedBrand?.map(lb => brands.find(b => b.value === lb));
    }, []);

    const notesHeight = window.innerHeight < 950 ? "200px" : "100%";

    const {formItems, isValid, dataRef, reset} = useForm({
        locale: teamsContext.fullLocale,
        fluid: true,
        readOnly: state.isSendingEvent,
        items: [
            {
                id: "name",
                type: "input",
                label: translate("Name"),
                placeholder: translate("TypeName"),
                required: true,
                initialValue: event?.name,
            },
            {
                id: "startDate",
                type: "datePicker",
                label: translate("StartDate"),
                placeholder: translate("SelectDate"),
                minDate: moment().toDate(),
                required: true,
                initialValue: event?.startDate ?? moment().startOf("day").toISOString(false),
            },
            {
                id: "startHour",
                type: "dropdown",
                label: translate("StartHour"),
                placeholder: translate("SelectHour"),
                items: Object.keys(hoursItems),
                required: true,
                search: true,
                initialValue: !!event?.startDate ? moment(event?.startDate).format("LT") : moment({hour: 19}).format('LT'),
            },
            {
                id: "endDate",
                type: "datePicker",
                label: translate("EndDate"),
                placeholder: translate("SelectDate"),
                minDate: moment().toDate(),
                required: true,
                initialValue: event?.endDate ?? moment().startOf("day").toISOString(false),
            },
            {
                id: "endHour",
                type: "dropdown",
                label: translate("EndHour"),
                placeholder: translate("SelectHour"),
                items: Object.keys(hoursItems),
                required: true,
                search: true,
                initialValue: !!event?.endDate ? moment(event?.endDate).format("LT") : moment({hour: 23}).format('LT'),
            },
            {
                id: "coverPictureUrl",
                type: "imagePicker",
                label: translate("CoverPicture"),
                buttonLabel: translate("SelectPicture"),
                buttonIcon: <CameraIcon/>,
                initialValue: event?.coverPictureUrl
            },
            {
                id: "notes",
                type: "richTextInput",
                label: translate("Notes"),
                maxLength: 3000,
                initialValue: event?.notes,
                height: notesHeight,
            }
        ],
        onDataChange: data => prevDataRef.current = data,
    }, [state.isSendingEvent, notesHeight, event?.id]);

    const checkDatesValidity = useCallback(() => {
        const startHourItemIndex = dataRef.current["startHour"] as string | undefined;
        const endHourItemIndex = dataRef.current["endHour"] as string | undefined;
        if (startHourItemIndex === undefined || endHourItemIndex === undefined) return true;
        const startDateIso = dataRef.current["startDate"] + "";
        const endDateIso = dataRef.current["endDate"] + "";
        const startHour: number = hoursItems[startHourItemIndex] as number ?? 0;
        const startMinutes: number = (startHour - Math.floor(startHour)) * 60;
        const endHour: number = hoursItems[endHourItemIndex] as number ?? 0;
        const endMinutes: number = (endHour - Math.floor(endHour)) * 60;
        const startDate = moment(startDateIso).set({hour: startHour, minute: startMinutes}).valueOf();
        const endDate = moment(endDateIso).set({hour: endHour, minute: endMinutes}).valueOf();
        return !!startDate && !!endDate && startDate < endDate;
    }, []);

    const handleSubmit = useCallback(async () => {
        dispatch({type: EventFormDispatchMessageType.SetIsSendingEvent, value: true});
        const event: IEvent = {
            id: props.eventId ?? "",
            buildingId: props.locationId,
            name: (dataRef.current["name"] ?? "") + "",
            startDate: (dataRef.current["startDate"] ?? "") + "",
            endDate: (dataRef.current["endDate"] ?? "") + "",
            linkedBrand: linkedBrandsRef.current,
            coverPictureUrl: (dataRef.current["coverPictureUrl"] ?? "") + "",
            notes: (dataRef.current["notes"] ?? "") + "",
        }
        const startHourLabel: string = (dataRef.current["startHour"] ?? "") + "";
        const endHourLabel: string = (dataRef.current["endHour"] ?? "") + "";
        const startHour = hoursItems[startHourLabel];
        const endHour = hoursItems[endHourLabel];
        event.startDate = moment(event.startDate).startOf("day").add(startHour, "hour").toISOString(false);
        event.endDate = moment(event.endDate).startOf("day").add(endHour, "hour").toISOString(false);
        if (event.id) await eventContext.updateEvent(event);
        else await eventContext.createEvent(event);
        dispatch({type: EventFormDispatchMessageType.SetIsSendingEvent, value: false});
        dispatch({type: EventFormDispatchMessageType.SetNotificationDialogVisibility, show: true});
        setTimeout(() => {
            dispatch({type: EventFormDispatchMessageType.SetNotificationDialogVisibility, show: false});
            setTimeout(() => {
                props.onSubmitDone();
            }, 200)
        }, 1500)
    }, []);

    const handleChangeLinkedBrands = useCallback(async (e, data) => {
        const items = data?.value as Array<DropdownItemProps & { value: Brand }>;
        const linkedBrands = items.map(item => item.value);
        linkedBrandsRef.current = linkedBrands;
    }, []);

    const areDatesValid = checkDatesValidity();

    return {
        ...state,
        isOnMobile: teamsContext.isOnMobile,
        formItems,
        isFormValid: isValid && state.areDatesValid,
        handleSubmit,
        brandsItems,
        handleChangeLinkedBrands,
        event,
        defaultSelectedLinkedBrands,
        areDatesValid,
    }
}