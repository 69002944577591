import React, {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {ILocationViewRef, LogicProps, Payload, State} from "./LocationView.interfaces";
import {LocationViewType} from "../../interfaces";
import {LocationFilters} from "../../const";
import {Immutable} from "../../interfaces/Immutable";
import {IFilterItem} from "../Filter/Filter.interfaces";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "toggleLocationView":
                state.locationView = state.locationView === LocationViewType.List ?
                    LocationViewType.Map : LocationViewType.List;
                break;
            case "updateFilterItems":
                state.filterItems = [...payload.items];
                break;
            case "setSelectedLocationId":
                state.selectedLocationId = payload.id;
                break;
            case "setIsSearching":
                state.isSearching = payload.value;
                break;
            case "setLocationView":
                state.locationView = payload.value;
                break;
            default:
                throw new Error("Payload type " + payloadType + " not implemented");
        }
    });
}

const updateExternalRef = (externalRef: React.MutableRefObject<ILocationViewRef | undefined> | undefined, state: Immutable<State>) => {
    if (!externalRef) return;
    if (!externalRef.current) {
        externalRef.current = {
            handleShowLocationDetails: () => 0,
            locationView: LocationViewType.Map,
            filterItems: [],
            setLocationView: () => 0,
        }
    }
    externalRef.current!.locationView = state.locationView;
    externalRef.current!.filterItems = state.filterItems as Array<IFilterItem>;
}

const getInitialState = (props: LogicProps): State => {
    const filters = LocationFilters(props.userContext.currentUser?.role);
    const showIncompleteBuildings = !!filters.find(f => f.key === "incomplete-buildings" && f.isChecked);
    const state: State = {
        filterItems: filters,
        locationView: showIncompleteBuildings ? LocationViewType.List : LocationViewType.Map,
        selectedLocationId: "",
        isSearching: showIncompleteBuildings,
    };
    updateExternalRef(props.externalRef, state);
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Immutable<State>>(initialState);
    const propsRef = useRef<Immutable<LogicProps>>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current as State, Array.isArray(payload) ? payload : [payload]);
        updateExternalRef(props.externalRef, stateRef.current);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef, render};
}