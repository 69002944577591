import {Logic} from "./LocationStats.interfaces";
import "./LocationStats.styles.scss";
import {ArrowDownIcon, ArrowUpIcon, Flex, Skeleton, Table, Text,} from "@fluentui/react-northstar";
import React, {useCallback, useMemo} from "react";
import languageObject from "../../../../translations";
import {Immutable} from "../../../../interfaces/Immutable";
import {LocationTypes} from "../../../../const";
import {LocationType} from "../../../../interfaces";
import {KpiModule} from "../../../../modules/KpiModule";
import {BuildingBasicKpis} from "../../../../interfaces/Kpis/BuildingBasicKpis";

export const LocationStatsRendering = (logic: Logic) => {

    const tableHeader = useMemo(() => {
        const getFilterIndicator = (column: string) => {
            if (logic.columnSort !== column) return null;
            return logic.sortAscending ? <ArrowDownIcon/> : <ArrowUpIcon/>
        }
        return logic.tableColumns.map(c => ({
            content: (
                <Flex className={"overflow-hidden no-select " + (c.sortable === false ? "" : "cursor-pointer")}
                      vAlign={"center"}>
                    <Flex vAlign={"center"} gap={"gap.small"} className={"overflow-hidden"}>
                        {c.icon}
                        <Text size={"medium"} weight={"semibold"} truncated
                              content={languageObject.translate(c.label)}/>
                        {getFilterIndicator(c.field)}
                    </Flex>
                </Flex>
            ),
            truncateContent: true,
            styles: {
                minWidth: c.minWidth ?? "unset",
                maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
            },
            key: "header-" + c.field,
            onClick: logic.handleSortColumn(c.field),
            className: "location-table-header-" + c.field,
        }));
    }, [logic.columnSort, logic.sortAscending, logic.tableColumns]);

    const renderField = useCallback((kpi: Immutable<BuildingBasicKpis>, field: string) => {
        switch (field) {
            case "type":
                const TypeIcon = LocationTypes[kpi.type].element;
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}
                          title={languageObject.translate(LocationType[kpi.type] ?? "")}>
                        <TypeIcon/>
                    </Flex>
                )
            case "name":
                return (
                    <Flex fill className={"no-select cursor-pointer"} vAlign={"center"} gap={"gap.smaller"}
                          title={kpi.name}>
                        <Text size={"medium"} content={kpi.name} truncated onClick={logic.handleShowBuildingKpis(kpi)}/>
                    </Flex>
                )
            case "clicks":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"} title={kpi.clicks + ""}>
                        <Text size={"medium"} content={kpi.clicks} truncated/>
                    </Flex>
                )
            case "visits":
                const visits = KpiModule.getTotalVisitsCount(kpi) + "";
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"} title={visits}>
                        <Text size={"medium"} content={visits} truncated/>
                    </Flex>
                )
            case "reviews":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"} title={kpi.reviews + ""}>
                        <Text size={"medium"} content={kpi.reviews} truncated/>
                    </Flex>
                )
            default:
                return null;
        }
    }, [logic.userFavoriteLocationIds]);

    const skeletons = useMemo(() => {
        return Array.from(Array(logic.visibleItemsCount === 0 ? 20 : logic.visibleItemsCount).keys()).map(i => {
            const items: Array<any> = logic.tableColumns.map((c, i) => ({
                key: "skeleton-location-" + i + "-" + c.field,
                styles: {
                    minWidth: c.minWidth ?? "unset",
                    maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
                },
                content: renderLocationFieldSkeleton(i, c.field),
                truncateContent: true,
            }))
            return {
                key: "skeleton-location-" + i,
                items
            };
        })
    }, [logic.visibleItemsCount]);

    const formatKpisForTable = useCallback((kpis: Immutable<Array<BuildingBasicKpis>>): Array<object> => {
        return kpis.map((location) => {
            const items: Array<object> = logic.tableColumns.map(c => ({
                key: "location-" + c.field + "-" + location.id,
                styles: {
                    minWidth: c.minWidth ?? "unset",
                    maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
                },
                content: renderField(location, c.field),
                truncateContent: true,
            }))
            return {
                key: "location-" + location.id,
                items
            };
        });
    }, [renderField]);

    const formattedLocations = logic.showSkeletons
        ? skeletons
        : formatKpisForTable(logic.items);

    const noLocationsMessage = formattedLocations.length > 0 ? null : renderNoLocationMessage();

    return (
        <Flex column fill>
            {!logic.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={logic.title}/>
                </Flex>
            )}
            <Flex fill column gap={"gap.medium"} className={"no-select"}>
                <Flex column fill gap={"gap.medium"} className={"pos-relative"}>
                    <Table
                        ref={logic.handleTableRef}
                        className={"fill-absolute location-stats-table"}
                        header={{items: tableHeader}}
                        rows={formattedLocations}
                    />
                    {noLocationsMessage}
                </Flex>
            </Flex>
        </Flex>
    );
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderNoLocationMessage = () => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"}>
            <Text size={"large"} content={languageObject.translate("NoResult")}
                  styles={{color: "darkgray"}}
            />
        </Flex>
    )
}

const renderLocationFieldSkeleton = (locationIndex: number, field: string) => {
    let skeletonContent: JSX.Element | null;
    switch (field) {
        case "type":
            skeletonContent = (
                <Flex className={"overflow-hidden"} vAlign={"center"} gap={"gap.small"}>
                    <Skeleton.Shape width="20px" height="20px"/>
                    <Skeleton.Line width="100%" height="20px"/>
                </Flex>
            )
            break;
        default:
            skeletonContent = (
                <Skeleton.Line width="100%" height="20px"/>
            )
    }
    return (
        <Skeleton className={"overflow-hidden"} key={"skeleton-location-" + locationIndex + "-" + field}
                  animation="pulse">
            {skeletonContent}
        </Skeleton>
    )
}