import {Logic, LogicProps} from "./SectorManagerDashboard.interfaces";
import {KpiModule} from "../../../modules/KpiModule";
import {SectorManagerBuildingKpis} from "../../../interfaces/Kpis/SectorManagerBuildingKpis";
import moment from "moment/moment";

export const SectorManagerDashboardLogic = (props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}

export const useLogic = (props: LogicProps) => {
    const {onSelectBuilding} = props;

    const visitsCount = getTotalVisitsCount(props.kpis);

    const reviewsCount = getTotalReviewsCount(props.kpis);

    const showSkeletons = props.showSkeletons;

    const buildingsKpis = props.kpis ?? [];

    const buildingsClicks = buildingsKpis.reduce((count, kpi) => count + kpi.clicks, 0);

    const visits = groupKpisVisitsPerDate(props.kpis);

    const visitsPerDayLabels = showSkeletons ? new Array<string>() : getVisitsPerDayLabels(visits);

    const visitsPerDayValues = showSkeletons ? new Array<number>() : visits.map(v => v.visits);

    return {
        visitsCount,
        reviewsCount,
        showSkeletons,
        buildingsKpis,
        visitsPerDayLabels,
        visitsPerDayValues,
        onSelectBuilding,
        buildingsClicks,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getTotalVisitsCount = (kpis: SectorManagerBuildingKpis | undefined): number => {
    return kpis?.reduce((count, kpi) => count + KpiModule.getTotalVisitsCount(kpi), 0) ?? 0;
}

const getTotalReviewsCount = (kpis: SectorManagerBuildingKpis | undefined): number => {
    return kpis?.reduce((count, kpi) => count + kpi.reviews, 0) ?? 0;
}

const getVisitsPerDayLabels = (dates: Array<{ date: string, visits: number }>) => {
    return dates.map(d => new Date(d.date).toLocaleDateString(moment.locale(), {day: 'numeric', month: 'short'}));
}

const groupKpisVisitsPerDate = (kpis: SectorManagerBuildingKpis | undefined) => {
    if (!kpis) return [];
    const visitsPerDate = kpis.reduce((list, kpi) => {
        kpi.visits.forEach(visit => {
            const item = list.find(i => i.date === visit.date);
            if (!item) list.push({...visit});
            else item.visits += visit.visits;
        });
        return list;
    }, new Array<{ date: string, visits: number }>());
    visitsPerDate.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    return visitsPerDate;
}