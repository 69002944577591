import {IBannerProps} from "./Banner.interfaces";
import {UserRole} from "../../interfaces/UserRole";
import {useCallback, useContext} from "react";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {IUserContext, UserContext} from "../../services/UserContext";
import {useNavigate} from "react-router-dom";
import {AppViews} from "../../const";
import {AppView} from "../../interfaces";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useBanner = (props: IBannerProps) => {
    const locationContext = useContext<ILocationContext>(LocationContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const navigate = useNavigate();

    const handleNavigateToIncompleteBuildings = useCallback(() => {
        navigate(AppViews[AppView.Buildings].path + "?showIncomplete=true");
    }, []);

    const isBasicUser = userContext.currentUser?.role === UserRole.Basic;

    const incompleteBuildingsCount = locationContext.incompleteCount;

    const showSectorManagerNotification = incompleteBuildingsCount > 0 &&
        userContext.currentUser?.role === UserRole.SectorManager;

    const isOnMobile = teamsContext.isOnMobile;

    return {
        isBasicUser,
        isOnMobile,
        handleNavigateToIncompleteBuildings,
        showSectorManagerNotification,
        incompleteBuildingsCount
    }
}