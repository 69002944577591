export interface IEventDetailsState {
    showShareExperienceSideView: boolean,
    showLocationDetailsSideView: boolean,
    showEventEditSideView: boolean,
}

export interface IEventDetailsProps {
    eventId: string,
    hideActions?: boolean
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum EventDetailsDispatchMessageType {
    SetExperienceSideViewVisibility, ToggleShowLocationDetailsSideView, ToggleShowEventEditSideView
}

type SetExperienceSideViewVisibility = {
    type: EventDetailsDispatchMessageType.SetExperienceSideViewVisibility,
    value: boolean,
}

type ToggleShowLocationDetailsSideView = {
    type: EventDetailsDispatchMessageType.ToggleShowLocationDetailsSideView,
}

type ToggleShowEventEditSideView = {
    type: EventDetailsDispatchMessageType.ToggleShowEventEditSideView,
}


export type EventDetailsDispatchMessage =
    SetExperienceSideViewVisibility
    | ToggleShowLocationDetailsSideView
    | ToggleShowEventEditSideView
