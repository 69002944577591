import {memo, useEffect, useMemo} from "react";
import {Logic, LogicProps, ReducerData} from "./BuildingKpiDetails.interfaces";
import {useReducer} from "./BuildingKpiDetails.reducer";
import {KpiApi} from "../../../apis/Kpi/kpiApi";
import moment from "moment/moment";
import {IReview} from "../../../interfaces/IReview";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const BuildingKpiDetailsLogic = memo((props: LogicProps): JSX.Element => {
    const reducerData: ReducerData = useReducer(props);
    const logic: Logic = useLogic(reducerData);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (reducerData: ReducerData) => {
    const {state, dispatch, propsRef} = reducerData;

    useEffect(function onBuildingIdChange() {
        fetchBuildingKpis(reducerData)(propsRef.current.buildingId).then();
    }, []);

    const publicReviews = useMemo(() => state.kpis?.reviews ?? [], [state.kpis?.reviews]);

    const privateReviews: IReview[] = useMemo(() => {
        return state.kpis?.reviews.filter(r => r.sectorManagerReview).map(r => ({
            ...r,
            review: r.sectorManagerReview ?? "",
            picture: ""
        })) ?? [];
    }, [state.kpis?.reviews]);

    const visitsChartData = useMemo(() => ({
        labels: state.kpis?.visits.map(i => new Date(i.date).toLocaleDateString(moment.locale(), {
            day: 'numeric',
            month: 'short'
        })) ?? [],
        values: state.kpis?.visits?.map(v => v.visits) ?? [],
    }), [state.kpis?.visits]);

    return {
        ...state,
        reducerData,
        publicReviews,
        privateReviews,
        handleTogglePublicReviewsSideView: handleTogglePublicReviewsSideView(reducerData),
        handleTogglePrivateReviewsSideView: handleTogglePrivateReviewsSideView(reducerData),
        visitsChartData,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const fetchBuildingKpis = ({dispatch, propsRef}: ReducerData) => async (buildingId: string) => {
    dispatch({type: "setLoaded", value: false});
    const kpis = await KpiApi.getBuildingKpis(buildingId, propsRef.current.start, propsRef.current.end);
    if (!kpis) return;
    kpis.visits.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    dispatch([
        {type: "setLoaded", value: true},
        {type: "setKpis", value: kpis},
    ]);
}

const handleTogglePublicReviewsSideView = ({state, dispatch}: ReducerData) => () => {
    dispatch({type: "setShowPublicReviewsSideView", value: !state.showPublicReviewsSideView});
}

const handleTogglePrivateReviewsSideView = ({state, dispatch}: ReducerData) => () => {
    dispatch({type: "setShowPrivateReviewsSideView", value: !state.showPrivateReviewsSideView});
}