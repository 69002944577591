import {useLogic} from "./LocationTable.logic";
import {AppView, ILocation} from "../../interfaces";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {IUserContext} from "../../services/UserContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import React from "react";
import {Immutable} from "../../interfaces/Immutable";

export interface State {
    initialized: boolean,
    columnSort: string | null,
    sortAscending: boolean,
    allowMultipleSelection: boolean,
    visibleBrandsCount: number,
    visibleItemsCount: number,
    visibleRange: [number, number],
    currentPage: number,
}

export interface Props {
    show?: boolean,
    locations: Immutable<Array<ILocation>>,
    showSkeletons: boolean,
    onShowDetails: (locationId: string) => void,
    view: AppView,
    fetchLocations: () => Promise<{ error: boolean, cancelled: boolean }>,
    externalRef?: React.MutableRefObject<Immutable<LocationTableRef> | undefined> | undefined,
    onInitialized?: () => void,
}

export interface ContextProps {
    teamsContext: ITeamsContext,
    userContext: IUserContext,
    locationContext: ILocationContext,
}

export type Logic = Omit<ReturnType<typeof useLogic>, "children">;

export type LogicProps = Immutable<Omit<Props, "externalRef"> & ContextProps & {
    children: (logic: Logic) => JSX.Element,
    externalRef?: React.MutableRefObject<LocationTableRef | undefined> | undefined,
}>;

export interface ILocationTableColumn {
    field: string,
    label: string,
    icon?: JSX.Element,
    maxWidth?: number,
    minWidth?: string,
    sortable?: boolean,
}

export enum LocationTableAction {
    Show, Call, Itinerary, ToggleFavorite
}

export type LocationTableRef = {
    columnSort: string | null,
    sortAscending: boolean,
    allowMultipleSelection: boolean,
    visibleBrandsCount: number,
    visibleItemsCount: number,
    visibleRange: [number, number],
    currentPage: number,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

type ToggleSortAscending = {
    type: "toggleSortAscending",
}

type SetProperty = {
    [K in keyof State]: {
        type: `set${Capitalize<string & K>}`,
        value: State[K],
    };
}[keyof State];

export type Payload =
    SetProperty |
    ToggleSortAscending