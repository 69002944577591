import {Logic} from "./SectorManagerDashboard.interfaces";
import {Flex} from "@fluentui/react-northstar";
import Kpi from "../Kpi/Kpi";
import translations, {languageObject} from "../../../translations";
import React from "react";

export const SectorManagerDashboardRendering = (logic: Logic) => {

    const buildingsListKpi = (
        <Kpi
            title={translations.translate("YourBuildings")}
            type={"locationStats"}
            buildingsKpis={logic.buildingsKpis}
            onSelectBuilding={logic.onSelectBuilding}
            showSkeletons={logic.showSkeletons}
            width={3}
        />
    )

    const buildingsClicksKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("Buildings")}
            value={logic.buildingsClicks}
            subtitle={translations.translate(logic.buildingsClicks <= 1 ? "Click" : "Clicks")}
        />
    )

    const visitsCountKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("VisitsCount")}
            value={logic.visitsCount}
            subtitle={translations.translate(logic.visitsCount <= 1 ? "Visit" : "Visits")}
        />
    )

    const reviewsCountKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("ReviewsCount")}
            value={logic.reviewsCount}
            subtitle={translations.translate(logic.reviewsCount <= 1 ? "Review" : "Reviews")}
        />
    )

    const visitsPerDayKpi = (
        <Kpi
            title={languageObject.translate("VisitsPerDay")}
            type={"lineChart"}
            showSkeletons={logic.showSkeletons}
            labels={logic.visitsPerDayLabels}
            values={logic.visitsPerDayValues}
            width={2}
        />
    )

    return (
        <Flex fill className={"pos-relative"}>
            <Flex column className={"fill-absolute overflow-scroll scaled-kpis-container"}>
                <Flex className={"w-100 kpis-grid"}>
                    {buildingsListKpi}
                    {buildingsClicksKpi}
                    {reviewsCountKpi}
                    {visitsCountKpi}
                    {visitsPerDayKpi}
                </Flex>
                <Flex styles={{width: "100%", minHeight: "20px"}}/>
            </Flex>
        </Flex>
    )
}