import {ITeamsContextState} from "./TeamsContext.interfaces";
import {useEffect, useState} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {FrameContexts, HostClientType, UserTeamRole} from "@microsoft/teams-js";
import moment from "moment";
import 'moment/locale/fr';
import {mergeThemes, teamsDarkTheme, teamsHighContrastTheme, teamsTheme} from "@fluentui/react-northstar";
import {ThemeCustomizations} from "const/ThemeCustomizations";
import {initTeamsFx} from "auth/TeamsFx/TeamsFx";
import languageObject from "../../translations";
import {TokenApi} from "apis/Token/TokenApi";
import {IConfigurationService} from "../ConfigurationService/ConfigurationService.interfaces";

const initialState: ITeamsContextState = {
    loaded: false,
    initialized: false,
    locale: "en",
    fullLocale: "en-US",
    teamsTheme: teamsTheme,
    themeClass: "teamsDefault",
    groupId: "",
    channelId: "",
    teamId: "",
    teamName: "",
    tenantId: "",
    channelName: "",
    userMail: "",
    userName: "",
    userId: "",
    hostClientType: "",
    isConfiguringApp: false,
    isDeletingApp: false,
    isOnMobile: false,
    isInPersonnalApp: false,
    subEntityId: "",
    isInTaskModule: false,
    entityId: "",
    isInMeeting: false,
    meetingId: "",
    isInMeetingSidePanel: false,
    userRole: UserTeamRole.User,
    isTouchScreen: false,
}

export const useTeamsContext = (configurationService: IConfigurationService) => {
    const [state, setState] = useState<ITeamsContextState>(initialState);

    useEffect(function onMount() {
        (async () => await initialize())();
    }, []);

    useEffect(function onInitialized() {
        (async () => {
            if (!state.initialized || state.loaded) return;
            const configuration = await configurationService.initialize();
            if (!configuration?.clientId) throw new Error("Invalid configuration");
            const clientId = configuration.clientId;
            const graphScopes = configuration.graphScopes.split(" ");
            await initializeTeamsFx(clientId, graphScopes);
            await TokenApi.saveToken();
        })()
    }, [state.initialized]);

    const initialize = async () => {
        await microsoftTeams.app.initialize();

        microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
            setTheme(theme);
        });

        const context = await microsoftTeams.app.getContext();

        moment.locale(context.app.locale);

        const stateUpdate: ITeamsContextState = {
            ...state,
            locale: context.app.locale?.substring(0, 2).toLowerCase(),
            fullLocale: context.app.locale as string,
            groupId: context.team?.groupId as string,
            channelId: context.channel?.id as string,
            hostClientType: context.app.host.clientType as string,
            teamId: context.team?.internalId as string,
            teamName: context.team?.displayName as string,
            tenantId: context.user?.tenant?.id as string,
            channelName: context.channel?.displayName as string,
            userMail: (context.user?.loginHint as string).toLowerCase(),
            userId: context.user?.id as string,
            isOnMobile: [HostClientType.ios, HostClientType.android, HostClientType.ipados]
                .includes(context.app.host.clientType),
            isInPersonnalApp: context.page.id === "conviviapp_personnal",
            subEntityId: context.page.subPageId as string,
            isConfiguringApp: context.page.frameContext === FrameContexts.settings,
            isDeletingApp: context.page.frameContext === FrameContexts.remove,
            isInTaskModule: context.page.frameContext === microsoftTeams.FrameContexts.task,
            userName: await getUserName(),
            meetingId: context.meeting?.id ?? "",
            isInMeeting: !!context.meeting?.id,
            isInMeetingSidePanel: context.page.frameContext === FrameContexts.sidePanel,
            userRole: context.team?.userRole ?? UserTeamRole.User,
            initialized: true,
        }

        languageObject.locale = stateUpdate.locale;

        if (stateUpdate.isOnMobile) document.body.className = "on-mobile";

        stateUpdate.isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        if (stateUpdate.isConfiguringApp) stateUpdate.entityId = await getEntityId();

        setState((prevState): ITeamsContextState => ({...prevState, ...stateUpdate}));
        setTheme(context.app.theme ?? "");
    }

    const initializeTeamsFx = async (clientId: string, graphScopes: Array<string>) => {
        if (!clientId || !graphScopes) return;
        await initTeamsFx({
            clientId: clientId,
            apiBaseUrl: `https://${process.env.REACT_APP_BACK_URL}`,
            scopes: graphScopes,
            inTeams: true,
            locale: state.locale,
            isWidget: false,
            isOnMobile: state.isOnMobile,
            theme: state.themeClass,
            tenantId: state.tenantId
        });
        microsoftTeams.app.notifySuccess();
        setState((prevState): ITeamsContextState => ({...prevState, loaded: true}));
    }

    const getEntityId = async () => {
        const settings = await microsoftTeams.pages.getConfig();
        return settings.entityId ?? "";
    }

    const getUserName = async () => {
        const token = await microsoftTeams.authentication.getAuthToken();
        if (token) {
            const tokenData = parseJWTToken(token);
            return tokenData.name;
        } else {
            throw new Error("Can't get token of user")
        }
    };

    const parseJWTToken = (token: string) => {
        const base64Url = token.split('.')[1];
        if (!base64Url) throw new Error("Token base64 url is undefined");
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    const setTheme = (value: string) => {
        let currentTheme = teamsTheme;
        let themeClass = "teamsDefault";
        switch (value) {
            case "dark":
                currentTheme = teamsDarkTheme;
                themeClass = "teamsDark";
                break;
            case "contrast":
                currentTheme = teamsHighContrastTheme;
                themeClass = "teamsHighContrast";
                break;
            default:
                break;
        }
        currentTheme = mergeThemes(currentTheme, ThemeCustomizations);
        document.body.id = themeClass;
        setState((prevState): ITeamsContextState => ({...prevState, teamsTheme: currentTheme, themeClass}));
    }

    return {
        ...state,
        setTheme,
        isLightTheme: state.themeClass === "teamsDefault",
        initializeTeamsFx,
    }
}