import {DateRangeType, Logic} from "./DateRangeSelector.interfaces";
import "./DateRangeSelector.styles.scss";
import React from "react";
import PopupMenuButton from "../PopupMenuButton/PopupMenuButton";
import {ArrowRightIcon, Button, Flex, FormDatepicker} from "@fluentui/react-northstar";
import {CalendarIcon} from "../../assets/icons";
import languageObject from "../../translations";
import moment from "moment";

export const DateRangeSelectorRendering = (logic: Logic) => {

    const trigger = (
        <Button
            icon={<CalendarIcon/>}
            content={renderDateTitle(logic.selectionMode, logic.selectedDate, logic.selectedEndDate)}
            onClick={logic.handleClickTrigger}
        />
    )

    const datePicker = (
        <Flex fill styles={{height: "240px"}}>
            {renderDatePicker(logic)}
        </Flex>
    )

    return (
        <PopupMenuButton
            position={"below"}
            align={"end"}
            keepOpenOnClick
            on={"click"}
            trigger={trigger}
            menu={[
                {
                    key: "selectMode",
                    disabled: true,
                    children: (
                        <Flex fill gap={"gap.small"} vAlign={"center"}>
                            <Button
                                primary={logic.selectionMode === DateRangeType.Day}
                                content={languageObject.translate("Day")}
                                onClick={logic.handleSwitchMode(DateRangeType.Day)}
                            />
                            <Button
                                primary={logic.selectionMode === DateRangeType.Week}
                                content={languageObject.translate("Week")}
                                onClick={logic.handleSwitchMode(DateRangeType.Week)}
                            />
                            <Button
                                primary={logic.selectionMode === DateRangeType.Month}
                                content={languageObject.translate("Month")}
                                onClick={logic.handleSwitchMode(DateRangeType.Month)}
                            />
                            <Button
                                primary={logic.selectionMode === DateRangeType.Custom}
                                content={languageObject.translate("Custom")}
                                onClick={logic.handleSwitchMode(DateRangeType.Custom)}
                            />
                        </Flex>
                    ),
                },
                {
                    key: "divider-1",
                    kind: "divider",
                    styles: {margin: "5px 0"},
                },
                ...(logic.selectionMode === DateRangeType.Custom ? [{
                    key: "customDateRange",
                    disabled: true,
                    children: (
                        <Flex fill gap={"gap.small"} vAlign={"center"}>
                            <Button
                                primary={!logic.isCustomEndDateSelected}
                                fluid
                                content={renderDateTitle(DateRangeType.Day, logic.selectedDate, logic.selectedEndDate)}
                                onClick={logic.handleToggleSelectedCustomDate(false)}
                            />
                            <ArrowRightIcon/>
                            <Button
                                primary={logic.isCustomEndDateSelected}
                                fluid
                                content={renderDateTitle(DateRangeType.Day, logic.selectedEndDate, logic.selectedEndDate)}
                                onClick={logic.handleToggleSelectedCustomDate(true)}
                            />
                        </Flex>
                    ),
                }] : []),
                {
                    key: "date-picker",
                    disabled: true,
                    children: datePicker,
                }
            ]}
        />
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDateTitle = (selectionMode: Logic["selectionMode"], date: Date, endDate: Date) => {
    switch (selectionMode) {
        case DateRangeType.Day:
            return date.toLocaleDateString(moment.locale(), {weekday: "long", day: "numeric", month: "long"});
        case DateRangeType.Week:
            return moment(date).startOf("week").format("L") + " - " + moment(date).endOf("week").format("L");
        case DateRangeType.Month:
            return date.toLocaleDateString(moment.locale(), {month: "long", year: "numeric"});
        case DateRangeType.Custom:
            return moment(date).format("L") + " - " + moment(endDate).format("L");
        default:
            return "";
    }
}

const renderDatePicker = (logic: Logic) => {
    const isCustomSelectionMode = logic.selectionMode === DateRangeType.Custom;
    const selectedDate = isCustomSelectionMode && logic.isCustomEndDateSelected ? logic.selectedEndDate : logic.selectedDate;
    return (
        <FormDatepicker
            shortDays={logic.shortDaysNames}
            months={logic.monthsNames}
            formatMonthDayYear={date => moment(date).locale(logic.locale).format("L")}
            buttonOnly
            calendar={{
                header: {
                    onPreviousClick: logic.handleChangeMonth("previous"),
                    onNextClick: logic.handleChangeMonth("next"),
                }
            }}
            firstDayOfWeek={logic.firstDayOfWeek}
            styles={{backgroundColor: "green"}}
            popup={{
                popperRef: logic.datePickerPopperRef,
                open: true,
                trigger: <div/>,
                contentRef: logic.popupContentRef,
            }}
            onDateChange={logic.handleChangeDate}
            maxDate={new Date()}
            dateRangeType={isCustomSelectionMode ? DateRangeType.Day : logic.selectionMode as number}
            selectedDate={selectedDate}
        />
    )
}