import {OpenHoursProps} from "./OpenHours.interfaces";
import {Divider, Flex, FlexItem, Text} from "@fluentui/react-northstar";
import {ILocationHours} from "../../interfaces";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {useOpenHoursLogic} from "./OpenHours.logic";
import {Immutable} from "../../interfaces/Immutable";

export const OpenHours = (props: OpenHoursProps) => {
    const translate = useTranslation();
    const logic = useOpenHoursLogic(props);

    const renderHoursList = useCallback((hours: Immutable<ILocationHours>, showFullDays: boolean) => {
        const groupedHours = logic.groupDaysPerHours(hours);
        return (
            <Flex column className={"w-100"} gap={"gap.smaller"}>
                {Array.from(groupedHours.keys()).map((key, index) => {
                    const hourLabel = key;
                    const days = groupedHours.get(key) ?? [];
                    if (days.length === 0) return null;
                    const daysLabel = days.length === 7 ?
                        translate("Everyday")
                        :
                        days.reduce((pd, d, i) => pd + (i > 0 ? ", " : "") + (showFullDays ? d : d.slice(0, 3)), "") ?? "";
                    return (
                        <Flex gap={"gap.smaller"} column key={"hour-" + hourLabel} className={"w-100"}>
                            {index <= 0 ? null : <Divider styles={{opacity: 0.5}}/>}
                            <Flex className={"w-100"}>
                                <Text content={daysLabel}/>
                                <FlexItem push>
                                    <Flex gap={"gap.medium"}>
                                        {!hourLabel ?
                                            <Text styles={{color: "darkgray"}} content={translate("Closed")}/>
                                            :
                                            <Text content={hourLabel}/>
                                        }
                                    </Flex>
                                </FlexItem>
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
        )
    }, []);

    const hours = useMemo(() => {
        return renderHoursList(props.hours, !logic.isOnMobile);
    }, [props.hours]);

    return (
        <Flex key={"open-hours-part"} column gap={"gap.small"} className={"w-100 x-padding"}>
            <Text size={"large"} content={translate("OpenHours")}/>
            {hours}
        </Flex>
    )
}