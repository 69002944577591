import React, {PropsWithChildren} from "react";
import "./ErrorBoundary.styles.scss";
import {Alert, AlertProps} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import languageObject from "../../translations";

export class ErrorBoundary extends React.Component<PropsWithChildren<any>, { error: string }> {
    constructor(props: PropsWithChildren<any>) {
        super(props);
        this.state = {
            error: ""
        };
        this.handleDismissAlert = this.handleDismissAlert.bind(this);
    }

    override componentDidCatch(error: Error) {
        this.setState({error: error.name});
        microsoftTeams.app.notifySuccess();
    }

    handleDismissAlert = (_: any, data?: AlertProps) => {
        const isHidden = !data?.visible;
        if (isHidden) this.setState({error: ""});
    }

    override render() {
        if (this.state.error) return (
            <div className={"errors-container"}>
                <Alert dismissible danger content={languageObject.translate("AnErrorOccured")}/>
            </div>
        )
        return this.props.children;
    }
}