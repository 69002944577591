import {useCallback, useEffect, useReducer} from "react";
import {FilterMobileDialogDispatchMessageType, IFilterMobileDialogProps} from "./FilterMobileDialog.interfaces";
import {initialState, reducer} from "./FilterMobileDialog.reducer";

export const useFilterMobileDialog = (props: IFilterMobileDialogProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(function onToggleShow() {
        if (props.show) return;
        dispatch({type: FilterMobileDialogDispatchMessageType.ClearOpenMenus});
    }, [props.show]);

    const handleToggleMenu = useCallback((id: string) => () => {
        dispatch({type: FilterMobileDialogDispatchMessageType.ToggleMenu, menuId: id});
    }, [])

    return {
        ...props,
        ...state,
        handleToggleMenu,
    }
}