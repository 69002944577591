import {useCallback, useContext} from "react";
import {IActivityContext} from "../../services/ActivityContext/ActivityContext.interfaces";
import {ActivityContext} from "../../services/ActivityContext/ActivityContext";

export const useActivityModal = () => {
    const activityContext = useContext<IActivityContext>(ActivityContext);

    const handleMouseEnterButton = useCallback(() => {
        activityContext.updateSawActivity();
    }, []);

    return {
        loaded: activityContext.loaded,
        activities: activityContext.list,
        hasNewActivity: activityContext.hasNewActivity,
        handleMouseEnterButton: handleMouseEnterButton,
    }
}