import React, {useContext} from "react";
import {LocationTableLogic} from "./LocationTable.logic";
import {LocationTableRendering} from "./LocationTable.rendering";
import {LogicProps, Props} from "./LocationTable.interfaces";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {IUserContext, UserContext} from "../../services/UserContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

const LocationTable = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);

    return (
        <LocationTableLogic
            {...props}
            externalRef={props.externalRef as LogicProps["externalRef"]}
            teamsContext={teamsContext}
            userContext={userContext}
            locationContext={locationContext}>
            {logic => <LocationTableRendering {...logic}/>}
        </LocationTableLogic>
    )
}

export default LocationTable;