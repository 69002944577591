import React, {useContext} from "react";
import {DateRangeSelectorLogic} from "./DateRangeSelector.logic";
import {DateRangeSelectorRendering} from "./DateRangeSelector.rendering";
import {Props} from "./DateRangeSelector.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

const DateRangeSelector = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    return (
        <DateRangeSelectorLogic {...props} teamsContext={teamsContext}>
            {logic => <DateRangeSelectorRendering {...logic}/>}
        </DateRangeSelectorLogic>
    )
}

export default DateRangeSelector;