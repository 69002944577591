import {Brand} from "../interfaces/Brand/Brand";

const brandNames = Object.values(Brand.valueOf());

const getBrandIdFromBrandName = (brand: string): Brand | null => {
    const index = brandNames.findIndex(b => b === brand);
    return index < 0 ? null : index;
}

const parseStringBrands = (brands: Array<string>): Array<Brand> => {
    const uniqueBrands = Array.from(new Set<string>(brands));
    const items = uniqueBrands.map(brand => getBrandIdFromBrandName(brand)).filter(brand => brand !== null) as Array<Brand>;
    return Array.from(new Set<Brand>(items));
}

export const BrandModule = {
    parseStringBrands,
}