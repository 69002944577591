import React, {useContext} from "react";
import {MapLogic} from "./Map.logic";
import {MapRendering} from "./Map.rendering";
import {Props} from "./Map.interfaces";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {IUserContext, UserContext} from "../../services/UserContext";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";

const Map = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);

    return (
        <MapLogic {...props} teamsContext={teamsContext} userContext={userContext} locationContext={locationContext}>
            {logic => <MapRendering {...logic}/>}
        </MapLogic>
    )
}

export default Map;