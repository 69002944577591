import {useCallback, useState} from "react";
import {IConfigurationService, IConfigurationServiceState} from "./ConfigurationService.interfaces";
import {setAxiosInstanceApiBaseUrl} from "apis/AxiosConfig/AxiosConfig";
import {IConfiguration} from "interfaces";
import {ConfigurationApi} from "apis/Configuration/ConfigurationApi";
import {LocalCacheService, SessionCacheService} from "../CacheService/CacheService.hook";

const cacheKey = "app_configuration";

const defaultConfiguration: IConfigurationServiceState = {
    loaded: false,
    data: {
        apiVersion: "",
        clientId: "",
        graphScopes: "",
        googleKey: "",
        appInsightKey: "",
        manifestId: "",
        isSectorManager: false,
        incompleteCount: 0,
        applicationVersion: "",
    }
};

export const useConfigurationService = (): IConfigurationService => {
    const [state, setState] = useState<IConfigurationServiceState>(defaultConfiguration);

    const initialize = useCallback(async () => {
        const config = await getConfig();
        if (!config) return null;
        setState({loaded: true, data: config});
        return config;
    }, []);

    return {
        ...state,
        initialize,
    }
}

const getConfig = async (): Promise<IConfiguration | null> => {
    const configuration: IConfiguration | null = await ConfigurationApi.getConfiguration();
    if (typeof configuration !== "object") throw new Error("Configuration is not in JSON format");
    if (!configuration) throw new Error("Invalid configuration");
    SessionCacheService.setItem(cacheKey, configuration);
    const isLastVersion = await checkVersion(configuration.applicationVersion);
    if (!isLastVersion) return null;
    const apiBaseUrl = `https://${process.env.REACT_APP_BACK_URL}/${configuration.apiVersion}/conviviapp`;
    setAxiosInstanceApiBaseUrl(apiBaseUrl);
    return configuration;
}

const checkVersion = async (lastVersion: string): Promise<boolean> => {
    const currentVersion = LocalCacheService.getItem("app_version");
    const isLastVersion = lastVersion === currentVersion;
    if (isLastVersion) return true;
    LocalCacheService.setItem("app_version", lastVersion);
    await handleHardReload(window.location.href);
    return false;
}

export const handleHardReload = async (url: string) => {
    if ("caches" in window) {
        const cachedKeys = await window.caches.keys();
        await Promise.all(cachedKeys.map(key => window.caches.delete(key)));
    }
    await fetch(url, {headers: {Pragma: 'no-cache', Expires: '-1', 'Cache-Control': 'no-cache'}});
    window.location.href = url;
    window.location.reload();
}

export const getCachedConfiguration = (): IConfiguration | null => {
    return SessionCacheService.getItem(cacheKey);
}