import {IUserPreviewProps} from "./UserPreview.interfaces";
import {useCallback, useContext, useEffect} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {IUserContext, UserContext} from "../../services/UserContext";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

const SYSTEM_USER_ID = "system";

export const useUserPreview = (props: IUserPreviewProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);

    const isSystem = props.userId === SYSTEM_USER_ID;

    const user = isSystem ? null : userContext.list.find(u => u.id === props.userId);

    useEffect(function onUserInitialized() {
        (async () => {
            if (isSystem || !!user) return;
            await userContext.fetchUsers([props.userId]);
        })()
    }, [props.userId]);

    const isLightTheme = teamsContext.isLightTheme;

    const isTouchScreen = teamsContext.isTouchScreen;

    const isCurrentUser = props.userId === teamsContext.userId;

    const handleStartChat = useCallback((userMail: string) => async (e: any) => {
        e.stopPropagation();
        const deepLink = "https://teams.microsoft.com/l/chat/0/0?users=" + userMail;
        await microsoftTeams.app.openLink(deepLink)
    }, []);

    const handleStartCall = useCallback((userMail: string) => async (e: any) => {
        e.stopPropagation();
        await microsoftTeams.app.openLink("https://teams.microsoft.com/l/call/0/0?users=" + userMail + "&withVideo=true")
    }, [])

    const showAvatar = props.showAvatar === undefined || props.showAvatar;

    return {
        ...props,
        showAvatar,
        handleStartChat,
        handleStartCall,
        user,
        isCurrentUser,
        isTouchScreen,
        isLightTheme,
        isSystem
    }
}