export enum ItemType {
    Location, Event, BuildingKpi
}

type BaseItemDetailsProps = {
    itemId: string | undefined,
    itemType: ItemType,
    onHide?: (() => void) | undefined,
    title?: string | undefined,
}

type LocationItemDetailsProps = BaseItemDetailsProps & {
    itemType: ItemType.Location,
}

type EventItemDetailsProps = BaseItemDetailsProps & {
    itemType: ItemType.Event,
}

type BuildingKpiItemDetailsProps = BaseItemDetailsProps & {
    itemType: ItemType.BuildingKpi,
    start: string,
    end: string,
}

export type ItemDetailsProps = LocationItemDetailsProps | EventItemDetailsProps | BuildingKpiItemDetailsProps;