import {useCallback, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {ILocationDetailsProps, LocationDetailsDispatchMessageType} from "./LocationDetails.interfaces";
import {initialState, reducer} from "./LocationDetails.reducer";
import {Brands} from "const";
import {BrandReference} from "interfaces/Brand/BrandReference";
import {IReview} from "interfaces/IReview";
import {EventContext, IEventContext} from "services/EventContext";
import {IEvent} from "interfaces/Event/IEvent";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "services/TeamsContext";
import {IUserContext, UserContext} from "services/UserContext";
import * as microsoftTeams from "@microsoft/teams-js";
import {IConfiguration, LocationType} from "interfaces";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {LocationModule} from "modules";
import {ConfigurationModule} from "modules/Configuration.module";
import {UserRole} from "../../interfaces/UserRole";
import copy from 'copy-to-clipboard';
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useLocationDetails = (props: ILocationDetailsProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const userContext = useContext<IUserContext>(UserContext);
    const eventContext = useContext<IEventContext>(EventContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const translate = useTranslation();
    const prevSelectedEventRef = useRef<IEvent>();
    const configRef = useRef<IConfiguration>(ConfigurationModule.getConfiguration());
    const copiedMessageTimeoutRef = useRef<NodeJS.Timeout>();
    const reviewsLoadedRef = useRef<boolean>(false);

    const isOnMobile = teamsContext.isOnMobile;

    const location = locationContext.findLocation(props.locationId);

    useEffect(function onMount() {
        (async () => {
            if (!location?.extendedProps?.reviews)
                await locationContext.getLocationReviews(props.locationId);
            await eventContext.fetchBuildingEvents(props.locationId);
        })();
    }, []);

    const referencedBrands = useMemo(() => {
        let referencedBrands = !!location ? location.brands.map(b => Brands[b]) : undefined;
        if (location?.flagship !== undefined && !referencedBrands?.find(b => b.id === location.flagship))
            referencedBrands?.push(Brands[location?.flagship]);
        if (referencedBrands?.length === 0) referencedBrands = undefined;
        return referencedBrands;
    }, [location?.brands, location?.flagship]);

    const brandReferences = useMemo(() => {
        return !!location ?
            location.availableReferences?.map(r => (BrandReference[r] + "").replaceAll(/_/g, " "))
            :
            undefined;
    }, [location?.availableReferences]);

    const sectorManagerReview = useMemo((): IReview | undefined => {
        return !!location ? {
            id: "sector-manager-review",
            userAadId: location.sectorManagerId,
            review: location.sectorManagerReview ?? "",
            buildingId: props.locationId,
            reviewDate: "",
            visitors: 1,
        } : undefined;
    }, [location?.sectorManagerReview]);

    const nextEvents = useMemo(() => {
        return eventContext.list.filter(e => e.buildingId === props.locationId);
    }, [eventContext.list.length]);

    const isLocationInFavoritesOfCurrentUser = userContext.currentUser?.applicationSettings.favoriteBuildings
        ?.includes(props.locationId) ?? false;

    const handleToggleShowReviewsSideView = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.ToggleReviewsSideView});
    }, []);

    const handleToggleShowCreateEventSideView = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.ToggleCreateEventSideView});
    }, []);

    const handleToggleShowShareExperienceSideView = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.ToggleShareExperienceSideView});
    }, []);

    const handleToggleEditLocationsSideView = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.ToggleEditLocationSideView});
    }, []);

    const handleSaveUpdatedLocation = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.ToggleEditLocationSideView});
    }, []);

    const handleShowEvent = useCallback((id: string) => () => {
        dispatch({type: LocationDetailsDispatchMessageType.SetSelectedEventId, id});
    }, []);

    const handleHideEvent = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.SetSelectedEventId, id: ""});
    }, []);

    const handleCall = useCallback(async () => {
        if (!location) return;
        await LocationModule.call(location.phone, isOnMobile);
    }, [location?.phone]);

    const handleShowItinerary = useCallback(() => {
        if (!location) return;
        const userPosition = userContext.currentUser?.geoLocation;
        LocationModule.showItinerary(userPosition, location.address.geoPosition);
    }, [location?.address.geoPosition]);

    const handleShowOnGoogleMaps = useCallback(async () => {
        if (!location || !configRef.current) return;
        const url = location.googleMapsLink;
        window.open(url, '_blank');
    }, [])

    const handleOpenWebsite = useCallback(async () => {
        if (!location?.website) return;
        const url = location.website;
        window.open(url, '_blank');
    }, []);

    const handleShare = useCallback(async () => {
        if (!location || !configRef.current) return;
        let message: string;
        switch (location.type) {
            case LocationType.NightClub:
                message = translate("WouldYouLikeToGoDancingInThisClubTonight")
                break;
            case LocationType.Restaurant:
                message = translate("WouldYouLikeToEatInThisRestaurant")
                break;
            case LocationType.WineMerchant:
            case LocationType.HighEnergyBar:
            case LocationType.LowEnergyBar:
                message = translate("WouldYouLikeToGoForADrinkInThisBar");
                break;
            default:
                message = ""
        }
        if (isOnMobile) {
            if (!!copiedMessageTimeoutRef.current) clearTimeout(copiedMessageTimeoutRef.current);
            copy(message + "\n\n" + location.googleMapsLink);
            dispatch({type: LocationDetailsDispatchMessageType.SetShowCopiedMessage, value: true});
            copiedMessageTimeoutRef.current = setTimeout(() => {
                copiedMessageTimeoutRef.current = undefined;
                dispatch({type: LocationDetailsDispatchMessageType.SetShowCopiedMessage, value: false});
            }, 1000);
            return;
        }
        await microsoftTeams.sharing.shareWebContent({
            content: [{
                type: "URL",
                // Fix issue Teams dialog actions not visible if text too long
                url: location.googleMapsLink.replace(/\+/g, "+ "),
                message: message
            }]
        });
    }, []);

    const handleToggleLocationInFavorites = useCallback(() => {
        dispatch({type: LocationDetailsDispatchMessageType.SetShowFavoriteMessage, value: true});
        userContext.toggleLocationInFavorite(props.locationId);
        setTimeout(() => dispatch({
            type: LocationDetailsDispatchMessageType.SetShowFavoriteMessage,
            value: false
        }), 800);
    }, [props.locationId]);

    const selectedEvent = useMemo(() => {
        if (!state.selectedEventId) return null;
        const event = eventContext.list.find(e => e.id === state.selectedEventId);
        if (!event) throw new Error("Can't find event with id : " + state.selectedEventId);
        prevSelectedEventRef.current = event;
        return event;
    }, [state.selectedEventId]);

    const loaded = !!location;

    const reviews = location?.extendedProps?.reviews;
    if (!reviewsLoadedRef.current && !!reviews) reviewsLoadedRef.current = true;

    const showReviewsSkeletons = !reviewsLoadedRef.current;

    const showEventSkeleton = !eventContext.areBuildingEventsFetched(props.locationId);

    const userRole = userContext.currentUser?.role ?? UserRole.Basic;

    const hasUserFullRights = [UserRole.SuperAdmin, UserRole.Admin, UserRole.BrandAmbassador].includes(userRole) ||
        (userRole === UserRole.SectorManager && location?.sectorManagerId === teamsContext.userId);

    const showUpdateInformation = userRole !== UserRole.Basic && !!location?.updateAt && !!location?.updateBy;

    const isLocationPhoneAvailable = !!location?.phone;

    const isLocationWebsiteAvailable = !!location?.website;

    const hideEvents = props.hideEvents || (nextEvents.length === 0 && !hasUserFullRights);

    return {
        ...state,
        location,
        reviews,
        referencedBrands,
        brandReferences,
        nextEvents,
        sectorManagerReview,
        showEventSkeleton,
        loaded,
        handleToggleShowReviewsSideView,
        handleToggleShowCreateEventSideView,
        handleToggleShowShareExperienceSideView,
        handleToggleEditLocationsSideView,
        handleSaveUpdatedLocation,
        handleShowEvent,
        selectedEvent,
        handleHideEvent,
        prevSelectedEventRef: prevSelectedEventRef.current,
        hideEvents,
        isOnMobile,
        isLocationInFavoritesOfCurrentUser,
        handleToggleLocationInFavorites,
        handleShowOnGoogleMaps,
        handleShare,
        handleCall,
        handleShowItinerary,
        showReviewsSkeletons,
        hasUserFullRights,
        isLocationPhoneAvailable,
        handleOpenWebsite,
        isLocationWebsiteAvailable,
        showUpdateInformation,
    }
}