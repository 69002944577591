import {useBuildings} from "./Buildings.hook";
import React from "react";
import {AppView} from "../../interfaces";
import LocationView from "../../components/LocationView/LocationView";

export const Buildings = () => {
    const logic = useBuildings();

    return (
        <LocationView
            view={AppView.Buildings}
            filterLocations={logic.handleFilterLocations}
            fetchLocations={logic.handleFetchLocations}
            externalRef={logic.locationViewRef}
        />
    )
}