import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, Payload, State} from "./SuggestionsDropdown.interfaces";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "setSearchFilter":
                state.filter = payload.value;
                break;
            case "setSearchTimeout":
                state.timeout = payload.value;
                break;
            case "setIsSearching":
                state.isSearching = payload.value;
                break;
            case "setSuggestions":
                state.suggestions = payload.value;
                break;
            default:
                throw new Error("Payload type " + payloadType + " not implemented");
        }
    });
}

const getInitialState = (props: LogicProps): State => {
    const state: State = {
        filter: props.initialSearchValue ?? "",
        timeout: null,
        isSearching: false,
        suggestions: [],
    };
    return state;
}

export const useReducer = (props: LogicProps) => {
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Readonly<State>>(initialState);
    const [, render] = ReactReducer(() => [], []);

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch};
}