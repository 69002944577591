import {
    ILocationDetailsState,
    LocationDetailsDispatchMessage,
    LocationDetailsDispatchMessageType
} from "./LocationDetails.interfaces";

export const initialState: ILocationDetailsState = {
    showReviewsSideView: false,
    showCreateEventSideView: false,
    showShareExperienceSideView: false,
    showEditLocationSideView: false,
    selectedEventId: "",
    showCopiedMessage: false,
    showFavoriteMessage: false,
}

export const reducer = (prevState: ILocationDetailsState, payload: LocationDetailsDispatchMessage): ILocationDetailsState => {
    const newState: ILocationDetailsState = {...prevState};
    switch (payload.type) {
        case LocationDetailsDispatchMessageType.ToggleReviewsSideView:
            newState.showReviewsSideView = !prevState.showReviewsSideView;
            return newState;
        case LocationDetailsDispatchMessageType.ToggleCreateEventSideView:
            newState.showCreateEventSideView = !prevState.showCreateEventSideView;
            return newState;
        case LocationDetailsDispatchMessageType.ToggleShareExperienceSideView:
            newState.showShareExperienceSideView = !prevState.showShareExperienceSideView;
            return newState;
        case LocationDetailsDispatchMessageType.ToggleEditLocationSideView:
            newState.showEditLocationSideView = !prevState.showEditLocationSideView;
            return newState;
        case LocationDetailsDispatchMessageType.SetSelectedEventId:
            newState.selectedEventId = payload.id;
            return newState;
        case LocationDetailsDispatchMessageType.SetShowCopiedMessage:
            newState.showCopiedMessage = payload.value;
            return newState;
        case LocationDetailsDispatchMessageType.SetShowFavoriteMessage:
            newState.showFavoriteMessage = payload.value;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}