import React, {useContext} from "react";
import {OpenHoursFormLogic} from "./OpenHoursForm.logic";
import {OpenHoursFormRendering} from "./OpenHoursForm.rendering";
import {Props} from "./OpenHoursForm.interfaces";
import {TeamsContext} from "../../../services/TeamsContext";
import {ITeamsContext} from "../../../services/TeamsContext/TeamsContext.interfaces";

const OpenHoursForm = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    return (
        <OpenHoursFormLogic {...props} teamsContext={teamsContext}>
            {logic => <OpenHoursFormRendering {...logic}/>}
        </OpenHoursFormLogic>
    )
}

export default OpenHoursForm;