import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LogicProps, Payload, State} from "./Dashboard.interfaces";
import {LocationFilters} from "../../const";
import moment from "moment";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof State;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const getInitialState = (props: LogicProps): State => {
    const filters = LocationFilters(props.userContext.currentUser?.role, ["types", "image-levels", "brands", "partner-only"]);
    const state: State = {
        searchFilter: "",
        filterItems: filters,
        dateRange: {
            start: moment().startOf("week").toISOString(false),
            end: moment().endOf("week").toISOString(false)
        },
        isFetching: true,
        sectorManagerKpis: undefined,
        superAdminKpis: undefined,
        selectedBuilding: undefined,
    };
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Readonly<State>>(initialState);
    const propsRef = useRef<Readonly<LogicProps>>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef};
}