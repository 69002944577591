import {IGeoPosition} from "../interfaces";

const getRadius = function (x: number) {
    return x * Math.PI / 180;
};

const getDistanceInMetersBetweenCoordinates = function (p1: google.maps.LatLng | IGeoPosition, p2: google.maps.LatLng | IGeoPosition) {
    const p1LatLng = {
        lat: typeof p1.lat === "function" ? p1.lat() : p1.lat,
        lng: typeof p1.lng === "function" ? p1.lng() : p1.lng
    };
    const p2LatLng = {
        lat: typeof p2.lat === "function" ? p2.lat() : p2.lat,
        lng: typeof p2.lng === "function" ? p2.lng() : p2.lng
    };
    // Earth’s mean radius in meter
    const R = 6378137;
    const dLat = getRadius(p2LatLng.lat - p1LatLng.lat);
    const dLong = getRadius(p2LatLng.lng - p1LatLng.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(getRadius(p1LatLng.lat)) * Math.cos(getRadius(p2LatLng.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const getMapVisibleRadiusInMeters = (map: google.maps.Map) => {
    const mapCenter = map.getCenter();
    const mapNorthEastBound = map.getBounds()?.getNorthEast();
    if (!mapCenter || !mapNorthEastBound) return 0;
    const distance = getDistanceInMetersBetweenCoordinates(mapCenter, mapNorthEastBound);
    return Math.round(distance);
}

export const MapModule = {
    getDistanceInMetersBetweenCoordinates,
    getMapVisibleRadiusInMeters,
}