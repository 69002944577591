import {AppView} from "interfaces";
import {Brand} from "interfaces/Brand/Brand";

export interface ISettingsState {
    applicationSettings: IApplicationSettings,
    showConfirmUpdateModal: boolean,
    isImporting: boolean,
    loaded: boolean,
    hasImportError: boolean,
    lastImportDate: string,
}

export type IApplicationSettings = {
    userAadId: string,
    favoriteBrands: Array<Brand>,
    favoriteBuildings: Array<string>,
    homepage: AppView,
    isSuperAdmin: boolean,
    isAdmin: boolean,
    isBrandAdmin: boolean,
    lastCGURead: string | null,
}

export enum UpdateBuildingType {
    Elite, Google
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum SettingsDispatchMessageType {
    UpdateApplicationFavoriteBrands,
    UpdateApplicationHomePage,
    SetIsImporting,
    SetApplicationSettings,
    SetIsLoaded,
    SetHasImportError,
    SetLastImportDate,
}

type SetApplicationSettings = {
    type: SettingsDispatchMessageType.SetApplicationSettings,
    settings: IApplicationSettings
}

type UpdateApplicationFavoriteBrands = {
    type: SettingsDispatchMessageType.UpdateApplicationFavoriteBrands,
    favoriteBrands: Array<Brand>,
};

type UpdateApplicationHomePage = {
    type: SettingsDispatchMessageType.UpdateApplicationHomePage,
    homePage: AppView
};

type SetIsImporting = {
    type: SettingsDispatchMessageType.SetIsImporting,
    value: boolean
}

type SetIsLoaded = {
    type: SettingsDispatchMessageType.SetIsLoaded,
    value: boolean,
}

type SetHasImportError = {
    type: SettingsDispatchMessageType.SetHasImportError,
    value: boolean,
}

type SetLastImportDate = {
    type: SettingsDispatchMessageType.SetLastImportDate,
    value: string,
}

export type SettingsDispatchMessage =
    SetApplicationSettings
    | UpdateApplicationFavoriteBrands
    | UpdateApplicationHomePage
    | SetIsImporting
    | SetIsLoaded
    | SetHasImportError
    | SetLastImportDate