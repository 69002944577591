import React, {memo, useContext} from "react";
import {Card, Flex, Image, Text} from "@fluentui/react-northstar";
import {Badge} from "components";
import "./EventPreview.styles.scss";
import {IEventPreviewProps} from "./EventPreview.interfaces";
import moment from "moment";
import {BuildingsIcon, LocationIcon} from "assets/icons";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import MarkdownView from "react-showdown";

const propsAreEqual = (prevProps: Readonly<IEventPreviewProps>, nextProps: Readonly<IEventPreviewProps>) => {
    return prevProps.event === nextProps.event;
}

export const EventPreview = memo((props: IEventPreviewProps) => {
    const locationContext = useContext<ILocationContext>(LocationContext);
    const translate = useTranslation();

    const {name, notes, startDate, buildingId, endDate} = props.event;
    const coverPictureUrl = props.event.coverPictureUrl;
    const location = locationContext.findLocation(buildingId);
    if (!location) {
        console.error("Can't find location with id : " + buildingId);
        return null;
    }
    const now = moment();

    const isEventLive = now >= moment(startDate) && now <= moment(endDate);
    let hourLabel: string;
    if (props.hideLocationData) hourLabel = moment(startDate).calendar();
    else if (isEventLive) {
        hourLabel = translate("EndingThe") + " " + moment(endDate).format("l") + " " + moment(endDate).format("LT");
    } else {
        hourLabel = translate("StartingAt") + " " + moment(startDate).format("LT");
    }

    const coverPicture = !coverPictureUrl ? null : (
        <Flex fill className={"event-preview-cover " + (props.horizontal ? "horizontal-cover" : "vertical-cover")}>
            <Image fluid src={coverPictureUrl}/>
        </Flex>
    )

    const liveBadge = (
        <Badge color={"red"} textColor={"white"} label={"LIVE"}/>
    )

    const locationDetails = (
        <Flex column gap={"gap.small"}>
            <Flex gap={"gap.small"}>
                <BuildingsIcon/>
                <Text content={location.name}/>
            </Flex>
            <Flex gap={"gap.small"}>
                <LocationIcon/>
                <Text content={location.address.fullAddress}/>
            </Flex>
        </Flex>
    )

    const content = (
        <Flex fill column gap="gap.medium" styles={{padding: "15px"}}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Flex fill column gap={"gap.smaller"}>
                    <Text weight={"bold"} size={"large"} content={name}/>
                    <Text weight={"semibold"} styles={{color: "gray"}} content={hourLabel}/>
                </Flex>
                {!isEventLive ? null :
                    <Flex styles={{marginRight: "-3px"}} className={"h-100"} hAlign={"end"}>{liveBadge}</Flex>}
            </Flex>
            {!notes ? null :
                <MarkdownView
                    onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                    className={"notes-viewer"}
                    markdown={notes}
                />
            }
            {props.hideLocationData ? null : locationDetails}
        </Flex>
    )

    return (
        <Flex fill onClick={props.onShow}>
            <Card
                className={"event-preview no-select"}
                horizontal={props.horizontal ?? false}
                fluid elevated>
                {coverPicture}
                {content}
            </Card>
        </Flex>
    )
}, propsAreEqual)