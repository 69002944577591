import {IFilterItem} from "components/Filter/Filter.interfaces";
import {LocationTypes} from "const/LocationTypes";
import {LocationImageLevels} from "const/LocationImageLevels";
import {Brands} from "./Brands";
import languageObject from "../translations";
import {UserRole} from "../interfaces/UserRole";
import {AppViews} from "./AppViews";
import {AppView} from "../interfaces";

export const LocationFilters = (userRole: UserRole | undefined, visibleItems?: Array<string | "types" | "image-levels" | "brands" | "currently-open" | "only-favorite-brands" | "partner-only" | "max-distance" | "incomplete-buildings">) => {

    const typeFilters: Array<IFilterItem> = Object.values(LocationTypes).map(type => {
        const TypeIcon = type.element;
        return {
            key: "type-" + type.shortLabel,
            value: type.id,
            icon: <TypeIcon/>,
            label: languageObject.translate(type.translationId),
        }
    });

    const imageLevelFilters: Array<IFilterItem> = Object.values(LocationImageLevels).map(imageLevel => {
        return {
            key: "image-level-" + imageLevel.translationId,
            value: imageLevel.id,
            label: languageObject.translate(imageLevel.translationId),
        }
    });

    const brandFilters: Array<IFilterItem> = Object.values(Brands).map(brand => {
        return {
            key: "brand-" + brand.stringId,
            value: brand.id,
            icon: <brand.element/>,
            label: brand.formattedName
        }
    });

    let filterItems: Array<IFilterItem> = [
        {key: "types", label: languageObject.translate("Types"), menu: typeFilters},
        {key: "image-levels", label: languageObject.translate("ImageLevels"), menu: imageLevelFilters},
        {key: "brands", label: languageObject.translate("Brands"), menu: brandFilters},
        {key: "currently-open", label: languageObject.translate("CurrentlyOpen")},
        {key: "only-favorite-brands", label: languageObject.translate("OnlyFavoriteBrands")},
        {key: "partner-only", label: languageObject.translate("RecommendedOnly")},
        {
            key: "max-distance",
            label: languageObject.translate("Within"),
            input: {
                unit: {
                    label: "m",
                    width: 12
                },
                value: 5000,
                validate: (value) => !isNaN(Number(value)),
                type: "tel"
            }
        },
    ];

    const canUserSeeIncompleteBuildings = userRole !== undefined && [UserRole.Admin, UserRole.SuperAdmin, UserRole.SectorManager].includes(userRole);
    const isBuildingsPage = window.location.pathname === AppViews[AppView.Buildings].path;

    if (canUserSeeIncompleteBuildings && isBuildingsPage) {
        const checkIncompleteBuildings = new URLSearchParams(window.location.search).get("showIncomplete") === "true";
        filterItems.push({
            key: "incomplete-buildings",
            label: languageObject.translate("IncompleteBuildings"),
            isChecked: checkIncompleteBuildings,
        })
    }

    if (visibleItems) filterItems = filterItems.filter(i => visibleItems.includes(i.key));

    return filterItems;
}