import React, {memo, useContext, useMemo} from "react";
import {Animation, Dialog, Flex, Text} from "@fluentui/react-northstar";
import {INotificationDialogProps} from "./NotificationDialog.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

const propsAreEqual = (prevProps: Readonly<INotificationDialogProps>, nextProps: Readonly<INotificationDialogProps>) => {
    return prevProps.show === nextProps.show;
}

export const NotificationDialog = memo((props: INotificationDialogProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    const content = useMemo(() => (
        <Flex fill column gap={"gap.medium"} hAlign={"center"}>
            {props.icon}
            <Flex fill column gap={"gap.medium"} hAlign={"center"}>
                <Text
                    align={"center"}
                    size={"large"}
                    weight={"bold"}
                    content={props.title}
                />
            </Flex>
        </Flex>
    ), []);

    return (
        <Animation
            onExited={props.onHide}
            visible={props.show}
            name={props.show ? 'fadeinDialog' : 'fadeoutDialog'}
            mountOnEnter
            unmountOnExit
        >
            {({classes}) => (
                <Dialog
                    open
                    styles={{maxWidth: teamsContext.isOnMobile ? "calc(100vw - 50px)" : "450px"}}
                    className={"no-select " + (classes ?? "")}
                    overlay={{className: props.show ? "open" : "close"}}
                    onClick={props.onHide}
                    content={content}
                />
            )}
        </Animation>
    )
}, propsAreEqual)