import {useCallback, useContext, useEffect, useReducer, useRef} from "react";
import Swiper from "swiper";
import {CarouselDispatchMessageType, ICarouselProps} from "./Carousel.interfaces";
import {initialState, reducer} from "./Carousel.reducer";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const useCarousel = (props: ICarouselProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const [state, dispatch] = useReducer(reducer, initialState(props.items.length, props.defaultCollapsed ?? false));
    const swiperRef = useRef<Swiper>();
    const prevIndexRef = useRef<number>();


    useEffect(function onItemsLengthChange() {
        dispatch({type: CarouselDispatchMessageType.SetHeaderButtonsVisibility, value: props.items.length > 1});
    }, [props.items.length]);

    useEffect(function onIndexChange() {
        sendRefToParent();
    }, [state.index]);

    const isEnd = useCallback((slidesPerView: ICarouselProps["slidesPerView"], spaceBetween: number) => {
        if (slidesPerView === "auto") {
            let totalSize = 0;
            swiperRef.current?.slides.forEach(slide => {
                totalSize += slide.clientWidth + spaceBetween;
            });
            let currentSize = (swiperRef.current as unknown as { width?: number })?.width ?? 0;
            currentSize -= swiperRef.current?.translate ?? 0;
            return totalSize <= currentSize;
        }
        return swiperRef.current?.isEnd ?? true;
    }, []);

    const isOnMobile = teamsContext.isOnMobile;
    const slidesPerView = props.slidesPerView ?? 1;
    const slidesPerGroup = props.slidesPerGroup ?? (props.slidesPerView !== "auto" ? props.slidesPerView : 1) ?? 1;
    const spaceBetween = props.spaceBetween ?? (teamsContext.isOnMobile ? -30 : 15);
    const isLastIndex = isEnd(props.slidesPerView, spaceBetween);
    const itemWidth = props.itemWidth ?? "100%";
    const autoSlides = props.slidesPerView === "auto";
    const canBeCollapsed = props.collapsible;
    const isCollapsed = state.isCollapsed;

    const sendRefToParent = useCallback(() => {
        if (!swiperRef.current || !props.onCarouselRef) return;
        const currentIndex = swiperRef.current.activeIndex;
        if (prevIndexRef.current === currentIndex) return;
        prevIndexRef.current = currentIndex;
        props.onCarouselRef({
            index: currentIndex,
            isLastIndex: isEnd(props.slidesPerView, spaceBetween),
            swipePrevious: handleClickPrevious,
            swipeNext: handleClickNext,
            swipeTo: (index: number, speed: number) => swiperRef.current?.slideTo(index, speed),
            prepend: swiperRef.current.prependSlide,
        });
    }, []);

    const handleSetSwiperRef = useCallback((ref: Swiper) => {
        swiperRef.current = ref;
        sendRefToParent();
    }, []);

    const handleClickItem = useCallback((id: string) => () => {
        if (!id || !props.onClickItem) return;
        props.onClickItem(id);
    }, [props.onClickItem]);

    const handleSeeAll = useCallback(() => {
        if (!props.onSeeAll) return;
        props.onSeeAll();
    }, [props.onSeeAll]);

    const handleClickPrevious = useCallback(() => {
        swiperRef.current?.slidePrev(600);
        const value = swiperRef.current?.activeIndex ?? 0;
        dispatch({type: CarouselDispatchMessageType.ChangeIndex, value});
    }, []);

    const handleClickNext = useCallback(() => {
        swiperRef.current?.slideNext(600);
        const value = swiperRef.current?.activeIndex ?? 0;
        dispatch({type: CarouselDispatchMessageType.ChangeIndex, value});
    }, []);

    const handleToggleCollapse = useCallback(() => {
        if (!props.collapsible) return;
        dispatch({type: CarouselDispatchMessageType.ToggleCollapse});
    }, [props.collapsible]);

    return {
        isOnMobile,
        handleClickNext,
        handleClickPrevious,
        handleSetSwiperRef,
        handleClickItem,
        handleSeeAll,
        currentIndex: state.index,
        slidesPerView,
        spaceBetween,
        labelId: props.labelId ?? "",
        items: props.items,
        isLastIndex,
        hideHeaderButtons: state.hideHeaderButtons,
        hideSeeAllButton: !props.onSeeAll,
        itemWidth,
        pushMoveButtons: props.pushMoveButtons ?? false,
        showNavigationButtons: props.showNavigationButtons ?? false,
        enablePagination: props.enablePagination ?? false,
        noPadding: props.noPadding ?? false,
        noHeader: props.noHeader ?? false,
        autoSlides,
        slidesPerGroup,
        canBeCollapsed,
        isCollapsed,
        handleToggleCollapse,
    }
}