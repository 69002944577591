import * as React from "react";
import {useContext, useMemo} from "react";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {ActivityContext} from "services/ActivityContext/ActivityContext";
import {IActivityContext} from "services/ActivityContext/ActivityContext.interfaces";
import {ActivityCard} from "components";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";

export const Activity = () => {
    const translate = useTranslation();
    const activityContext = useContext<IActivityContext>(ActivityContext);

    const activityCards = useMemo(() => !activityContext.loaded ? (
        <Loader key={"activity-loader"} className={"w-100 h-100"}/>
    ) : activityContext.list.length === 0 ? (
        <Flex fill hAlign={"center"} vAlign={"center"} key={"no-activity"}>
            <Text styles={{color: "darkgray"}} content={translate("NoActivity")}/>
        </Flex>
    ) : (
        <Flex key={"activity-cards"} fill column gap={"gap.medium"} className={"activities-container"}>
            {activityContext.list.map(activity => (
                <ActivityCard key={"activity" + activity.id} activity={activity}/>
            ))}
        </Flex>
    ), [activityContext.loaded]);

    const content = useMemo(() => (
        <Flex fill column gap={"gap.medium"} className={"pos-relative"}>
            <Flex fill className={"x-padding overflow-scroll fill-absolute"}>
                {activityCards}
            </Flex>
        </Flex>
    ), [activityCards.key]);

    return content;
}