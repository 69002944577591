import {useCallback, useContext, useMemo, useReducer} from "react";
import {EventDetailsDispatchMessageType, IEventDetailsProps} from "./EventDetails.interfaces";
import {initialState, reducer} from "./EventDetails.reducer";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {EventContext, IEventContext} from "../../services/EventContext";
import {LocationModule} from "../../modules";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {IUserContext, UserContext} from "../../services/UserContext";
import {UserRole} from "../../interfaces/UserRole";

export const useEventDetails = (props: IEventDetailsProps) => {
    const locationContext = useContext<ILocationContext>(LocationContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const eventContext = useContext<IEventContext>(EventContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const {isOnMobile} = teamsContext;

    const eventData = useMemo(() => {
        const event = eventContext.list.find(e => e.id === props.eventId);
        if (!event) throw new Error("Can't find event with id : " + props.eventId);
        const eventLocation = locationContext.findLocation(event.buildingId);
        if (!eventLocation) throw new Error("Can't find location with id : " + event.buildingId);
        return {
            event,
            location: eventLocation
        }
    }, [props.eventId, eventContext.list, locationContext.list]);

    const userRole = userContext.currentUser?.role ?? UserRole.Basic;

    const hasUserFullRights = [UserRole.SuperAdmin, UserRole.Admin, UserRole.BrandAmbassador].includes(userRole) ||
        (userRole === UserRole.SectorManager && eventData.location?.sectorManagerId === teamsContext.userId);

    const handleCall = useCallback(async () => {
        if (!eventData.location) return;
        await LocationModule.call(eventData.location.phone, isOnMobile);
    }, [eventData.location?.phone]);

    const handleShowItinerary = useCallback(() => {
        if (!eventData.location) return;
        const userPosition = userContext.currentUser?.geoLocation;
        LocationModule.showItinerary(userPosition, eventData.location.address.geoPosition);
    }, [eventData.location?.address.geoPosition]);

    const handleSetShareExperienceSideViewVisibility = useCallback((value: boolean) => () => {
        dispatch({type: EventDetailsDispatchMessageType.SetExperienceSideViewVisibility, value});
    }, []);

    const handleToggleLocationDetailsSideView = useCallback(() => {
        dispatch({type: EventDetailsDispatchMessageType.ToggleShowLocationDetailsSideView});
    }, []);

    const handleToggleEventEditSideView = useCallback(() => {
        dispatch({type: EventDetailsDispatchMessageType.ToggleShowEventEditSideView});
    }, []);

    const loaded = locationContext.loaded && eventContext.loaded && !!eventData.event;
    return {
        ...state,
        ...eventData,
        loaded,
        handleSetShareExperienceSideViewVisibility,
        handleToggleLocationDetailsSideView,
        handleCall,
        handleShowItinerary,
        hasUserFullRights,
        handleToggleEventEditSideView,
        isOnMobile,
    }
}