import {useCallback, useContext, useRef} from "react";
import {ILocation} from "interfaces";
import {IUserContext, UserContext} from "services/UserContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {UserRole} from "../../interfaces/UserRole";
import {Immutable} from "../../interfaces/Immutable";
import {ILocationViewRef} from "../../components/LocationView/LocationView.interfaces";
import {InRangeRequest} from "../../interfaces/InRangeRequest";

export const useFavorites = () => {
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const locationViewRef = useRef<Immutable<ILocationViewRef>>();

    const userRole = userContext.currentUser?.role;
    const userFavoriteBuildingsIds = userContext.currentUser?.applicationSettings.favoriteBuildings;

    const handleFilterLocations = useCallback((locations: Immutable<Array<ILocation>>) => {
        const filteredLocations = locations.filter(l => userFavoriteBuildingsIds?.includes(l.id));
        if (userRole === UserRole.Basic)
            return filteredLocations.filter(l => l.isShow);
        return filteredLocations;
    }, [userRole, userFavoriteBuildingsIds]);

    const handleFetchLocations = useCallback(async (request: InRangeRequest) => {
        return await locationContext.fetchCurrentUserFavoritesLocations(request);
    }, []);

    return {
        handleFilterLocations,
        locationViewRef,
        handleFetchLocations
    }
}