export interface ILocationDetailsProps {
    locationId: string,
    hideEvents?: boolean,
}

export interface ILocationDetailsState {
    showReviewsSideView: boolean,
    showCreateEventSideView: boolean,
    showShareExperienceSideView: boolean,
    showEditLocationSideView: boolean,
    selectedEventId: string,
    showCopiedMessage: boolean,
    showFavoriteMessage: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum LocationDetailsDispatchMessageType {
    ToggleReviewsSideView,
    ToggleCreateEventSideView,
    ToggleShareExperienceSideView,
    ToggleEditLocationSideView,
    SetSelectedEventId,
    SetShowCopiedMessage,
    SetShowFavoriteMessage,
}

type ToggleReviewsSideView = {
    type: LocationDetailsDispatchMessageType.ToggleReviewsSideView,
};

type ToggleCreateEventSideView = {
    type: LocationDetailsDispatchMessageType.ToggleCreateEventSideView,
};

type ToggleShareExperienceSideView = {
    type: LocationDetailsDispatchMessageType.ToggleShareExperienceSideView,
};

type ToggleEditLocationSideView = {
    type: LocationDetailsDispatchMessageType.ToggleEditLocationSideView
}

type SetSelectedEventId = {
    type: LocationDetailsDispatchMessageType.SetSelectedEventId,
    id: string,
}

type SetShowCopiedMessage = {
    type: LocationDetailsDispatchMessageType.SetShowCopiedMessage,
    value: boolean,
}

type SetShowFavoriteMessage = {
    type: LocationDetailsDispatchMessageType.SetShowFavoriteMessage,
    value: boolean,
}

export type LocationDetailsDispatchMessage =
    ToggleReviewsSideView
    | ToggleCreateEventSideView
    | ToggleShareExperienceSideView
    | ToggleEditLocationSideView
    | SetSelectedEventId
    | SetShowCopiedMessage
    | SetShowFavoriteMessage
