import {AppView} from "../../interfaces";
import {instance} from "../AxiosConfig/AxiosConfig";
import {AxiosResponse} from "axios";
import {IApplicationSettings} from "../../views/Settings/Settings.interfaces";

const getUserSettings = (userId: string): Promise<IApplicationSettings> => {
    return new Promise<IApplicationSettings>(resolve => {
        return instance.get("/UserApplicationSettings/" + userId).then((response: AxiosResponse) => {
            return resolve(response.data);
        }).catch(_ => {
            return resolve({
                userAadId: userId,
                isSuperAdmin: false,
                isAdmin: false,
                isBrandAdmin: false,
                favoriteBrands: [],
                favoriteBuildings: [],
                homepage: AppView.AroundMe,
                lastCGURead: null,
            })
        })
    })
}

const updateUserSettings = (settings: IApplicationSettings): Promise<void> => {
    return new Promise<void>(resolve => {
        return instance.put("/UserApplicationSettings/" + settings.userAadId, settings).then(() => {
            return resolve();
        }).catch(_ => {
            instance.post("/UserApplicationSettings", settings).then(() => {
                return resolve();
            }).catch(_ => {
                throw new Error("Can't create user settings with id : " + settings.userAadId);
            })
        })
    })
}

export const UserSettingsApi = {
    getUserSettings,
    updateUserSettings
}