import {useActivityContext} from "./ActivityContext.hook";
import {IActivityContextProps} from "./ActivityContext.interfaces";
import {ActivityContext} from "./ActivityContext";

export const ActivityContextProvider = (props: IActivityContextProps) => {
    const value = useActivityContext(props);

    return (
        <ActivityContext.Provider value={value}>
            {props.children}
        </ActivityContext.Provider>
    )
}