import React from 'react';
import {useLocation, useNavigate, useParams,} from 'react-router-dom';

export interface WithRouterProps {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
}

export const withRouter = <Props extends object>(Component: React.ComponentClass<Props, any>) => {
    return (props: Props) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();

        return (
            <Component
                {...(props as Props & WithRouterProps)}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    };
};

export default withRouter;