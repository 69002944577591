import {Logic, LogicProps} from "./Kpi.interfaces";
import {useReducer} from "./Kpi.reducer";
import {ComponentSlotStyle} from "@fluentui/react-northstar";

export const KpiLogic = (props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}

export const useLogic = (props: LogicProps) => {
    const {state} = useReducer();

    const styles: ComponentSlotStyle = {};
    if (props.width) styles.gridColumn = `span ${props.width}`;
    if (props.height) styles.gridRow = `span ${props.height}`;

    return {
        ...props,
        ...state,
        styles,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////