import {CarouselDispatchMessage, CarouselDispatchMessageType, ICarouselState} from "./Carousel.interfaces";

export const initialState = (itemsLength: number, defaultCollapsed: boolean): ICarouselState => {
    return {
        index: 0,
        hideHeaderButtons: itemsLength <= 1,
        isCollapsed: defaultCollapsed,
    }
}

export const reducer = (prevState: ICarouselState, payload: CarouselDispatchMessage): ICarouselState => {
    const newState: ICarouselState = {...prevState};
    switch (payload.type) {
        case CarouselDispatchMessageType.SetHeaderButtonsVisibility:
            newState.hideHeaderButtons = !payload.value;
            return newState;
        case CarouselDispatchMessageType.ChangeIndex:
            newState.index = payload.value;
            return newState;
        case CarouselDispatchMessageType.ToggleCollapse:
            newState.isCollapsed = !prevState.isCollapsed;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}