import * as React from "react";
import {memo, useMemo} from "react";
import moment from "moment";
import {Button, CallIcon, Card, ChatIcon, Flex, Text} from "@fluentui/react-northstar";
import {IReviewCardProps} from "./ReviewCard.interfaces";
import {useReviewCard} from "./ReviewCard.hook";
import {UserPreview} from "components/UserPreview/UserPreview";
import {PopupPicture} from "../PopupPicture/PopupPicture";

const propsAreEqual = (prevProps: Readonly<IReviewCardProps>, nextProps: Readonly<IReviewCardProps>) => {
    return (
        prevProps.review.id === nextProps.review.id &&
        prevProps.review.review === nextProps.review.review &&
        prevProps.enableCommunicationActions === nextProps.enableCommunicationActions &&
        prevProps.showPicture === nextProps.showPicture
    )
}

export const ReviewCard = memo((props: IReviewCardProps) => {
    const logic = useReviewCard(props);
    const {reviewDate, review, picture} = props.review;

    const time = useMemo(() => (
        <Text color={"brand"} weight={"semibold"} truncated content={moment(reviewDate).format("LL")}/>
    ), [reviewDate]);

    const userPreview = useMemo(() => (
        <UserPreview userId={props.review.userAadId} showName/>
    ), [props.review.userAadId]);

    const reviewText = useMemo(() => !review ? null : (
        <Flex className={"w-100"}>
            <Text content={review}/>
        </Flex>
    ), [review]);

    const reviewPicture = useMemo(() => (!props.showPicture || !picture) ? null : (
        <PopupPicture src={picture} maxHeightDesktop={200} maxHeightMobile={100}/>
    ), [picture]);

    const communicationActions = useMemo(() => !props.enableCommunicationActions || logic.isCurrentUser ? null : (
        <Flex gap={"gap.smaller"}>
            <Button text iconOnly icon={<CallIcon outline/>} onClick={logic.handleCall}/>
            <Button text iconOnly icon={<ChatIcon outline/>} onClick={logic.handleSendMessage}/>
        </Flex>
    ), [props.enableCommunicationActions, logic.isCurrentUser, logic.handleSendMessage]);

    return (
        <Flex className={"w-100"}>
            <Card fluid className={"review-card"}>
                <Flex column gap={"gap.medium"}>
                    <Flex gap={"gap.small"} space={"between"} vAlign={"start"}>
                        <Flex gap={"gap.small"} vAlign={"center"}>
                            {userPreview}
                        </Flex>
                        {props.enableCommunicationActions ? communicationActions : time}
                    </Flex>
                    {reviewText}
                    {reviewPicture}
                </Flex>
            </Card>
        </Flex>
    )
}, propsAreEqual)