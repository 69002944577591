import * as React from "react";
import {memo} from "react";
import "./BrandCard.styles.scss";
import {Card, Flex} from "@fluentui/react-northstar";
import {IBrandCardProps} from "./BrandCard.interfaces";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";

const propsAreEqual = (prevProps: Readonly<IBrandCardProps>, nextProps: Readonly<IBrandCardProps>) => {
    return prevProps.brand.id === nextProps.brand.id && prevProps.isFlagship === nextProps.isFlagship;
}

export const BrandCard = memo((props: IBrandCardProps) => {
    const translate = useTranslation();

    let title = props.brand.formattedName;
    if (props.isFlagship) title = translate("Flagship") + ": " + title;

    return (
        <Flex className={"brand-card-container"} title={title}>
            <Card elevated fluid className={"brand-card " + (props.isFlagship ? "brand-card-flagship" : "")}>
                <props.brand.element height={40} width={40} className={"brand"}/>
            </Card>
        </Flex>
    )
}, propsAreEqual)