import {EventFormDispatchMessage, EventFormDispatchMessageType, IEventFormState} from "./EventForm.interfaces";

export const initialState: IEventFormState = {
    areDatesValid: true,
    isSendingEvent: false,
    showNotificationDialog: false,
}

export const reducer = (prevState: IEventFormState, payload: EventFormDispatchMessage): IEventFormState => {
    const newState: IEventFormState = {...prevState};
    const payloadType = payload.type;
    switch (payloadType) {
        case EventFormDispatchMessageType.SetDatesValidity:
            newState.areDatesValid = payload.value;
            return newState;
        case EventFormDispatchMessageType.SetNotificationDialogVisibility:
            newState.showNotificationDialog = payload.show;
            return newState;
        case EventFormDispatchMessageType.SetIsSendingEvent:
            newState.isSendingEvent = payload.value;
            return newState;
        default:
            throw new Error("Message " + EventFormDispatchMessageType[payloadType] + " not implemented");
    }
}