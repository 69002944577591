import {Logic} from "./PopupMenuButton.interfaces";
import "./PopupMenuButton.styles.scss";
import {Flex, MenuButton, Popup} from "@fluentui/react-northstar";
import React from "react";

export const PopupMenuButtonRendering = (logic: Logic) => {

    const trigger = (
        <Flex fill={logic.fillTrigger} onClick={logic.handleToggleOpen} ref={logic.triggerRef}
              {...(!logic.isTouchScreen && logic.on === "hover" && {onMouseEnter: logic.handleOpen})}
              {...(!logic.isTouchScreen && {onMouseLeave: logic.handleLeaveTrigger})}>
            {logic.trigger}
        </Flex>
    )

    const menuButton = (
        <MenuButton
            open
            on={"hover"}
            onClick={!logic.keepOpenOnClick ? logic.handleClose : logic.stopPropagation}
            className={"no-select " + (logic.className ?? "")}
            menu={logic.menu}
            {...(!logic.isTouchScreen && {
                mouseLeaveDelay: logic.mouseLeaveDelay,
                onOpenChange: logic.handleMenuOpenChange
            })}
        />
    )

    return (
        <Popup
            open={logic.open}
            offset={logic.offset ?? [0, 0]}
            closeOnScroll
            {...(!logic.isTouchScreen && {mouseLeaveDelay: logic.mouseLeaveDelay})}
            {...(logic.position && {position: logic.position})}
            {...(logic.align && {align: logic.align})}
            trigger={trigger}
            content={{
                className: "popup-menu-button",
                content: menuButton,
            }}
        />
    )
}