import {useLocationDetails} from "./LocationDetails.hook";
import {LocationImageLevels, LocationTypes} from "const";
import {LocationModule} from "modules";
import {
    AcceptIcon,
    AddIcon,
    ArrowSyncIcon,
    Button,
    CircleIcon,
    Divider,
    EyeSlashIcon,
    Flex,
    FlexItem,
    GlobeIcon,
    Image,
    ShareAltIcon,
    Skeleton,
    StarIcon,
    Text,
    Tooltip
} from "@fluentui/react-northstar";
import "./LocationDetails.styles.scss";
import React, {memo, useCallback, useMemo} from "react";
import {Badge, BrandCard, EventPreview, ReviewCard} from "components";
import {CallIcon, EditIcon, GoogleMapsIcon, LocationIcon, SmileyEditIcon} from "assets/icons";
import {Carousel} from "components/Carousel/Carousel";
import {SideView} from "components/SideView/SideView";
import {OpenHours} from "components/OpenHours/OpenHours";
import {EventForm} from "components/EventForm/EventForm";
import {ReviewForm} from "components/ReviewForm/ReviewForm";
import {ILocationDetailsProps} from "./LocationDetails.interfaces";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {EditLocationForm} from "../EditLocationForm/EditLocationForm";
import {EventDetails} from "../EventDetails/EventDetails";
import {FlagshipBadge} from "../FlagshipBadge/FlagshipBadge";
import {IReview} from "../../interfaces/IReview";
import {LocationImageLevel} from "../../interfaces";
import moment from "moment";
import {UserPreview} from "../UserPreview/UserPreview";
import {Immutable} from "../../interfaces/Immutable";

const propsAreEqual = (prevProps: Readonly<ILocationDetailsProps>, nextProps: Readonly<ILocationDetailsProps>) => {
    return prevProps.locationId === nextProps.locationId
}

export const LocationDetails = memo((props: ILocationDetailsProps) => {
    const translate = useTranslation();
    const logic = useLocationDetails(props);

    const flagshipBadge = useMemo(() => logic.location?.flagship === undefined ? null : (
        <Flex className={"location-details-flagship-badge"}>
            <FlagshipBadge brand={logic.location.flagship}/>
        </Flex>
    ), [logic.location?.flagship]);

    const badges = useMemo(() => !logic.location ? null : (
        <Flex className={"location-details-badges"} key={"badges"}>
            {/* Type */}
            <Badge label={LocationTypes[logic.location.type].shortLabel}/>
            {/* Price range */}
            <Badge label={LocationModule.getPriceRangeLabel(logic.location.priceRange)}/>
            {/* Popularity */}
            {logic.location.imageLevel === LocationImageLevel.Incomplete ? null :
                <Badge label={LocationImageLevels[logic.location.imageLevel].translationId}/>
            }
        </Flex>
    ), [logic.location?.type, logic.location?.priceRange, logic.location?.imageLevel]);

    const cover = useMemo(() => logic.location?.pictures?.length === 0 ? null : (
        <Flex className={"location-details-cover"}>
            {(logic.location?.pictures?.length ?? 0) <= 1 ?
                <Image fluid src={logic.location?.pictures[0] ?? ""}/>
                :
                <Carousel
                    items={logic.location?.pictures.map(url => <Image key={"img-" + url} fluid src={url}/>) ?? []}
                    slidesPerView={1}
                    spaceBetween={0}
                    showNavigationButtons
                    enablePagination
                    noPadding
                    noHeader
                />
            }
            {flagshipBadge}
            {badges}
        </Flex>
    ), [badges, flagshipBadge, logic.location?.pictures]);

    const openStateLabel = useMemo(() => (
        <Flex gap={"gap.small"} vAlign={"center"}>
            <CircleIcon
                size={"smaller"}
                styles={{
                    color: logic.location?.extendedProps?.openStateData?.isOpen ? "#68C92C" : "red",
                    marginTop: "2px"
                }}
            />
            <Text size={"medium"} content={logic.location?.extendedProps?.openStateData?.stateLabel}/>
        </Flex>
    ), [logic.location?.extendedProps?.openStateData]);

    const topActions = (
        <Flex gap={"gap.smaller"}>
            {!logic.location?.website ? null :
                <Button
                    text
                    iconOnly
                    icon={<GlobeIcon outline/>}
                    onClick={logic.handleOpenWebsite}
                />
            }
            {!logic.location?.googleMapsLink ? null :
                <Button
                    text
                    iconOnly
                    icon={<GoogleMapsIcon/>}
                    onClick={logic.handleShowOnGoogleMaps}
                />
            }
            {!logic.location?.googleMapsLink ? null :
                logic.isOnMobile ?
                    <Tooltip
                        open={logic.showCopiedMessage}
                        pointing
                        offset={[0, 10]}
                        content={translate("LinkCopied")}
                        trigger={
                            <Flex
                                className={"cursor-pointer"}
                                hAlign={"center"}
                                vAlign={"center"}
                                styles={{height: "32px", width: "32px"}}
                                onClick={logic.handleShare}>
                                <ShareAltIcon outline/>
                            </Flex>
                        }
                    />
                    :
                    <Button
                        text
                        iconOnly
                        icon={<ShareAltIcon outline/>}
                        onClick={logic.handleShare}
                    />
            }
            <Tooltip
                open={logic.showFavoriteMessage}
                pointing
                align={"end"}
                offset={[0, 10]}
                content={translate(logic.isLocationInFavoritesOfCurrentUser ? "AddedToFavorites" : "RemovedFromFavorites")}
                trigger={
                    <Button
                        text
                        iconOnly
                        icon={
                            <StarIcon
                                outline={false}
                                className={"favorite-btn-icon " + (logic.isLocationInFavoritesOfCurrentUser ? "fill" : "")}
                            />
                        }
                        onClick={logic.handleToggleLocationInFavorites}
                    />
                }
            />
        </Flex>
    );

    const topPart = !logic.location ? null : (
        <Flex key={"top-part"} className={"w-100"} column gap={"gap.small"} vAlign={"center"}>
            <Flex>
                {cover}
            </Flex>
            <Flex fill column gap={"gap.smaller"} className={"x-padding"}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    {!LocationModule.isLocationVisible(logic.location) ? <EyeSlashIcon/> : null}
                    <Text weight={"bold"} size={"large"} content={logic.location.name}/>
                    <FlexItem push>
                        {topActions}
                    </FlexItem>
                </Flex>
                {openStateLabel}
                {!logic.location.notes ? null :
                    <Flex>
                        <Text content={logic.location.notes}/>
                    </Flex>
                }
            </Flex>
        </Flex>
    );

    const renderReviewItems = useCallback((reviews: Immutable<Array<IReview>> | undefined, showSkeleton: boolean, showPictures: boolean) => {
        if (!showSkeleton)
            return reviews?.map(r => (
                <Flex className={"w-100"} key={"review-item-" + r.id} styles={{marginBottom: "8px"}}>
                    <ReviewCard review={r} showPicture={showPictures}/>
                </Flex>
            )) ?? [];
        return [
            <Skeleton animation="pulse" key={"review-skeleton"}>
                <Skeleton.Shape className={"skeleton-review"}/>
            </Skeleton>
        ];
    }, []);

    const reviewItemsWithoutPictures = useMemo(() => (
        renderReviewItems(logic.reviews, logic.showReviewsSkeletons, false)
    ), [logic.reviews, logic.showReviewsSkeletons]);

    const reviewItemsWithPictures = useMemo(() => (
        renderReviewItems(logic.reviews, logic.showReviewsSkeletons, true)
    ), [logic.reviews, logic.showReviewsSkeletons]);

    const reviewsPart = useMemo(() => reviewItemsWithoutPictures.length === 0 ? null : (
        <Carousel
            key={"reviews-carousel-part"}
            labelId={"Reviews"}
            items={reviewItemsWithoutPictures}
            onSeeAll={logic.handleToggleShowReviewsSideView}
        />
    ), [reviewItemsWithoutPictures]);

    const reviewsSideView = useMemo(() => reviewItemsWithPictures.length === 0 ? null : (
        <SideView
            key={"reviews-side-view"}
            align={"right"}
            show={logic.showReviewsSideView}
            onHide={logic.handleToggleShowReviewsSideView}
            title={translate("Reviews")}
            content={
                <Flex fill column gap={"gap.small"}>
                    {reviewItemsWithPictures}
                    <div style={{minHeight: "10px"}}/>
                </Flex>
            }
        />
    ), [logic.showReviewsSideView, reviewItemsWithPictures]);

    const createEventSideView = useMemo(() => !logic.location ? null : (
        <SideView
            key={"create-event-side-view"}
            align={"right"}
            show={logic.showCreateEventSideView}
            onHide={logic.handleToggleShowCreateEventSideView}
            title={translate("CreateEvent")}
            content={
                <EventForm
                    key={"event-form-" + logic.showCreateEventSideView}
                    onSubmitDone={logic.handleToggleShowCreateEventSideView}
                    locationId={logic.location.id}
                />
            }
        />
    ), [logic.showCreateEventSideView, !!logic.location]);

    const shareExperienceSideView = useMemo(() => !logic.location ? null : (
        <SideView
            key={"share-experience-side-view"}
            align={"right"}
            show={logic.showShareExperienceSideView}
            onHide={logic.handleToggleShowShareExperienceSideView}
            title={translate("ShareMyExperience")}
            content={
                <ReviewForm
                    key={"review-form-" + logic.showShareExperienceSideView}
                    onSubmitDone={logic.handleToggleShowShareExperienceSideView}
                    locationId={logic.location.id}
                />
            }
        />
    ), [logic.showShareExperienceSideView, !!logic.location]);

    const editLocationSideView = useMemo(() => !logic.location ? null : (
        <SideView
            key={"edit-location-side-view"}
            align={"right"}
            show={logic.showEditLocationSideView}
            onHide={logic.handleToggleEditLocationsSideView}
            title={translate("ModifyTheRecord")}
            content={
                <EditLocationForm
                    key={"edit-location-form-" + logic.showEditLocationSideView}
                    onSaveDone={logic.handleSaveUpdatedLocation}
                    location={logic.location}
                />
            }
            noPadding
        />
    ), [logic.showEditLocationSideView, !!logic.location]);

    const eventPreviewSideView = useMemo(() => (
        <SideView
            align={"right"}
            show={!!logic.selectedEventId}
            content={!logic.prevSelectedEventRef ? null :
                <EventDetails hideActions eventId={logic.prevSelectedEventRef?.id ?? ""}/>
            }
            onHide={logic.handleHideEvent}
            title={logic.prevSelectedEventRef?.name ?? ""}
            noPadding
        />
    ), [logic.selectedEventId]);

    const informationPart = useMemo(() => !logic.location ? null : (
        <Flex key={"information-part"} column gap={"gap.medium"} className={"w-100 x-padding"}>
            <Text size={"large"} content={translate("Information")}/>
            {!logic.location.address.geoPosition ? null :
                <Flex gap={"gap.small"} vAlign={"center"} className={"cursor-pointer"}
                      onClick={logic.handleShowItinerary}>
                    <LocationIcon/>
                    <Text content={logic.location.address.fullAddress}/>
                </Flex>
            }
            {!logic.isLocationPhoneAvailable ? null :
                <Flex gap={"gap.small"} vAlign={"center"} onClick={logic.isOnMobile ? logic.handleCall : undefined}>
                    <CallIcon/>
                    <Text content={LocationModule.formatPhoneNumber(logic.location.phone)}/>
                </Flex>
            }
            {!logic.showUpdateInformation ? null :
                <Flex styles={{gap: "5px"}} vAlign={"center"}>
                    <Flex gap={"gap.small"} vAlign={"center"}>
                        <ArrowSyncIcon/>
                        <Text styles={{whiteSpace: "nowrap"}} content={translate("UpdatedAtBy", {
                            at: moment(logic.location.updateAt).format("L"),
                            by: logic.location.updateBy !== "system" ? "" : translate("TheSystem").toLowerCase()
                        })}/>
                    </Flex>
                    {(!logic.location.updateBy || logic.location.updateBy === "system") ? null :
                        <UserPreview showAvatar={false} showName={true} userId={logic.location.updateBy}/>
                    }
                </Flex>
            }
        </Flex>
    ), [logic.location?.id, logic.isLocationPhoneAvailable, logic.showUpdateInformation, logic.location?.address]);

    const openHoursPart = useMemo(() => !logic.location ? null : (
        <OpenHours key={"open-hours-part"} hours={logic.location.hours}/>
    ), [logic.location?.id, logic.location?.hours]);

    const nextEventsItems = useMemo(() => {
        if (logic.showEventSkeleton) return [
            <Skeleton animation="pulse" key={"skeleton-event"}>
                <Skeleton.Shape className={"skeleton-event"}/>
            </Skeleton>
        ];
        return logic.nextEvents?.map(e => (
            <Flex key={"event-preview-" + e.id} className={"w-100"} styles={{marginBottom: "8px"}}>
                <EventPreview
                    onShow={logic.handleShowEvent(e.id)}
                    event={e}
                    horizontal={!logic.isOnMobile}
                    hideLocationData
                />
            </Flex>
        ));
    }, [logic.showEventSkeleton, logic.nextEvents]);

    const nextEventsPart = useMemo(() => logic.hideEvents ? null : (
        <Flex key={"next-events-part"} column gap={"gap.small"} className={"w-100"} vAlign={"center"}>
            <Carousel
                labelId={"NextEvents"}
                items={nextEventsItems}
                spaceBetween={15}
            />
            {!logic.hasUserFullRights ? null :
                <Flex className={"x-padding"}>
                    <Button
                        primary fluid icon={<AddIcon outline/>}
                        content={translate("CreateEvent")}
                        onClick={logic.handleToggleShowCreateEventSideView}
                    />
                </Flex>
            }
        </Flex>
    ), [logic.hideEvents, nextEventsItems, logic.hasUserFullRights]);

    const referencedBrandsPart = useMemo(() => !logic.referencedBrands ? null : (
        <Flex key={"referenced-brands-part"} column gap={"gap.small"} className={"w-100"} vAlign={"center"}>
            <Carousel
                labelId={"ReferencedBrands"}
                items={logic.referencedBrands.map(b => (
                    <Flex key={"brand-item-" + b.id} styles={{marginBottom: "8px"}}>
                        <BrandCard isFlagship={b.id === logic.location?.flagship} brand={b}/>
                    </Flex>
                ))}
                spaceBetween={10}
                slidesPerView={"auto"}
                slidesPerGroup={3}
                itemWidth={"55px"}
            />
        </Flex>
    ), [logic.referencedBrands]);

    const availableReferencesPart = useMemo(() => !logic.brandReferences ? null : (
        <Flex key={"available-references-part"} column gap={"gap.small"} className={"w-100 x-padding"}
              vAlign={"center"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Text size={"large"} content={translate("AvailableReferences")}/>
            </Flex>
            <Flex fill gap={"gap.small"} column>
                {logic.brandReferences.map(r => (
                    <Flex key={"brand-reference-" + r} gap={"gap.small"}>
                        <AcceptIcon outline/>
                        <Text content={r}/>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    ), [logic.brandReferences]);

    const sectorManagerPart = useMemo(() => !logic.sectorManagerReview?.userAadId ? null : (
        <Flex key={"sector-manager-part"} className={"x-padding"} column gap={"gap.small"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Text size={"large"} content={translate("SectorManager")}/>
            </Flex>
            <Flex fill gap={"gap.small"} column>
                <ReviewCard review={logic.sectorManagerReview} enableCommunicationActions/>
            </Flex>
        </Flex>
    ), [logic.sectorManagerReview?.review]);

    const actionsPart = useMemo(() => {
        const formattedPhoneNumber = LocationModule.formatPhoneNumber(logic.location?.phone ?? "");
        return (
            <Flex key={"actions-part"} column className={"w-100"} gap={"gap.small"}>
                {!logic.isLocationPhoneAvailable ? null :
                    logic.isOnMobile ?
                        <Button fluid content={formattedPhoneNumber} icon={<CallIcon/>} onClick={logic.handleCall}/>
                        :
                        <Flex styles={{marginTop: "-5px", height: "32px"}}
                              gap={"gap.smaller"} className={"w-100"} hAlign={"center"} vAlign={"center"}>
                            <CallIcon/>
                            <Text weight={"semibold"} content={formattedPhoneNumber}/>
                        </Flex>
                }
                <Button
                    primary fluid content={translate("ShareMyExperience")}
                    icon={<SmileyEditIcon className={"icon-primary"}/>}
                    onClick={logic.handleToggleShowShareExperienceSideView}
                />
                {(logic.hideEvents || !logic.hasUserFullRights) ? null :
                    <>
                        <Divider/>
                        <Button
                            fluid content={translate("EditLocation")}
                            onClick={logic.handleToggleEditLocationsSideView}
                            icon={<EditIcon/>}
                        />
                    </>
                }
            </Flex>
        )
    }, [logic.hasUserFullRights, logic.isLocationPhoneAvailable, logic.location?.phone]);

    if (!logic.loaded) return null;

    const elementsOrder = [
        topPart,
        informationPart,
        openHoursPart,
        reviewsPart,
        nextEventsPart,
        referencedBrandsPart,
        availableReferencesPart,
        sectorManagerPart
    ].filter(e => !!e);

    return (
        <Flex fill column className={"location-details"}>
            <Flex fill column gap={"gap.small"} className={"location-details-content overflow-scroll"}>
                {elementsOrder.map((e, index) => (
                    <React.Fragment key={e?.key}>
                        <Flex column>
                            {index > 0 ? <Divider styles={{padding: "10px 0"}}/> : null}
                            {e}
                        </Flex>
                    </React.Fragment>
                ))}
                <Flex styles={{marginBottom: "20px"}}/>
            </Flex>
            <Flex className={"x-padding y-padding actions"}>
                {actionsPart}
            </Flex>
            {reviewsSideView}
            {createEventSideView}
            {shareExperienceSideView}
            {editLocationSideView}
            {eventPreviewSideView}
        </Flex>
    )
}, propsAreEqual);