import React, {useMemo} from "react";
import {Button, Card, Divider, Flex, Image, Text} from "@fluentui/react-northstar";
import "./EventDetails.styles.scss";
import {useEventDetails} from "./EventDetails.hook";
import {BuildingsIcon, CallIcon, EditIcon, LocationIcon, SmileyEditIcon} from "../../assets/icons";
import moment from "moment";
import {Brands} from "../../const";
import {SideView} from "../SideView/SideView";
import {ReviewForm} from "../ReviewForm/ReviewForm";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {IEventDetailsProps} from "./EventDetails.interfaces";
import {LocationDetails} from "../LocationDetails/LocationDetails";
import {EventForm} from "components/EventForm/EventForm";
import {LocationModule} from "../../modules";
import MarkdownView from "react-showdown";

export const EventDetails = (props: IEventDetailsProps) => {
    const translate = useTranslation();
    const logic = useEventDetails(props);

    const eventStartDate = moment(logic.event.startDate);
    const eventEndDate = moment(logic.event.endDate);

    const cover = useMemo(() => !logic.event?.coverPictureUrl ? null : (
        <Flex key={"cover"} className={"event-details-cover"}>
            <Image fluid src={logic.event?.coverPictureUrl}/>
        </Flex>
    ), [logic.event.coverPictureUrl]);

    const topPart = useMemo(() => !logic.event ? null : (
        <Flex key={"top-part"} className={"w-100"} column gap={"gap.small"} vAlign={"center"}>
            <Flex>
                {cover}
            </Flex>
            <Flex fill column gap={"gap.smaller"} className={"x-padding"}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    <Text weight={"bold"} size={"large"} content={logic.event.name}/>
                </Flex>
                <Flex column gap={"gap.small"} vAlign={"center"}>
                    <Flex gap={"gap.smaller"}>
                        <Text
                            styles={{color: "darkgray"}} weight={"semibold"}
                            content={eventStartDate.format("L") + " " + eventStartDate.format("LT")}
                        />
                        {eventStartDate === eventEndDate ? null :
                            <>
                                <Text styles={{color: "darkgray"}} weight={"semibold"}
                                      content={" - "}/>
                                <Text
                                    styles={{color: "darkgray"}} weight={"semibold"}
                                    content={eventEndDate.format("L") + " " + eventEndDate.format("LT")}
                                />
                            </>
                        }
                    </Flex>
                    {!logic.event.notes ? null :
                        <MarkdownView
                            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                            className={"notes-viewer"}
                            markdown={logic.event.notes}
                        />
                    }
                </Flex>
            </Flex>
        </Flex>
    ), [logic.event.id, cover, logic.event.name, eventStartDate, eventEndDate, logic.event.notes]);

    const informationPart = useMemo(() => !logic.event ? null : (
        <Flex key={"information-part"} column gap={"gap.medium"} className={"w-100 x-padding"}>
            <Text size={"large"} content={translate("Information")}/>
            <Flex gap={"gap.small"} className={"cursor-pointer"} onClick={logic.handleShowItinerary}>
                <LocationIcon/>
                <Text content={logic.location.address.fullAddress}/>
            </Flex>
            {!logic.location.phone ? null :
                <Flex gap={"gap.small"} onClick={logic.isOnMobile ? logic.handleCall : undefined}>
                    <CallIcon/>
                    <Text content={LocationModule.formatPhoneNumber(logic.location.phone)}/>
                </Flex>
            }
            <Flex gap={"gap.small"}>
                <BuildingsIcon/>
                <Text content={logic.location.name}/>
            </Flex>
        </Flex>
    ), [!!logic.event]);

    const linkedBrandPart = useMemo((): JSX.Element | null => {
        if (!logic.event.linkedBrand || logic.event.linkedBrand.length === 0) return null;
        return (
            <Flex key={"linked-brand-part"} className={"x-padding"} column gap={"gap.small"}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    <Text size={"large"} content={translate("PartnerBrands")}/>
                </Flex>
                <Flex column className={"w-100"} gap={"gap.small"}>
                    {logic.event.linkedBrand.map(b => {
                        const brand = Brands[b];
                        const BrandIcon = brand.element;
                        const brandName = brand.formattedName;
                        return (
                            <Card fluid key={"brand" + b}>
                                <Flex gap={"gap.medium"} vAlign={"center"}>
                                    <BrandIcon width={30} height={30}/>
                                    <Text content={brandName}/>
                                </Flex>
                            </Card>
                        )
                    })}
                </Flex>
            </Flex>
        )
    }, [logic.event.linkedBrand]);

    const actionsPart = useMemo(() => props.hideActions ? null : (
        <>
            <Divider styles={{padding: 0}}/>
            <Flex className={"x-padding y-padding"}>
                <Flex key={"actions-part"} column className={"w-100"} gap={"gap.small"}>
                    <Button
                        fluid content={translate("ShowLocation")} icon={<BuildingsIcon/>}
                        onClick={logic.handleToggleLocationDetailsSideView}
                    />
                    <Button
                        primary fluid content={translate("ShareMyExperience")}
                        icon={<SmileyEditIcon className={"icon-primary"}/>}
                        onClick={logic.handleSetShareExperienceSideViewVisibility(true)}
                    />
                    {!logic.hasUserFullRights ? null :
                        <>
                            <Divider/>
                            <Button
                                fluid content={translate("EditEvent")}
                                onClick={logic.handleToggleEventEditSideView}
                                icon={<EditIcon/>}
                            />
                        </>
                    }
                </Flex>
            </Flex>
        </>
    ), []);

    const shareExperienceSideView = useMemo(() => props.hideActions || !logic.location ? null : (
        <SideView
            key={"share-experience-side-view"}
            align={"right"}
            show={logic.showShareExperienceSideView}
            onHide={logic.handleSetShareExperienceSideViewVisibility(false)}
            title={translate("ShareMyExperience")}
            content={
                <ReviewForm onSubmitDone={logic.handleSetShareExperienceSideViewVisibility(false)}
                            locationId={logic.location.id}/>
            }
        />
    ), [logic.showShareExperienceSideView]);

    const locationDetailsSideView = useMemo(() => props.hideActions ? null : (
        <SideView
            align={"right"}
            show={logic.showLocationDetailsSideView}
            content={<LocationDetails hideEvents locationId={logic.location.id}/>}
            onHide={logic.handleToggleLocationDetailsSideView}
            title={logic.location.name}
            noPadding
        />
    ), [logic.showLocationDetailsSideView]);

    const eventEditSideView = useMemo(() => props.hideActions ? null : (
        <SideView
            align={"right"}
            show={logic.showEventEditSideView}
            content={
                <EventForm
                    key={logic.showEventEditSideView + ""}
                    eventId={props.eventId}
                    locationId={logic.location.id}
                    onSubmitDone={logic.handleToggleEventEditSideView}
                />
            }
            onHide={logic.handleToggleEventEditSideView}
            title={translate("EditEvent")}
        />
    ), [logic.showEventEditSideView]);

    if (!logic.loaded) return null;

    const elementsOrder = [
        topPart,
        informationPart,
        linkedBrandPart
    ].filter(e => !!e);

    return (
        <Flex fill column className={"pos-relative overflow-hidden"}>
            <Flex fill column gap={"gap.small"} className={"overflow-scroll"} styles={{paddingBottom: "15px"}}>
                {elementsOrder.map((e, index) => (
                    <React.Fragment key={e?.key}>
                        <Flex column>
                            {index > 0 ? <Divider styles={{padding: "10px 0"}}/> : null}
                            {e}
                        </Flex>
                    </React.Fragment>
                ))}
            </Flex>
            {actionsPart}
            {shareExperienceSideView}
            {locationDetailsSideView}
            {eventEditSideView}
        </Flex>
    )
}