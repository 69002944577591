import {useContext, useMemo} from "react";
import {ItemDetailsProps, ItemType} from "./ItemDetails.interfaces";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "services/TeamsContext";
import {EventContext, IEventContext} from "services/EventContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useItemDetails = (props: ItemDetailsProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const eventContext = useContext<IEventContext>(EventContext);

    const isOnMobile = teamsContext.isOnMobile;

    const isItemExisting = useMemo(() => {
        if (props.itemId === undefined) return false;
        switch (props.itemType) {
            case ItemType.Location:
                return !!locationContext.findLocation(props.itemId);
            case ItemType.Event:
                return !!eventContext.list.find(e => e.id === props.itemId);
            case ItemType.BuildingKpi:
                return true;
            default:
                return false;
        }
    }, [props.itemId]);

    const itemName = useMemo(() => {
        if (!props.itemId || !isItemExisting) return;
        switch (props.itemType) {
            case ItemType.Location:
                const location = locationContext.findLocation(props.itemId);
                if (!location) throw new Error("Can't find location with id : " + props.itemId);
                return location.name;
            case ItemType.Event:
                const event = eventContext.list.find(e => e.id === props.itemId);
                if (!event) throw new Error("Can't find event with id : " + props.itemId);
                return event.name;
            case ItemType.BuildingKpi:
                return;
        }
    }, [props.itemId, props.itemType]);

    return {
        ...props,
        show: isItemExisting,
        isOnMobile,
        itemName,
    }
}