import {useCallback, useContext, useRef} from "react";
import {ILocationViewRef} from "components/LocationView/LocationView.interfaces";
import {ILocation} from "../../interfaces";
import {IUserContext, UserContext} from "../../services/UserContext";
import {UserRole} from "../../interfaces/UserRole";
import {Immutable} from "../../interfaces/Immutable";
import {InRangeRequest} from "../../interfaces/InRangeRequest";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";

export const useAroundMe = () => {
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const locationViewRef = useRef<Immutable<ILocationViewRef>>();

    const {currentUser} = userContext;

    const handleShowLocationDetails = useCallback((id: string) => {
        if (!locationViewRef.current) return;
        locationViewRef.current?.handleShowLocationDetails(id);
    }, []);

    const handleFilterLocations = useCallback((locations: Immutable<Array<ILocation>>) => {
        if (currentUser?.role === UserRole.Basic) return locations.filter(l => l.isShow);
        return locations;
    }, []);

    const handleFetchLocations = useCallback(async (request: InRangeRequest) => {
        return await locationContext.fetchLocations(request);
    }, []);

    return {
        handleShowLocationDetails,
        handleFilterLocations,
        handleFetchLocations,
        locationViewRef
    }
}