import {IUserContextProps} from "./UserContext.interfaces";
import {useUserContext} from "./UserContext.hook";
import {UserContext} from "./UserContext";
import {ArrowSyncIcon, Button, Flex, Image, Loader, Text} from "@fluentui/react-northstar";
import languageObject from "../../translations";

export const UserContextProvider = (props: IUserContextProps) => {
    const value = useUserContext(props.teamsContext, props.configuration);

    if (value.showPermissionPage) return (
        <Flex column fill gap={"gap.small"} padding={"padding.medium"} vAlign={"center"} hAlign={"center"}
              className={"fill-absolute overflow-hidden"}>
            <Flex className={"w-100"} column gap={"gap.small"} hAlign={"center"}>
                <Image styles={{width: "150px"}} fluid src={"/loginImage.svg"}/>
                <Text size={"larger"} weight={"bold"} content={languageObject.translate("AutorizePermissions")}/>
            </Flex>
            <Text size={"large"} weight={"semibold"} content={languageObject.translate("HowToProceed")}/>
            <Flex gap={"gap.medium"} column hAlign={"center"} className={"overflow-scroll"}>
                <Flex column gap={"gap.small"} className={"w-100"}>
                    {Array.from({length: 7}).map((_, i) => (
                        <Text content={(i + 1) + ". " + languageObject.translate("AutorizePermissionsStep" + (i + 1))}/>
                    ))}
                </Flex>
            </Flex>
            <Flex className="w-100" styles={{padding: "10px"}}>
                <Button
                    fluid
                    icon={<ArrowSyncIcon outline/>}
                    primary
                    content={languageObject.translate("Refresh")}
                    onClick={() => window.location.reload()}
                />
            </Flex>
        </Flex>
    )

    if (!value.loaded) return <Loader styles={{height: "100vh"}}/>;
    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    )
}