import {IActivity} from "interfaces";
import {instance} from "../AxiosConfig/AxiosConfig";

const getByUserId = (userId: string) => {
    return new Promise<Array<IActivity>>(resolve => {
        return instance.get("/Activity/byUser/" + userId).then(resp => {
            return resolve(resp.data);
        }).catch(_ => {
            throw new Error("Can't get activities of user with id : " + userId);
        });
    });
}

export const ActivitiesApi = {
    getByUserId
}