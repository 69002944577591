import * as imageConversion from 'image-conversion';
import * as microsoftTeams from "@microsoft/teams-js";

const optimizeImage = (content: string, maxSizeInKo: number = 500): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        const maxSize = 1000;
        img.onload = async () => {
            if (img.height > maxSize) {
                img.width = (maxSize * img.width) / img.height;
                img.height = maxSize;
            }
            if (img.width > maxSize) {
                img.height = (maxSize * img.height) / img.width;
                img.width = maxSize;
            }
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0, img.width, img.height);

            let contentType = content.split("data:")[1]?.split(";base64,")[0];

            switch (contentType) {
                case "image/webp":
                case "image/gif":
                case "image/svg+xml":
                case "image/png":
                    contentType = "image/png";
                    break;
                default:
                    contentType = "image/jpeg"
                    break;
            }

            canvas.toBlob(async (blob) => {
                if (!blob) reject("Blob image is undefined");
                else {
                    if (blob.size > maxSizeInKo * 1000) blob = await imageConversion.compressAccurately(blob, maxSizeInKo);
                    resolve(await imageConversion.filetoDataURL(blob))
                }
            }, contentType);
        };
        img.src = content;
    });
};

const showFullscreenImage = (url: string) => {
    showFullscreenImages([url]);
}

const showFullscreenImages = (urls: Array<string>) => {
    microsoftTeams.media.viewImages(urls.map(url => ({
        type: microsoftTeams.media.ImageUriType.URL,
        value: url
    })), (err) => {
        console.error(err);
    });
}

export const ImageModule = {
    optimizeImage,
    showFullscreenImage,
    showFullscreenImages
}