import {useCallback, useMemo, useRef} from "react";
import {ILocation} from "interfaces";
import {IRecommendationsProps} from "./Recommendations.interfaces";
import {Immutable} from "../../interfaces/Immutable";

export const useRecommendations = (props: IRecommendationsProps) => {
    const oldRecommendations = useRef<Immutable<Array<ILocation>>>([]);

    const getSlidesPerView = useCallback(() => {
        const documentWidth = document.body.offsetWidth;
        const idealSlideWidth = 400;
        const visibleElements = Math.floor(documentWidth / idealSlideWidth);
        return visibleElements;
    }, []);

    const recommendedLocations = useMemo((): Immutable<Array<ILocation>> => {
        let locations: Immutable<Array<ILocation>> = props.visibleLocations.filter(l => l.isRecommended);
        locations = locations.filter(l => !!l && l.extendedProps?.openStateData?.isOpen);
        if (locations.length === 0) return oldRecommendations.current;
        oldRecommendations.current = locations;
        return locations;
    }, [props.visibleLocations]);

    const slidesPerView = getSlidesPerView();

    const recommendedLocationsIds = useMemo(() => {
        return recommendedLocations.reduce((oldLoc, curLoc) => oldLoc + curLoc.id, "");
    }, [recommendedLocations])

    return {
        locations: recommendedLocations,
        slidesPerView,
        recommendedLocationsIds,
    }
}