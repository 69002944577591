import {
    EditLocationFormDispatchMessage,
    EditLocationFormDispatchMessageType,
    IEditLocationFormProps,
    IEditLocationFormState
} from "./EditLocationForm.interfaces";
import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";

const reduce = (state: IEditLocationFormState, payloads: Array<EditLocationFormDispatchMessage>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case EditLocationFormDispatchMessageType.ToggleRecommendedByPernodRicard:
                state.isRecommended = !state.isRecommended;
                break;
            case EditLocationFormDispatchMessageType.ToggleFlagship:
                state.hasFlagship = !state.hasFlagship;
                break;
            case EditLocationFormDispatchMessageType.RemovePicture:
                state.removedPictureUrls = state.removedPictureUrls.concat(payload.url);
                break;
            case EditLocationFormDispatchMessageType.RestorePicture:
                state.removedPictureUrls = state.removedPictureUrls.filter(url => url !== payload.url);
                break;
            case EditLocationFormDispatchMessageType.SetIsUpdatingLocation:
                state.isUpdatingLocation = payload.value;
                break;
            case EditLocationFormDispatchMessageType.SetNotificationDialogVisibility:
                state.showNotificationDialog = payload.show;
                break;
            case EditLocationFormDispatchMessageType.SetSelectedBrands:
                state.selectedBrands = payload.value;
                break;
            case EditLocationFormDispatchMessageType.SetAddress:
                state.fullAddress = payload.fullAdress;
                state.geoPosition = payload.geoPosition;
                break;
            default:
                throw new Error("Payload type " + payloadType + " not implemented");
        }
    });
}

const getInitialState = (props: IEditLocationFormProps): IEditLocationFormState => {
    const state: IEditLocationFormState = {
        hasFlagship: !!props.location.flagship,
        isRecommended: props.location.isRecommended,
        removedPictureUrls: [],
        isUpdatingLocation: false,
        showNotificationDialog: false,
        selectedBrands: [...props.location.brands],
        fullAddress: !props.location.address.geoPosition ? "" : props.location.address.fullAddress,
        geoPosition: props.location.address.geoPosition,
    };
    return state;
}

export const useReducer = (props: IEditLocationFormProps) => {
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Readonly<IEditLocationFormState>>(initialState);
    const [, render] = ReactReducer(() => [], []);

    const dispatch = useCallback((payload: EditLocationFormDispatchMessage | Array<EditLocationFormDispatchMessage>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, render};
}