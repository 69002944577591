export interface ICarouselRef {
    index: number,
    isLastIndex: boolean,
    swipePrevious: () => void,
    swipeNext: () => void,
    swipeTo: (index: number, speed: number) => void,
    prepend: (slides: Array<HTMLElement> | Array<string>) => void,
}

export interface ICarouselProps {
    items: Array<JSX.Element>,
    labelId?: string,
    icon?: JSX.Element,
    slidesPerView?: number | "auto",
    slidesPerGroup?: number,
    spaceBetween?: number,
    onClickItem?: (id: string) => () => void,
    onSeeAll?: () => void,
    itemWidth?: string,
    pushMoveButtons?: boolean,
    showNavigationButtons?: boolean,
    enablePagination?: boolean,
    noPadding?: boolean,
    noHeader?: boolean,
    onCarouselRef?: (carousel: ICarouselRef) => void,
    collapsible?: boolean,
    defaultCollapsed?: boolean,
}

export interface ICarouselState {
    index: number,
    hideHeaderButtons: boolean,
    isCollapsed: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum CarouselDispatchMessageType {
    ChangeIndex, SetHeaderButtonsVisibility, ToggleCollapse,
}

type ChangeIndex = {
    type: CarouselDispatchMessageType.ChangeIndex,
    value: number,
};

type SetHeaderButtonsVisibility = {
    type: CarouselDispatchMessageType.SetHeaderButtonsVisibility,
    value: boolean,
}

type ToggleCollapse = {
    type: CarouselDispatchMessageType.ToggleCollapse,
}

export type CarouselDispatchMessage = ChangeIndex | SetHeaderButtonsVisibility | ToggleCollapse;
