import {
    IPopupPictureState,
    PopupPictureDispatchMessage,
    PopupPictureDispatchMessageType
} from "./PopupPicture.interfaces";

export const initialState: IPopupPictureState = {
    showPictureFullscreen: false,
}

export const reducer = (prevState: IPopupPictureState, payload: PopupPictureDispatchMessage): IPopupPictureState => {
    const newState: IPopupPictureState = {...prevState};
    const payloadType = payload.type;
    switch (payloadType) {
        case PopupPictureDispatchMessageType.ToggleShowPictureFullscreen:
            newState.showPictureFullscreen = !prevState.showPictureFullscreen;
            return newState;
        default:
            throw new Error("Message " + PopupPictureDispatchMessageType[payloadType] + " not implemented");
    }
}