import {instance} from "../AxiosConfig/AxiosConfig";
import {IEvent, IRawEvent} from "interfaces/Event/IEvent";
import {Brand} from "../../interfaces/Brand/Brand";
import {IRawLocation} from "../../interfaces/Location/IRawLocation";

const parseRawEvent = (rawEvent: IRawEvent): IEvent => {
    const event: IEvent = {...rawEvent, linkedBrand: new Array<Brand>()};
    if (!event.startDate.endsWith("Z")) event.startDate += "Z";
    if (!event.endDate.endsWith("Z")) event.endDate += "Z";
    event.linkedBrand = rawEvent.linkedBrand?.map(b => parseInt(b));
    if (!!event.coverPictureUrl && !event.coverPictureUrl.startsWith("http"))
        event.coverPictureUrl = `https://${process.env.REACT_APP_BACK_URL}${event.coverPictureUrl}`;
    return event;
}

const convertToRawEvent = (event: IEvent): IRawEvent => {
    const rawEvent: IRawEvent = {...event, linkedBrand: new Array<string>()};
    rawEvent.linkedBrand = event.linkedBrand?.map(b => b + "");
    if (rawEvent.coverPictureUrl.startsWith("https://")) {
        const url = new URL(rawEvent.coverPictureUrl);
        rawEvent.coverPictureUrl = url.pathname + url.search;
    }
    return rawEvent;
}

const getFromGeoPositionAndDateRange = (lat: number, lng: number, radius: number, start: string, end: string):
    Promise<{ events: Array<IEvent>, buildings: Array<IRawLocation> }> => {
    return new Promise<{ events: Array<IEvent>, buildings: Array<IRawLocation> }>(resolve => {
        const queryParams = new URLSearchParams({
            lat: lat + "",
            lng: lng + "",
            radius: radius + "",
            start: start + "",
            end: end + ""
        }).toString();
        return instance.get<Array<{ event: IRawEvent, building: IRawLocation }>>("/events/inrange?" + queryParams)
            .then(resp => {
                const events = resp.data.map(item => parseRawEvent(item.event));
                const buildings = resp.data.map(item => item.building);
                return resolve({events, buildings});
            }).catch(_ => {
                throw new Error("Can't get locations from geolocation");
            });
    });
}

const createEvent = (event: IEvent) => {
    return new Promise<void>(resolve => {
        return instance.post(`/events`, convertToRawEvent(event)).then(() => {
            return resolve();
        }).catch(_ => {
            throw new Error("Can't create event");
        });
    });
}

const updateEvent = (event: IEvent) => {
    return new Promise<void>(resolve => {
        return instance.put(`/events/${event.id}`, convertToRawEvent(event)).then(() => {
            return resolve();
        }).catch(_ => {
            throw new Error("Can't update event");
        });
    });
}

const getByBuilding = (buildingId: string) => {
    return new Promise<Array<IEvent>>(resolve => {
        return instance.get("/events/byBuilding/" + buildingId).then(resp => {
            return resolve(resp.data.map(parseRawEvent));
        }).catch(_ => {
            throw new Error("Can't get events of building : " + buildingId);
        });
    });
}

export const EventsApi = {
    getFromGeoPositionAndDateRange,
    createEvent,
    getByBuilding,
    updateEvent,
}