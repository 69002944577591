import {memo} from "react";
import {Logic, LogicProps, Props} from "./BarChartKpi.interfaces";
import {ActiveElement, ChartEvent} from "chart.js";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const BarChartKpiLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {

    const noDataAvailable = props.values.length === 0;

    return {
        ...props,
        noDataAvailable,
        handleBarClick,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleBarClick = (onClick: Props["onClick"]) => (event: ChartEvent, elements: ActiveElement[]) => {
    if (!onClick || elements.length < 0) return;
    const clickedBar = elements[0];
    const index = clickedBar?.index;
    if (index !== undefined) onClick(index);
};