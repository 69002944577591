import React, {useContext} from "react";
import {PopupMenuButtonLogic} from "./PopupMenuButton.logic";
import {PopupMenuButtonRendering} from "./PopupMenuButton.rendering";
import {Props} from "./PopupMenuButton.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

const PopupMenuButton = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    return (
        <PopupMenuButtonLogic {...props} isTouchScreen={teamsContext.isTouchScreen}>
            {logic => <PopupMenuButtonRendering {...logic} children={logic.children}/>}
        </PopupMenuButtonLogic>
    )
}

export default PopupMenuButton;