import React, {memo, useCallback} from "react";
import {Avatar, CallVideoIcon, ChatIcon, Flex, MenuButton, RobotIcon, Skeleton, Text} from "@fluentui/react-northstar";
import {IUserPreviewProps} from "./UserPreview.interfaces";
import {IGraphUserData} from "services/GraphService";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {useUserPreview} from "./UserPreview.hook";
import {IUser} from "../../interfaces/IUser";

const propsAreEqual = (prevProps: Readonly<IUserPreviewProps>, nextProps: Readonly<IUserPreviewProps>): boolean => {
    return (
        prevProps.userId === nextProps.userId &&
        prevProps.showName === nextProps.showName
    )
}

export const UserPreview = memo((props: IUserPreviewProps) => {
    const translate = useTranslation();
    const logic = useUserPreview(props);

    const renderMenuButton = useCallback((user: IGraphUserData, trigger: JSX.Element) => {
        return (
            <Flex className={props.showName ? "w-100" : ""}>
                <MenuButton
                    className={"no-select" + (props.showName ? " w-100" : "")}
                    onClick={(e: any) => e.stopPropagation()}
                    trigger={trigger}
                    on={logic.isTouchScreen ? "click" : "hover"}
                    mouseLeaveDelay={0}
                    menu={[
                        {
                            key: "user-name",
                            content: (
                                <Text
                                    styles={{color: logic.isLightTheme ? "black" : "white"}}
                                    content={user.displayName}
                                    weight={"semibold"}
                                />
                            ),
                            disabled: true,
                        },
                        {
                            key: "send-message",
                            content: translate("SendMessage"),
                            icon: <ChatIcon outline/>,
                            onClick: logic.handleStartChat(user.userPrincipalName),
                        },
                        {
                            key: "call",
                            content: translate("Call"),
                            icon: <CallVideoIcon outline/>,
                            onClick: logic.handleStartCall(user.userPrincipalName)
                        }
                    ]}
                />
            </Flex>
        )
    }, [logic.handleStartChat, logic.handleStartCall, logic.isLightTheme]);

    const renderSystemPreview = useCallback((showName: boolean) => (
        <Flex className={"w-100"} vAlign={"center"} gap={"gap.small"}>
            <Flex>
                <Avatar className={"no-select"} icon={<RobotIcon/>} name={translate("System")}/>
            </Flex>
            {!showName ? null : <Text truncated size={"medium"} content={translate("System")}/>}
        </Flex>
    ), []);

    const renderUserPreview = useCallback((user: IUser, isCurrentUser: boolean, showName: boolean) => (
        <Flex className={"w-100 " + (!isCurrentUser ? "cursor-pointer" : "")} vAlign={"center"} gap={"gap.small"}>
            {!logic.showAvatar ? null :
                <Flex>
                    <Avatar className={"no-select"} image={user.picture} name={user.displayName}/>
                </Flex>
            }
            {!showName ? null : <Text truncated size={"medium"} content={user.displayName}/>}
        </Flex>
    ), []);

    const renderSkeleton = useCallback((userId: string, showName: boolean) => (
        <Skeleton key={"user-preview-skeleton-" + userId} animation="pulse"
                  title={translate("FetchingUserWithId", {userId})}>
            <Flex vAlign={"center"} gap={"gap.small"}>
                {!logic.showAvatar ? null : <Skeleton.Shape round width="32px" height="32px"/>}
                {!showName ? null : <Skeleton.Line width="150px" height="20px"/>}
            </Flex>
        </Skeleton>
    ), []);

    if (!logic.userId) return null;
    if (!logic.isSystem && !logic.user) return renderSkeleton(logic.userId, logic.showName ?? false);
    let preview: JSX.Element;
    if (logic.isSystem) preview = renderSystemPreview(logic.showName ?? false);
    else preview = renderUserPreview(logic.user!, logic.isCurrentUser, logic.showName ?? false);
    if (props.disableCommunication || logic.isCurrentUser || logic.isSystem) return preview;
    return renderMenuButton(logic.user!, preview);
}, propsAreEqual)