import {useFavorites} from "./Favorites.hook";
import React from "react";
import {AppView} from "../../interfaces";
import LocationView from "../../components/LocationView/LocationView";

export const Favorites = () => {
    const logic = useFavorites();

    return (
        <LocationView
            view={AppView.Favorites}
            filterLocations={logic.handleFilterLocations}
            externalRef={logic.locationViewRef}
            fetchLocations={logic.handleFetchLocations}
        />
    )
}