import React, {useCallback, useContext, useMemo, useRef} from "react";
import {EditLocationFormDispatchMessageType, IEditLocationFormProps} from "./EditLocationForm.interfaces";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {Brands, LocationImageLevels, LocationTypes} from "const";
import {Brand} from "interfaces/Brand/Brand";
import {Checkbox, DropdownItemProps, DropdownProps, Flex, Text} from "@fluentui/react-northstar";
import {
    ILocationContext,
    ILocationCustomUpdateData,
    ILocationInternalUpdateData
} from "../../services/LocationContext/LocationContext.interfaces";
import {IUserContext, UserContext} from "../../services/UserContext";
import {UserRole} from "../../interfaces/UserRole";
import {LocationModule} from "../../modules";
import {ImageModule} from "../../modules/Image.module";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {LocationImageLevel, LocationType, PriceRange, WeekDay} from "../../interfaces";
import {OpenHoursFormRef} from "./OpenHoursForm/OpenHoursForm.interfaces";
import {useReducer} from "./EditLocationForm.reducer";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {BuildingsApi} from "../../apis/Buildings/BuildingsApi";

export const useEditLocationForm = (props: IEditLocationFormProps) => {
    const translate = useTranslation();
    const userContext = useContext<IUserContext>(UserContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const {state, dispatch} = useReducer(props);
    const openHoursFormRef = useRef<Readonly<OpenHoursFormRef>>();

    const userRole = userContext.currentUser?.role ?? UserRole.Basic;

    const handleToggleIsRecommended = useCallback(() => {
        dispatch({type: EditLocationFormDispatchMessageType.ToggleRecommendedByPernodRicard});
    }, []);

    const handleToggleHasFlagship = useCallback(() => {
        dispatch({type: EditLocationFormDispatchMessageType.ToggleFlagship});
    }, []);

    const handleRemovePicture = useCallback((url: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch({type: EditLocationFormDispatchMessageType.RemovePicture, url});
    }, []);

    const handleRestorePicture = useCallback((url: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch({type: EditLocationFormDispatchMessageType.RestorePicture, url});
    }, []);

    const handleChangeSelectedBrands = useCallback((event: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => {
        const items = data?.value as Array<DropdownItemProps & { value: Brand }>;
        const selectedBrands = items.map(item => item.value);
        dispatch({type: EditLocationFormDispatchMessageType.SetSelectedBrands, value: selectedBrands});
    }, []);

    const handleFetchAddressSuggestions = useCallback(async (filter: string): Promise<Array<ILocationSuggestion>> => {
        if (!filter) return [];
        return await BuildingsApi.getSuggestions({
            buildingIds: null,
            includeHiddenBuildings: false,
            searchFilter: filter,
            language: teamsContext.locale,
        }, true);
    }, []);

    const handleSelectAddressSuggestion = useCallback((suggestion: ILocationSuggestion) => {
        dispatch({
            type: EditLocationFormDispatchMessageType.SetAddress,
            fullAdress: suggestion.name,
            geoPosition: suggestion.geoPosition,
        })
    }, []);

    const handleClearSelectedAddress = useCallback(() => {
        if (!state.fullAddress) return;
        dispatch({type: EditLocationFormDispatchMessageType.SetAddress, fullAdress: "", geoPosition: undefined});
    }, []);

    const canEditAdminFields = [UserRole.SuperAdmin, UserRole.Admin].includes(userRole) ||
        (userRole === UserRole.SectorManager && props.location.sectorManagerId === userContext.currentUser?.id);

    const canEditBrandAmbassadorFields = canEditAdminFields || userRole === UserRole.BrandAmbassador;

    const brandsItems = useMemo((): Array<DropdownItemProps & { value: Brand }> => {
        return Object.values(Brands).map(brand => ({
            key: "brand-" + brand.id,
            value: brand.id,
            header: brand.formattedName,
            className: "brand-dropdown-item",
            image: brand.iconPath
        }));
    }, []);

    const isLocationComplete = LocationModule.isLocationComplete(props.location);

    const buildingDetailsForm = useForm({
        locale: teamsContext.fullLocale,
        fluid: true,
        disabled: state.isUpdatingLocation || isLocationComplete,
        items: isLocationComplete ? [] : [
            {
                id: "building",
                type: "title",
                value: translate("LocationDetails"),
            },
            {
                id: "name",
                type: "input",
                required: true,
                initialValue: props.location.name,
                label: translate("Name"),
                placeholder: translate("EnterName"),
                clearable: false,
            },
            {
                id: "type",
                type: "dropdown",
                required: true,
                initialValue: props.location.type + "",
                label: translate("Type"),
                placeholder: translate("SelectAType"),
                items: Object.keys(LocationTypes),
                multiple: false,
                renderItem: itemId => {
                    const type = LocationTypes[itemId as LocationType];
                    if (!type) return <></>;
                    const Icon = type.element;
                    return (
                        <Flex gap={"gap.small"} vAlign={"center"}>
                            <Icon/>
                            <Text size={"medium"} content={translate(type.translationId)}/>
                        </Flex>
                    )
                },
                renderSelectedItem: itemId => translate(LocationTypes[itemId as LocationType]?.translationId),
                clearable: false,
            },
            {
                id: "imageLevel",
                type: "dropdown",
                required: true,
                initialValue: props.location.imageLevel + "",
                label: translate("ImageLevel"),
                placeholder: translate("SelectAnImageLevel"),
                items: Object.keys(LocationImageLevels).filter(key => key !== LocationImageLevel.Incomplete + ""),
                multiple: false,
                renderItem: itemId => {
                    const imageLevel = LocationImageLevels[itemId as LocationImageLevel];
                    if (!imageLevel) return <></>;
                    return <Text size={"medium"} content={translate(imageLevel.translationId)}/>
                },
                renderSelectedItem: itemId => translate(LocationImageLevels[itemId as LocationImageLevel]?.translationId),
                clearable: false,
            },
            {
                id: "priceRange",
                required: true,
                type: "radioGroup",
                label: translate("PriceRange"),
                initialValue: PriceRange.Medium,
                items: [
                    {label: "€", value: PriceRange.Low},
                    {label: "€€", value: PriceRange.Medium},
                    {label: "€€€", value: PriceRange.High},
                    {label: "€€€€", value: PriceRange.Expensive},
                ]
            },
            {
                id: "phone",
                type: "input",
                inputMode: "tel",
                initialValue: props.location.phone,
                label: translate("Phone"),
                placeholder: translate("EnterPhoneNumber"),
            },
            {
                id: "website",
                type: "input",
                initialValue: props.location.website,
                label: translate("Website"),
                placeholder: translate("EnterWebsite"),
            },
            {
                id: "picture",
                type: "imagePicker",
                initialValue: props.location.pictures?.[0],
                label: translate("Picture")
            }
        ]
    }, [isLocationComplete]);

    const buildingCustomForm = useForm({
        locale: teamsContext.fullLocale,
        fluid: true,
        disabled: state.isUpdatingLocation,
        items: [
            {
                id: "show-section",
                type: "title",
                value: translate("Visibility"),
                hidden: !canEditAdminFields,
            },
            {
                id: "show",
                type: "checkbox",
                toggle: true,
                initialValue: LocationModule.isLocationVisible(props.location),
                label: translate("ShowLocation"),
                hidden: !canEditAdminFields,
                disabled: !canEditAdminFields || !state.geoPosition || state.isUpdatingLocation,
            },
            {
                id: "divider-show",
                type: "divider",
                hidden: !canEditAdminFields,
            },
            {
                id: "promotion-section",
                type: "title",
                value: translate("Promotion")
            },
            {
                id: "recommendNote",
                type: "textArea",
                placeholder: translate("SpecifyWhyYouWantToPromoteThisClient"),
                label: translate("RecommendedByPernodRicard"),
                disabled: !canEditBrandAmbassadorFields || !state.isRecommended,
                initialValue: props.location.recommendNote,
                topRightElement: (
                    <Checkbox
                        toggle
                        disabled={state.isUpdatingLocation}
                        checked={state.isRecommended}
                        labelPosition={"start"}
                        onChange={handleToggleIsRecommended}
                        styles={{marginTop: "-5px", marginBottom: "2px"}}
                    />
                )
            },
            {
                id: "flagship",
                type: "dropdown",
                placeholder: translate("SelectBrand"),
                label: translate("Flagship"),
                multiple: false,
                initialValue: props.location.flagship,
                disabled: !canEditBrandAmbassadorFields || !state.hasFlagship || state.isUpdatingLocation,
                items: Object.keys(Brands),
                clearable: false,
                renderItem: (item) => {
                    const brand = Brands[item as Brand];
                    if (!brand) return "";
                    const BrandIcon = brand.element;
                    return (
                        <Flex fill vAlign={"center"} gap={"gap.smaller"}>
                            <BrandIcon height={25} width={25}/>
                            <Text size={"medium"} content={brand.formattedName}/>
                        </Flex>
                    )
                },
                renderSelectedItem: (item) => {
                    const brand = Brands[item as Brand];
                    return brand?.formattedName;
                },
                topRightElement: (
                    <Checkbox
                        toggle
                        disabled={state.isUpdatingLocation}
                        checked={state.hasFlagship}
                        labelPosition={"start"}
                        onChange={handleToggleHasFlagship}
                        styles={{marginTop: "-5px", marginBottom: "2px"}}
                    />
                )
            },
            {
                id: "divider-2",
                type: "divider",
                hidden: !canEditAdminFields,
            },
            {
                id: "sector-manager-section",
                type: "title",
                value: translate("SectorManager"),
                hidden: !canEditAdminFields,
            },
            {
                id: "sectorManagerReview",
                type: "textArea",
                label: translate("LocationReview"),
                placeholder: translate("ProvideUsefulInformationToPernodRicardColleaguesWhoVisitThisFacility"),
                initialValue: props.location.sectorManagerReview,
                hidden: !canEditAdminFields,
                minHeight: "80px",
            }
        ]
    }, [state.isRecommended, state.hasFlagship, state.isUpdatingLocation, state.geoPosition]);

    const getUpdatedInternalFields = useCallback(() => {
        if (LocationModule.isLocationComplete(props.location)) return null;
        const internalUpdatedFields: ILocationInternalUpdateData = {
            brands: state.selectedBrands.map(b => Brand[b] ?? "").filter(b => b),
            addressFull: state.fullAddress,
            addressLatitude: state.geoPosition?.lat ?? 0,
            addressLongitude: state.geoPosition?.lng ?? 0,
            imageLevel: Number(buildingDetailsForm.dataRef.current["imageLevel"]) as LocationImageLevel,
            name: buildingDetailsForm.dataRef.current["name"] as string,
            hours: {
                monday: openHoursFormRef.current?.data?.[WeekDay.Monday] ?? [],
                tuesday: openHoursFormRef.current?.data?.[WeekDay.Tuesday] ?? [],
                wednesday: openHoursFormRef.current?.data?.[WeekDay.Wednesday] ?? [],
                thursday: openHoursFormRef.current?.data?.[WeekDay.Thursday] ?? [],
                friday: openHoursFormRef.current?.data?.[WeekDay.Friday] ?? [],
                saturday: openHoursFormRef.current?.data?.[WeekDay.Saturday] ?? [],
                sunday: openHoursFormRef.current?.data?.[WeekDay.Sunday] ?? [],
            },
            phone: buildingDetailsForm.dataRef.current["phone"] as string,
            picture: buildingDetailsForm.dataRef.current["picture"] as string,
            priceRange: Number(buildingDetailsForm.dataRef.current["priceRange"]) as PriceRange,
            type: Number(buildingDetailsForm.dataRef.current["type"]) as LocationType,
            website: buildingDetailsForm.dataRef.current["website"] as string,
        }
        return internalUpdatedFields;
    }, []);

    const getUpdatedCustomFields = useCallback(() => {
        const customUpdatedFields: ILocationCustomUpdateData = {
            isShow: buildingCustomForm.dataRef.current["show"] as boolean,
            isRecommended: state.isRecommended,
            recommendNote: buildingCustomForm.dataRef.current["recommendNote"] as string ?? "",
            sectorManagerReview: buildingCustomForm.dataRef.current["sectorManagerReview"] as string ?? "",
            pictures: props.location.pictures.filter(url => !state.removedPictureUrls.includes(url)),
            notes: "",
        }
        if (!!buildingCustomForm.dataRef.current["flagship"])
            customUpdatedFields.flagship = Number(buildingCustomForm.dataRef.current["flagship"]);
        return customUpdatedFields;
    }, []);

    const handleSave = useCallback(async () => {
        dispatch({type: EditLocationFormDispatchMessageType.SetIsUpdatingLocation, value: true});
        const customFields = getUpdatedCustomFields();
        const internalFields = getUpdatedInternalFields();
        await locationContext.updateLocation(props.location.id, customFields, internalFields);
        dispatch([
            {type: EditLocationFormDispatchMessageType.SetIsUpdatingLocation, value: false},
            {type: EditLocationFormDispatchMessageType.SetNotificationDialogVisibility, show: true}
        ]);
        setTimeout(() => {
            dispatch({type: EditLocationFormDispatchMessageType.SetNotificationDialogVisibility, show: false});
            setTimeout(() => {
                props.onSaveDone();
            }, 200)
        }, 1500)
    }, [props.location.id, state.isRecommended, state.removedPictureUrls, props.onSaveDone]);

    const isValid = buildingDetailsForm.isValid && buildingCustomForm.isValid && !!state.geoPosition;

    const hideBuildingDetailsForm = !canEditAdminFields || LocationModule.isLocationComplete(props.location);

    const eliteAddress = props.location.address.fullAddress;

    return {
        ...state,
        buildingDetailsForm,
        buildingCustomForm,
        isValid,
        handleRemovePicture,
        handleRestorePicture,
        handleSave,
        canEditAdminFields,
        canEditBrandAmbassadorFields,
        handleShowImageFullscreen,
        initialHours: props.location.hours,
        hideBuildingDetailsForm,
        brandsItems,
        location: props.location,
        handleChangeSelectedBrands,
        openHoursFormRef,
        handleFetchAddressSuggestions,
        handleSelectAddressSuggestion,
        handleClearSelectedAddress,
        eliteAddress,
        isLocationComplete,
    }
}

const handleShowImageFullscreen = (url: string) => () => {
    ImageModule.showFullscreenImage(url);
}