import {AppView, IAppView} from "interfaces";
import {BellIcon, BuildingsIcon, CalendarIcon, LocationIcon, SettingsIcon, StarIcon} from "assets/icons";
import {AroundMe as AroundMeElement} from "views/AroundMe/AroundMe";
import {Activity as ActivityElement} from "views/Activity/Activity";
import {Favorites as FavoritesElement} from "views/Favorites/Favorites";
import {Events as EventsElement} from "views/Events/Events";
import {Buildings as BuildingsElement} from "views/Buildings/Buildings";
import {Settings as SettingsElement} from "views/Settings/Settings";
import {Dashboard as DashboardElement} from "views/Dashboard/Dashboard";
import React from "react";
import {ContentIcon} from "@fluentui/react-northstar";

const Activity: IAppView = {
    id: AppView.Activity,
    icon: <BellIcon/>,
    translationId: "Activity",
    path: "/activity",
    element: ActivityElement,
}

const AroundMe: IAppView = {
    id: AppView.AroundMe,
    icon: <LocationIcon/>,
    translationId: "AroundMe",
    path: "/around",
    element: AroundMeElement,
}

const Events: IAppView = {
    id: AppView.Events,
    icon: <CalendarIcon/>,
    translationId: "Events",
    path: "/events",
    element: EventsElement,
}

const Favorites: IAppView = {
    id: AppView.Favorites,
    icon: <StarIcon/>,
    translationId: "Favorites",
    path: "/favorites",
    element: FavoritesElement,
}

const Buildings: IAppView = {
    id: AppView.Buildings,
    icon: <BuildingsIcon/>,
    translationId: "Buildings",
    path: "/buildings",
    element: BuildingsElement,
}

const Settings: IAppView = {
    id: AppView.Settings,
    icon: <SettingsIcon/>,
    translationId: "Settings",
    path: "/settings",
    element: SettingsElement,
}

const Dashboard: IAppView = {
    id: AppView.Dashboard,
    icon: <ContentIcon outline size={"large"}/>,
    translationId: "Dashboard",
    path: "/dashboard",
    element: DashboardElement,
}

export const AppViews: Record<AppView, IAppView> = {
    [AppView.Activity]: Activity,
    [AppView.AroundMe]: AroundMe,
    [AppView.Events]: Events,
    [AppView.Favorites]: Favorites,
    [AppView.Buildings]: Buildings,
    [AppView.Settings]: Settings,
    [AppView.Dashboard]: Dashboard,
}