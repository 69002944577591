import {AppView} from "interfaces";
import {AppDispatchMessage, AppDispatchMessageType, IAppState} from "./App.interfaces";
import {AppViews} from "const";

export const initialState = (homePage: AppView | undefined): IAppState => {
    const view = Object.values(AppViews).find(v => {
        return v.id === homePage;
    }) ?? AppViews[AppView.AroundMe];
    return {
        currentView: view,
        openMenu: false,
    }
}

export const reducer = (prevState: IAppState, payload: AppDispatchMessage): IAppState => {
    const newState: IAppState = {...prevState};
    switch (payload.type) {
        case AppDispatchMessageType.ToggleOpenMenu:
            newState.openMenu = !prevState.openMenu;
            return newState;
        case AppDispatchMessageType.ChangeAppView:
            newState.currentView = payload.view;
            newState.openMenu = false;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}