import {StorageType} from "./CacheService.interfaces";

const CacheService = (storageType: StorageType) => {
    const getItem = <T>(key: string): T | null => {
        const storage = getStorage();
        const itemString = storage.getItem(key);
        if (!itemString) return null;
        return JSON.parse(itemString) as T;
    }

    const setItem = <T>(key: string, value: T) => {
        const storage = getStorage();
        storage.setItem(key, JSON.stringify(value));
    }

    const removeItem = (key: string) => {
        const storage = getStorage();
        storage.removeItem(key);
    }

    const removeItems = (keys: Array<string>) => {
        const storage = getStorage();
        keys.forEach(key => storage.removeItem(key));
    }

    const clear = () => {
        const storage = getStorage();
        storage.clear();
    }

    const getAndRemoveItem = <T>(key: string): T | null => {
        const item = getItem<T>(key);
        removeItem(key);
        return item
    }

    const getStorage = (): Storage => {
        let storage: Storage;
        switch (storageType) {
            case StorageType.Local:
                storage = localStorage;
                break;
            case StorageType.Session:
                storage = sessionStorage;
                break;
            default:
                throw new Error("Invalid storage type : " + storageType);
        }
        if (!storage) throw new Error(StorageType[storageType] + " storageType is not available");
        return storage;
    }

    return {
        getItem,
        setItem,
        removeItem,
        removeItems,
        clear,
        getAndRemoveItem
    }
}

export const LocalCacheService = CacheService(StorageType.Local);

export const SessionCacheService = CacheService(StorageType.Session);

export const useCacheService = (storageType: StorageType) => {
    return storageType === StorageType.Local ? LocalCacheService : SessionCacheService;
}