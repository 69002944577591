import React from "react";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {Props} from "./NumberKpi.interfaces";

const NumberKpi = (props: Props) => {
    return (
        <Flex column fill>
            {!props.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={props.title}/>
                </Flex>
            )}
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex column fill vAlign={"center"} hAlign={"center"}>
                    <Text
                        styles={{fontSize: "4rem"}}
                        weight={"bold"}
                        content={props.value ?? "0"}
                    />
                    <Text size={"large"} content={props.subtitle}/>
                </Flex>
            }
        </Flex>
    );
}

export default NumberKpi;