export enum Brand {
    Aberlour,
    Absolut_Elyx,
    Absolut,
    Altos,
    Ararat,
    Augier,
    Avion,
    Ballantine__s,
    Becherovka,
    Beefeater,
    Blenders_Pride,
    Brancott_Estate,
    Campo_Viejo,
    Ceder__s,
    Chivas_Regal,
    Church_Road,
    Clan_Campbell,
    Del_Maguey,
    George_Wyndham,
    Green_Spot,
    Havana_Club,
    Imperial_Blue,
    Imperial,
    Italicus,
    Jacob__s_Creek,
    Jameson,
    Jefferson__s,
    JP_Wiser,
    Kahlua,
    Kenwood,
    KI_NO_BI,
    Lillet,
    Long_John,
    Lorbe,
    Lot_no_40,
    Malfy,
    Malibu,
    Martell,
    Method_And_Madness,
    Midleton_Very_Rare,
    Minttu,
    Monkey_47,
    GH_Mumm,
    Olmeca,
    Ostoya,
    Passport_Scotch,
    Pastis_51,
    Pernod,
    Perrier_Jouet,
    Pipers_100,
    Plymouth_gin,
    Powers,
    Rabbit_Hole,
    Ramazzotti,
    Redbreast,
    Ricard,
    Royal_Salute,
    Royal_Stag,
    Scapa,
    Seagrams_Gin,
    Secret_Speyside,
    Smooth_Ambler,
    Something_Special,
    St_Hugo,
    Stoneleigh,
    Suze,
    The_Glenlivet,
    Tx,
    Ungava,
    Wyborowa_Wodka,
    Ysios,
}

