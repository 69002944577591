import {ShorthandCollection} from "@fluentui/react-northstar/dist/es/types";
import {InputProps, MenuItemProps, PopupProps} from "@fluentui/react-northstar";
import * as React from "react";
import {Immutable} from "../../interfaces/Immutable";

export interface IFilterState {
    items: Immutable<Array<IFilterItem>>,
    open: boolean,
}

export interface IFilterProps {
    items: Readonly<Immutable<Array<IFilterItem>>>,
    onUpdateItems: (items: Array<IFilterItem>) => void,
}

export interface IFilterItem {
    key: string,
    value?: string | number | undefined,
    label: string,
    icon?: JSX.Element | undefined,
    isChecked?: boolean | undefined,
    menu?: Array<IFilterItem> | undefined,
    input?: {
        unit: {
            label: string,
            width: number,
        }
        value: string | number,
        validate?: ((value: string | number) => boolean) | undefined,
        type?: "tel" | undefined,
    } | undefined
}

export interface IFilterLogic {
    open: boolean,
    handleOpen: () => void,
    handleClose: () => void,
    handleToggleOpen: () => void,
    items: ShorthandCollection<MenuItemProps>,
    handleToggleItem: (itemKey: string) => (e: React.SyntheticEvent) => void,
    handleFilterItemInputValueChange: (itemKey: string) => (e: React.SyntheticEvent, data?: InputProps) => void,
    handleClearFilters: (e: React.SyntheticEvent) => void,
    handlePopupHoverOpenChange: (e: React.SyntheticEvent, data?: PopupProps) => void,
    isOnMobile: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum FilterDispatchMessageType {
    ToggleCheck,
    Open,
    Close,
    ToggleOpen,
    InputValueChange
}

type ToggleCheck = {
    type: FilterDispatchMessageType.ToggleCheck,
    itemKey: string,
};

type Open = {
    type: FilterDispatchMessageType.Open
}

type Close = {
    type: FilterDispatchMessageType.Close
}

type ToggleOpen = {
    type: FilterDispatchMessageType.ToggleOpen
}

type InputValueChange = {
    type: FilterDispatchMessageType.InputValueChange,
    itemKey: string,
    value: string | number,
}

export type FilterDispatchMessage = ToggleCheck | InputValueChange | Open | Close | ToggleOpen;