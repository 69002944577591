import {AppView} from "interfaces";
import {BuildingsIcon, CalendarIcon, LocationIcon, StarIcon} from "assets/icons";

const getViewIcon = (view: AppView, size: number): JSX.Element | null => {
    switch (view) {
        case AppView.AroundMe:
            return <LocationIcon width={size} height={size}/>;
        case AppView.Events:
            return <CalendarIcon width={size} height={size}/>;
        case AppView.Favorites:
            return <StarIcon width={size} height={size}/>;
        case AppView.Buildings:
            return <BuildingsIcon width={size} height={size}/>;
        default:
            return null;
    }
}

export const AppModule = {
    getViewIcon,
}