import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import {ILocationTableColumn, LocationTableAction, Logic, LogicProps} from "./LocationTable.interfaces";
import {useReducer} from "./LocationTable.reducer";
import {AppView, ILocation} from "../../interfaces";
import {MenuItemProps} from "@fluentui/react-northstar";
import {LocationModule} from "../../modules";
import {UserRole} from "../../interfaces/UserRole";
import {Immutable} from "../../interfaces/Immutable";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return (
        prevProps.show === nextProps.show &&
        prevProps.locations === nextProps.locations &&
        prevProps.showSkeletons === nextProps.showSkeletons &&
        prevProps.view === nextProps.view
    )
}

export const LocationTableLogic = memo((props: LogicProps): JSX.Element => {
    const reducer = useReducer(props);
    const logic: Logic = useLogic(reducer);
    return props.children(logic);
}, keepSameLogic);

const initialColumns: Array<ILocationTableColumn> = [
    {field: "type", label: "Type", maxWidth: 12, minWidth: "150px"},
    {field: "name", label: "Name", maxWidth: 18, minWidth: "200px"},
    {field: "flagship", label: "Flagship", maxWidth: 8, minWidth: "75px"},
    {field: "recommended", label: "Recommended", maxWidth: 8, minWidth: "120px"},
    {field: "brands", label: "Brands", maxWidth: 15, sortable: false, minWidth: "170px"},
    {field: "distance", label: "Distance", maxWidth: 10, minWidth: "100px"},
    {field: "state", label: "State", sortable: false, maxWidth: 10, minWidth: "180px"},
    {field: "visible", label: "Visible", maxWidth: 7, minWidth: "100px"},
    {field: "complete", label: "Complete", maxWidth: 7, minWidth: "100px"},
    {field: "actions", label: "", maxWidth: 5, sortable: false}
]

export const useLogic = ({state, dispatch, propsRef, externalRef}: ReturnType<typeof useReducer>) => {
    const tableRef = useRef<HTMLDivElement | null>(null);

    const {isOnMobile} = propsRef.current.teamsContext;

    useEffect(function onMount() {
        const tableResizeObserver = new ResizeObserver(handleTableResize);
        if (tableRef.current) tableResizeObserver.observe(tableRef.current);
        return () => {
            tableResizeObserver.disconnect();
            if (externalRef) externalRef.current = undefined;
        }
    }, []);

    const handleTableResize = useCallback((entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) return;
            updateVisibleBrandsCount();
            updateVisibleItemsCount();
            if (!state.initialized) {
                dispatch({type: "setInitialized", value: true}, false);
                propsRef.current.onInitialized?.();
            }
        });
    }, []);

    const updateVisibleBrandsCount = useCallback(() => {
        const brandsColumn = document.body.querySelector(".location-table-header-brands");
        const brandItemWidth = 40; //px -> one brand
        const columnWidth = (brandsColumn?.clientWidth ?? 120) - 30;
        const visibleBrandsCount = Math.round(columnWidth / brandItemWidth);
        dispatch({type: "setVisibleBrandsCount", value: visibleBrandsCount});
    }, []);

    const updateVisibleItemsCount = useCallback(() => {
        if (!tableRef.current) return;
        const itemHeight = 50; //px -> one row
        const height = tableRef.current?.clientHeight ?? 0;
        const visibleItemsCount = Math.floor(height / itemHeight) - 1; // -1 to Remove header from items
        const rangeStart = visibleItemsCount * state.currentPage;
        const rangeEnd = rangeStart + visibleItemsCount;
        dispatch([
            {type: "setVisibleItemsCount", value: visibleItemsCount},
            {type: "setVisibleRange", value: [rangeStart, rangeEnd]}
        ]);
    }, []);

    const handleSortColumn = useCallback((columnName: string) => async () => {
        const column = initialColumns.find(c => c.field === columnName);
        if (!column || (column.sortable !== undefined && !column.sortable)) return;
        if (columnName === state.columnSort) dispatch({type: "toggleSortAscending"});
        else dispatch({type: "setColumnSort", value: columnName});
        await propsRef.current.fetchLocations();
    }, []);

    const handleLocationActionClick = useCallback((location: Immutable<ILocation>) => async (e: any, data?: MenuItemProps) => {
        e.stopPropagation();
        const action = (data as any)?.value;
        if (action === undefined) return;
        switch (action) {
            case LocationTableAction.Show:
                propsRef.current.onShowDetails(location.id);
                break;
            case LocationTableAction.Call:
                await LocationModule.call(location.phone, isOnMobile);
                break;
            case LocationTableAction.Itinerary:
                const userPosition = propsRef.current.userContext.currentUser?.geoLocation;
                await LocationModule.showItinerary(userPosition, location.address.geoPosition);
                break;
            case LocationTableAction.ToggleFavorite:
                propsRef.current.userContext.toggleLocationInFavorite(location.id);
                break;
        }
    }, []);

    const handleSelectPage = useCallback((page: number) => async () => {
        const rangeStart = state.visibleItemsCount * page;
        const rangeEnd = rangeStart + state.visibleItemsCount;
        dispatch([
            {type: "setVisibleRange", value: [rangeStart, rangeEnd]},
            {type: "setCurrentPage", value: page},
        ]);
        await propsRef.current.fetchLocations();
    }, []);

    const tableColumns = useMemo(() => {
        return getTableColumns(propsRef.current.view, propsRef.current.userContext.currentUser?.role);
    }, [propsRef.current.view, propsRef.current.userContext.currentUser?.role]);

    const locations = propsRef.current.locationContext.list;

    const showSkeletons = propsRef.current.showSkeletons || state.visibleBrandsCount === 0 || state.visibleItemsCount === 0;

    const userFavoriteLocationIds = propsRef.current.userContext.currentUser?.applicationSettings.favoriteBuildings ?? [];

    const totalItems = propsRef.current.locationContext.totalLocationsCount;

    const lastPage = state.visibleItemsCount === 0 || totalItems === state.visibleItemsCount
        ? 0 : Math.floor(totalItems / state.visibleItemsCount);

    return {
        ...state,
        showSkeletons,
        locations: locations,
        tableColumns,
        handleSortColumn,
        handleShowLocationDetails: propsRef.current.onShowDetails,
        handleLocationActionClick,
        userFavoriteLocationIds,
        show: propsRef.current.show,
        tableRef,
        handleSelectPage,
        totalItems,
        lastPage,
        isOnMobile: propsRef.current.teamsContext.isOnMobile,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getTableColumns = (view: AppView, userRole: UserRole | undefined) => {
    let columns = new Array<ILocationTableColumn>(...initialColumns);
    const isBuildingView = view === AppView.Buildings;
    const isUserNotBasic = userRole !== UserRole.Basic;
    const showVisibleColumn = isBuildingView && isUserNotBasic;
    if (!showVisibleColumn) {
        columns = columns.filter(c => c.field !== "visible");
        const brandsColumn = columns.find(c => c.field === "brands");
        if (brandsColumn) brandsColumn.maxWidth = 20;
        const flagshipColumn = columns.find(c => c.field === "flagship");
        if (flagshipColumn) flagshipColumn.maxWidth = 10;
        const recommendedColumn = columns.find(c => c.field === "recommended");
        if (recommendedColumn) recommendedColumn.maxWidth = 10;
    }
    return columns;
}