import {IReviewFormState, ReviewFormDispatchMessage, ReviewFormDispatchMessageType} from "./ReviewForm.interfaces";

export const initialState: IReviewFormState = {
    isSendingFeedback: false,
    showNotificationDialog: false,
}

export const reducer = (prevState: IReviewFormState, payload: ReviewFormDispatchMessage): IReviewFormState => {
    const newState: IReviewFormState = {...prevState};
    const payloadType = payload.type;
    switch (payloadType) {
        case ReviewFormDispatchMessageType.SetNotificationDialogVisibility:
            newState.showNotificationDialog = payload.show;
            return newState;
        case ReviewFormDispatchMessageType.SetIsSendingFeedback:
            newState.isSendingFeedback = payload.value;
            return newState;
        default:
            throw new Error("Message " + ReviewFormDispatchMessageType[payloadType] + " not implemented");
    }
}