import {Logic} from "./KeywordsKpi.interfaces";
import React from "react";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import translations from "../../../../translations";

export const KeywordsKpiRendering = (logic: Logic) => {

    const requests = logic.sortedKeywords.map((r, i) => (
        <Flex key={r.name} gap={"gap.small"} vAlign={"center"} className={"overflow-hidden"}>
            <Text
                truncated
                content={(i + 1) + ". " + r.name}
                weight={"bold"}
                size={"larger"}
            />
            <Text
                truncated
                content={"x" + r.count}
                styles={{color: "darkgray"}}
                size={"large"}
            />
        </Flex>
    ));

    return (
        <Flex column fill>
            {!logic.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={logic.title}/>
                </Flex>
            )}
            {logic.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill vAlign={"center"} hAlign={"center"}>
                    <Flex column gap={"gap.medium"}>
                        {requests.length === 0 ?
                            <Text size={"large"} content={translations.translate("NoResult")}/>
                            :
                            requests
                        }
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}