import {useCallback, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {initialState, reducer} from "./Events.reducer";
import {EventsDispatchMessageType} from "./Events.interfaces";
import moment from "moment";
import {IEvent} from "interfaces/Event/IEvent";
import Swiper from "swiper";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "services/TeamsContext";
import {EventContext, IEventContext} from "services/EventContext";
import {IUserContext, UserContext} from "../../services/UserContext";
import {ICarouselRef} from "../../components/Carousel/Carousel.interfaces";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useEvents = () => {
    const locationContext = useContext<ILocationContext>(LocationContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const eventContext = useContext<IEventContext>(EventContext);
    const userContext = useContext<IUserContext>(UserContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const swiperRef = useRef<Swiper>();
    const carouselRef = useRef<ICarouselRef>();

    const isOnMobile = teamsContext.isOnMobile;

    useEffect(function onMount() {
        (async () => {
            const userPosition = userContext.currentUser?.geoLocation;
            if (!userPosition) return;
            const radius = 100000;// 100km
            const start = moment().toISOString(false);
            const end = moment().startOf("day").add(30, "day").toISOString(false);
            await eventContext.fetchEventsFromGeoLocationAndDateRange({...userPosition, radius, start, end});
            dispatch({type: EventsDispatchMessageType.SetIsLoading, value: false});
        })()
    }, []);

    const handleCarouselRef = useCallback((ref: ICarouselRef) => {
        carouselRef.current = ref;
        if (!carouselRef.current) return;
        const index = carouselRef.current?.index ?? 0;
        dispatch({type: EventsDispatchMessageType.SetPrevButtonVisibility, value: index > 0});
        const isEnd = carouselRef.current?.isLastIndex ?? true;
        dispatch({type: EventsDispatchMessageType.SetNextButtonVisibility, value: !isEnd});
    }, []);

    const handleChangeSearchValue = useCallback((filter) => {
        dispatch({type: EventsDispatchMessageType.UpdateSearchFilter, filter});
    }, []);

    const handleSetSwiperRef = useCallback((ref: Swiper) => {
        swiperRef.current = ref;
    }, []);

    const handleShowEvent = useCallback((id: string) => () => {
        dispatch({type: EventsDispatchMessageType.SetSelectedEventId, id});
    }, []);

    const handleClearSelectedEvent = useCallback(() => {
        dispatch({type: EventsDispatchMessageType.SetSelectedEventId, id: ""});
    }, []);

    const getSlidesPerView = useCallback(() => {
        if (isOnMobile) return 1;
        const documentWidth = document.body.offsetWidth;
        const idealSlideWidth = 400;
        const visibleElements = Math.floor(documentWidth / idealSlideWidth);
        return visibleElements;
    }, []);

    const handleShowPreviousEvents = useCallback(() => {
        carouselRef.current?.swipePrevious();
    }, []);

    const handleShowNextEvents = useCallback(() => {
        carouselRef.current?.swipeNext();
    }, []);

    const searchEvents = useCallback((events: Array<IEvent>, filter: string) => {
        const searchKeywords = filter.split(" ").filter(k => !!k).map(keyword => keyword.toLowerCase());
        return events.filter(event => {
            const eventLocation = locationContext.findLocation(event.buildingId);
            const eventLocationName = eventLocation?.name ?? "";
            const eventLocationAddress = eventLocation?.address.fullAddress ?? "";
            const eventName = event.name.toLowerCase();
            const eventNotes = event.notes?.toLowerCase() ?? "";
            const eventStartDate = moment(event.startDate).format("LL");
            const eventEndDate = moment(event.endDate).format("LL");
            const eventKeywords: Array<string> = [
                eventLocationName, eventLocationAddress, eventName, eventNotes, eventStartDate, eventEndDate
            ].filter(k => !!k).map(keyword => keyword.toLowerCase());
            for (let i = 0; i < searchKeywords.length; i++) {
                for (let j = 0; j < eventKeywords.length; j++) {
                    if (eventKeywords[j]?.includes(searchKeywords[i] ?? "")) {
                        return true;
                    }
                }
            }
            return false;
        });
    }, [])

    const groupEventsByStartDay = useCallback((events: Array<IEvent>): Map<string, Array<IEvent>> => {
        let eventsByDay = new Map<string, Array<IEvent>>();
        const now = moment().startOf("day");
        events.forEach(event => {
            let startDay = moment(event.startDate);
            if (now > startDay) startDay = now;
            const formattedStartDay = startDay.format("YYYY-MM-DD");
            if (eventsByDay.has(formattedStartDay)) eventsByDay.get(formattedStartDay)?.push(event);
            else eventsByDay.set(formattedStartDay, new Array<IEvent>(event));
        })
        eventsByDay = new Map(Array.from(eventsByDay.entries()).sort((a, b) => {
            return moment(a[0]).valueOf() - moment(b[0]).valueOf();
        }));
        eventsByDay.forEach((value) => {
            value.sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());
        })
        return eventsByDay;
    }, []);

    const filteredEvents = useMemo(() => {
        let events = [...eventContext.list];
        if (!!state.searchFilter) events = searchEvents(events, state.searchFilter);
        return groupEventsByStartDay(events);
    }, [state.searchFilter, eventContext.list]);

    const disablePrevButton = carouselRef.current?.index === 0 ?? true;

    const noEvents = eventContext.list.length === 0;

    return {
        ...state,
        events: filteredEvents,
        columnsPerPage: getSlidesPerView(),
        handleSearchChange: handleChangeSearchValue,
        handleSetSwiperRef,
        handleShowPreviousRecommendation: handleShowPreviousEvents,
        handleShowNextRecommendation: handleShowNextEvents,
        handleClearSelectedEvent,
        handleShowEvent,
        handleCarouselRef,
        disablePrevButton,
        noEvents,
        isOnMobile
    }
}