import {IConfiguration} from "../interfaces";

const getConfiguration = () => {
    if (!sessionStorage) return;
    const stringConfig = sessionStorage.getItem("app_configuration");
    if (!stringConfig) return;
    return JSON.parse(stringConfig);
}

const getValue = (key: keyof IConfiguration) => {
    const config = getConfiguration();
    if (!config) return null;
    return config[key];
}

export const ConfigurationModule = {
    getConfiguration,
    getValue
}