import {OpenHoursProps} from "./OpenHours.interfaces";
import {useCallback, useContext, useMemo} from "react";
import {ILocationHours, WeekDay} from "../../interfaces";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {WeekDaysModule} from "../../modules/WeekDays.module";
import {HoursModule} from "../../modules/Hours.module";
import {Immutable} from "../../interfaces/Immutable";

export const useOpenHoursLogic = (props: OpenHoursProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const translate = useTranslation();

    const {isOnMobile} = teamsContext;

    const cultureDaysOrder = useMemo(() => WeekDaysModule.getWeekDaysOrderPerCulture(), []);

    const groupDaysPerHours = useCallback((hours: Immutable<ILocationHours>): Map<string, Array<string>> => {
        const items = new Map<string, Array<string>>();
        cultureDaysOrder.forEach(key => {
            const weekDay = Number(key) as WeekDay;
            const weekDayTranslationId = WeekDay[weekDay] + "";
            const dayHours = hours[weekDay];
            const formattedHours = dayHours.reduce((fh, h, i) => {
                const currentGroup = h.reduce(() => {
                    const beginHour = HoursModule.formatHourByCulture(h[0]);
                    const endHour = HoursModule.formatHourByCulture(h[1]);
                    return beginHour + " - " + endHour + (i > 0 ? "  " : "");
                });
                return !!fh ? `${fh} | ${currentGroup}` : currentGroup;
            }, "");
            if (!items.has(formattedHours)) items.set(formattedHours, new Array<string>());
            items.get(formattedHours)?.push(translate(weekDayTranslationId));
        })
        return items;
    }, []);

    return {
        groupDaysPerHours,
        isOnMobile,
        cultureDaysOrder
    }
}