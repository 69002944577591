import {Logic} from "./Dashboard.interfaces";
import "./Dashboard.styles.scss";
import React, {useMemo} from "react";
import {Flex} from "@fluentui/react-northstar";
import {Toolbar} from "../../components";
import {UserRole} from "../../interfaces/UserRole";
import SectorManagerDashboard from "./SectorManagerDashboard/SectorManagerDashboard";
import SuperAdminDashboard from "./SuperAdminDashboard/SuperAdminDashboard";
import {ItemDetails} from "../../components/ItemDetails/ItemDetails";
import {ItemType} from "../../components/ItemDetails/ItemDetails.interfaces";
import moment from "moment";
import languageObject from "../../translations";

export const DashboardRendering = (logic: Logic) => {

    const kpis = useMemo(() => {
        let content: JSX.Element | null;
        switch (logic.userRole) {
            case UserRole.SuperAdmin:
                content = (
                    <SuperAdminDashboard
                        showSkeletons={logic.isFetching}
                        kpis={logic.superAdminKpis}
                        onSelectBuilding={logic.handleSelectBuilding}
                    />
                )
                break;
            case UserRole.SectorManager:
                content = (
                    <SectorManagerDashboard
                        showSkeletons={logic.isFetching}
                        kpis={logic.sectorManagerKpis}
                        onSelectBuilding={logic.handleSelectBuilding}
                    />
                )
                break;
            default:
                content = null;
        }
        return content;
    }, [logic.isFetching, logic.superAdminKpis, logic.sectorManagerKpis]);

    const buildingDetails = useMemo(() => {
        const startLabel = new Date(logic.dateRange.start).toLocaleDateString(moment.locale(), {
            day: 'numeric',
            month: 'short'
        });
        const endLabel = new Date(logic.dateRange.end).toLocaleDateString(moment.locale(), {
            day: 'numeric',
            month: 'short'
        });
        const title = logic.selectedBuilding?.name + " (" +
            languageObject.translate("FromPeriodToPeriod", {
                start: startLabel,
                end: endLabel
            }) + ")";
        return (
            <ItemDetails
                itemId={logic.selectedBuilding?.id}
                itemType={ItemType.BuildingKpi}
                title={title}
                onHide={logic.handleHideBuildingDetails}
                start={logic.dateRange?.start}
                end={logic.dateRange?.end}
            />
        )
    }, [logic.selectedBuilding]);

    return (
        <Flex column fill className={"kpis-container no-select"}>
            <Toolbar
                align={"end"}
                onSearchChange={logic.handleSearchChange}
                filterItems={logic.filterItems}
                onFiltersChange={logic.handleFiltersChange}
                onDateRangeChange={logic.handleDateRangeChange}
                dateRange={logic.dateRange}
                onSelectSuggestion={logic.handleSelectSuggestion}
                fetchSuggestions={logic.handleFetchSuggestions}
            />
            {kpis}
            {buildingDetails}
        </Flex>
    );
}