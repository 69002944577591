import {useEventContext} from "./EventContext.hook";
import {IEventContextProps} from "./EventContext.interfaces";
import {EventContext} from "./EventContext";

export const EventContextProvider = (props: IEventContextProps) => {
    const value = useEventContext(props.teamsContext, props.locationContext);

    return (
        <EventContext.Provider value={value}>
            {props.children}
        </EventContext.Provider>
    )
}