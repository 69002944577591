import {useLogic} from "./DateRangeSelector.logic";
import {useReducer} from "./DateRangeSelector.reducer";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";

export interface State {
    selectionMode: DateRangeType,
    isCustomEndDateSelected: boolean,
    selectedDate: string,
}

export interface Props {
    dateRange: { start: string, end: string } | undefined,
    onDateRangeChange: (start: string, end: string) => void,
}

export interface ContextProps {
    teamsContext: ITeamsContext,
}

export type Logic = Omit<ReturnType<typeof useLogic>, "children">;

export type LogicProps = Props & ContextProps & { children: (logic: Logic) => JSX.Element };

export type ReducerData = ReturnType<typeof useReducer>;

export enum DateRangeType {
    Day = 0,
    Week = 1,
    Month = 2,
    WorkWeek = 3,
    Custom = 4,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

type SetProperty = { type: string, value: never } | {
    [K in keyof State]: {
        type: `set${Capitalize<string & K>}`,
        value: State[K],
    };
}[keyof State];

export type Payload = SetProperty;