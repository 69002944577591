import {AxiosResponse} from "axios";
import {IConfiguration} from "interfaces/IConfiguration";
import {instance} from "../AxiosConfig/AxiosConfig";

const getConfiguration = (): Promise<IConfiguration> => {
    return new Promise<IConfiguration>(resolve => {
        const url = `https://${process.env.REACT_APP_BACK_URL}/v1/conviviapp/settings`;
        return instance.get(url).then((response: AxiosResponse) => {
            return resolve(response.data);
        }).catch(ex => {
            throw new Error(`Can't get client configuration, ${ex}`);
        })
    })
}

export const ConfigurationApi = {
    getConfiguration,
}