export const languageObject = {
    locale: "en",
    translate: (id: string, parameters?: Record<string, string | number | JSX.Element>): string => {
        if (!id) return "";
        const object = (languageObject as any)[languageObject.locale] ?? (languageObject as any).en;
        let translation = object[id] ?? (languageObject as any).en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters) {
                const regex = new RegExp("{" + param + "}", "g");
                translation = translation.replace(regex, parameters[param] + "");
            }
        }
        return translation;
    },
    en: {
        "Welcome": "Welcome",
        "Settings": "Settings",
        "Recommended": "Recommended",
        "Closed": "Closed",
        "CloseAt": "Close at {hour}",
        "OpenUntil": "Open until {hour}",
        "OpenAt": "Open at {hour}",
        "AnErrorOccured": "An error occured",
        "Call": "Call",
        "Itinerary": "Itinerary",
        "AddToFavorite": "Add to favorite",
        "Type": "Type",
        "Name": "Name",
        "Flagship": "Flagship",
        "Brands": "Brands",
        "Distance": "Distance",
        "State": "State",
        "NoLocationToShow": "No location to show",
        "NoResult": "No result",
        "NightClub": "Night Club",
        "HighEnergyBar": "High Energy Bar",
        "LowEnergyBar": "Low Energy Bar",
        "Restaurant": "Restaurant",
        "WineMerchant": "Wine Merchant",
        "ToggleFavorite": "Favorite",
        "SearchLocation": "Search location",
        "AroundMe": "Around me",
        "Activity": "Activity",
        "Buildings": "Buildings",
        "Events": "Events",
        "Favorites": "Favorites",
        "ShowListView": "Show list view",
        "ShowMapView": "Show map view",
        "Filter": "Filter",
        "Filters": "Filters",
        "Reset": "Reset",
        "Types": "Types",
        "ImageLevels": "Image levels",
        "CurrentlyOpen": "Currently open",
        "OnlyFavoriteBrands": "Favorite brands only",
        "RecommendedOnly": "Recommended only",
        "Within": "Within",
        "Show": "Show",
        "RecentActivity": "Recent activity",
        "UserSharedPrivateReviewOn": "Shared a private review on {locationName}",
        "UserHasVisitedLocationWithPeople": "Has visited location {locationName} with {count} people",
        "StartingAt": "Starting at",
        "EndingThe": "Ending the",
        "SearchEvent": "Search event",
        "Application": "Application",
        "FavoriteBrands": "Favorite brands",
        "HomePage": "Home page",
        "MakeAnUpdate": "Make an update",
        "SelectAFile": "Select a file",
        "Search": "Search",
        "Reviews": "Reviews",
        "SeeAll": "See all",
        "Information": "Information",
        "OpenHours": "Open hours",
        "NextEvents": "Next events",
        "CreateEvent": "Create event",
        "ReferencedBrands": "Referenced brands",
        "AvailableReferences": "Available references",
        "SectorManager": "Sector manager",
        "Book": "Book",
        "ShareMyExperience": "Share my experience",
        "EditLocation": "Edit location",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday",
        "SelectBrands": "Select brands",
        "ShowLocation": "Show location",
        "PartnerBrands": "Partner brands",
        "StartDate": "Start date",
        "EndDate": "End date",
        "SelectPicture": "Select picture",
        "CoverPicture": "Cover picture",
        "SelectDate": "Select date",
        "Save": "Save",
        "SelectBrand": "Select brand",
        "TypeName": "Type name",
        "TypeSomeNotes": "Type some notes",
        "Remove": "Remove",
        "Review": "Review",
        "ExperienceDetails": "Experience details",
        "NumberVisitors": "Number of visitors",
        "SectorManagerReview": "Sector manager review",
        "ReportPrivateReviewToSectorManager": "Report private review to sector manager",
        "TypeReview": "Type review",
        "Picture": "Picture",
        "Notes": "Notes",
        "ThisFieldIsRequired": "This field is required",
        "Mainstream": "Mainstream",
        "Premium": "Premium",
        "Leader": "Leader",
        "Iconic": "Iconic",
        "EventDetails": "Event details",
        "LocationDetails": "Location details",
        "Share": "Share",
        "VisitDate": "Visit date",
        "Visibility": "Visibility",
        "Promotion": "Promotion",
        "SpecifyWhyYouWantToPromoteThisClient": "Specify why you want to promote this client",
        "RecommendedByPernodRicard": "Recommended by Pernod Ricard",
        "LocationReview": "Location review",
        "ProvideUsefulInformationToPernodRicardColleaguesWhoVisitThisFacility": "Provide useful information to Pernod Ricard colleagues who visit this facility",
        "Pictures": "Pictures",
        "ModifyTheRecord": "Modify the record",
        "NeedToConsent": "You need to consent",
        "ClickButtonToContinue": "Click on the button to continue",
        "Consent": "Consent",
        "EditTextLink": "Link",
        "EditTextEdit": "Edit",
        "EditTextRemove": "Remove",
        "EditTextConfirm": "Confirm",
        "RetrievingLocations": "Retrieving locations",
        "RemoveFromFavorites": "Remove from favorites",
        "WouldYouLikeToGoForADrinkInThisBar": "Would you like to go for a drink in this bar?",
        "WouldYouLikeToEatInThisRestaurant": "Would you like to eat in this restaurant?",
        "WouldYouLikeToGoDancingInThisClubTonight": "Would you like to go dancing in this club tonight?",
        "NoReview": "No review",
        "SendMessage": "Send message",
        "FetchingUserWithId": "Fetching user with id : {userId}",
        "AutorizePermissions": "Allow permissions",
        "HowToProceed": "How to proceed?",
        "AutorizePermissionsStep1": "Check Microsoft Teams app has access to your geolocation",
        "AutorizePermissionsStep2": "Click on your profile picture",
        "AutorizePermissionsStep3": "Then on Settings",
        "AutorizePermissionsStep4": "Next on App permissions",
        "AutorizePermissionsStep5": "Finally on Conviviapp",
        "AutorizePermissionsStep6": "Check all required permissions",
        "AutorizePermissionsStep7": "Click on Refresh button",
        "Refresh": "Refresh",
        "FetchingEvents": "Fetching events",
        "NoActivity": "No activity",
        "NoUpcomingEvents": "No upcoming events",
        "LinkCopied": "Link copied",
        "Building": "Building",
        "Address": "Address",
        "Phone": "Phone",
        "InitializationInProgress": "Initialization in progress",
        "UpdateGoogleData": "Update Google data",
        "Everyday": "Everyday",
        "ResetFilters": "Reset filters",
        "Continue": "Continue",
        "FeedbackShared": "Feedback shared",
        "AddedToFavorites": "Added to favorites",
        "RemovedFromFavorites": "Removed from favorites",
        "CharacterLimitReached": "Character limit reached",
        "EditEvent": "Edit event",
        "To": "To",
        "StartHour": "Start hour",
        "EndHour": "End hour",
        "SelectHour": "Select hour",
        "StartDateGreaterThanEndDate": "Start date is greater than end date",
        "EventPublished": "Event published",
        "LocationUpdated": "Location updated",
        "EventUpdated": "Event updated",
        "Visible": "Visible",
        "Unknown": "Unknown",
        "Hidden": "Hidden",
        "UpdateMayTakeMoreThan10Min": "The update may take more than 10 minutes",
        "AnUpdateIsInProgress": "An update is in progress",
        "AnErrorOccuredDuringUpdate": "An error occurred during the update",
        "IAcceptCGU": "I accept terms and conditions of use",
        "CGU": "Terms and conditions of use",
        "UpdatedAtBy": "Updated on {at} by {by}",
        "TheSystem": "The system",
        "LastDataUpdateDoneOn": "Last data update done on {date}",
        "ReviewLegalText": "Your comments must be objective, neutral, appropriate, relevant and based on facts, not personal information. You must not make insulting comments or refer to racial origin, political opinions, religious beliefs or refer to data concerning the health or sex life or sexual orientation of an individual.",
        "UnknownUser": "Unknown user",
        "NewEvent": "New event",
        "Unavailable": "Unavailable",
        "BuildingVisibilityDisabledMessage": "You must enter an address before you can edit this field",
        "Complete": "Complete",
        "EnterName": "Enter name",
        "SelectAType": "Select a type",
        "SelectAnImageLevel": "Select an image level",
        "EnterPhoneNumber": "Enter phone number",
        "ImageLevel": "Image level",
        "PriceRange": "Price range",
        "Website": "Website",
        "EnterWebsite": "Enter website",
        "EnterFullAddress": "Enter full address",
        "WeekDays": "Week days",
        "SelectWeekDays": "Select week days",
        "TimeSlot": "Time slot",
        "AddTimeSlot": "Add time slot",
        "SelectStartHour": "Select start hour",
        "SelectEndHour": "Select end hour",
        "Open": "Open",
        "EmailAddress": "Email address",
        "EnterEmailAddress": "Enter email address",
        "IncompleteBuildings": "Incomplete buildings",
        "XBuildingsToComplete": "{count} buildings to be completed",
        "XBuildingToComplete": "{count} building to be completed",
        "CantEditBuildingAfterAddressCompleteMessage": "Please note: once the address has been entered, the details and hours can no longer be changed",
        "EliteAddress": "Elite address",
        "Dashboard": "Dashboard",
        "Days": "Days",
        "YourBuildings": "Your buildings",
        "Visits": "Visits",
        "VisitsCount": "Visits count",
        "ReviewsCount": "Reviews count",
        "VisitInTheLastDays": "Visit in the last {days} days",
        "VisitsInTheLastDays": "Visits in the last {days} days",
        "ReviewInTheLastDays": "Review in the last {days} days",
        "ReviewsInTheLastDays": "Reviews in the last {days} days",
        "ExportData": "Export data",
        "VisitsPerDay": "Visits per day",
        "Visit": "Visit",
        "NoDataAvailable": "No data available",
        "Click": "Click",
        "Clicks": "Clicks",
        "BuildingsInFavorites": "Buildings in favorites",
        "CreatedEvents": "Created events",
        "ListViewClicks": "List view clicks",
        "RecommendedBuildings": "Recommended buildings",
        "Event": "Event",
        "TopBuildingsClicks": "Top buildings clicks",
        "TopRecommendedBuildingsClicks": "Top recommended buildings clicks",
        "TopFavoritesBuildings": "Top favorites buildings",
        "MostSearchedKeywords": "Most searched keywords",
        "ConnectionsPerDay": "Connections per day",
        "PublicReviews": "Public reviews",
        "PrivateReviews": "Private reviews",
        "Statistics": "Statistics",
        "ClicksCount": "Clicks count",
        "PublicReviewsCount": "Public reviews count",
        "PrivateReviewsCount": "Private reviews count",
        "FromPeriodToPeriod": "From {start} to {end}",
        "Day": "Day",
        "Week": "Week",
        "Month": "Month",
        "Times": "Times",
        "Custom": "Custom",
        "Connections": "Connections",
        "Connection": "Connection",
    },
    fr: {
        "Welcome": "Bienvenue",
        "Settings": "Paramètres",
        "Recommended": "Recommandé",
        "Closed": "Fermé",
        "CloseAt": "Ferme à {hour}",
        "OpenUntil": "Ouvert jusqu'à {hour}",
        "OpenAt": "Ouvre à {hour}",
        "AnErrorOccured": "Une erreur s'est produite",
        "Call": "Appeler",
        "Itinerary": "Itinéraire",
        "AddToFavorite": "Ajouter en favori",
        "Type": "Type",
        "Name": "Nom",
        "Flagship": "Flagship",
        "Brands": "Marques",
        "Distance": "Distance",
        "State": "Statut",
        "NoLocationToShow": "Aucun établissement à afficher",
        "NoResult": "Aucun résultat",
        "NightClub": "Night Club",
        "HighEnergyBar": "High Energy Bar",
        "LowEnergyBar": "Low Energy Bar",
        "Restaurant": "Restaurant",
        "WineMerchant": "Caviste",
        "ToggleFavorite": "Favori",
        "SearchLocation": "Rechercher un lieu",
        "AroundMe": "Autour de moi",
        "Activity": "Activité",
        "Buildings": "Établissements",
        "Events": "Événements",
        "Favorites": "Favoris",
        "ShowListView": "Afficher la vue liste",
        "ShowMapView": "Afficher la vue carte",
        "Filter": "Filtrer",
        "Filters": "Filtres",
        "Reset": "Réinitialiser",
        "Types": "Typologies",
        "ImageLevels": "Niveaux d'image",
        "CurrentlyOpen": "Ouvert actuellement",
        "OnlyFavoriteBrands": "Avec mes marques favorites",
        "RecommendedOnly": "Recommandé seulement",
        "Within": "À moins de",
        "Show": "Afficher",
        "RecentActivity": "Activité récente",
        "UserSharedPrivateReviewOn": "A partagé un commentaire privé sur {locationName}",
        "UserHasVisitedLocationWithPeople": "A effectué une visite à {locationName} en compagnie de {count} personnes",
        "StartingAt": "À partir de",
        "EndingThe": "Jusqu'au",
        "SearchEvent": "Rechercher un événement",
        "Application": "Application",
        "FavoriteBrands": "Marques favorites",
        "HomePage": "Page d'accueil",
        "MakeAnUpdate": "Effectuer une mise à jour",
        "SelectAFile": "Sélectionner un fichier",
        "Search": "Rechercher",
        "Reviews": "Commentaires",
        "SeeAll": "Tout voir",
        "Information": "Informations",
        "OpenHours": "Horaires",
        "NextEvents": "Prochains événements",
        "CreateEvent": "Créer un événement",
        "ReferencedBrands": "Marques référencées",
        "AvailableReferences": "Références disponibles",
        "SectorManager": "Chef de secteur",
        "Book": "Réserver",
        "ShareMyExperience": "Partager mon expérience",
        "EditLocation": "Modifier l'établissement",
        "Monday": "Lundi",
        "Tuesday": "Mardi",
        "Wednesday": "Mercredi",
        "Thursday": "Jeudi",
        "Friday": "Vendredi",
        "Saturday": "Samedi",
        "Sunday": "Dimanche",
        "SelectBrands": "Sélectionner des marques",
        "ShowLocation": "Afficher l'établissement",
        "PartnerBrands": "Marques partenaires",
        "StartDate": "Date de départ",
        "EndDate": "Date de fin",
        "SelectPicture": "Sélectionner une photo",
        "CoverPicture": "Photo de couverture",
        "SelectDate": "Sélectionner une date",
        "Save": "Sauvegarder",
        "SelectBrand": "Sélectionner une marque",
        "TypeName": "Saisir un nom",
        "TypeSomeNotes": "Saisir quelques notes",
        "Remove": "Supprimer",
        "Review": "Commentaire",
        "ExperienceDetails": "Détails de l'expérience",
        "NumberVisitors": "Nombre de visiteurs",
        "SectorManagerReview": "Commentaire pour le chef de secteur",
        "ReportPrivateReviewToSectorManager": "Remontez une information privée au chef de secteur",
        "TypeReview": "Saisir un commentaire",
        "Picture": "Photo",
        "Notes": "Description",
        "ThisFieldIsRequired": "Ce champ est requis",
        "Mainstream": "Mainstream",
        "Premium": "Premium",
        "Leader": "Leader",
        "Iconic": "Iconic",
        "EventDetails": "Détails de l'événement",
        "LocationDetails": "Détails de l'établissement",
        "Share": "Partager",
        "VisitDate": "Date de visite",
        "Visibility": "Visibilité",
        "Promotion": "Mise en avant",
        "SpecifyWhyYouWantToPromoteThisClient": "Préciser pourquoi vous souhaitez mettre ce client en avant",
        "RecommendedByPernodRicard": "Recommandé par Pernod Ricard",
        "LocationReview": "Commentaire sur l'établissement",
        "ProvideUsefulInformationToPernodRicardColleaguesWhoVisitThisFacility": "Précisez les informations utiles aux collègues de Pernod Ricard qui visiterait cet établissement",
        "Pictures": "Photos",
        "ModifyTheRecord": "Modifier la fiche",
        "NeedToConsent": "Vous devez donner votre accord",
        "ClickButtonToContinue": "Cliquez sur le bouton pour continuer",
        "Consent": "Consentir",
        "EditTextLink": "Lien",
        "EditTextEdit": "Modifier",
        "EditTextRemove": "Supprimer",
        "EditTextConfirm": "Valider",
        "RetrievingLocations": "Récupération des lieux",
        "RemoveFromFavorites": "Supprimer des favoris",
        "WouldYouLikeToGoForADrinkInThisBar": "Ça te dirait d'aller boire un coup dans ce bar ?",
        "WouldYouLikeToEatInThisRestaurant": "Ça te dirait d'aller manger dans ce restaurant ?",
        "WouldYouLikeToGoDancingInThisClubTonight": "Ça te dirait d'aller danser dans cette boîte ce soir ?",
        "NoReview": "Aucun commentaire",
        "SendMessage": "Envoyer un message",
        "FetchingUserWithId": "Récupération de l'utilisateur avec l'id : {userId}",
        "AutorizePermissions": "Autoriser les permissions",
        "HowToProceed": "Comment procéder ?",
        "AutorizePermissionsStep1": "Vérifiez que l'application Microsoft Teams a bien accès à votre localisation",
        "AutorizePermissionsStep2": "Cliquez sur votre photo de profil en haut à gauche",
        "AutorizePermissionsStep3": "Puis sur Paramètres",
        "AutorizePermissionsStep4": "Ensuite sur Autorisations de l'application",
        "AutorizePermissionsStep5": "Enfin sur Conviviapp",
        "AutorizePermissionsStep6": "Cochez toutes les permissions requises",
        "AutorizePermissionsStep7": "Cliquez sur le bouton Rafraîchir",
        "Refresh": "Rafraîchir",
        "FetchingEvents": "Récupération des événements",
        "NoActivity": "Aucune activité",
        "NoUpcomingEvents": "Aucun événement à venir",
        "LinkCopied": "Lien copié",
        "Building": "Établissement",
        "Address": "Adresse",
        "Phone": "Téléphone",
        "InitializationInProgress": "Initialisation en cours",
        "UpdateGoogleData": "Mettre à jour les données de Google",
        "Everyday": "Tous les jours",
        "ResetFilters": "Réinitialiser les filtres",
        "Continue": "Continuer",
        "FeedbackShared": "Expérience envoyée",
        "AddedToFavorites": "Ajouté aux favoris",
        "RemovedFromFavorites": "Supprimé des favoris",
        "CharacterLimitReached": "Limite de caractères atteinte",
        "EditEvent": "Modifier l'événement",
        "To": "Au",
        "StartHour": "Heure de départ",
        "EndHour": "Heure de fin",
        "SelectHour": "Sélectionner une heure",
        "StartDateGreaterThanEndDate": "Date de départ supérieure à la date de fin",
        "EventPublished": "Événement publié",
        "LocationUpdated": "Établissement mis à jour",
        "EventUpdated": "Événement mis à jour",
        "Visible": "Visible",
        "Unknown": "Inconnu",
        "Hidden": "Masqué",
        "UpdateMayTakeMoreThan10Min": "La mise à jour peut durer plus de 10 minutes",
        "AnUpdateIsInProgress": "Une mise à jour est en cours",
        "AnErrorOccuredDuringUpdate": "Une erreur s'est produite pendant la mise à jour",
        "IAcceptCGU": "J'accepte les conditions générales d'utilisation",
        "CGU": "Conditions générales d'utilisation",
        "UpdatedAtBy": "Mis à jour le {at} par {by}",
        "TheSystem": "Le système",
        "LastDataUpdateDoneOn": "Dernière mise à jour des données effectuée le {date}",
        "ReviewLegalText": "Vos commentaires doivent rester objectifs, neutres, adéquates, pertinents et basés sur des faits et non des informations personnelles. Vous ne devez pas avoir de propos insultants ou faire référence à l'origine raciale, aux opinions politiques, aux convictions religieuses ou faire référence à des données concernant la santé ou concernant la vie sexuelle ou l'orientation sexuelle d'une personne physique.",
        "UnknownUser": "Utilisateur inconnu",
        "NewEvent": "Nouvel événement",
        "Unavailable": "Indisponible",
        "BuildingVisibilityDisabledMessage": "Vous devez saisir une adresse avant de pouvoir modifier ce champ",
        "Complete": "Complet",
        "EnterName": "Saisir un nom",
        "SelectAType": "Sélectionner un type",
        "SelectAnImageLevel": "Sélectionner un niveau d'image",
        "EnterPhoneNumber": "Saisir un numéro de téléphone",
        "ImageLevel": "Niveau d'image",
        "PriceRange": "Gamme de prix",
        "Website": "Site internet",
        "EnterWebsite": "Saisir l'url du site internet",
        "EnterFullAddress": "Saisir l'adresse complète",
        "WeekDays": "Jours de la semaine",
        "SelectWeekDays": "Sélectionner les jours de la semaine",
        "TimeSlot": "Plage horaire",
        "AddTimeSlot": "Ajouter une plage horaire",
        "SelectStartHour": "Sélectionner l'heure de départ",
        "SelectEndHour": "Sélectionner l'heure de fin",
        "Open": "Ouvert",
        "EmailAddress": "Adresse mail",
        "EnterEmailAddress": "Saisir une adresse mail",
        "IncompleteBuildings": "Établissements incomplets",
        "XBuildingsToComplete": "{count} établissements à compléter",
        "XBuildingToComplete": "{count} établissement à compléter",
        "CantEditBuildingAfterAddressCompleteMessage": "Attention : une fois l'adresse renseignée, les détails et les horaires ne seront plus modifiables",
        "EliteAddress": "Adresse Elite",
        "Dashboard": "Tableau de bord",
        "Days": "Jours",
        "YourBuildings": "Vos établissements",
        "Visits": "Visites",
        "VisitsCount": "Nombre de visites",
        "ReviewsCount": "Nombre de commentaires",
        "VisitsInTheLastDay": "Visite dans les {days} derniers jours",
        "VisitsInTheLastDays": "Visites dans les {days} derniers jours",
        "ReviewInTheLastDays": "Commentaire dans les {days} derniers jours",
        "ReviewsInTheLastDays": "Commentaires dans les {days} derniers jours",
        "ExportData": "Exporter les données",
        "VisitsPerDay": "Visites par jour",
        "Visit": "Visite",
        "NoDataAvailable": "Aucune donnée disponible",
        "Click": "Clic",
        "Clicks": "Clics",
        "BuildingsInFavorites": "Établissements en favoris",
        "CreatedEvents": "Évènements créés",
        "ListViewClicks": "Clics sur la vue liste",
        "RecommendedBuildings": "Établissements recommandés",
        "Event": "Événement",
        "TopBuildingsClicks": "Établissements les plus cliqués",
        "TopRecommendedBuildingsClicks": "Établissements recommandés les plus cliqués",
        "TopFavoritesBuildings": "Établissements les plus mis en favoris",
        "MostSearchedKeywords": "Mots clés les plus recherchés",
        "ConnectionsPerDay": "Connexions par jour",
        "PublicReviews": "Commentaires publics",
        "PrivateReviews": "Commentaires privés",
        "Statistics": "Statistiques",
        "ClicksCount": "Nombre de clics",
        "PublicReviewsCount": "Nombre de commentaires publics",
        "PrivateReviewsCount": "Nombre de commentaires privés",
        "FromPeriodToPeriod": "Du {start} au {end}",
        "Day": "Jour",
        "Week": "Semaine",
        "Month": "Mois",
        "Times": "Fois",
        "Custom": "Personnalisé",
        "Connections": "Connexions",
        "Connection": "Connexion",
    }
}

export default languageObject;