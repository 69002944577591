import {Logic} from "./BarChartKpi.interfaces";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import translations from "../../../../translations";
import {
    ActiveElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartEvent,
    ChartOptions,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import {brandColors} from "../../../../const/ThemeCustomizations";
import {Bar} from "react-chartjs-2";
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
);

export const BarChartKpiRendering = (logic: Logic) => {
    return (
        <Flex column fill>
            {!logic.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={logic.title}/>
                </Flex>
            )}
            {logic.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill className={"pos-relative"} styles={{marginTop: "20px"}}>
                    <Flex className={"fill-absolute"} vAlign={"center"} hAlign={"center"}>
                        {renderBarChart(logic)}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

const renderBarChart = (logic: Logic) => {
    if (logic.showSkeletons) return null;
    if (logic.noDataAvailable) return (
        <Text content={translations.translate("NoDataAvailable")}/>
    )
    return renderBarChartContent({
        xLabels: logic.labels,
        data: logic.values,
        isLightTheme: logic.isLightTheme,
        onClick: logic.handleBarClick(logic.onClick),
    });
}

const renderBarChartContent = (data: {
    xLabels: Array<string>,
    data: Array<number>,
    isLightTheme: boolean,
    onClick: (event: ChartEvent, elements: ActiveElement[]) => void,
}) => {
    const chartTextColor = data.isLightTheme ? "#252423" : "white";
    const chartGridColor = data.isLightTheme ? "rgb(237, 235, 233)" : "rgb(91, 91, 91)";
    const chartLabels = data.xLabels;
    const chartOptions: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 6,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 5,
                    stepSize: 1,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
        },
        onClick: data.onClick,
    };
    const chartData: ChartData<"bar"> = {
        labels: chartLabels,
        datasets: [{
            data: data.data,
            borderColor: brandColors[800],
            backgroundColor: brandColors[800],
        }]
    };
    return <Bar options={chartOptions} data={chartData}/>;
}