export interface IPopupPictureState {
    showPictureFullscreen: boolean,
}

export interface IPopupPictureProps {
    src: string,
    maxHeightDesktop?: number,
    maxHeightMobile?: number,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum PopupPictureDispatchMessageType {
    ToggleShowPictureFullscreen
}

type ToggleShowPictureFullscreen = {
    type: PopupPictureDispatchMessageType.ToggleShowPictureFullscreen,
};

export type PopupPictureDispatchMessage = ToggleShowPictureFullscreen;
