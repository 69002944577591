import {
    EventDetailsDispatchMessage,
    EventDetailsDispatchMessageType,
    IEventDetailsState
} from "./EventDetails.interfaces";

export const initialState: IEventDetailsState = {
    showShareExperienceSideView: false,
    showLocationDetailsSideView: false,
    showEventEditSideView: false,
}

export const reducer = (prevState: IEventDetailsState, payload: EventDetailsDispatchMessage): IEventDetailsState => {
    const newState: IEventDetailsState = {...prevState};
    switch (payload.type) {
        case EventDetailsDispatchMessageType.SetExperienceSideViewVisibility:
            newState.showShareExperienceSideView = payload.value;
            return newState;
        case EventDetailsDispatchMessageType.ToggleShowLocationDetailsSideView:
            newState.showLocationDetailsSideView = !prevState.showLocationDetailsSideView;
            return newState;
        case EventDetailsDispatchMessageType.ToggleShowEventEditSideView:
            newState.showEventEditSideView = !prevState.showEventEditSideView;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}