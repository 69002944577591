import {useTeamsContext} from "./TeamsContext.hook";
import {Provider} from "@fluentui/react-northstar";
import {ConsentPage} from "auth";
import {TeamsContext} from "./TeamsContext";
import {ITeamsContextProps} from "./TeamsContext.interfaces";

export const TeamsContextProvider = (props: ITeamsContextProps) => {
    const value = useTeamsContext(props.configuration);

    if (!value.initialized) return null;

    const showConsentPage = !value.loaded;

    return (
        <TeamsContext.Provider value={value}>
            <Provider lang={value.locale} theme={value.teamsTheme}>
                {showConsentPage ? <ConsentPage/> : props.children}
            </Provider>
        </TeamsContext.Provider>
    )
}