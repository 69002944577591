import React, {memo} from "react";
import {Flex, Image} from "@fluentui/react-northstar";
import "./PopupPicture.styles.scss";
import {IPopupPictureProps} from "./PopupPicture.interfaces";
import {usePopupPicture} from "./PopupPicture.hook";
import {ImageModule} from "../../modules/Image.module";

const propsAreEqual = (prevProps: Readonly<IPopupPictureProps>, nextProps: Readonly<IPopupPictureProps>) => {
    return prevProps.src === nextProps.src;
}

export const PopupPicture = memo((props: IPopupPictureProps) => {
    const logic = usePopupPicture(props);

    let triggerPictureMaxHeight = 100;
    if (!logic.isOnMobile && !!logic.maxHeightDesktop) triggerPictureMaxHeight = logic.maxHeightDesktop;
    else if (logic.isOnMobile && !!logic.maxHeightMobile) triggerPictureMaxHeight = logic.maxHeightMobile;

    return (
        <Flex className={"w-100"}>
            <Image
                fluid
                src={logic.src}
                styles={{
                    maxHeight: triggerPictureMaxHeight + "px",
                    objectFit: "cover",
                    cursor: "pointer",
                }}
                onClick={handleShowPictureFullscreen(props.src)}
            />
        </Flex>
    )
}, propsAreEqual)

const handleShowPictureFullscreen = (url: string) => () => {
    ImageModule.showFullscreenImage(url);
}