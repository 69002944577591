import axios from "axios";
import * as microsoftTeams from "@microsoft/teams-js";

export const getTeamsToken = async (): Promise<string> => {
    return await microsoftTeams.authentication.getAuthToken();
}

const instance = axios.create();

const setAxiosInstanceApiBaseUrl = (baseUrl: string) => {
    instance.defaults.baseURL = baseUrl;
}

// For each request add authentication bearer token
instance.interceptors.request.use(async function (config) {
    if (!config.headers) config.headers = {};
    const token = await getTeamsToken();
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = "Bearer " + token;
    return config;
}, function (error) {
    return Promise.reject(error);
});

export {
    instance,
    setAxiosInstanceApiBaseUrl,
}
