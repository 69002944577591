import {IGraphBatchRequest, IGraphUserData} from "./GraphService.interfaces";
import {teamsFxGraphClient} from "auth/TeamsFx/TeamsFx";
import {GuidModule} from "../../modules/Guid.module";
import languageObject from "../../translations";

export class GraphService {

    private static readonly localStorageUserDataRef = "user_data_";

    static searchForUsersAsync = (query: string): Promise<Array<IGraphUserData>> => {
        return new Promise<Array<IGraphUserData>>(async resolve => {
            if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined")
            const usersRawData = (await teamsFxGraphClient
                .api('/users')
                .filter(`startswith(displayName, '${query}')`)
                .select("id,displayName,userPrincipalName")
                .get()).value;
            const usersData: Array<IGraphUserData> = usersRawData.map((data: any) => data as IGraphUserData);

            await this.attachPicturesToUsersDataAsync(usersData);

            this.saveRemoteDataToLocalStorage(usersData);

            resolve(usersData);
        })
    }

    static areUsersInTeam = async (userIds: Array<string>, teamId: string): Promise<Array<string>> => {
        if (userIds.length === 0) return new Array<string>();
        if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined")
        const batchUsersRequests = {
            requests: userIds.map((id: string) => ({
                id: id,
                method: "GET",
                url: `/teams/${teamId}/members?$filter=(microsoft.graph.aadUserConversationMember/userId eq '${id}')&$select=id`
            }))
        }
        const usersRawData = (await teamsFxGraphClient
            .api('/$batch')
            .post(JSON.stringify(batchUsersRequests))).responses;
        const validUserIds = new Array<string>();
        usersRawData.forEach((request: any) => {
            const count = request.body["@odata.count"];
            if (count > 0) validUserIds.push(request.id);
        });
        return validUserIds;
    }

    static getUsersDataAsync = (usersIds: Array<string>): Promise<Array<IGraphUserData>> => {
        return new Promise<Array<IGraphUserData>>(async resolve => {
            if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined")

            const validIds = usersIds.filter(id => GuidModule.isValidGuid(id));
            if (validIds.length === 0) return resolve([]);

            const localUsersData = new Array<IGraphUserData>();
            if (!!localStorage) {
                validIds.forEach(id => {
                    const localData = localStorage.getItem(this.localStorageUserDataRef + id);
                    if (!!localData) {
                        const localUser = JSON.parse(localData) as IGraphUserData;
                        if (localUser.userPrincipalName) localUsersData.push(localUser);
                    }
                })
                if (localUsersData.length === validIds.length) return resolve(localUsersData);
            }
            const localUsersIds = localUsersData.map(u => u.id);

            let usersIdsToFetch = validIds.filter(id => !localUsersIds.includes(id));
            if (usersIdsToFetch.length === 0) return resolve([]);

            const usersData = new Array<IGraphUserData>();

            const batchUsersRequests = new Array<{ requests: Array<IGraphBatchRequest> }>();
            batchUsersRequests.push({requests: new Array<IGraphBatchRequest>()});

            let requestCount = 0;

            while (usersIdsToFetch.length > 0) {
                // 15 conditions is the limit for odata filter
                const idsToFetch = usersIdsToFetch.slice(0, 15);
                usersIdsToFetch = usersIdsToFetch.slice(15, usersIdsToFetch.length - 1);

                const filter = idsToFetch.map(id => "id eq '" + id + "'").join(" or ");

                const request: IGraphBatchRequest = {
                    id: "request-" + requestCount,
                    url: `/users?$filter=(${encodeURIComponent(filter)})&$select=id,displayName,userPrincipalName`,
                    method: "GET"
                };

                let lastBatchRequest = batchUsersRequests[batchUsersRequests.length - 1];
                if (!lastBatchRequest) throw new Error("Batch request is undefined");

                // 20 is the limit of steps in a batch request
                if (lastBatchRequest.requests.length < 20) {
                    lastBatchRequest.requests.push(request);
                } else {
                    lastBatchRequest = {requests: new Array<IGraphBatchRequest>()};
                    lastBatchRequest.requests.push(request);
                    batchUsersRequests.push(lastBatchRequest);
                }

                requestCount += 1;
            }

            for (const batchRequest of batchUsersRequests) {
                if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined");
                try {
                    const responses = (await teamsFxGraphClient.api('/$batch').post(JSON.stringify(batchRequest))).responses;
                    responses.forEach((request: any) => {
                        if (!!request?.body?.value) usersData.push(...request.body.value);
                    });
                } catch (e) {
                    console.log(e);
                }
            }

            const notFoundUsersIds = validIds.filter(id => !usersData.find(u => u.id === id));

            usersData.push(...notFoundUsersIds.map(id => ({
                id,
                picture: "",
                displayName: languageObject.translate("UnknownUser"),
                userPrincipalName: ""
            })))

            await this.attachPicturesToUsersDataAsync(usersData);

            this.saveRemoteDataToLocalStorage(usersData);

            usersData.push(...localUsersData);

            return resolve(usersData);
        });
    }

    static saveRemoteDataToLocalStorage = (usersData: Array<IGraphUserData>) => {
        if (!!localStorage)
            usersData.forEach(u => localStorage.setItem(this.localStorageUserDataRef + u.id, JSON.stringify(u)));
    }

    static attachPicturesToUsersDataAsync = (usersData: Array<IGraphUserData>): Promise<void> => {
        return new Promise<void>(async resolve => {
            if (!usersData || usersData.length === 0) {
                resolve();
                return;
            }
            const pictures = await this.getUsersPicturesAsync(usersData.map(u => u.id));
            usersData.forEach((user: IGraphUserData) => {
                const picture = pictures.get(user.id);
                if (!picture) console.warn("Can't find picture for user with id : " + user.id);
                user.picture = picture ?? "";
            });
            resolve();
        })
    }

    static getUsersPicturesAsync = (usersIds: Array<string>): Promise<Map<string, string>> => {
        return new Promise<Map<string, string>>(async resolve => {
            if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined")

            const pictures = new Map<string, string>();

            const localStoragePictures = this.getUsersPicturesFromLocalStorage(usersIds);
            localStoragePictures.forEach((value, key) => pictures.set(key, value));

            let usersIdsToFetch = usersIds.filter(id => !pictures.get(id));
            if (usersIdsToFetch.length === 0) {
                resolve(pictures);
                return;
            }

            const batchPicturesRequests = new Array<{ requests: Array<IGraphBatchRequest> }>();

            while (usersIdsToFetch.length > 0) {
                // 20 is the limit of steps in a batch request
                const idsToFetch = usersIdsToFetch.slice(0, 20);
                usersIdsToFetch = usersIdsToFetch.slice(20, usersIdsToFetch.length - 1);

                const requests: { requests: Array<IGraphBatchRequest> } = {
                    requests: idsToFetch.map((id: string) => ({
                        id: id,
                        method: "GET",
                        url: `/users/${id}/photos/48x48/$value`
                    }))
                }

                batchPicturesRequests.push(requests);
            }

            for (const batchRequest of batchPicturesRequests) {
                if (!teamsFxGraphClient) throw new Error("TeamxFx graph client is undefined");
                const responses = (await teamsFxGraphClient.api('/$batch').post(JSON.stringify(batchRequest))).responses;
                responses.forEach((request: any) => {
                    let base64Image = `data:${request.headers["Content-Type"]};base64,${request.body}`;
                    if (!(request.headers["Content-Type"] + "").startsWith("image/")) base64Image = "";
                    pictures.set(request.id, base64Image);
                });
            }

            resolve(pictures);
        })
    }

    static getUsersPicturesFromLocalStorage = (usersIds: Array<string>) => {
        const pictures = new Map<string, string>();
        if (!!localStorage) {
            usersIds.forEach(id => {
                const localData = localStorage.getItem(this.localStorageUserDataRef + id);
                if (!!localData) {
                    const user = (JSON.parse(localData) as IGraphUserData);
                    if (!!user) pictures.set(id, user.picture ?? "");
                }
            })
        }
        return pictures;
    }
}