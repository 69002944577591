import React from "react";
import {SectorManagerDashboardLogic} from "./SectorManagerDashboard.logic";
import {SectorManagerDashboardRendering} from "./SectorManagerDashboard.rendering";
import {Props} from "./SectorManagerDashboard.interfaces";

const SectorManagerDashboard = (props: Props): JSX.Element => {
    return (
        <SectorManagerDashboardLogic {...props}>
            {logic => <SectorManagerDashboardRendering {...logic}/>}
        </SectorManagerDashboardLogic>
    )
}

export default SectorManagerDashboard;