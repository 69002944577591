import {memo, useCallback, useEffect, useMemo, useRef} from "react";
import {Logic, LogicProps} from "./LocationView.interfaces";
import {useReducer} from "./LocationView.reducer";
import {IFilterItem} from "../Filter/Filter.interfaces";
import {UserRole} from "../../interfaces/UserRole";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {BuildingsApi} from "../../apis/Buildings/BuildingsApi";
import {ILocation, LocationViewType} from "../../interfaces";
import {IViewport} from "../../interfaces/IViewport";
import moment from "moment/moment";
import {LocationModule} from "../../modules";
import {MapRef} from "../Map/Map.interfaces";
import {Brand} from "../../interfaces/Brand/Brand";
import {LocationTableRef} from "../LocationTable/LocationTable.interfaces";
import {Immutable} from "../../interfaces/Immutable";
import {KpiApi} from "../../apis/Kpi/kpiApi";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return (
        prevProps.view === nextProps.view &&
        prevProps.fetchLocations === nextProps.fetchLocations &&
        prevProps.filterLocations === nextProps.filterLocations &&
        prevProps.userContext.currentUser?.applicationSettings === nextProps.userContext.currentUser?.applicationSettings &&
        prevProps.locationContext.list === nextProps.locationContext.list &&
        prevProps.locationContext.loaded === nextProps.locationContext.loaded &&
        prevProps.locationContext.totalLocationsCount === nextProps.locationContext.totalLocationsCount
    )
}

export const LocationViewLogic = memo((props: LogicProps): JSX.Element => {
    const reducer = useReducer(props);
    const logic: Logic = useLogic(reducer);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = ({state, dispatch, propsRef, render}: ReturnType<typeof useReducer>) => {
    const mapRef = useRef<Readonly<MapRef>>();
    const tableRef = useRef<Immutable<LocationTableRef>>();
    const locationIdsRef = useRef<Array<string>>([]);

    useEffect(function onMount() {
        propsRef.current.locationContext.clearLocations();
        if (propsRef.current.teamsContext.isOnMobile && state.locationView === LocationViewType.List)
            handleFetchLocations().then();
        if (propsRef.current.externalRef?.current) {
            propsRef.current.externalRef!.current!.handleShowLocationDetails = handleShowLocationDetails;
            propsRef.current.externalRef!.current!.setLocationView = (view) => dispatch({
                type: "setLocationView",
                value: view
            });
        }
    }, []);

    const checkIfShowingIncompleteBuildings = useCallback((items: Immutable<Array<IFilterItem>>): boolean => {
        const incompleteBuildingsFilter = items.find(i => i.key === "incomplete-buildings");
        return incompleteBuildingsFilter?.isChecked ?? false;
    }, []);

    const fetchSuggestions = useCallback(async (filter: string) => {
        const isValidFilter = filter.length > 3;
        const canSearchHiddenBuildings = propsRef.current.userContext.currentUser?.role !== UserRole.Basic;
        const suggestions: Array<ILocationSuggestion> = !isValidFilter ? [] : await BuildingsApi.getSuggestions({
            searchFilter: filter,
            language: propsRef.current.teamsContext.locale,
            buildingIds: null,
            includeHiddenBuildings: canSearchHiddenBuildings,
        });
        return suggestions;
    }, []);

    const handleMapRef = useCallback((ref: MapRef) => {
        mapRef.current = ref;
    }, []);

    const handleToggleLocationView = useCallback(async () => {
        const isOnMobile = propsRef.current.teamsContext.isOnMobile;
        propsRef.current.locationContext.clearLocations();
        dispatch({type: "toggleLocationView"});
        if (isOnMobile || state.locationView === LocationViewType.Map) await handleFetchLocations();
        if (state.locationView === LocationViewType.List) await KpiApi.reportListViewClick();
    }, []);

    const handleUpdateFilterItems = useCallback(async (items: Array<IFilterItem>) => {
        dispatch({type: "updateFilterItems", items});
        await handleFetchLocations();
        const isMaxDistanceChecked = items.find(i => i.key === "max-distance")?.isChecked;
        if (isMaxDistanceChecked) mapRef.current?.centerMapToLocations();
    }, []);

    const handleSearchChange = useCallback(async (filter: string) => {
        if (!filter) {
            mapRef.current?.centerToUserPosition();
            mapRef.current?.clearSelectedSuggestionZoneCircle();
        }
    }, []);

    const handleSelectSuggestion = useCallback(async (suggestion: ILocationSuggestion) => {
        propsRef.current.locationContext.clearLocations();
        mapRef.current?.clearSelectedSuggestionZoneCircle();
        if (!suggestion.geoPosition) return console.error("No GPS coordinates found");
        const isBuilding = suggestion.location !== null;
        if (isBuilding) {
            mapRef.current?.goToGeoPosition({...suggestion.geoPosition, zoom: 22}, suggestion.id);
        } else {
            const placeData: { lat: number, lng: number, viewport?: IViewport } = {...suggestion.geoPosition};
            if (suggestion.viewport) placeData.viewport = suggestion.viewport;
            mapRef.current?.goToGeoPosition(placeData);
        }
    }, []);

    const handleShowLocationDetails = useCallback(async (id: string) => {
        mapRef.current?.hideLocationPreview();
        dispatch({type: "setSelectedLocationId", id});
        await KpiApi.reportBuildingClick(id);
    }, []);

    const handleClearSelectedLocation = useCallback(() => {
        dispatch({type: "setSelectedLocationId", id: ""});
    }, []);

    const handleSaveCGU = useCallback(async () => {
        await propsRef.current.userContext.updateSettings({lastCGURead: moment().toISOString()});
    }, []);

    const handleFetchLocations = useCallback(async (): Promise<{
        error: boolean,
        cancelled: boolean
    }> => {
        const position = mapRef.current!.getMapCenter();
        const bounds = mapRef.current!.getMapBounds();
        const userPosition = propsRef.current.userContext.currentUser?.geoLocation;
        const radius = mapRef.current!.getMapRadius();
        if (!userPosition) return {error: true, cancelled: false};
        const filters = LocationModule.getInRangeFilters(state.filterItems);
        const userFavoriteBrands = propsRef.current.userContext.currentUser?.applicationSettings.favoriteBrands
            .map(b => Brand[b] ?? "").filter(b => b) ?? [];
        const isOnMobile = propsRef.current.teamsContext.isOnMobile;
        let take = isOnMobile ? 100 : undefined;
        if (state.locationView === LocationViewType.Map || isOnMobile) return await propsRef.current.fetchLocations({
            position, bounds, userPosition, filters, userFavoriteBrands, radius, take
        });
        const skip = (tableRef.current?.currentPage ?? 0) * (tableRef.current?.visibleItemsCount ?? 0);
        take = tableRef.current?.visibleItemsCount;
        const orderBy = tableRef.current?.columnSort ?? undefined;
        const sortAscending = tableRef.current?.sortAscending;
        return await propsRef.current.fetchLocations({
            position, skip, take, bounds, userPosition, orderBy, sortAscending, filters,
            userFavoriteBrands, radius
        });
    }, []);

    const locations = useMemo((): Immutable<Array<ILocation>> => {
        return LocationModule.getFilteredLocations(
            propsRef.current.locationContext.list,
            state.filterItems,
            propsRef.current.userContext.currentUser?.applicationSettings.favoriteBrands ?? [],
        );
    }, [
        propsRef.current.locationContext.list,
        state.filterItems,
        propsRef.current.fetchLocations,
        propsRef.current.filterLocations,
    ]);

    const mapLocations = useMemo((): Immutable<Array<ILocation>> => {
        let filteredLocations: Immutable<Array<ILocation>> = [...locations];
        if (!!propsRef.current.filterLocations) filteredLocations = propsRef.current.filterLocations(filteredLocations, "map");
        locationIdsRef.current = filteredLocations.map(l => l.id);
        return filteredLocations;
    }, [locations]);

    const tableLocations = useMemo(() => {
        let filteredTableLocations: Immutable<Array<ILocation>> = [...locations];
        if (!!propsRef.current.filterLocations) filteredTableLocations = propsRef.current.filterLocations(filteredTableLocations, "table");
        const showOnlyIncompleteBuildings = checkIfShowingIncompleteBuildings(state.filterItems);
        if (showOnlyIncompleteBuildings) filteredTableLocations = filteredTableLocations
            .filter(l => !LocationModule.isLocationComplete(l));
        locationIdsRef.current = filteredTableLocations.map(l => l.id);
        return filteredTableLocations;
    }, [locations]);

    const isShowingIncompleteBuildings = checkIfShowingIncompleteBuildings(state.filterItems);

    const disableToggleViewButton = isShowingIncompleteBuildings;

    const totalCount = propsRef.current.locationContext.totalLocationsCount;

    const showRecommendations = propsRef.current.showRecommendations;

    return {
        ...state,
        mapLocations,
        tableLocations,
        recommendedLocations: propsRef.current.locationContext.recommendedList,
        handleToggleLocationView,
        handleUpdateFilterItems,
        handleSearchChange,
        handleShowLocationDetails,
        handleClearSelectedLocation,
        handleMapRef,
        isOnMobile: propsRef.current.teamsContext.isOnMobile,
        areLocationsLoaded: propsRef.current.locationContext.loaded,
        currentUser: propsRef.current.userContext.currentUser,
        isMapFullscreen: mapRef.current?.isFullscreen ?? false,
        handleSelectSuggestion,
        view: propsRef.current.view,
        handleSaveCGU,
        disableToggleViewButton,
        fetchSuggestions,
        isShowingIncompleteBuildings,
        totalCount,
        showRecommendations,
        handleFetchLocations,
        mapRef,
        tableRef,
        render,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////