import Swiper from "swiper";

export interface IEventsState {
    searchFilter: string,
    swiperRef: Swiper | null,
    selectedEventId: string,
    disableNextButton: boolean,
    disablePrevButton: boolean,
    isLoading: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum EventsDispatchMessageType {
    UpdateSearchFilter,
    SetSwiperRef,
    SetSelectedEventId,
    SetNextButtonVisibility,
    SetPrevButtonVisibility,
    SetIsLoading,
}

type UpdateSearchFilter = {
    type: EventsDispatchMessageType.UpdateSearchFilter,
    filter: string,
};

type SetSwiperRef = {
    type: EventsDispatchMessageType.SetSwiperRef,
    ref: Swiper,
};

type SetSelectedEventId = {
    type: EventsDispatchMessageType.SetSelectedEventId,
    id: string,
}

type SetNextButtonVisibility = {
    type: EventsDispatchMessageType.SetNextButtonVisibility,
    value: boolean,
}

type SetPrevButtonVisibility = {
    type: EventsDispatchMessageType.SetPrevButtonVisibility,
    value: boolean,
}

type SetIsLoading = {
    type: EventsDispatchMessageType.SetIsLoading,
    value: boolean,
}

export type EventsDispatchMessage =
    UpdateSearchFilter
    | SetSwiperRef
    | SetSelectedEventId
    | SetNextButtonVisibility
    | SetPrevButtonVisibility
    | SetIsLoading;
