import {ILocationImageLevel, LocationImageLevel} from "interfaces";

const mainstream: ILocationImageLevel = {
    id: LocationImageLevel.Mainstream,
    color: "#BBBBBB",
    translationId: "Mainstream",
}

const premium: ILocationImageLevel = {
    id: LocationImageLevel.Premium,
    color: "#FFBB36",
    translationId: "Premium",
}

const leader: ILocationImageLevel = {
    id: LocationImageLevel.Leader,
    color: "#FF6B00",
    translationId: "Leader",
}

const iconic: ILocationImageLevel = {
    id: LocationImageLevel.Iconic,
    color: "#FF0000",
    translationId: "Iconic",
}

const incomplete: ILocationImageLevel = {
    id: LocationImageLevel.Incomplete,
    color: "#000",
    translationId: "Unknown",
}

export const LocationImageLevels: Record<LocationImageLevel, ILocationImageLevel> = {
    [LocationImageLevel.Mainstream]: mainstream,
    [LocationImageLevel.Premium]: premium,
    [LocationImageLevel.Leader]: leader,
    [LocationImageLevel.Iconic]: iconic,
    [LocationImageLevel.Incomplete]: incomplete,
}