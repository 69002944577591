import {Component, PropsWithChildren} from 'react';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {createBrowserHistory} from "history";
import withRouter from "./withRouter.hook";
import {getCachedConfiguration} from "../ConfigurationService/ConfigurationService.hook";

export interface ITelemetryProviderProps extends PropsWithChildren<any> {
    userId: string,
}

export interface ITelemetryProviderState {
    initialized: boolean;
    reactPlugin: any;
}

const browserHistory = createBrowserHistory();

const reactPlugin: any = new ReactPlugin();

class TelemetryProvider extends Component<ITelemetryProviderProps, ITelemetryProviderState> {

    override componentDidMount() {
        const configurationString = sessionStorage.getItem("app_configuration");
        if (!configurationString) return;
        const configuration = getCachedConfiguration();
        const insightKey = configuration?.appInsightKey;
        if (!insightKey) return;

        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: insightKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });
        appInsights.loadAppInsights();
        appInsights.setAuthenticatedUserContext(this.props.userId);
    }

    override render() {
        return this.props.children
    }
}

export default withRouter<ITelemetryProviderProps>(withAITracking(reactPlugin, TelemetryProvider))