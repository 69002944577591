export interface IReviewFormProps {
    locationId: string,
    onSubmitDone: () => void,
}

export interface IReviewFormState {
    isSendingFeedback: boolean,
    showNotificationDialog: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum ReviewFormDispatchMessageType {
    SetNotificationDialogVisibility, SetIsSendingFeedback
}

type SetNotificationDialogVisibility = {
    type: ReviewFormDispatchMessageType.SetNotificationDialogVisibility,
    show: boolean,
};

type SetIsSendingFeedback = {
    type: ReviewFormDispatchMessageType.SetIsSendingFeedback,
    value: boolean,
}

export type ReviewFormDispatchMessage = SetNotificationDialogVisibility | SetIsSendingFeedback;
