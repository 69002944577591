import {Button, FilterIcon, Flex, MenuButton} from "@fluentui/react-northstar";
import React, {memo, useMemo} from "react";
import {IFilterProps} from "./Filter.interfaces";
import {useFilter} from "./Filter.hook";
import "./Filter.styles.scss";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {FilterMobileDialog} from "./MobileDialog/FilterMobileDialog";

const propsAreEqual = (prevProps: Readonly<IFilterProps>, nextProps: Readonly<IFilterProps>) => {
    return prevProps.items === nextProps.items;
}

export const Filter = memo((props: IFilterProps) => {
    const translate = useTranslation();
    const logic = useFilter(props);

    const trigger = useMemo(() => (
        <Button
            text
            className={logic.isOnMobile ? "stretch-btn" : ""}
            icon={<FilterIcon/>}
            onClick={logic.isOnMobile ? logic.handleOpen : logic.handleToggleOpen}
            onMouseEnter={logic.handleOpen}
            content={translate("Filter")}
        />
    ), []);

    const desktopContent = useMemo(() => logic.isOnMobile ? null : (
        <MenuButton
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            className={"no-select"}
            open={logic.open}
            mouseLeaveDelay={500}
            trigger={trigger}
            onOpenChange={logic.handlePopupHoverOpenChange}
            on={"hover"}
            align={"end"}
            menu={logic.items}
        />
    ), [logic.open, logic.items]);

    const mobileContent = useMemo(() => !logic.isOnMobile ? null : (
        <Flex>
            {trigger}
            <FilterMobileDialog
                menuItems={logic.items}
                items={props.items}
                show={logic.open}
                onHide={logic.handleClose}
                handleClearFilters={logic.handleClearFilters}
                handleFilterItemInputValueChange={logic.handleFilterItemInputValueChange}
                handleToggleItem={logic.handleToggleItem}
            />
        </Flex>
    ), [props.items, logic.open]);

    return (
        <Flex className={"filter"}>
            {logic.isOnMobile ? mobileContent : desktopContent}
        </Flex>
    )
}, propsAreEqual)