import {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {ILocationContextState, Payload} from "./LocationContext.interfaces";
import {ILocation} from "../../interfaces";

const reduce = (state: ILocationContextState, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof ILocationContextState;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const getInitialState = (): ILocationContextState => {
    const state: ILocationContextState = {
        loaded: false,
        list: new Array<ILocation>(),
        incompleteCount: 0,
        fetchTimeout: undefined,
        cancelToken: undefined,
        totalLocationsCount: 0,
        recommendedList: [],
        prevInRangeRequest: undefined,
        prevFetchId: "",
    };
    return state;
}

export const useReducer = () => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(), []);
    const stateRef = useRef<Readonly<ILocationContextState>>(initialState);

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current, Array.isArray(payload) ? payload : [payload]);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch};
}