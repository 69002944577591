import {useEvents} from "./Events.hook";
import {Flex, Image, Loader, Text} from "@fluentui/react-northstar";
import React, {useContext, useMemo} from "react";
import {Toolbar} from "components/Toolbar/Toolbar";
import {Carousel} from "../../components/Carousel/Carousel";
import {DayColumn} from "../../components/DayColumn/DayColumn";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {ItemDetails} from "../../components/ItemDetails/ItemDetails";
import {ItemType} from "../../components/ItemDetails/ItemDetails.interfaces";
import SadSvg from "assets/images/sad.svg";

export const Events = () => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const translate = useTranslation();
    const logic = useEvents();

    const {isOnMobile} = teamsContext;

    const toolbar = useMemo(() => logic.noEvents ? null : (
        <Flex className={"x-padding"}>
            <Toolbar
                onSearchChange={logic.handleSearchChange}
                searchBarPlaceholder={translate("SearchEvent")}
                {...(!isOnMobile && {
                    handleMoveForward: logic.handleShowNextRecommendation,
                    handleMoveBackward: logic.handleShowPreviousRecommendation
                })}
                disablePrevButton={logic.disablePrevButton}
                disableNextButton={logic.disableNextButton}
            />
        </Flex>
    ), [logic.noEvents, logic.disablePrevButton, logic.disableNextButton]);

    const columns = useMemo((): Array<JSX.Element> => {
        if (logic.noEvents) return [];
        const columns = Array.from(logic.events.keys()).map((date, index) => (
            <DayColumn
                key={"events-date-column-" + date}
                index={index}
                date={date}
                events={logic.events.get(date)}
                onShowEvent={logic.handleShowEvent}
            />
        ));
        if (columns.length % logic.columnsPerPage === 0) return columns;
        while (columns.length % logic.columnsPerPage !== 0) {
            columns.push(
                <DayColumn
                    key={"events-date-column-fill-" + columns.length}
                    index={columns.length}
                />
            );
        }
        return columns;
    }, [logic.events, logic.columnsPerPage]);

    const eventDetails = useMemo(() => logic.noEvents ? null : (
        <ItemDetails
            itemId={logic.selectedEventId}
            itemType={ItemType.Event}
            onHide={logic.handleClearSelectedEvent}
        />
    ), [logic.noEvents, logic.selectedEventId]);

    const carousel = useMemo(() => logic.noEvents ? null : (
        <Flex fill className={"overflow-hidden"} styles={logic.isOnMobile ? {} : {marginLeft: "-1px"}}>
            <Carousel
                items={columns}
                spaceBetween={isOnMobile ? -30 : 0}
                slidesPerView={logic.columnsPerPage}
                noHeader
                noPadding={!isOnMobile}
                onCarouselRef={logic.handleCarouselRef}
            />
        </Flex>
    ), [columns, logic.columnsPerPage, logic.noEvents]);

    const noEventsMessage = useMemo(() => (
        <Flex fill column hAlign={"center"} vAlign={"center"} gap={"gap.large"}>
            <Image width={isOnMobile ? "50%" : "300px"} src={SadSvg}/>
            <Text size={"larger"} content={translate("NoUpcomingEvents") + "..."}/>
        </Flex>
    ), []);

    return (
        <Flex fill column className={"events"} gap={"gap.medium"}>
            {toolbar}
            {logic.isLoading ?
                <Loader label={translate("FetchingEvents")} styles={{height: "100%"}}/>
                :
                logic.noEvents ?
                    noEventsMessage
                    :
                    carousel
            }
            {eventDetails}
        </Flex>
    )
}