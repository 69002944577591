import * as microsoftTeams from "@microsoft/teams-js";
import {ErrorWithCode, IdentityType, TeamsFx, TeamsUserCredential,} from "@microsoft/teamsfx";
import {IInitArgs} from "./TeamsFx.interfaces";
import {TokenApi} from "apis/Token/TokenApi";
import {Client} from "@microsoft/microsoft-graph-client";

export let teamsFxGraphClient: Client | undefined = undefined;

const graphUrl = "https://graph.microsoft.com/";

let scopes = new Array<string>();

let teamsFx: TeamsFx | undefined = undefined;

let teamsFxCredential: TeamsUserCredential | undefined = undefined;

let teamsFxConfig: Record<string, string> = {};

let initArgs: IInitArgs | undefined = undefined;

const checkConfig = (config: IInitArgs) => {
    initArgs = config;
    if (!config.scopes || config.scopes.length === 0)
        config.scopes = [".default"];
    if (!config.clientId) throw new Error("TeamsFx client id is invalid");
    if (!config.apiBaseUrl) throw new Error("TeamsFx api base url is invalid");
    // Remove last slash from api base url
    if (config.apiBaseUrl.lastIndexOf('/') === config.apiBaseUrl.length - 1)
        config.apiBaseUrl = config.apiBaseUrl.substring(0, config.apiBaseUrl.length - 1);
};

const checkUserConsentedTeamsApp = async (): Promise<void> => {
    try {
        await microsoftTeams.authentication.getAuthToken({silent: true});
    } catch (e) {
        microsoftTeams.app.notifySuccess();
        try {
            await microsoftTeams.authentication.getAuthToken({silent: false});
        } catch (e) {
            await showErrorPage("You need to consent app");
        }
    }
};

export const initTeamsFx = async (config: IInitArgs): Promise<void> => {
    checkConfig(config);
    scopes = config.scopes?.map(s => graphUrl + s) ?? [];
    teamsFxConfig = {
        initiateLoginEndpoint: config.apiBaseUrl + "/auth-start.html",
        apiEndpoint: config.apiBaseUrl + "",
        clientId: config.clientId + "",
        tenantId: config.tenantId + "",
        scopes: scopes.join(" "),
        authorityHost: "https://login.microsoftonline.com/common/v2.0",
        applicationIdUri: config.apiBaseUrl + "/auth-start.html",
        loginPageTitle: config.loginPageTitle ?? "",
        loginPageSubtitle: config.loginPageSubtitle ?? "",
    };
    teamsFx = new TeamsFx(IdentityType.User, teamsFxConfig);
    teamsFxCredential = new TeamsUserCredential(teamsFxConfig);
    try {
        await checkUserConsentedTeamsApp();
        await getGraphClient();
    } catch (error: any) {
        await showErrorPage(error);
    }
}

const initGraphClient = async () => {
    if (!teamsFxCredential) throw new Error("TeamsFx credential is undefined");
    const graphToken = await TokenApi.getGraphToken();
    if (!graphToken) throw new Error("Invalid graph token");
    teamsFxGraphClient = Client.init({authProvider: (callback) => callback(undefined, graphToken)});
}

const getGraphClient = async (): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
        if (!teamsFx) return reject("TeamsFx is undefined");
        if (!teamsFxCredential) return reject("TeamsFx creadential is undefined");
        if (!teamsFxConfig.tenantId) return reject("Tenand id not defined in TeamsFx config");
        try {
            await initGraphClient();
            return resolve();
        } catch (error) {
            try {
                await showLoginPage();
                await handleLoginButtonClick(async (): Promise<void> => {
                    if (!teamsFxCredential) throw new Error("TeamsFx credential undefined");
                    setDOMElementVisibility("teamsfx-login-button", false);
                    setDOMElementVisibility("teamsfx-login-button-loading", true);
                    try {
                        await teamsFxCredential.login(scopes);
                    } catch (e: any) {
                        // Refresh window in case of invalid response
                        // This issue happens only on Android devices, can't parse result
                        // After reload it should work fine thanks to SSO
                        if (e.code === "InvalidResponse") window.location.reload();
                        else if (e.code === "ConsentFailed") return await showErrorPage("CancelledByUser");
                        return await showErrorPage(e);
                    }
                });
                const initGraphClientInterval = setInterval(async () => {
                    try {
                        await initGraphClient();
                        clearInterval(initGraphClientInterval);
                        return resolve();
                    } catch (_) {
                        // Ignore exception
                    }
                }, 3000);
            } catch (loginError: any) {
                const error: ErrorWithCode = loginError as ErrorWithCode;
                return reject(error.message);
            }
        }
    });
};

const setDOMElementVisibility = (id: string, visible: boolean) => {
    const element = document.getElementById(id);
    if (!element) throw new Error(`Can't find element with id ${id} in the DOM`);
    element.style.display = visible ? "flex" : "none";
};

const showLoginPage = () => {
    const root = document.getElementById("root");
    if (!root) throw new Error("Can't find root");
    if (teamsFxConfig.loginPageTitle) {
        const loginPageTitle = document.getElementById("teamsfx-login-title");
        if (!loginPageTitle) throw new Error("Login page title element not found in the DOM");
        loginPageTitle.innerText = teamsFxConfig.loginPageTitle;
    }
    if (teamsFxConfig.loginPageSubtitle) {
        const loginPageSubtitle = document.getElementById("teamsfx-login-subtitle");
        if (!loginPageSubtitle) throw new Error("Login page subtitle element not found in the DOM");
        loginPageSubtitle.innerText = teamsFxConfig.loginPageSubtitle;
    }
    setDOMElementVisibility("teamsfx-login-page", true);
    setDOMElementVisibility("teamsfx-login-button", true);
    setDOMElementVisibility("teamsfx-login-button-loading", false);
    microsoftTeams.app.notifySuccess();
};

const handleLoginButtonClick = (action: () => Promise<void>): Promise<void> => {
    return new Promise<void>((resolve) => {
        const loginButton = document.getElementById("teamsfx-login-button");
        if (!loginButton) throw new Error("Can't find login button");
        const onClickLogin = async () => {
            await action();
            loginButton.removeEventListener("click", onClickLogin);
            resolve();
        };
        loginButton.addEventListener("click", onClickLogin);
    });
};

export const showErrorPage = async (error: string) => {
    const root = document.getElementById("root");
    if (!root) return;
    const containerObjStyle: Record<string, string | number> = {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "text-align": "center",
        overflow: "auto",
        color: initArgs?.theme === "default" ? "black" : "white"
    };
    let containerStyle = "";
    for (const [key, value] of Object.entries(containerObjStyle))
        containerStyle += key + ":" + value + ";";
    const locale = initArgs?.locale ?? "en";
    const headerTitle = errorPageTranslations.translate("ErrorHeader", locale) + "...";
    const errorMessage = errorPageTranslations.translate(error, locale);
    const widgetResetMessage = errorPageTranslations.translate("PleaseResetWidget", locale);
    const imageSize = initArgs?.isWidget ? "220" : "300";
    const textSpace = initArgs?.isWidget ? "2px" : "8px";
    root.innerHTML = "" +
        "<div style='" + containerStyle + "'>" +
        "   <img width='" + imageSize + "' height='" + imageSize + "' alt='error image' src='/errorImage.svg'/>" +
        "   <h2 style='margin: " + textSpace + "'>" + headerTitle + "</h2>" +
        "   <p style='margin: " + textSpace + "; font-size: 1rem; max-width: 400px; word-break: break-word;'>" +
        "   " + errorMessage +
        (initArgs?.isWidget ? ("<br/><p style='margin: 4px; font-size: 0.8rem'>" + widgetResetMessage + "</p>") : "") +
        "   </p>" +
        "</div>";
}

export const errorPageTranslations = {
    translate: (id: string, locale: string): string => {
        return (errorPageTranslations as any)[locale][id as string] ??
            (errorPageTranslations as any).en[id as string] ?? id;
    },
    en: {
        ErrorHeader: "An error occured",
        CancelledByUser: "You need to consent app before using it",
        PleaseResetWidget: "Please reset the widget",
        NeedToConsent: "You need to consent",
        ClickButtonToContinue: "Click on the button to continue",
        Consent: "Consent"
    },
    fr: {
        ErrorHeader: "Une erreur s'est produite",
        CancelledByUser: "Vous devez consentir aux droits demandés par l'application afin de pouvoir l'utiliser",
        PleaseResetWidget: "Veuillez réinitialiser le widget",
        NeedToConsent: "Vous devez donner votre accord",
        ClickButtonToContinue: "Cliquez sur le bouton pour continuer",
        Consent: "Consentir"
    },
    de: {
        ErrorHeader: "Es ist ein Fehler aufgetreten",
        CancelledByUser: "Sie müssen der Anwendung zustimmen, bevor Sie sie verwenden",
        PleaseResetWidget: "Bitte setzen Sie das Widget zurück",
        NeedToConsent: "Sie müssen zustimmen",
        ClickButtonToContinue: "Klicken Sie auf die Schaltfläche, um fortzufahren",
        Consent: "Zustimmung"
    },
    it: {
        ErrorHeader: "Si è verificato un errore",
        CancelledByUser: "È necessario acconsentire all'applicazione prima di utilizzarla",
        PleaseResetWidget: "Si prega di resettare il widget",
        NeedToConsent: "È necessario il consenso",
        ClickButtonToContinue: "Cliccare sul pulsante per continuare",
        Consent: "Consenso"
    },
    es: {
        ErrorHeader: "Se ha producido un error",
        CancelledByUser: "Es necesario consentir la aplicación antes de usarla",
        PleaseResetWidget: "Por favor, reinicie el widget",
        NeedToConsent: "Es necesario que consienta",
        ClickButtonToContinue: "Haga clic en el botón para continuar",
        Consent: "Consentimiento"
    },
    pt: {
        ErrorHeader: "Ocorreu um erro",
        CancelledByUser: "É necessário o consentimento do pedido antes de o utilizar",
        PleaseResetWidget: "Por favor, reinicie o widget",
        NeedToConsent: "É necessário o seu consentimento",
        ClickButtonToContinue: "Clique no botão para continuar",
        Consent: "Consentimento"
    }
}