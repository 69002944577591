import {
    ActivityContextDispatchMessage,
    ActivityContextDispatchMessageType,
    IActivityContextState
} from "./ActivityContext.interfaces";
import {IActivity} from "../../interfaces";

export const initialState: IActivityContextState = {
    loaded: false,
    list: new Array<IActivity>(),
    hasNewActivity: false,
}

export const reducer = (prevState: IActivityContextState, payload: ActivityContextDispatchMessage): IActivityContextState => {
    const newState: IActivityContextState = {...prevState};
    const payloadType = payload.type;
    switch (payloadType) {
        case ActivityContextDispatchMessageType.SetActivityList:
            newState.list = payload.items;
            newState.loaded = true;
            return newState;
        case ActivityContextDispatchMessageType.SetHasNewActivity:
            newState.hasNewActivity = payload.value;
            return newState;
        default:
            throw new Error("Message " + ActivityContextDispatchMessageType[payloadType] + " not implemented");
    }
}