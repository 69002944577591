import moment from "moment/moment";
import {Immutable} from "../interfaces/Immutable";

const formatHourByCulture = (hour: Immutable<string> | undefined) => {
    if (!hour) return "?";
    if (hour.length !== 5 || !hour.match(/\d\d:\d\d/)) return hour;
    const mom = moment(hour, 'HH:mm');
    return mom.format("LT");
}

export const HoursModule = {
    formatHourByCulture
}