import {AxiosResponse} from "axios";
import {instance} from "../AxiosConfig/AxiosConfig";
import {KpiRequest} from "../../interfaces/KpiRequest";
import {SectorManagerBuildingKpis} from "../../interfaces/Kpis/SectorManagerBuildingKpis";
import {SuperAdminKpis} from "../../interfaces/Kpis/SuperAdminKpis";
import {BuildingKpis} from "../../interfaces/Kpis/BuildingKpis";

const reportBuildingClick = (buildingId: string) => new Promise<boolean>(resolve => (
    instance.get(`/pkpi/click/building/${buildingId}`).then((response: AxiosResponse) => {
        return resolve(response.data);
    }).catch(_ => {
        return resolve(false);
    })
));

const reportListViewClick = () => new Promise<boolean>(resolve => (
    instance.get("/pkpi/click/listview").then((response: AxiosResponse) => {
        return resolve(response.data);
    }).catch(_ => {
        return resolve(false);
    })
));

const getSectorManagerKpis = (request: KpiRequest) => new Promise<SectorManagerBuildingKpis | null>(resolve => (
    instance.post(`/kpi/sectorManager`, request).then((response: AxiosResponse) => {
        return resolve(response.data);
    }).catch(_ => {
        return resolve(null);
    })
));

const getSuperAdminKpis = (request: KpiRequest) => new Promise<SuperAdminKpis | null>(resolve => (
    instance.post(`/kpi/superAdmin`, request).then((response: AxiosResponse) => {
        return resolve(response.data);
    }).catch(_ => {
        return resolve(null);
    })
));

const getBuildingKpis = (buildingId: string, start: string, end: string) => new Promise<BuildingKpis | null>(resolve => (
    instance.get(`/kpi/building/${buildingId}?start=${start}&end=${end}`).then((response: AxiosResponse) => {
        return resolve(response.data);
    }).catch(_ => {
        return resolve(null);
    })
));

export const KpiApi = {
    getSectorManagerKpis,
    getSuperAdminKpis,
    getBuildingKpis,
    reportBuildingClick,
    reportListViewClick
}