import React, {useCallback, useMemo, useReducer as ReactReducer, useRef} from "react";
import {LocationStatsRef, LogicProps, Payload, State} from "./LocationStats.interfaces";
import {Immutable} from "../../../../interfaces/Immutable";

const reduce = (state: State, payloads: Array<Payload>) => {
    payloads.forEach(payload => {
        const payloadType = payload.type;
        switch (payloadType) {
            case "toggleSortAscending":
                state.sortAscending = !state.sortAscending;
                break;
            case "toggleSelectAll":
                if (state.selectedBuildings.includes("all")) state.selectedBuildings = [];
                else state.selectedBuildings = ["all"];
                break;
            default:
                const setProperty = `${payloadType[3]?.toLowerCase()}${payloadType.slice(4)}` as keyof State;
                if (!(setProperty in state)) throw new Error("Payload type " + payloadType + " not implemented");
                state[setProperty] = payload.value as never;
                break;
        }
    });
}

const updateExternalRef = (externalRef: React.MutableRefObject<LocationStatsRef | undefined> | undefined, state: State) => {
    if (!externalRef) return;
    if (!externalRef.current) {
        externalRef.current = {
            selectedBuildings: [],
        }
    }
    externalRef.current!.selectedBuildings = state.selectedBuildings;
}

const getInitialState = (props: LogicProps): State => {
    const state: State = {
        loaded: false,
        columnSort: null,
        sortAscending: true,
        visibleItemsCount: 0,
        table: null,
        selectedBuildings: [],
    };
    updateExternalRef(props.externalRef, state);
    return state;
}

export const useReducer = (props: LogicProps) => {
    const [, render] = ReactReducer(() => [], []);
    const initialState = useMemo(() => getInitialState(props), []);
    const stateRef = useRef<Immutable<State>>(initialState);
    const propsRef = useRef<Immutable<LogicProps>>(props);
    propsRef.current = props;

    const dispatch = useCallback((payload: Payload | Array<Payload>, reRender?: boolean) => {
        reduce(stateRef.current as State, Array.isArray(payload) ? payload : [payload]);
        updateExternalRef(props.externalRef, stateRef.current as State);
        if (reRender === undefined || reRender) render();
    }, []);

    return {state: stateRef.current, dispatch, propsRef};
}