import React, {useContext} from "react";
import {LocationStatsLogic} from "./LocationStats.logic";
import {LocationStatsRendering} from "./LocationStats.rendering";
import {LogicProps, Props} from "./LocationStats.interfaces";
import {ITeamsContext} from "../../../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../../../services/TeamsContext";
import {IUserContext, UserContext} from "../../../../services/UserContext";
import {ILocationContext} from "../../../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../../../services/LocationContext/LocationContext";

const LocationStats = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const locationContext = useContext<ILocationContext>(LocationContext);

    return (
        <LocationStatsLogic
            {...props}
            teamsContext={teamsContext}
            userContext={userContext}
            locationContext={locationContext}
            externalRef={props.externalRef as LogicProps["externalRef"]}>
            {logic => <LocationStatsRendering {...logic}/>}
        </LocationStatsLogic>
    )
}

export default LocationStats;