import "./Menu.styles.scss";
import {Button, Flex, Segment, Text} from "@fluentui/react-northstar";
import {memo, useMemo} from "react";
import {CloseIcon} from "assets/icons";
import {AppView} from "interfaces";
import {IMenuProps} from "./Menu.interfaces";
import {useMenu} from "./Menu.hook";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";

const propsAreEqual = (prevProps: Readonly<IMenuProps>, nextProps: Readonly<IMenuProps>): boolean => {
    return prevProps.currentView === nextProps.currentView && prevProps.open === nextProps.open;
}

export const Menu = memo((props: IMenuProps) => {
    const translate = useTranslation();
    const logic = useMenu(props);

    const header = useMemo(() => (
        <Flex className={"p-20px w-100 x-padding"} gap={"gap.small"} space={"between"}
              vAlign={"center"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Text weight={"light"} size={"larger"} content={translate("Welcome")}/>
                <Text weight={"bold"} size={"larger"} content={logic.userFirstName}/>
            </Flex>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Button text iconOnly icon={<CloseIcon/>} onClick={logic.handleClose}/>
            </Flex>
        </Flex>
    ), []);

    const items = useMemo(() => (
        <Flex fill column>
            {logic.appViews.map(appView => (
                <Flex
                    vAlign={"center"} gap={"gap.medium"} key={"appview-" + AppView[appView.id]}
                    className={"menu-item " + (appView.id === logic.currentView ? "active" : "")}
                    onClick={logic.handleChangeView(appView)}>
                    {appView.icon}
                    <Text size={"large"} content={translate(appView.translationId)}/>
                </Flex>
            ))}
        </Flex>
    ), [logic.appViews, logic.currentView]);

    return (
        <Segment className={"menu no-select " + (logic.open ? "" : "closed")}>
            <Flex fill column>
                {header}
                {items}
            </Flex>
        </Segment>
    )
}, propsAreEqual)