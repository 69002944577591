import {IToolbarState, ToolbarDispatchMessage, ToolbarDispatchMessageType} from "./Toolbar.interfaces";

export const initialState: IToolbarState = {
    searchFilter: "",
}

export const reducer = (prevState: IToolbarState, payload: ToolbarDispatchMessage): IToolbarState => {
    const newState: IToolbarState = {...prevState};
    switch (payload.type) {
        case ToolbarDispatchMessageType.ChangeSearchFilter:
            newState.searchFilter = payload.searchFilter;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}