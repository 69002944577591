import {useCallback, useEffect} from "react";
import 'moment/locale/fr';
import {IGeoPosition, ILocation, WeekDay} from "interfaces";
import {ILocationCustomUpdateData, ILocationInternalUpdateData} from "./LocationContext.interfaces";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {LocationModule} from "modules";
import {IUserContext} from "../UserContext";
import {IReview} from "../../interfaces/IReview";
import {v4 as uuidv4} from "uuid";
import {IRawLocation} from "../../interfaces/Location/IRawLocation";
import {BuildingsApi} from "../../apis/Buildings/BuildingsApi";
import {IConfigurationService} from "../ConfigurationService/ConfigurationService.interfaces";
import {BrandModule} from "../../modules/Brand.module";
import {Immutable} from "../../interfaces/Immutable";
import {useReducer} from "./LocationContext.reducer";
import {InRangeRequest} from "../../interfaces/InRangeRequest";
import {RawInRangeRequest} from "../../interfaces/RawInRangeRequest";
import {CancelTokenSource} from "axios";

export const useLocationContext = (teamsContext: ITeamsContext, userContext: IUserContext, configuration: IConfigurationService) => {
    const {state, dispatch} = useReducer();

    useEffect(function onMount() {
        (async () => {
            if (!teamsContext.initialized || !userContext.currentUser?.geoLocation) return;
            dispatch({type: "setLoaded", value: true});
        })()
    }, [teamsContext.initialized]);

    useEffect(function onConfigurationLoaded() {
        if (!configuration.loaded) return;
        dispatch({type: "setIncompleteCount", value: configuration.data.incompleteCount});
    }, [configuration.loaded]);

    const processFetchRequest = useCallback(async (
        data: InRangeRequest,
        id: string,
        fetchFunc: (data: RawInRangeRequest, onCancelToken: (cancelToken: CancelTokenSource) => void,
        ) => Promise<{
            count: number,
            buildings: Array<IRawLocation>,
            cancelled?: boolean
        } | null>
    ) => {
        const areSameIds = state.prevFetchId === id;
        if (areSameIds && areSameInRangeRequests(state.prevInRangeRequest, data))
            return {error: false, cancelled: false};
        dispatch([
            {type: "setPrevInRangeRequest", value: {...data}},
            {type: "setPrevFetchId", value: id},
        ], false);
        const isResultPaginated = data.skip !== undefined && data.take !== undefined;
        if (state.cancelToken) state.cancelToken.cancel();
        dispatch({type: "setLoaded", value: false});
        const rawRequest = formatInRangeRequestToRawInRangeRequest(data);
        const rawLocationsResult = await fetchFunc(rawRequest, cancelToken => dispatch({
            type: "setCancelToken",
            value: cancelToken
        }, false));
        if (rawLocationsResult === null) {
            dispatch({type: "setLoaded", value: true});
            return {error: true, cancelled: false};
        }
        if (rawLocationsResult.cancelled) return {error: true, cancelled: true};
        const buildings = rawLocationsResult.buildings.filter(loc => loc);
        setLocationsList(buildings, rawLocationsResult.count, isResultPaginated);
        return {error: false, cancelled: false};
    }, []);

    const fetchLocations = useCallback(async (data: InRangeRequest) => {
        return await processFetchRequest(data, "locations", BuildingsApi.getFromGeoPosition);
    }, []);

    const fetchIncompleteLocations = useCallback(async (data: InRangeRequest) => {
        return await processFetchRequest(data, "incomplete", BuildingsApi.getIncompleteBuildings);
    }, []);

    const fetchCurrentUserFavoritesLocations = useCallback(async (data: InRangeRequest) => {
        return await processFetchRequest(data, "favorites", BuildingsApi.getUserFavorites);
    }, []);

    const fetchCurrentUserBuildings = useCallback(async (data: InRangeRequest) => {
        return await processFetchRequest(data, "buildings", BuildingsApi.getUserBuildings);
    }, []);

    const updateLocation = useCallback((
        buildingId: string,
        customData: ILocationCustomUpdateData,
        internalData: ILocationInternalUpdateData | null,
    ) => {
        return new Promise<void>(async resolve => {
            const newList = [...state.list].filter(l => l.id !== buildingId);
            let newBuilding = {...state.list.find(l => l.id === buildingId)} as ILocation | undefined;
            if (!newBuilding) throw new Error("Can't find building with id : " + buildingId);
            newBuilding = {...newBuilding, ...customData};
            let incompleteCount = state.incompleteCount;
            if (internalData !== null) {
                newBuilding.brands = BrandModule.parseStringBrands(internalData.brands);
                newBuilding.name = internalData.name;
                newBuilding.type = internalData.type;
                newBuilding.imageLevel = internalData.imageLevel;
                newBuilding.phone = internalData.phone;
                newBuilding.website = internalData.website;
                newBuilding.priceRange = internalData.priceRange;
                newBuilding.address = {
                    ...newBuilding.address,
                    fullAddress: internalData.addressFull,
                    geoPosition: null,
                }
                if (internalData.addressLatitude && internalData.addressLongitude) {
                    if (!newBuilding.address.geoPosition) incompleteCount -= 1;
                    newBuilding.address.geoPosition = {
                        lat: internalData.addressLatitude,
                        lng: internalData.addressLongitude,
                    }
                }
                newBuilding.pictures = [internalData.picture];
                newBuilding.hours = {
                    [WeekDay.Monday]: internalData.hours.monday,
                    [WeekDay.Tuesday]: internalData.hours.tuesday,
                    [WeekDay.Wednesday]: internalData.hours.wednesday,
                    [WeekDay.Thursday]: internalData.hours.thursday,
                    [WeekDay.Friday]: internalData.hours.friday,
                    [WeekDay.Saturday]: internalData.hours.saturday,
                    [WeekDay.Sunday]: internalData.hours.sunday,
                };
                newBuilding.extendedProps = {
                    ...newBuilding.extendedProps,
                    openStateData: LocationModule.getOpenStateData(newBuilding.hours),
                };
                newBuilding.googleMapsLink = LocationModule.generateGoogleMapsLink(
                    newBuilding.name,
                    newBuilding.address.fullAddress,
                    newBuilding.address.geoPosition?.lat ?? 0,
                    newBuilding.address.geoPosition?.lng ?? 0,
                );
            }
            newList.push(newBuilding);
            dispatch([
                {type: "setList", value: newList},
                {type: "setIncompleteCount", value: incompleteCount},
            ])
            if (internalData !== null) await BuildingsApi.updateInternalFields(buildingId, internalData);
            await BuildingsApi.updateCustomFields(buildingId, {
                ...customData,
                flagship: customData.flagship !== undefined ? (customData.flagship + "") : undefined,
            });
            resolve();
        })
    }, []);

    const publishReview = useCallback(async (review: IReview) => {
        const reviewWithId = {...review, id: uuidv4()};
        const existingLocationIndex = state.list.findIndex(l => l.id === review.buildingId);
        if (existingLocationIndex < 0) throw new Error("Can't find location with id : " + review.buildingId);
        const location = state.list[existingLocationIndex];
        if (!location) throw new Error("Location is undefined");
        const reviews = new Array<IReview>();
        const locationReviews = location.extendedProps.reviews;
        if (!!locationReviews) reviews.push(...locationReviews);
        reviews.unshift(reviewWithId);
        const newLocation: Immutable<ILocation> = {...location, extendedProps: {...location.extendedProps, reviews}};
        const newList = [...state.list];
        newList[existingLocationIndex] = newLocation;
        dispatch({type: "setList", value: newList});
        await BuildingsApi.publishReview(reviewWithId);
    }, []);

    const getLocationReviews = useCallback(async (locationId: string) => {
        let reviews = await BuildingsApi.getReviews(locationId);
        reviews = reviews.sort((a, b) => new Date(b.reviewDate).getTime() - new Date(a.reviewDate).getTime());
        let isRecommended = false;
        let existingLocationIndex = state.list.findIndex(l => l.id === locationId);
        if (existingLocationIndex < 0) {
            isRecommended = true;
            existingLocationIndex = state.recommendedList.findIndex(l => l.id === locationId);
        }
        if (existingLocationIndex < 0) throw new Error("Can't find location with id : " + locationId);
        const existingLocation = isRecommended ? state.recommendedList[existingLocationIndex] : state.list[existingLocationIndex];
        if (!existingLocation) throw new Error("Can't find location with id : " + locationId);
        const newLocation: Immutable<ILocation> = {
            ...existingLocation,
            extendedProps: {...existingLocation.extendedProps, reviews}
        };
        const newList = isRecommended ? [...state.recommendedList] : [...state.list];
        newList[existingLocationIndex] = newLocation;
        dispatch({type: isRecommended ? "setRecommendedList" : "setList", value: newList});
        return reviews;
    }, []);

    const setLocationsList = useCallback((rawLocations: Array<IRawLocation>, totalLocationsCount?: number, keepPreviousLocations?: boolean) => {
        const locations: Immutable<Array<ILocation>> = rawLocations
            .map(rawLoc => LocationModule.formatRawLocation(rawLoc))
            .filter(l => !!l) as Array<Immutable<ILocation>>;
        let recommendedLocations: Immutable<Array<ILocation>> = locations.filter(l => l.isRecommended);
        if (recommendedLocations.length === 0) recommendedLocations = state.recommendedList;
        dispatch([
            {type: "setList", value: locations},
            {type: "setRecommendedList", value: recommendedLocations},
            {type: "setLoaded", value: true},
            {type: "setTotalLocationsCount", value: totalLocationsCount ?? state.totalLocationsCount},
        ]);
    }, []);

    const findLocation = useCallback((id: string) => {
        let location: Immutable<ILocation> | undefined;
        location = state.recommendedList.find(l => l.id === id);
        if (!location) location = state.list.find(l => l.id === id);
        return location;
    }, []);

    const clearLocations = useCallback(() => {
        if (state.list.length === 0 && state.recommendedList.length === 0 && state.totalLocationsCount === 0) return;
        dispatch([
            {type: "setList", value: []},
            {type: "setRecommendedList", value: []},
            {type: "setPrevInRangeRequest", value: undefined},
            {type: "setTotalLocationsCount", value: 0}
        ]);
    }, []);

    return {
        ...state,
        updateLocation,
        fetchLocations,
        publishReview,
        getLocationReviews,
        setLocationsList,
        fetchCurrentUserFavoritesLocations,
        fetchCurrentUserBuildings,
        fetchIncompleteLocations,
        findLocation,
        clearLocations,
    };
}

const formatInRangeRequestToRawInRangeRequest = (request: InRangeRequest): RawInRangeRequest => {
    const northEastBound: IGeoPosition = request.bounds?.getNorthEast().toJSON() as IGeoPosition;
    const southWestBound: IGeoPosition = request.bounds?.getSouthWest().toJSON() as IGeoPosition;
    return {
        userPosition: request.userPosition,
        position: request.position,
        viewport: {northeast: northEastBound, southwest: southWestBound},
        skip: request.skip,
        take: request.take,
        radius: request.radius,
        orderBy: request.orderBy,
        sortAscending: request.sortAscending,
        filters: request.filters,
        userFavoriteBrands: request.userFavoriteBrands,
    }
}

const areSameInRangeRequests = (r1: InRangeRequest | undefined, r2: InRangeRequest | undefined) => {
    if (!r1 || !r2) return false;
    return JSON.stringify(r1).split("").sort().join() === JSON.stringify(r2).split("").sort().join();
}