import * as React from "react";
import {useCallback, useContext, useMemo, useState} from "react";
import {ILocationPreviewProps, LocationPreviewAction} from "./LocationPreview.interfaces";
import {CallIcon, ItineraryIcon, StarIcon} from "assets/icons";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {IUserContext, UserContext} from "services/UserContext";
import {Flex, SubtractIcon} from "@fluentui/react-northstar";
import {LocationModule} from "modules";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

const locationPreviewActions = Object.keys(LocationPreviewAction)
    .filter((key: string | number) => !isNaN(Number(key)))
    .map(key => Number(key) as LocationPreviewAction);

export const useLocationPreview = (props: ILocationPreviewProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const translate = useTranslation();
    const [thumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);

    const {isOnMobile} = teamsContext;

    const isLocationInFavoritesOfCurrentUser = userContext.currentUser?.applicationSettings.favoriteBuildings
        ?.includes(props.location.id) ?? false;

    const handleShowDetails = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation();
        props.showDetails(props.location.id);
    }, [props.location.id]);

    const handleCall = useCallback(async () => {
        await LocationModule.call(props.location.phone, isOnMobile);
    }, [props.location.phone]);

    const handleShowItinerary = useCallback(() => {
        const userPosition = userContext.currentUser?.geoLocation;
        LocationModule.showItinerary(userPosition, props.location.address.geoPosition);
    }, [props.location.address.geoPosition]);

    const handleToggleFavorite = useCallback(() => {
        userContext.toggleLocationInFavorite(props.location.id);
    }, [props.location.id]);

    const handleImageLoaded = useCallback(() => {
        setThumbnailLoaded(true);
    }, []);

    const moreOptions = useMemo(() => {
        return locationPreviewActions.map((action: LocationPreviewAction) => {
            let icon: JSX.Element;
            let label: string;
            let onClick: () => void;
            switch (action) {
                case LocationPreviewAction.Call:
                    if (!props.location.phone) return null;
                    icon = <CallIcon/>;
                    label = translate(LocationPreviewAction[action] ?? "");
                    onClick = handleCall;
                    break;
                case LocationPreviewAction.Itinerary:
                    icon = <ItineraryIcon/>;
                    label = translate(LocationPreviewAction[action] ?? "");
                    onClick = handleShowItinerary;
                    break;
                case LocationPreviewAction.AddToFavorite:
                    icon = (
                        <Flex vAlign={"center"} hAlign={"center"} className={"pos-relative"}>
                            <StarIcon/>
                            {!isLocationInFavoritesOfCurrentUser ? null : <SubtractIcon className={"no-icon"}/>}
                        </Flex>
                    )
                    label = translate(isLocationInFavoritesOfCurrentUser ? "RemoveFromFavorites" : (LocationPreviewAction[action] ?? ""));
                    onClick = handleToggleFavorite;
                    break;
            }
            return (
                {
                    key: "location-preview-action-" + LocationPreviewAction[action],
                    content: label,
                    value: action,
                    icon,
                    onClick
                }
            )
        }).filter(option => !!option);
    }, [isLocationInFavoritesOfCurrentUser]);

    return {
        location: props.location,
        handleShowDetails: handleShowDetails,
        actions: moreOptions,
        showFavoriteIcon: isLocationInFavoritesOfCurrentUser,
        handleCall,
        handleShowItinerary,
        handleToggleFavorite,
        thumbnailLoaded,
        handleImageLoaded,
        isRecommendation: props.isRecommendation,
    }
}