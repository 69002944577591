import React from "react";
import {SuperAdminDashboardLogic} from "./SuperAdminDashboard.logic";
import {SuperAdminDashboardRendering} from "./SuperAdminDashboard.rendering";
import {Props} from "./SuperAdminDashboard.interfaces";

const SuperAdminDashboard = (props: Props): JSX.Element => {
    return (
        <SuperAdminDashboardLogic {...props}>
            {logic => <SuperAdminDashboardRendering {...logic}/>}
        </SuperAdminDashboardLogic>
    )
}

export default SuperAdminDashboard;