import {ILocation} from "interfaces";
import {Immutable} from "../../interfaces/Immutable";

export interface ILocationPreviewProps {
    location: Immutable<ILocation>,
    showDetails: (id: string) => void,
    isRecommendation?: boolean,
}

export enum LocationPreviewAction {
    Call, Itinerary, AddToFavorite
}