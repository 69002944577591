import {useCallback, useContext, useMemo} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {IReviewCardProps} from "./ReviewCard.interfaces";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";
import {useTranslation} from "services/TranslationService/TranslationService.hook";
import {IUserContext, UserContext} from "../../services/UserContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const useReviewCard = (props: IReviewCardProps) => {
    const locationContext = useContext<ILocationContext>(LocationContext);
    const userContext = useContext<IUserContext>(UserContext);
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const translate = useTranslation();

    const isCurrentUser = teamsContext.userId === props.review.userAadId;

    const location = useMemo(() => {
        return locationContext.findLocation(props.review.buildingId);
    }, [props.review.buildingId]);

    const user = useMemo(() => {
        return userContext.list.find(u => u.id === props.review.userAadId);
    }, [props.review.userAadId, userContext.list.length]);

    const handleCall = useCallback(async () => {
        if (!user) return;
        const userMail = user.userPrincipalName;
        await microsoftTeams.app.openLink("https://teams.microsoft.com/l/call/0/0?users=" + userMail + "&withVideo=true");
    }, [!!user]);

    const handleSendMessage = useCallback(async () => {
        if (!location || !user) return;
        const userMail = user.userPrincipalName;
        let deepLink = "https://teams.microsoft.com/l/chat/0/0?users=" + userMail;
        deepLink += "&message=";
        deepLink += encodeURIComponent("Conviviapp" + "\n");
        deepLink += encodeURIComponent("____________________________________________________________" + "\n");
        deepLink += encodeURIComponent(translate("Building") + ": " + location.name + "\n");
        deepLink += encodeURIComponent(translate("Address") + ": " + location.address.fullAddress + "\n");
        deepLink += encodeURIComponent(translate("Phone") + ": " + location.phone + "\n");
        deepLink += encodeURIComponent("____________________________________________________________" + "\n\n\r\n");
        await microsoftTeams.app.openLink(deepLink)
    }, [!!user, !!location]);

    return {
        handleCall,
        handleSendMessage,
        location,
        user,
        isCurrentUser,
    }
}