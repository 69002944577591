import {Button, Flex, Header, Image, Text} from "@fluentui/react-northstar";
import {languageObject} from "translations";

export const ConsentPage = () => {
    return (
        <Flex
            id={"teamsfx-login-page"}
            styles={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                display: "none"
            }}
            fill
            vAlign={"center"}
            hAlign={"center"}
            column
        >
            <Flex id={"teamsfx-consent-container"} fill vAlign={"center"} hAlign={"center"} column>
                <Image id={"teamsfx-login-image"} width="300px" height="300px" alt="login image"
                       src={"/loginImage.svg"}/>
                <Header
                    id={"teamsfx-login-title"}
                    styles={{marginTop: "10px", marginBottom: "5px"}}
                    as={"h2"}
                    content={languageObject.translate("NeedToConsent")}
                />
                <Text
                    id={"teamsfx-login-subtitle"}
                    styles={{marginTop: "0", marginBottom: "15px"}}
                    content={languageObject.translate("ClickButtonToContinue")}
                />
                <Button id={"teamsfx-login-button"} primary content={languageObject.translate("Consent")}/>
                <Button
                    styles={{display: "none"}}
                    id={"teamsfx-login-button-loading"}
                    primary
                    loading
                    disabled
                    content={languageObject.translate("Consent")}
                />
            </Flex>
        </Flex>
    );
};