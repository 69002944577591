import React, {memo, useCallback, useMemo} from "react";
import {
    Accordion,
    Button,
    Checkbox,
    ChevronDownIcon,
    ChevronEndIcon,
    CloseIcon,
    Divider,
    Flex,
    Input,
    RetryIcon,
    Text
} from "@fluentui/react-northstar";
import "./FilterMobileDialog.styles.scss";
import {IFilterMobileDialogProps} from "./FilterMobileDialog.interfaces";
import {useFilterMobileDialog} from "./FilterMobileDialog.hook";
import {useTranslation} from "../../../services/TranslationService/TranslationService.hook";
import {IFilterItem} from "../Filter.interfaces";
import {Immutable} from "../../../interfaces/Immutable";

const propsAreEqual = (prevProps: Readonly<IFilterMobileDialogProps>, nextProps: Readonly<IFilterMobileDialogProps>) => {
    return (
        prevProps.show === nextProps.show &&
        prevProps.items === nextProps.items
    )
}

export const FilterMobileDialog = memo((props: IFilterMobileDialogProps) => {
    const translate = useTranslation();
    const logic = useFilterMobileDialog(props);

    const renderItem = useCallback((item: Immutable<IFilterItem>, openMenuIds: Array<string>, inMenu: boolean = false) => {
        const hasMenu = !!item.menu;
        const isMenuOpen = hasMenu && openMenuIds.includes(item.key);
        const unitWidth = (!!item.input?.unit ? item.input.unit.width : 0) + 15;
        const inputMaxWidth = unitWidth + 80;
        return (
            <Flex gap="gap.small" className={"w-100"}>
                {!inMenu ? null : <Divider vertical/>}
                {!hasMenu ?
                    <Flex className={"w-100"} vAlign={"center"} onClick={logic.handleToggleItem(item.key)}
                          styles={{minHeight: "55px"}}>
                        <Flex gap={"gap.small"} className={"w-100"} styles={{maxWidth: "calc(100% - 30px)"}}>
                            {item.icon}
                            <Text truncated content={translate(item.label)}/>
                        </Flex>
                        {!item.input ? null :
                            <Flex
                                className={"filter-input-container"}
                                styles={{maxWidth: inputMaxWidth + "px"}}
                                onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => e.stopPropagation()}>
                                <Input
                                    type={item.input.type ?? ""}
                                    fluid
                                    value={item.input.value}
                                    input={{styles: {paddingRight: unitWidth + "px"}}}
                                    className={"filter-input"}
                                    icon={<Text styles={{color: "grey"}} content={item.input.unit.label}/>}
                                    onChange={logic.handleFilterItemInputValueChange(item.key)}
                                />
                            </Flex>
                        }
                        <Flex hAlign={"end"}>
                            <Checkbox checked={item.isChecked} labelPosition={"start"}/>
                        </Flex>
                    </Flex>
                    :
                    <Accordion
                        className={"w-100"}
                        activeIndex={isMenuOpen ? [0] : []}
                        renderPanelTitle={() => (
                            <Flex className={"w-100"} vAlign={"center"} styles={{minHeight: "55px"}}
                                  onClick={logic.handleToggleMenu(item.key)} gap={"gap.small"}>
                                <Flex className={"w-100"} styles={{maxWidth: "calc(100% - 26px)"}}>
                                    <Text truncated content={translate(item.label)}/>
                                </Flex>
                                {isMenuOpen ?
                                    <ChevronDownIcon outline styles={{color: "#C8C6C4"}}/>
                                    :
                                    <ChevronEndIcon outline styles={{color: "#C8C6C4"}}/>
                                }
                            </Flex>
                        )}
                        renderPanelContent={() => (
                            <Flex column className={"w-100"} styles={{marginTop: "10px"}}>
                                {item.menu?.map(item => renderItem(item, openMenuIds, true))}
                            </Flex>
                        )}
                        panels={[{content: "", title: ""}]}
                    />
                }
            </Flex>
        )
    }, []);

    const content = useMemo(() => !logic.show ? null : (
        <Flex fill column className={"filter-mobile-dialog"}>
            <Flex className={"w-100"} column>
                <Flex className={"w-100"} space={"between"}>
                    <Text size={"large"} weight={"semibold"} content={translate("Filters")}/>
                    <Button icon={<CloseIcon outline/>} iconOnly text onClick={logic.onHide}/>
                </Flex>
                <Flex styles={{marginLeft: "-8px"}}>
                    <Button
                        icon={<RetryIcon/>}
                        primary
                        text
                        content={translate("ResetFilters")} onClick={logic.handleClearFilters}
                    />
                </Flex>
            </Flex>
            <Flex fill column styles={{overflow: "scroll"}}>
                {props.items.map(item => renderItem(item, logic.openMenuIds))}
            </Flex>
        </Flex>
    ), [logic.show, logic.items, logic.openMenuIds]);

    if (!logic.show) return null;
    return (
        <Flex column className={"filter-mobile-dialog-container"}>
            {content}
        </Flex>
    )
}, propsAreEqual)