import {memo, useCallback, useEffect, useMemo, useRef} from "react";
import {Logic, LogicProps, MapRef} from "./Map.interfaces";
import {useReducer} from "./Map.reducer";
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";
import {IGeoPosition, ILocation, LocationType} from "../../interfaces";
import {ConfigurationModule} from "../../modules/Configuration.module";
import {MapModule} from "../../modules/Map.module";
import GoogleMapReact from "google-map-react";
import {MapDarkTheme} from "../../const/MapDarkTheme";
import {Cluster} from "@googlemaps/markerclusterer/dist/cluster";
import {Immutable} from "../../interfaces/Immutable";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return (
        prevProps.show === nextProps.show &&
        prevProps.filteredLocations === nextProps.filteredLocations &&
        prevProps.preventFetchOnIdle === nextProps.preventFetchOnIdle &&
        prevProps.blockFetch === nextProps.blockFetch &&
        prevProps.userContext.currentUser?.geoLocation === nextProps.userContext.currentUser?.geoLocation &&
        prevProps.teamsContext.isLightTheme === nextProps.teamsContext.isLightTheme &&
        prevProps.locationContext.loaded === nextProps.locationContext.loaded
    )
}

export const MapLogic = memo((props: LogicProps): JSX.Element => {
    const reducer = useReducer(props);
    const logic: Logic = useLogic(reducer);
    return props.children(logic);
}, keepSameLogic);

const mapMinZoom = 8;

export const useLogic = ({state, dispatch, propsRef, externalRef}: ReturnType<typeof useReducer>) => {
    const mapRef = useRef<google.maps.Map>();

    const {isLightTheme, isOnMobile} = propsRef.current.teamsContext;

    const googleMapsApiKey = useMemo(() => ConfigurationModule.getValue("googleKey"), []);

    useEffect(function onMount() {
        if (!externalRef.current) return;
        externalRef.current!.goToGeoPosition = goToGeoPosition;
        externalRef.current!.visibleLocationIds = state.visibleLocations;
        externalRef.current!.centerToUserPosition = centerMapToUser;
        externalRef.current!.centerMapToLocations = centerMapToLocations;
        externalRef.current!.clearSelectedSuggestionZoneCircle = clearSelectedSuggestionZoneCircle;
        externalRef.current!.hideLocationPreview = handleCloseLocationPreview;
        externalRef.current!.refreshLocations = fetchMapBoundsLocations;
        externalRef.current!.getMapCenter = getMapCenter;
        externalRef.current!.getMapBounds = getMapBounds;
        externalRef.current!.getMapRadius = getMapRadius;
        propsRef.current.onInitialized?.();
    }, []);

    useEffect(function onUnmount() {
        return () => {
            state.mapListeners.forEach(listener => listener.remove());
            state.markerListeners.forEach(listener => listener.remove());
        }
    }, []);

    useEffect(function onUserLocationFound() {
        if (!mapRef.current || !getUserGeolocation()) return;
        initializeMapListeners();
        centerMapToUser();
    }, [!!propsRef.current.userContext.currentUser?.geoLocation]);

    useEffect(function onMountOnMobile() {
        if (!isOnMobile) return;
        const onTouchStart = (e: TouchEvent) => {
            const targetTagName = (e.target as HTMLElement)?.tagName;
            if (targetTagName.toLowerCase() === "img") return;
            handleCloseLocationPreview();
        }
        document.body.addEventListener("touchstart", onTouchStart);
        return () => {
            document.body.removeEventListener("touchstart", onTouchStart);
        }
    }, []);

    useEffect(function onMountOnDesktop() {
        if (isOnMobile) return;
        document.body.addEventListener("wheel", handleCloseLocationPreview);
        return () => {
            document.body.removeEventListener("wheel", handleCloseLocationPreview);
        }
    }, []);

    useEffect(function onLocationsChange() {
        refreshLocations(propsRef.current.filteredLocations, state.visibleLocationPreviewId);
    }, [propsRef.current.filteredLocations]);

    const getMapCenter = useCallback((): google.maps.LatLngLiteral | undefined => {
        if (state.selectedSuggestionZoneCircle)
            return state.selectedSuggestionZoneCircle.getCenter()?.toJSON();
        return mapRef.current?.getCenter()?.toJSON();
    }, []);

    const getMapBounds = useCallback((): google.maps.LatLngBounds | undefined => {
        if (state.selectedSuggestionZoneCircle)
            return state.selectedSuggestionZoneCircle.getBounds() ?? undefined;
        return mapRef.current?.getBounds();
    }, []);

    const getMapRadius = useCallback((): number | undefined => {
        if (state.selectedSuggestionZoneCircle)
            return state.selectedSuggestionZoneCircle.getRadius() - 100;
        return undefined;
    }, []);

    const getUserGeolocation = useCallback(() => {
        return propsRef.current.userContext.currentUser?.geoLocation;
    }, []);

    const fetchMapBoundsLocations = useCallback(async (force?: boolean) => {
        const userGeolocation = getUserGeolocation();
        if (!mapRef.current || !userGeolocation) return;
        if (!force && propsRef.current.blockFetch) return;
        const mapCenter = mapRef.current!.getCenter();
        if (!mapCenter) return;
        const geoPosition: IGeoPosition = {lat: mapCenter.lat(), lng: mapCenter.lng()};
        const mapZoom = mapRef.current?.getZoom() ?? 18;
        if (state.prevBounds?.zoom === mapZoom && state.prevBounds?.center === geoPosition) return;
        dispatch({type: "setPrevBounds", value: {zoom: mapZoom, center: geoPosition}});
        const fetchResult = await propsRef.current.fetchLocations(!propsRef.current.preventFetchOnIdle);
        if (fetchResult.cancelled) return;
        if (propsRef.current.defaultBuildingId && !state.isDefaultBuildingAlreadyShown) {
            dispatch({type: "setIsDefaultBuildingAlreadyShown", value: true}, false);
            propsRef.current.showLocationDetails(propsRef.current.defaultBuildingId);
        }
        if (state.selectedSuggestionBuildingData) {
            showMarkerPreview(state.selectedSuggestionBuildingData?.id, state.selectedSuggestionBuildingData?.position);
            dispatch({type: "setSelectedSuggestionBuildingData", value: undefined}, false);
        }
    }, []);

    const initializeMapListeners = useCallback(() => {
        if (!mapRef.current) return;
        state.mapListeners.forEach(listener => listener.remove());
        const zoomListener = mapRef.current?.addListener("zoom_changed", setZoomLevel);
        const idleListener = mapRef.current?.addListener("idle", fetchMapBoundsLocations);
        const dragStartListener = mapRef.current?.addListener("dragstart", handleCloseLocationPreview);
        dispatch({type: "addMapListeners", value: [idleListener, zoomListener, dragStartListener]});
    }, []);

    const clearSelectedSuggestionZoneCircle = useCallback(() => {
        if (!state.selectedSuggestionZoneCircle) return;
        state.selectedSuggestionZoneCircle?.setMap(null);
        dispatch({type: "setSelectedSuggestionZoneCircle", value: undefined}, false);
    }, []);

    const initializeMarker = useCallback((location: Immutable<ILocation>) => {
        if (!mapRef.current || !location.address.geoPosition) return;
        dispatch({type: "addVisibleLocation", value: location.id}, false);
        const isMarkerSelectedSuggestion = state.selectedSuggestionBuildingData?.id === location.id;
        const newMarker = new google.maps.Marker({
            map: mapRef.current,
            position: location.address.geoPosition,
            icon: generateLocationMarkerIcon(location),
            optimized: true,
            visible: true,
            opacity: isMarkerSelectedSuggestion ? 0.5 : 1,
        });
        newMarker.set("locationId", location.id);
        newMarker.set("isRecommended", location.isRecommended);
        newMarker.set("isShow", location.isShow);
        if (isOnMobile) {
            const clickListener = newMarker.addListener("click", handleShowMarkerPreview(location.id));
            dispatch({type: "addMarkerListeners", value: [clickListener]}, false);
        } else {
            const clickListener = newMarker.addListener("click", handleShowLocationDetails(location.id));
            const mouseOverListener = newMarker.addListener("mouseover", handleShowMarkerPreview(location.id));
            const mouseOutListener = newMarker.addListener("mouseout", handleLeaveMarker);
            dispatch({type: "addMarkerListeners", value: [clickListener, mouseOverListener, mouseOutListener]}, false);
        }
        dispatch({type: "bindMarker", id: location.id, marker: newMarker}, false);
        state.clusterer?.addMarker(newMarker, true);
    }, []);

    const refreshLocations = useCallback((locations: Immutable<Array<ILocation>>, visibleLocationPreviewId: string) => {
        const userGeolocation = getUserGeolocation();
        if (!state.loaded || !mapRef.current || !userGeolocation) return;
        dispatch({type: "setVisibleLocations", value: []}, false);
        state.markers.forEach(marker => marker.setMap(null));
        dispatch({type: "clearMarkers"}, false);
        state.clusterer?.clearMarkers();
        locations.forEach(initializeMarker);
        state.clusterer?.render();
        if (!!visibleLocationPreviewId && !locations.find(l => l.id === visibleLocationPreviewId))
            handleCloseLocationPreview();
    }, []);

    const renderBoundsZoneCircle = useCallback((bounds: google.maps.LatLngBounds) => {
        if (!mapRef.current) return;
        const boundsCenter = bounds.getCenter();
        const northCenterGeoPosition: IGeoPosition = {
            lat: bounds.getNorthEast().lat(),
            lng: boundsCenter.lng(),
        };
        const radius = MapModule.getDistanceInMetersBetweenCoordinates(boundsCenter, northCenterGeoPosition);
        dispatch({
            type: "setSelectedSuggestionZoneCircle", value: new google.maps.Circle({
                strokeColor: isLightTheme ? "white" : "#151515",
                strokeOpacity: 0.5,
                strokeWeight: 1,
                fillColor: "#244489",
                fillOpacity: 0.2,
                map: mapRef.current,
                center: boundsCenter,
                radius: radius,
            })
        }, false);
    }, []);

    const goToGeoPosition: MapRef["goToGeoPosition"] = useCallback((data, buildingId) => {
        if (!mapRef.current) return;
        const geoPosition: IGeoPosition = {lat: data.lat, lng: data.lng};
        if (!!buildingId) dispatch({
            type: "setSelectedSuggestionBuildingData",
            value: {id: buildingId, position: geoPosition}
        }, false);
        const bounds = new google.maps.LatLngBounds();
        if (data.viewport) {
            bounds.extend({...data.viewport.northeast});
            bounds.extend({...data.viewport.southwest});
            renderBoundsZoneCircle(bounds);
        } else {
            bounds.extend(geoPosition);
        }
        mapRef.current.fitBounds(bounds);
        if (data.zoom !== undefined) mapRef.current?.setZoom(data.zoom);
    }, []);

    const handleCloseLocationPreview = useCallback(() => {
        dispatch([
            {type: "setPrevLocationPreviewId", value: ""},
            {type: "setVisibleLocationPreviewId", value: ""}
        ]);
    }, []);

    const handleMapLoaded = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
        if (propsRef.current.defaultGeoPosition) centerMapToGeoPosition(propsRef.current.defaultGeoPosition, propsRef.current.defaultZoomLevel);
        else centerMapToUser();
        initializeMapListeners();
        const clusterer = new MarkerClusterer({
            markers: [],
            map: mapRef.current,
            renderer: {render: renderCluster},
            algorithm: new SuperClusterAlgorithm({
                radius: 200,
                maxZoom: 20,
                minPoints: 3,
            })
        });
        dispatch([
            {type: "setClusterer", value: clusterer},
            {type: "setLoaded", value: true}
        ]);
    }, []);

    const handleToggleFullscreen = useCallback(() => {
        handleCloseLocationPreview();
        dispatch({type: "toggleFullScreen"});
        propsRef.current.onToggleFullscreen?.(state.showFullscreen);
    }, []);

    const setZoomLevel = useCallback((zoomLevel?: number) => {
        if (!mapRef.current) return;
        if (zoomLevel === undefined) zoomLevel = mapRef.current?.getZoom() ?? 18;
        if (state.prevZoom === zoomLevel) return;
        dispatch({type: "setPrevZoom", value: zoomLevel}, false);
        handleCloseLocationPreview();
        dispatch({type: "setDisableZoomOutButton", value: zoomLevel <= mapMinZoom});
        mapRef.current.setZoom(zoomLevel);
    }, []);

    const handleZoomIn = useCallback(() => {
        if (!mapRef.current) return;
        const zoomLevel = (mapRef.current.getZoom() ?? 10) + 1;
        setZoomLevel(zoomLevel);
    }, []);

    const handleZoomOut = useCallback(() => {
        if (!mapRef.current) return;
        const zoomLevel = (mapRef.current.getZoom() ?? 10) - 1;
        setZoomLevel(zoomLevel);
    }, []);

    const centerMapToUser = useCallback((zoomLevel?: number) => {
        const userGeolocation = getUserGeolocation();
        if (!mapRef.current || !userGeolocation) return;
        const bounds = new google.maps.LatLngBounds({...userGeolocation});
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(zoomLevel ?? 14);
    }, []);

    const centerMapToGeoPosition = useCallback((geoPosition: IGeoPosition, zoomLevel?: number) => {
        const userGeolocation = getUserGeolocation();
        if (!mapRef.current || !userGeolocation) return;
        const bounds = new google.maps.LatLngBounds({...geoPosition});
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(zoomLevel ?? 18);
    }, []);

    const centerMapToLocations = useCallback(() => {
        const userGeolocation = getUserGeolocation();
        if (!mapRef.current || !userGeolocation) return;
        const bounds = new google.maps.LatLngBounds({...userGeolocation});
        propsRef.current.filteredLocations.forEach(l => {
            if (l.address.geoPosition) bounds.extend({
                lat: l.address.geoPosition.lat,
                lng: l.address.geoPosition.lng
            });
        });
        mapRef.current.fitBounds(bounds);
    }, []);

    const googleMapOptions = useMemo(() => {
        const options: GoogleMapReact.MapOptions = {
            fullscreenControl: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            backgroundColor: isLightTheme ? "#fff" : "#2d2c2c",
            clickableIcons: false,
            minZoom: mapMinZoom,
        };
        const styles: Array<GoogleMapReact.MapTypeStyle> = [
            {featureType: "poi", stylers: [{visibility: "off"}]},
            {featureType: "transit", elementType: "labels.icon", stylers: [{visibility: "off"}]},
            {"featureType": "road.highway", elementType: "labels", stylers: [{visibility: "off"}]},
            {"featureType": "road.arterial", elementType: "labels", stylers: [{visibility: "off"}]},
        ];
        if (!isLightTheme) styles.push(...MapDarkTheme);
        options.styles = styles;
        return options;
    }, [isLightTheme]);

    const renderCluster = useCallback((cluster: Cluster): google.maps.Marker => {
        let marker: google.maps.Marker | undefined = cluster.marker;
        const markerId = cluster.position.toString();
        if (!marker) marker = state.markers.get(markerId);
        if (!!marker) {
            const isCurrentlyVisible = marker.getVisible();
            if (isCurrentlyVisible && cluster.count === 0) marker.setVisible(false);
            else if (!isCurrentlyVisible && cluster.count > 0) marker.setVisible(true);
            marker.setLabel({
                text: String(cluster.count),
                color: "white",
                fontSize: "16px"
            });
            return marker;
        }
        marker = new google.maps.Marker({
            label: {
                text: String(cluster.count),
                color: "white",
                fontSize: "16px"
            },
            icon: {
                path: 'M 0, 0 m -20, 0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0',
                fillColor: "#244489",
                strokeColor: isLightTheme ? "white" : "#151515",
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 1,
                scale: 1.5 + (cluster.count / 100),
            },
            position: cluster.position,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
            optimized: true,
            visible: cluster.count > 0,
        });
        dispatch({type: "bindMarker", id: markerId, marker}, false);
        return marker;
    }, []);

    const handleUserPinClick = useCallback(() => {
        centerMapToUser(18);
    }, []);

    const handleLeaveMarker = useCallback((e: any & { domEvent: { toElement?: Element } }) => {
        let className: string = e.domEvent?.toElement?.className + "";
        className += e.domEvent?.toElement?.parentElement?.className + "";
        const isLocationPreview = className?.includes("location-pin-container");
        const isLocationPreviewTarget = className?.includes("map-location-preview-target");
        if (isLocationPreview || isLocationPreviewTarget) return;
        handleCloseLocationPreview();
    }, []);

    const getMarkerRelativePositionInPixelsOnMap = useCallback((position: IGeoPosition): [number, number] => {
        if (!mapRef.current) return [0, 0];
        const projection = mapRef.current!.getProjection();
        const bounds = mapRef.current!.getBounds();
        if (!projection || !bounds) return [0, 0];
        const topRight = projection!.fromLatLngToPoint(bounds!.getNorthEast());
        const bottomLeft = projection!.fromLatLngToPoint(bounds!.getSouthWest());
        const scale = Math.pow(2, mapRef.current!.getZoom()!);
        const worldPoint = projection!.fromLatLngToPoint(position);
        return [Math.floor((worldPoint!.x - bottomLeft!.x) * scale), Math.floor((worldPoint!.y - topRight!.y) * scale)];
    }, []);

    const showMarkerPreview = useCallback((locationId: string, geoPosition: IGeoPosition) => {
        if (locationId === state.prevLocationPreviewId) return;
        dispatch([
            {type: "setPrevLocationPreviewId", value: locationId},
            {type: "setMarkerPreviewPosition", value: getMarkerRelativePositionInPixelsOnMap(geoPosition)},
            {type: "setVisibleLocationPreviewId", value: locationId}
        ]);
    }, []);

    const handleShowMarkerPreview = useCallback((locationId: string) => (e: {
        latLng: google.maps.LatLng,
        domEvent: { target: HTMLDivElement }
    }) => {
        const geoPosition: IGeoPosition = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        showMarkerPreview(locationId, geoPosition);
    }, []);

    const handleShowLocationDetails = useCallback((locationId: string) => () => {
        dispatch({type: "setVisibleLocationPreviewId", value: ""});
        propsRef.current.showLocationDetails(locationId);
    }, []);

    const generateLocationMarkerIcon = useCallback((location: Immutable<ILocation>): google.maps.Icon => {
        const isRecommended = location.isRecommended;
        const isShow = location.isShow;
        const markerScale = 1.1;
        const markerColor = isRecommended ? "%23FFBB36" : "%23244489";
        const markerOpacity = isShow ? 1 : 0.5;
        const iconColor = "white";
        const strokeColor = propsRef.current.teamsContext.isLightTheme ? "white" : "%23151515";
        const strokeOpacity = 0.5;
        let icon: string;
        switch (location.type) {
            case LocationType.NightClub:
                icon = `
                <g fill="${iconColor}" transform="translate(6.5 6) scale(2.3 2.3)">
                    <path d="M4.79099 2.31094V4.41898C4.66839 4.34806 4.52605 4.30747 4.37422 4.30747C3.91387 4.30747 3.54068 4.68066 3.54068 5.14101C3.54068 5.60136 3.91387 5.97455 4.37422 5.97455C4.83457 5.97455 5.20776 5.60136 5.20776 5.14101V0.942341C5.20776 0.711773 4.97934 0.5508 4.76221 0.628349L2.09488 1.58097C1.96218 1.62836 1.8736 1.75405 1.8736 1.89496V4.83575C1.751 4.76483 1.60866 4.72424 1.45683 4.72424C0.99648 4.72424 0.623291 5.09743 0.623291 5.55778C0.623291 6.01813 0.99648 6.39132 1.45683 6.39132C1.91718 6.39132 2.29037 6.01813 2.29037 5.55778V3.20402L4.79099 2.31094ZM4.79099 1.86839L2.29037 2.76147V1.9537L4.79099 1.06062V1.86839ZM4.37422 4.72424C4.6044 4.72424 4.79099 4.91083 4.79099 5.14101C4.79099 5.37118 4.6044 5.55778 4.37422 5.55778C4.14405 5.55778 3.95745 5.37118 3.95745 5.14101C3.95745 4.91083 4.14405 4.72424 4.37422 4.72424ZM1.45683 5.14101C1.68701 5.14101 1.8736 5.3276 1.8736 5.55778C1.8736 5.78795 1.68701 5.97455 1.45683 5.97455C1.22666 5.97455 1.04006 5.78795 1.04006 5.55778C1.04006 5.3276 1.22666 5.14101 1.45683 5.14101Z"/>
                </g>
            `;
                break;
            case LocationType.HighEnergyBar:
                icon = `
                <g fill="${iconColor}" transform="translate(7.5 5.8) scale(2.4 2.4)">
                    <path d="M5.01377 0.974046C5.12811 0.953869 5.20444 0.844824 5.18427 0.730488C5.16409 0.616151 5.05504 0.53982 4.94071 0.559997L3.15381 0.875332C3.07012 0.8901 3.00356 0.953798 2.98512 1.03675L2.90491 1.39771H0.772724C0.65662 1.39771 0.5625 1.49183 0.5625 1.60793V2.86927C0.5625 3.33369 0.938981 3.71017 1.40339 3.71017C1.40339 4.33541 1.85832 4.8544 2.45522 4.95419C2.45475 4.9599 2.45451 4.96568 2.45451 4.97151V6.02263H1.61362C1.49751 6.02263 1.40339 6.11675 1.40339 6.23285C1.40339 6.34896 1.49751 6.44308 1.61362 6.44308H3.71585C3.83196 6.44308 3.92608 6.34896 3.92608 6.23285C3.92608 6.11675 3.83196 6.02263 3.71585 6.02263H2.87496V4.97151C2.87496 4.96568 2.87472 4.9599 2.87426 4.95419C3.47116 4.8544 3.92608 4.33541 3.92608 3.71017C4.39049 3.71017 4.76697 3.33369 4.76697 2.86927V1.60793C4.76697 1.49183 4.67285 1.39771 4.55675 1.39771H3.33561L3.36511 1.26499L5.01377 0.974046ZM2.81148 1.81816L2.71804 2.2386H0.982947V1.81816H2.81148ZM0.982947 2.65905H2.62461L2.35445 3.87477C2.32927 3.98811 2.40073 4.10041 2.51407 4.12559C2.6274 4.15078 2.7397 4.07932 2.76489 3.96598L3.05532 2.65905H4.34653V2.86927C4.34653 3.10148 4.15828 3.28972 3.92608 3.28972H3.71585C3.59975 3.28972 3.50563 3.38384 3.50563 3.49994V3.71017C3.50563 4.17458 3.12915 4.55106 2.66474 4.55106C2.20032 4.55106 1.82384 4.17458 1.82384 3.71017V3.49994C1.82384 3.38384 1.72972 3.28972 1.61362 3.28972H1.40339C1.17119 3.28972 0.982947 3.10148 0.982947 2.86927V2.65905ZM4.34653 2.2386H3.14875L3.24218 1.81816H4.34653V2.2386Z"/>
                </g>
            `;
                break;
            case LocationType.LowEnergyBar:
                icon = `
                <g fill="${iconColor}" transform="translate(8 9.5) scale(0.65 0.65)">
                    <path d="M1.94118 0C0.869094 0 0 0.869095 0 1.94118V7C0 11.4183 3.58172 15 8 15C12.0114 15 15.3332 12.0476 15.911 8.19727H16.6487C18.4981 8.19727 19.9973 6.69803 19.9973 4.84863C19.9973 2.99923 18.4981 1.5 16.6487 1.5H15.9496C15.7499 0.640464 14.9791 0 14.0588 0H1.94118ZM1.5 1.94118C1.5 1.69752 1.69752 1.5 1.94118 1.5H14.0588C14.3025 1.5 14.5 1.69752 14.5 1.94118V7C14.5 10.5899 11.5899 13.5 8 13.5C4.41015 13.5 1.5 10.5899 1.5 7V1.94118ZM16 3H16.6487C17.6697 3 18.4973 3.82766 18.4973 4.84863C18.4973 5.8696 17.6697 6.69727 16.6487 6.69727H16V3Z"/>
                </g>
            `;
                break;
            case LocationType.Restaurant:
                icon = `
                <g fill="${iconColor}" transform="translate(7.8 6) scale(2.4 2.4)">
                    <path d="M0.781901 0.712036C0.891882 0.712036 0.981038 0.801193 0.981038 0.911173V2.106C0.981038 2.36611 1.14728 2.5874 1.37931 2.66942V0.911173C1.37931 0.801193 1.46847 0.712036 1.57845 0.712036C1.68843 0.712036 1.77759 0.801193 1.77759 0.911173V2.66942C2.00962 2.5874 2.17586 2.36611 2.17586 2.106V0.911173C2.17586 0.801193 2.26502 0.712036 2.375 0.712036C2.48498 0.712036 2.57414 0.801193 2.57414 0.911173V2.106C2.57414 2.5877 2.23207 2.98951 1.77759 3.08177V6.08874C1.77759 6.19873 1.68843 6.28788 1.57845 6.28788C1.46847 6.28788 1.37931 6.19873 1.37931 6.08874V3.08177C0.924834 2.98951 0.582764 2.5877 0.582764 2.106V0.911173C0.582764 0.801193 0.671921 0.712036 0.781901 0.712036ZM3.56128 1.30091C3.62463 1.23756 3.69605 1.18842 3.76896 1.15592V3.10168H3.37069V1.70772C3.37069 1.57335 3.43988 1.42231 3.56128 1.30091ZM3.76896 3.49996V6.08874C3.76896 6.19873 3.85812 6.28788 3.9681 6.28788C4.07808 6.28788 4.16724 6.19873 4.16724 6.08874V0.911173C4.16724 0.801193 4.07808 0.712036 3.9681 0.712036C3.7042 0.712036 3.45696 0.841983 3.27966 1.01928C3.10236 1.19658 2.97241 1.44382 2.97241 1.70772V3.30082C2.97241 3.4108 3.06157 3.49996 3.17155 3.49996H3.76896Z"/>
                </g>
            `;
                break;
            case LocationType.WineMerchant:
                icon = `
                <g fill="${iconColor}" transform="translate(10 7.5) scale(1 1)">
                    <path d="M6.73329 5.30322C6.76374 5.02876 6.56593 4.78159 6.29147 4.75114C6.01701 4.72069 5.76984 4.9185 5.73939 5.19296C5.69864 5.56027 5.54266 5.90526 5.2938 6.17847C5.04493 6.45168 4.71597 6.63909 4.35404 6.71384C4.08361 6.7697 3.90966 7.03421 3.96551 7.30464C4.02137 7.57508 4.28588 7.74903 4.55631 7.69317C5.12505 7.5757 5.642 7.2812 6.03308 6.85187C6.42415 6.42254 6.66926 5.88042 6.73329 5.30322ZM0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 7.54013 1.52733 9.22354 3.50094 9.46917C3.50032 9.47937 3.5 9.48965 3.5 9.5V13H2C1.72386 13 1.5 13.2239 1.5 13.5C1.5 13.7761 1.72386 14 2 14H6C6.27614 14 6.5 13.7761 6.5 13.5C6.5 13.2239 6.27614 13 6 13H4.5V9.5C4.5 9.48965 4.49968 9.47937 4.49906 9.46917C6.47267 9.22354 8 7.54013 8 5.5V0.5C8 0.223858 7.77614 0 7.5 0H0.5ZM1 3V1H7V3H1ZM1 4H7V5.5C7 7.15685 5.65685 8.5 4 8.5C2.34315 8.5 1 7.15685 1 5.5V4Z"/>
                </g>
            `;
                break;
        }
        const locationPinHeight = 54 * markerScale;
        const locationPinWidth = 45 * markerScale;
        const svgUrl = `
            data:image/svg+xml;utf-8,
            <svg xmlns="http://www.w3.org/2000/svg" opacity="${markerOpacity}" viewBox="-1.05 -1.05 30 36" height="30px" width="25px">
                <g fill="${markerColor}" stroke="${strokeColor}" stroke-width="1.5" stroke-opacity="${strokeOpacity}">
                    <path d="M14 0a14 14 0 0 1 13.9 14c0 5.8-4.2 12.2-12.3 19.2-1 .8-2.4.8-3.3 0l-.5-.4C4 25.9 0 19.7 0 13.9A14 14 0 0 1 14 0Z"/>
                </g>
                ${icon}
            </svg>
        `;
        return {url: svgUrl, scaledSize: new google.maps.Size(locationPinWidth, locationPinHeight)};
    }, []);

    const isFetchingLocations = !propsRef.current.locationContext.loaded;

    return {
        ...state,
        handleMapLoaded,
        handleToggleFullscreen,
        googleMapOptions,
        handleZoomIn,
        handleZoomOut,
        centerMapToUser,
        handleCloseLocationPreview,
        handleShowLocationDetails,
        user: propsRef.current.userContext.currentUser,
        googleMapsApiKey,
        markerPreviewPositionRef: state.markerPreviewPosition,
        isOnMobile,
        show: propsRef.current.show,
        isFetchingLocations,
        handleUserPinClick,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////