import {ICarouselProps} from "./Carousel.interfaces";
import React, {useMemo} from "react";
import {
    ArrowRightIcon,
    Button,
    ChevronDownIcon,
    ChevronEndIcon,
    ChevronStartIcon,
    Flex,
    FlexItem,
    Text
} from "@fluentui/react-northstar";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {useCarousel} from "./Carousel.hook";
import "./Carousel.styles.scss";
import {useTranslation} from "services/TranslationService/TranslationService.hook";

export const Carousel = (props: ICarouselProps) => {
    const translate = useTranslation();
    const logic = useCarousel(props);

    const moveButtons = useMemo(() => logic.hideHeaderButtons || logic.isOnMobile ? null : (
        <FlexItem push={logic.pushMoveButtons}>
            <Flex gap={"gap.smaller"} vAlign={"end"} styles={{marginBottom: "-3px"}}>
                <Button
                    text iconOnly onClick={logic.handleClickPrevious}
                    icon={<ChevronStartIcon/>}
                    disabled={logic.currentIndex === 0}
                />
                <Button
                    text iconOnly onClick={logic.handleClickNext}
                    icon={<ChevronEndIcon/>}
                    disabled={logic.isLastIndex}
                />
            </Flex>
        </FlexItem>
    ), [logic.pushMoveButtons, logic.currentIndex, logic.isLastIndex, logic.hideHeaderButtons]);

    const header = useMemo(() => props.noHeader ? null : (
        <Flex gap={"gap.small"} vAlign={"center"} className={"carousel-header w-100 x-padding"}>
            <Flex gap={"gap.smaller"} vAlign={"center"} onClick={logic.handleToggleCollapse}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    {props.icon}
                    {!props.labelId ? null : <Text size={"large"} content={translate(props.labelId)}/>}
                </Flex>
                {!logic.canBeCollapsed ? null :
                    <Button
                        text
                        icon={
                            <ChevronDownIcon outline styles={{
                                marginTop: "3px",
                                transform: `rotate(${logic.isCollapsed ? "180deg" : "0deg"})`
                            }}/>
                        }
                        iconOnly
                    />
                }
            </Flex>
            {(logic.canBeCollapsed && logic.isCollapsed) ? null :
                <>
                    {logic.hideHeaderButtons ? null : moveButtons}
                    {logic.hideSeeAllButton ? null :
                        <FlexItem push={!logic.pushMoveButtons}>
                            <Button text iconPosition={"after"} content={translate("SeeAll")}
                                    icon={<ArrowRightIcon outline/>} onClick={logic.handleSeeAll}/>
                        </FlexItem>
                    }
                </>
            }
        </Flex>
    ), [logic.hideSeeAllButton, moveButtons, logic.handleSeeAll, props.icon, logic.canBeCollapsed, logic.isCollapsed, logic.handleToggleCollapse]);

    const items = useMemo(() => {
        if (logic.items.length === 0) return null;
        if (logic.items.find(i => !i.key)) throw new Error("Carousel items must have a key");
        const isAutoSlides = logic.slidesPerView === "auto";
        const className = !isAutoSlides ? "carousel-item" : "";
        const renders = logic.items.map(i => (
            <SwiperSlide
                key={"item-" + i.key}
                className={className}
                style={{display: "flex", width: logic.itemWidth}}
                onClick={logic.handleClickItem(i.key + "")}>
                <Flex fill className={(logic.autoSlides && logic.isOnMobile) ? "x-padding" : ""}>
                    {i}
                </Flex>
            </SwiperSlide>
        ));
        if (!isAutoSlides) {
            while (renders.length < logic.slidesPerView)
                renders.push(<SwiperSlide key={"empty-item-" + renders.length}/>)
        }
        return renders;
    }, [logic.items]);

    const navigationButtons = useMemo(() => !logic.showNavigationButtons ? null : (
        <Flex vAlign={"center"} space={"between"} className={"carousel-navigation-buttons"}>
            <ChevronStartIcon outline className={"carousel-navigation-button"} id={"carousel-navigation-button-start"}
                              size={"largest"}/>
            <ChevronEndIcon outline className={"carousel-navigation-button"} id={"carousel-navigation-button-end"}
                            size={"largest"}/>
        </Flex>
    ), []);

    return useMemo(() => (
        <Flex column gap={"gap.small"} className={"w-100 no-select"} vAlign={"center"}>
            {header}
            {!items || logic.isCollapsed ? null :
                <Flex fill className={"pos-relative"}>
                    {navigationButtons}
                    <Swiper
                        cssMode={logic.isOnMobile}
                        onSwiper={logic.handleSetSwiperRef}
                        slidesPerView={logic.slidesPerView}
                        slidesPerGroup={logic.slidesPerGroup}
                        spaceBetween={logic.spaceBetween}
                        noSwiping={!logic.isOnMobile}
                        noSwipingClass={logic.isOnMobile ? "" : "carousel"}
                        pagination={{
                            enabled: logic.enablePagination,
                            bulletActiveClass: "carousel-pagination-bullet-active",
                        }}
                        navigation={{
                            enabled: logic.showNavigationButtons,
                            prevEl: "#carousel-navigation-button-start",
                            nextEl: "#carousel-navigation-button-end"
                        }}
                        modules={[Pagination, Navigation]}
                        {...((!logic.isOnMobile && logic.autoSlides) && {
                            slidesOffsetBefore: 20,
                            slidesOffsetAfter: 20
                        })}
                        className={"carousel " + (props.noPadding || logic.autoSlides ? "" : "x-padding")}>
                        {items}
                    </Swiper>
                </Flex>
            }
        </Flex>
    ), [header, items, logic.handleSetSwiperRef, logic.slidesPerView, logic.spaceBetween, logic.isCollapsed])
}