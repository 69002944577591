import React from "react";
import {KeywordsKpiLogic} from "./KeywordsKpi.logic";
import {KeywordsKpiRendering} from "./KeywordsKpi.rendering";
import {Props} from "./KeywordsKpi.interfaces";

const KeywordsKpi = (props: Props): JSX.Element => {
    return (
        <KeywordsKpiLogic {...props}>
            {logic => <KeywordsKpiRendering {...logic}/>}
        </KeywordsKpiLogic>
    )
}

export default KeywordsKpi;