import * as React from "react";
import {memo, useCallback, useRef} from "react";
import {Logic, LogicProps} from "./SuggestionsDropdown.interfaces";
import {useReducer} from "./SuggestionsDropdown.reducer";
import {DropdownProps} from "@fluentui/react-northstar";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return (
        prevProps.placeholder === nextProps.placeholder &&
        prevProps.inverted === nextProps.inverted &&
        prevProps.fetchSuggestions === nextProps.fetchSuggestions &&
        prevProps.onSearchChange === nextProps.onSearchChange &&
        prevProps.onSelectSuggestion === nextProps.onSelectSuggestion &&
        prevProps.disabled === nextProps.disabled
    )
}

export const SuggestionsDropdownLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {
    const {state, dispatch} = useReducer(props);
    const searchInputRef = useRef<HTMLInputElement>();
    const cancelOnSearchChangeRef = useRef<boolean>(false);

    const handleSearchInputBlur = useCallback(() => {
        // Fix issue on IOS after blur scroll is stuck
        document.documentElement.scrollTop = 0;
    }, []);

    const handleChangeSearchValue = useCallback((e: React.SyntheticEvent | null, data: DropdownProps) => {
        if (data.highlightedIndex !== undefined) return;
        const searchFilter = data?.searchQuery + "";
        dispatch({type: "setSearchFilter", value: searchFilter});
        if (!!state.timeout) clearTimeout(state.timeout);
        const timeout = !searchFilter ? 0 : 600;
        dispatch({
            type: "setSearchTimeout", value: setTimeout(async () => {
                if (props.onSearchChange) props.onSearchChange(searchFilter);
                if (!props.fetchSuggestions) return;
                dispatch({type: "setIsSearching", value: true});
                const suggestions = await props.fetchSuggestions(searchFilter);
                dispatch([
                    {type: "setSuggestions", value: suggestions},
                    {type: "setIsSearching", value: false}
                ]);
            }, timeout)
        }, false);
    }, [props.onSearchChange, props.fetchSuggestions]);

    const handleSelectSuggestion = useCallback((e: React.SyntheticEvent | null, data: DropdownProps) => {
        const selectedSuggestion: ILocationSuggestion | undefined = (data.value as {
            value?: ILocationSuggestion
        })?.value;
        if (!selectedSuggestion) return;
        cancelOnSearchChangeRef.current = true;
        dispatch({type: "setSearchFilter", value: selectedSuggestion.name});
        if (!!props.onSelectSuggestion) props.onSelectSuggestion(selectedSuggestion);
        if (!!searchInputRef.current) searchInputRef.current?.blur();
    }, [props.onSelectSuggestion]);

    const handleSetSearchInputRef = useCallback((ref: HTMLInputElement) => {
        searchInputRef.current = ref;
    }, []);

    const isSearching = state.filter.length > 0 && state.isSearching;

    return {
        ...state,
        handleSearchInputBlur,
        handleChangeSearchValue,
        isSearching,
        handleSetSearchInputRef,
        handleSelectSuggestion,
        placeholder: props.placeholder,
        clearable: props.clearable ?? false,
        inverted: props.inverted ?? false,
        rightIcon: props.rightIcon,
        disabled: props.disabled ?? false,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////