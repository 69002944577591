import {Logic} from "./Map.interfaces";
import "./Map.styles.scss";
import React, {useMemo, useRef} from "react";
import {UserPin} from "../UserPin/UserPin";
import GoogleMapReact from "google-map-react";
import {Button, CollapseIcon, ExpandIcon, Flex, Loader, ZoomInIcon, ZoomOutIcon} from "@fluentui/react-northstar";
import {LocationMarkerPreview} from "../LocationMarkerPreview/LocationMarkerPreview";

const franceGeoPosition: google.maps.LatLngLiteral = {lat: 47.076872983431244, lng: 2.852504727506009};

export const MapRendering = (logic: Logic) => {

    const markerPreviewTargetRef = useRef<HTMLDivElement | null>(null);

    const userPin = useMemo(() => (!logic.loaded || !logic.user?.geoLocation) ? null : (
        <UserPin
            onClick={logic.handleUserPinClick}
            user={logic.user}
            {...logic.user.geoLocation}
        />
    ), [logic.loaded, logic.user?.geoLocation]);

    const map = useMemo(() => (
        <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map}) => logic.handleMapLoaded(map)}
            bootstrapURLKeys={{key: logic.googleMapsApiKey}}
            defaultCenter={franceGeoPosition}
            defaultZoom={1}
            shouldUnregisterMapOnUnmount
            options={logic.googleMapOptions}>
            {userPin}
        </GoogleMapReact>
    ), [userPin]);

    const loader = useMemo(() => !!logic.user?.geoLocation && !logic.isFetchingLocations ? null : (
        <Flex className={"map-loader"}>
            <Loader secondary/>
        </Flex>
    ), [logic.isFetchingLocations]);

    const mapControls = useMemo(() => {
        const fullscreenButtonIcon = logic.showFullscreen ? <CollapseIcon outline/> : <ExpandIcon outline/>;
        return (
            <>
                <Button className={"map-fullscreen-btn"} onClick={logic.handleToggleFullscreen}
                        icon={fullscreenButtonIcon}/>
                <Flex column className={"map-zoom-btns"} gap={"gap.small"}>
                    <Button onClick={logic.handleZoomIn} icon={<ZoomInIcon outline/>}/>
                    <Button onClick={logic.handleZoomOut} icon={<ZoomOutIcon outline/>}
                            disabled={logic.disableZoomOutButton}/>
                </Flex>
            </>
        )
    }, [logic.showFullscreen, logic.disableZoomOutButton])

    const locationMarkerTarget = useMemo(() => {
        const targetLeftPos = logic.markerPreviewPositionRef[0] + "px";
        const targetTopPos = ((logic.markerPreviewPositionRef[1] ?? 0) - 56) + "px";
        return (
            <div
                ref={ref => markerPreviewTargetRef.current = ref}
                style={{top: targetTopPos, left: targetLeftPos}}
                className={"map-location-preview-target"}
            />
        )
    }, [logic.markerPreviewPositionRef])

    const locationMarkerPreview = useMemo(() => logic.hideLocationPreview || !logic.show ? null : (
        <LocationMarkerPreview
            locationId={logic.visibleLocationPreviewId}
            showDetails={logic.handleShowLocationDetails(logic.visibleLocationPreviewId)}
            onLeave={logic.handleCloseLocationPreview}
            target={markerPreviewTargetRef.current ?? undefined}
        />
    ), [logic.show, markerPreviewTargetRef.current, logic.visibleLocationPreviewId, logic.hideLocationPreview]);

    const mapContainerClassName = [
        "pos-relative",
        !logic.isOnMobile && "map-min-height",
        logic.showFullscreen && "map-fullscreen",
    ].filter(c => c).join(" ");

    return (
        <Flex fill column className={"no-select" + (!logic.show ? " map-hidden" : "")}>
            <Flex fill column id={"map-container"} className={mapContainerClassName}>
                {map}
                {mapControls}
                {loader}
                {locationMarkerPreview}
                {locationMarkerTarget}
            </Flex>
        </Flex>
    )
}