import {LocationTableAction, Logic} from "./LocationTable.interfaces";
import "./LocationTable.styles.scss";
import React, {useCallback, useMemo} from "react";
import {
    AcceptIcon,
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowUpIcon,
    Button,
    CircleIcon,
    Flex,
    MenuButton,
    MenuItemProps,
    MoreIcon,
    Skeleton,
    StarIcon as FUIStarIcon,
    SubtractIcon,
    Table,
    Text
} from "@fluentui/react-northstar";
import {ILocation, LocationType} from "../../interfaces";
import {Brands, LocationTypes} from "../../const";
import {Brand} from "../../interfaces/Brand/Brand";
import {LocationModule} from "../../modules";
import {ShorthandCollection} from "@fluentui/react-northstar/dist/es/types";
import {CallIcon, EndArrowIcon, EyeIcon, ItineraryIcon, StarIcon} from "../../assets/icons";
import languageObject from "../../translations";
import {Immutable} from "../../interfaces/Immutable";

export const LocationTableRendering = (logic: Logic) => {

    const tableHeader = useMemo(() => {
        const getFilterIndicator = (column: string) => {
            if (logic.columnSort !== column) return null;
            return logic.sortAscending ? <ArrowDownIcon/> : <ArrowUpIcon/>
        }
        return logic.tableColumns.map(c => ({
            content: (
                <Flex className={"no-select " + (c.sortable === false ? "" : "cursor-pointer")} vAlign={"center"}>
                    <Flex vAlign={"center"} gap={"gap.small"}>
                        {c.icon}
                        <Text size={"medium"} weight={"semibold"} truncated
                              content={languageObject.translate(c.label)}/>
                        {getFilterIndicator(c.field)}
                    </Flex>
                </Flex>
            ),
            truncateContent: true,
            styles: {
                minWidth: c.minWidth ?? "unset",
                maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
            },
            key: "header-" + c.field,
            onClick: logic.handleSortColumn(c.field),
            className: "location-table-header-" + c.field,
        }));
    }, [logic.columnSort, logic.sortAscending, logic.tableColumns]);

    const renderLocationField = useCallback((location: Immutable<ILocation>, field: string, visibleBrandsCount: number, userFavoritesBuildings: Immutable<Array<string>>) => {
        switch (field) {
            case "type":
                const TypeIcon = LocationTypes[location.type].element;
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}>
                        <TypeIcon/>
                        <Text size={"medium"} content={languageObject.translate(LocationType[location.type] ?? "")}
                              truncated/>
                    </Flex>
                )
            case "name":
                const showStarIcon = userFavoritesBuildings.includes(location.id);
                const handleShowPreview = () => logic.handleShowLocationDetails(location.id);
                return (
                    <Flex fill className={"no-select cursor-pointer"} vAlign={"center"} gap={"gap.smaller"}
                          onClick={handleShowPreview}>
                        {showStarIcon ? <FUIStarIcon styles={{color: "goldenrod"}} size={"small"}/> : null}
                        <Text size={"medium"} content={location.name} truncated/>
                    </Flex>
                )
            case "recommended":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}>
                        {!location.isRecommended ? null : <AcceptIcon outline/>}
                    </Flex>
                )
            case "brands":
                if (visibleBrandsCount === 0) return null;
                const brands = [...location.brands];
                if (location.flagship !== undefined && !brands.includes(location.flagship)) brands.push(location.flagship);
                const hiddenBrandsCount = brands.length - (visibleBrandsCount - 1);
                return (
                    <Flex fill className={"pos-relative no-select"}>
                        <Flex fill className={"fill-absolute"} vAlign={"center"} gap={"gap.small"}>
                            <Flex fill vAlign={"center"} gap={"gap.small"}>
                                {brands.slice(0, visibleBrandsCount - 1).map(b => {
                                    const brandData = Brands[b];
                                    const BrandIcon = brandData.element;
                                    return (
                                        <Flex key={location.id + "-brand-" + Brand[b]} title={brandData.formattedName}>
                                            <BrandIcon width={30} height={30}/>
                                        </Flex>
                                    )
                                })}
                            </Flex>
                            {hiddenBrandsCount <= 0 ? null :
                                <Flex fill vAlign={"center"}>
                                    <Text size={"medium"} styles={{color: "darkgray"}}
                                          content={"+ " + hiddenBrandsCount}/>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                )
            case "flagship":
                if (location.flagship === undefined) return null;
                const flagshipBrand = Brands[location.flagship];
                return (
                    <Flex fill vAlign={"center"} className={"no-select"}>
                        <Flex title={flagshipBrand.formattedName}>
                            <flagshipBrand.element width={30} height={30}/>
                        </Flex>
                    </Flex>
                )
            case "distance":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}>
                        <Text size={"medium"} content={LocationModule.getDistanceLabel(location.distance)}/>
                    </Flex>
                )
            case "state":
                return (
                    <Flex gap={"gap.small"} vAlign={"center"}>
                        <CircleIcon size={"smaller"}
                                    styles={{color: location.extendedProps?.openStateData?.isOpen ? "#68C92C" : "red"}}/>
                        <Text size={"medium"} content={location.extendedProps?.openStateData?.stateLabel}/>
                    </Flex>
                )
            case "visible":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}>
                        {!LocationModule.isLocationVisible(location) ? null : <AcceptIcon outline/>}
                    </Flex>
                )
            case "complete":
                return (
                    <Flex fill className={"no-select"} vAlign={"center"} gap={"gap.small"}>
                        {!LocationModule.isLocationComplete(location) ? null : <AcceptIcon outline/>}
                    </Flex>
                )
            case "actions":
                const isInFavoriteOfUser = logic.userFavoriteLocationIds?.includes(location.id) ?? false;
                return renderLocationActions(location, isInFavoriteOfUser, logic.handleLocationActionClick, logic.isOnMobile);
            default:
                return null;
        }
    }, [logic.handleShowLocationDetails, logic.userFavoriteLocationIds]);

    const skeletons = useMemo(() => {
        return Array.from(Array(logic.visibleItemsCount === 0 ? 20 : logic.visibleItemsCount).keys()).map(i => {
            const items: Array<any> = logic.tableColumns.map((c, i) => ({
                key: "skeleton-location-" + i + "-" + c.field,
                styles: {
                    minWidth: c.minWidth ?? "unset",
                    maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
                },
                content: renderLocationFieldSkeleton(i, c.field),
                truncateContent: true,
            }))
            return {
                key: "skeleton-location-" + i,
                items
            };
        })
    }, [logic.visibleItemsCount]);

    const formatLocationsForTable = useCallback((locations: Immutable<Array<ILocation>>, visibleBrandsCount: number, visibleRange: ReadonlyArray<number>, userFavoritesBuildings: Immutable<Array<string>>): Array<object> => {
        return locations.map((location) => {
            const items: Array<object> = logic.tableColumns.map(c => ({
                key: "location-" + c.field + "-" + location.id,
                styles: {
                    minWidth: c.minWidth ?? "unset",
                    maxWidth: !!c.maxWidth ? (c.maxWidth + "%") : "unset",
                },
                content: renderLocationField(location, c.field, visibleBrandsCount, userFavoritesBuildings),
                truncateContent: true,
            }))
            return {
                key: "location-" + location.id,
                items
            };
        });
    }, [renderLocationField]);

    const formattedLocations = logic.showSkeletons
        ? skeletons
        : formatLocationsForTable(logic.locations, logic.visibleBrandsCount, logic.visibleRange, logic.userFavoriteLocationIds);

    const noLocationsMessage = formattedLocations.length > 0 ? null : renderNoLocationMessage();

    const pagination = useMemo(() => (
        renderPagination(logic.currentPage, logic.lastPage, logic.handleSelectPage)
    ), [logic.locations.length, logic.currentPage, logic.lastPage]);

    const mainStyles = window.innerHeight <= 800 ? {
        minHeight: "calc(100vh - 60px)"
    } : {};

    return (
        <Flex fill column gap={"gap.medium"} styles={mainStyles}
              className={"no-select " + (!logic.show ? " hidden" : "")}>
            <Flex column fill gap={"gap.medium"} className={"pos-relative"}>
                <Table
                    ref={logic.tableRef}
                    className={"fill-absolute locations-table"}
                    header={{items: tableHeader}}
                    rows={formattedLocations}
                />
                {noLocationsMessage}
            </Flex>
            {pagination}
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderPagination = (currentPage: number, lastPage: number, handleSelectPage: Logic["handleSelectPage"]) => {
    if (lastPage === 0) return <div style={{minHeight: "52px"}}/>;
    const goFirstPageBtn = (
        <Button
            disabled={currentPage <= 0}
            text icon={<EndArrowIcon className={"icon-btn"}/>} iconOnly
            onClick={handleSelectPage(0)}
        />
    )
    const goLastPageBtn = (
        <Button
            disabled={currentPage >= lastPage}
            text icon={<EndArrowIcon style={{transform: "rotate(180deg)"}} className={"icon-btn"}/>} iconOnly
            onClick={handleSelectPage(lastPage)}
        />
    )
    const goBackBtn = (
        <Button
            disabled={currentPage <= 0}
            text icon={<ArrowLeftIcon/>} iconOnly
            onClick={handleSelectPage(currentPage - 1)}
        />
    );
    const goNextBtn = (
        <Button
            disabled={currentPage === lastPage}
            text icon={<ArrowRightIcon/>} iconOnly
            onClick={handleSelectPage(currentPage + 1)}
        />
    );
    const renderPageButton = (page: number) => (
        <Button
            primary={page === currentPage}
            icon={page + 1} iconOnly
            onClick={handleSelectPage(page)}
        />
    );
    return (
        <Flex gap={"gap.small"} hAlign={"center"} styles={{paddingBottom: "20px"}}>
            {goFirstPageBtn}
            {goBackBtn}
            {currentPage < 2 ? null : <Text content={"..."} size={"larger"}/>}
            {currentPage < lastPage || lastPage - 2 < 0 ? null : renderPageButton(currentPage - 2)}
            {currentPage - 1 < 0 ? null : renderPageButton(currentPage - 1)}
            {renderPageButton(currentPage)}
            {currentPage + 1 > lastPage || lastPage < currentPage + 1 ? null : renderPageButton(currentPage + 1)}
            {currentPage > 0 || lastPage < currentPage + 2 ? null : renderPageButton(currentPage + 2)}
            {currentPage > (lastPage - 2) ? null : <Text content={"..."} size={"larger"}/>}
            {goNextBtn}
            {goLastPageBtn}
        </Flex>
    )
}

const renderNoLocationMessage = () => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"}>
            <Text size={"large"} content={languageObject.translate("NoResult")}
                  styles={{color: "darkgray"}}
            />
        </Flex>
    )
}

const renderLocationActions = (
    location: Immutable<ILocation>,
    isInFavoriteOfUser: boolean,
    handleLocationActionClick: Logic["handleLocationActionClick"],
    isOnMobile: boolean
) => {
    const stopPropagation = (event: React.SyntheticEvent) => event.stopPropagation();
    const actions: ShorthandCollection<MenuItemProps> = [
        {
            key: "location-action-" + LocationTableAction.Show,
            content: languageObject.translate("Show"),
            icon: <EyeIcon/>,
            value: LocationTableAction.Show,
        },
        (location.phone && isOnMobile && {
            key: "location-action-" + LocationTableAction.Call,
            content: languageObject.translate("Call"),
            icon: <CallIcon/>,
            value: LocationTableAction.Call,
        }),
        (LocationModule.isLocationComplete(location) && {
            key: "location-action-" + LocationTableAction.Itinerary,
            content: languageObject.translate("Itinerary"),
            icon: <ItineraryIcon/>,
            value: LocationTableAction.Itinerary,
        }),
        {
            key: "location-action-" + LocationTableAction.ToggleFavorite,
            content: languageObject.translate(isInFavoriteOfUser ? "RemoveFromFavorites" : "AddToFavorite"),
            icon: (
                <Flex vAlign={"center"} hAlign={"center"} className={"pos-relative"}>
                    <StarIcon/>
                    {!isInFavoriteOfUser ? null : <SubtractIcon className={"no-icon"}/>}
                </Flex>
            ),
            value: LocationTableAction.ToggleFavorite,
        }
    ].filter(option => !!option);
    return (
        <Flex fill vAlign={"center"} hAlign={"end"}>
            <MenuButton
                trigger={<Button text onClick={stopPropagation} iconOnly icon={<MoreIcon size={"large"}/>}/>}
                onClick={stopPropagation}
                onMenuItemClick={handleLocationActionClick(location)}
                menu={actions}
            />
        </Flex>
    )
}

const renderLocationFieldSkeleton = (locationIndex: number, field: string) => {
    let skeletonContent: JSX.Element | null;
    switch (field) {
        case "type":
            skeletonContent = (
                <Flex className={"overflow-hidden"} vAlign={"center"} gap={"gap.small"}>
                    <Skeleton.Shape width="20px" height="20px"/>
                    <Skeleton.Line width="100%" height="20px"/>
                </Flex>
            )
            break;
        case "flagship":
        case "recommended":
        case "visible":
        case "complete":
            skeletonContent = (
                <Skeleton.Shape width="20px" height="20px"/>
            )
            break;
        case "state":
            skeletonContent = (
                <Flex className={"overflow-hidden"} vAlign={"center"} gap={"gap.small"}>
                    <Skeleton.Shape round width="10px" height="10px"/>
                    <Skeleton.Line width="100%" height="20px"/>
                </Flex>
            )
            break;
        case "actions":
            skeletonContent = (
                <Flex className={"w-100"} hAlign={"end"}>
                    <Button disabled text iconOnly icon={<MoreIcon size={"large"}/>}/>
                </Flex>
            )
            break;
        default:
            skeletonContent = (
                <Skeleton.Line width="100%" height="20px"/>
            )
    }
    return (
        <Skeleton className={"overflow-hidden"} key={"skeleton-location-" + locationIndex + "-" + field}
                  animation="pulse">
            {skeletonContent}
        </Skeleton>
    )
}