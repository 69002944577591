export interface IEventFormProps {
    eventId?: string,
    locationId: string,
    onSubmitDone: () => void,
}

export interface IEventFormState {
    areDatesValid: boolean,
    isSendingEvent: boolean,
    showNotificationDialog: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum EventFormDispatchMessageType {
    SetDatesValidity, SetNotificationDialogVisibility, SetIsSendingEvent
}

type SetDatesValidity = {
    type: EventFormDispatchMessageType.SetDatesValidity,
    value: boolean,
}

type SetNotificationDialogVisibility = {
    type: EventFormDispatchMessageType.SetNotificationDialogVisibility,
    show: boolean,
};

type SetIsSendingEvent = {
    type: EventFormDispatchMessageType.SetIsSendingEvent,
    value: boolean,
}

export type EventFormDispatchMessage =
    SetDatesValidity
    | SetNotificationDialogVisibility
    | SetIsSendingEvent
