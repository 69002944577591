import {LocationViewType} from "interfaces";
import {IFilterItem} from "components/Filter/Filter.interfaces";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {Immutable} from "../../interfaces/Immutable";

export interface IToolbarState {
    searchFilter: string,
}

export interface IToolbarProps {
    filterItems?: Immutable<Array<IFilterItem>>,
    locationView?: LocationViewType,
    onSearchChange?: (filter: string) => void,
    onToggleLocationView?: () => void,
    onFiltersChange?: (items: Array<IFilterItem>) => void,
    searchBarPlaceholder?: string,
    handleMoveForward?: () => void,
    handleMoveBackward?: () => void,
    disableNextButton?: boolean,
    disablePrevButton?: boolean,
    onSelectSuggestion?: (suggestion: ILocationSuggestion) => void,
    isSearching?: boolean | undefined,
    disableToggleViewButton?: boolean | undefined,
    fetchSuggestions?: ((filter: string) => Promise<Array<ILocationSuggestion>>) | undefined,
    totalCount?: number | undefined,
    align?: "start" | "end",
    dateRange?: { start: string, end: string },
    onDateRangeChange?: ((start: string, end: string) => void) | undefined,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum ToolbarDispatchMessageType {
    ChangeSearchFilter
}

type ChangeSearchFilter = {
    type: ToolbarDispatchMessageType.ChangeSearchFilter,
    searchFilter: string,
};

export type ToolbarDispatchMessage = ChangeSearchFilter;