import {memo, useEffect, useMemo} from "react";
import {Logic, LogicProps, ReducerData} from "./LocationStats.interfaces";
import {useReducer} from "./LocationStats.reducer";
import {ILocationTableColumn} from "../../../../components/LocationTable/LocationTable.interfaces";
import {Immutable} from "../../../../interfaces/Immutable";
import {BuildingBasicKpis} from "../../../../interfaces/Kpis/BuildingBasicKpis";

const keepSameLogic = (prevProps: LogicProps, nextProps: LogicProps): boolean => {
    return (
        prevProps.width === nextProps.width &&
        prevProps.height === nextProps.height &&
        prevProps.showSkeletons === nextProps.showSkeletons
    )
}

export const LocationStatsLogic = memo((props: LogicProps): JSX.Element => {
    const reducerData: ReducerData = useReducer(props);
    const logic: Logic = useLogic(reducerData);
    return props.children(logic);
}, keepSameLogic);

const tableColumns: Array<ILocationTableColumn> = [
    {field: "type", label: "Type", maxWidth: 10, minWidth: "50px"},
    {field: "name", label: "Name", maxWidth: 45, minWidth: "150px"},
    {field: "clicks", label: "Clicks", maxWidth: 15, minWidth: "100px"},
    {field: "visits", label: "Visits", maxWidth: 15, minWidth: "100px"},
    {field: "reviews", label: "Reviews", maxWidth: 15, minWidth: "110px"},
]

export const useLogic = (reducerData: ReducerData) => {
    const {state, propsRef} = reducerData;

    useEffect(function onMount() {
        const tableResizeObserver = new ResizeObserver(handleTableResize(reducerData));
        if (state.table) tableResizeObserver.observe(state.table as HTMLDivElement);
        return () => {
            tableResizeObserver.disconnect();
        }
    }, []);

    const showSkeletons = propsRef.current.showSkeletons;

    const userFavoriteLocationIds = propsRef.current.userContext.currentUser?.applicationSettings.favoriteBuildings ?? [];

    const sortedBuildings = useMemo(() => {
        const clonedBuildings = [...propsRef.current.buildingsKpis];
        switch (state.columnSort) {
            case "type":
                clonedBuildings.sort((a, b) => a.type - b.type);
                break;
            case "name":
                clonedBuildings.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case "clicks":
                clonedBuildings.sort((a, b) => a.clicks - b.clicks);
                break;
            case "visits":
                clonedBuildings.sort((a, b) => {
                    const aTotalVisits = a.visits.reduce((acc, cur) => acc + cur.visits, 0);
                    const bTotalVisits = b.visits.reduce((acc, cur) => acc + cur.visits, 0);
                    return aTotalVisits - bTotalVisits;
                });
                break;
            case "reviews":
                clonedBuildings.sort((a, b) => a.reviews - b.reviews);
                break;
            default:
                break;
        }
        if (!state.sortAscending) clonedBuildings.reverse();
        return clonedBuildings;
    }, [propsRef.current.buildingsKpis, state.sortAscending, state.columnSort])

    const methods = useMemo(() => ({
        handleSortColumn: handleSortColumn(reducerData),
        handleTableRef: handleTableRef(reducerData),
        handleToggleSelectAll: handleToggleSelectAll(reducerData),
        handleShowBuildingKpis: handleShowBuildingKpis(reducerData),
    }), []);

    return {
        ...state,
        ...methods,
        reducerData,
        showSkeletons,
        tableColumns,
        userFavoriteLocationIds,
        isOnMobile: propsRef.current.teamsContext.isOnMobile,
        items: sortedBuildings,
        title: propsRef.current.title,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const updateVisibleItemsCount = async (reducerData: ReducerData) => {
    if (!reducerData.state.table) return;
    const itemHeight = 50; //px -> one row
    const height = reducerData.state.table?.clientHeight ?? 0;
    const visibleItemsCount = Math.floor(height / itemHeight) - 1; // -1 to Remove header from items
    reducerData.dispatch({type: "setVisibleItemsCount", value: visibleItemsCount});
}

const handleSortColumn = (reducerData: ReducerData) => (columnName: string) => async () => {
    const column = tableColumns.find(c => c.field === columnName);
    if (!column || (column.sortable !== undefined && !column.sortable)) return;
    if (columnName === reducerData.state.columnSort) reducerData.dispatch({type: "toggleSortAscending"});
    else reducerData.dispatch({type: "setColumnSort", value: columnName});
}

const handleTableResize = (reducerData: ReducerData) => (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame(async () => {
        if (!Array.isArray(entries) || !entries.length) return;
        await updateVisibleItemsCount(reducerData);
    });
}

const handleTableRef = (reducerData: ReducerData) => (ref: HTMLDivElement | null) => {
    reducerData.dispatch({type: "setTable", value: ref}, false);
}

const handleToggleSelectAll = (reducerData: ReducerData) => () => {
    reducerData.dispatch({type: "toggleSelectAll"});
}

const handleShowBuildingKpis = (reducerData: ReducerData) => (kpi: Immutable<BuildingBasicKpis>) => () => {
    reducerData.propsRef.current.onSelectBuilding(kpi.id, kpi.name);
}