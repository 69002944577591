import {instance} from "apis/AxiosConfig/AxiosConfig";
import {AxiosResponse} from "axios";

const getGraphToken = () => {
    return new Promise<string | null>(resolve => {
        return instance.get("/graphtoken").then((response: AxiosResponse) => {
            return resolve(response.data);
        }).catch(_ => {
            return resolve(null);
        });
    });
}

const saveToken = () => {
    return new Promise<void>(resolve => {
        return instance.get("/StoreToken").then((response: AxiosResponse) => {
            if (response.status !== 200)
                throw new Error("Can't save token");
            return resolve();
        }).catch(_ => {
            throw new Error("Can't save token");
        });
    });
}

export const TokenApi = {
    getGraphToken,
    saveToken,
}