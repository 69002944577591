import React, {memo, useMemo} from "react";
import {
    Button,
    Card,
    CircleIcon,
    EyeSlashIcon,
    Flex,
    FlexItem,
    Image,
    MenuButton,
    MoreIcon,
    Skeleton,
    StarIcon,
    Text
} from "@fluentui/react-northstar";
import {Badge} from "components";
import "./LocationPreview.styles.scss";
import {ILocationPreviewProps} from "./LocationPreview.interfaces";
import {useLocationPreview} from "./LocationPreview.hook";
import {LocationModule} from "modules";
import {LocationImageLevels, LocationTypes} from "const";
import {LocationIcon} from "assets/icons";
import {FlagshipBadge} from "../FlagshipBadge/FlagshipBadge";
import {LocationImageLevel} from "../../interfaces";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";

const propsAreEqual = (prevProps: Readonly<ILocationPreviewProps>, nextProps: Readonly<ILocationPreviewProps>) => {
    return prevProps.location.id === nextProps.location.id;
}

export const LocationPreview = memo((props: ILocationPreviewProps) => {
    const logic = useLocationPreview(props);
    const translate = useTranslation();

    const {name, priceRange, extendedProps, flagship} = props.location;
    const thumbnail = props.location.thumbnail;
    const type = LocationTypes[props.location.type];
    const popularity = LocationImageLevels[props.location.imageLevel];

    const flagshipBadge = useMemo(() => flagship === undefined ? null : (
        <Flex className={"flagship-badge"}>
            <FlagshipBadge brand={flagship}/>
        </Flex>
    ), [flagship]);

    const badges = useMemo(() => (
        <Flex className={"location-preview-badges"}>
            {/* Type */}
            <Badge label={type.shortLabel}/>
            {/* Price range */}
            <Badge label={LocationModule.getPriceRangeLabel(priceRange)}/>
            {/* Popularity */}
            {popularity.id === LocationImageLevel.Incomplete ? null : <Badge label={popularity.translationId}/>}
        </Flex>
    ), [type.shortLabel, priceRange, popularity.translationId]);

    const cover = useMemo(() => (
        <Flex className={"location-preview-cover " + (logic.isRecommendation ? "recommendation" : "")}>
            <Image
                fluid
                key={"image" + thumbnail}
                src={thumbnail}
                styles={{display: logic.thumbnailLoaded ? "block" : "none"}}
                onLoad={logic.handleImageLoaded}
            />
            {logic.thumbnailLoaded ? null :
                <Skeleton animation={"pulse"}>
                    <Skeleton.Shape height={"100vh"} width={"100vw"}/>
                </Skeleton>
            }
            {flagshipBadge}
            {badges}
        </Flex>
    ), [thumbnail, badges, flagshipBadge, logic.thumbnailLoaded]);

    const distanceLabel = useMemo(() => (
        !props.location.isShow ?
            <Flex className={"no-wrap"} vAlign={"center"}>
                <EyeSlashIcon size={"small"} style={{color: "darkgray", marginRight: "5px"}}/>
                <Text styles={{color: "darkgray"}} content={translate("Hidden")}/>
            </Flex>
            :
            <Flex className={"no-wrap"} vAlign={"center"}>
                <LocationIcon fill={"darkgray"} width={15} height={15} style={{marginRight: "3px"}}/>
                <Text styles={{color: "darkgray"}} content={LocationModule.getDistanceLabel(props.location.distance)}/>
            </Flex>
    ), [props.location.isShow, props.location.distance]);

    const openStateLabel = useMemo(() => (
        <Flex gap={"gap.small"} vAlign={"center"}>
            <CircleIcon
                size={"smaller"}
                styles={{
                    color: extendedProps?.openStateData?.isOpen ? "#68C92C" : "red",
                    marginTop: "2px"
                }}
            />
            <Text size={"medium"} content={extendedProps?.openStateData?.stateLabel}/>
        </Flex>
    ), [extendedProps?.openStateData?.isOpen, extendedProps?.openStateData?.stateLabel]);

    const actionsDropdown = useMemo(() => (
        <MenuButton
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            trigger={
                <Button iconOnly text icon={<MoreIcon size={"large"}/>} onClick={e => e.stopPropagation()}/>
            }
            align={"end"}
            menu={logic.actions}
        />
    ), [logic.actions]);

    const content = useMemo(() => (
        <Flex fill column className={"content-container"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Flex fill className={"pos-relative"} styles={{height: "25px"}}>
                    <Flex className={"fill-absolute"} vAlign={"center"} gap={"gap.small"}>
                        {logic.showFavoriteIcon ? <StarIcon styles={{color: "goldenrod"}} size={"small"}/> : null}
                        <Text truncated weight={"bold"} size={"large"} content={name}/>
                    </Flex>
                </Flex>
                <FlexItem push>
                    {distanceLabel}
                </FlexItem>
            </Flex>
            <Flex gap={"gap.small"} vAlign={"center"}>
                {openStateLabel}
                <FlexItem push>
                    {actionsDropdown}
                </FlexItem>
            </Flex>
        </Flex>
    ), [name, distanceLabel, openStateLabel, actionsDropdown, logic.showFavoriteIcon]);

    return (
        <Flex fill onTouchStart={(e: React.SyntheticEvent) => e.stopPropagation()}>
            <Card className={"location-preview no-select"} fluid elevated onClick={logic.handleShowDetails}>
                {cover}
                {content}
            </Card>
        </Flex>
    )
}, propsAreEqual)