import {Alert, Button, Divider, ExcelColorIcon, Flex, FormDropdown, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {useSettings} from "./Settings.hook";
import "./Settings.styles.scss";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {GoogleIcon} from "../../assets/icons";
import {UpdateBuildingType} from "./Settings.interfaces";
import moment from "moment";

export const Settings = () => {
    const translate = useTranslation();
    const logic = useSettings();

    //////////////// FORM ELEMENTS ////////////////

    const favoriteBrandsDropdown = useMemo(() => {
        const value = logic.favoriteBrandsItems.filter(i => logic.applicationSettings.favoriteBrands.includes(i.value));
        return (
            <FormDropdown
                fluid
                inverted={!logic.isOnMobile}
                multiple
                label={translate("FavoriteBrands")}
                items={logic.favoriteBrandsItems}
                value={value}
                onChange={logic.handleChangeApplicationFavoriteBrands}
                placeholder={translate("SelectBrands")}
            />
        )
    }, [logic.favoriteBrandsItems, logic.applicationSettings.favoriteBrands, logic.handleChangeApplicationFavoriteBrands])

    const homePageDropdown = useMemo(() => {
        const value = logic.homePageViewItems.find(i => i.value === logic.applicationSettings.homepage);
        return (
            <FormDropdown
                fluid
                inverted={!logic.isOnMobile}
                label={translate("HomePage")}
                items={logic.homePageViewItems}
                value={value}
                onChange={logic.handleChangeApplicationHomePage}
            />
        )
    }, [logic.homePageViewItems, logic.applicationSettings.homepage, logic.handleChangeApplicationHomePage])

    const importErrorAlert = !logic.hasImportError ? null : (
        <Flex>
            <Alert
                attached
                danger
                content={translate("AnErrorOccuredDuringUpdate")}
                visible
            />
        </Flex>
    )

    const updateAlert = (
        <Flex>
            <Alert
                attached
                warning
                content={translate(logic.cantImport ? "AnUpdateIsInProgress" : "UpdateMayTakeMoreThan10Min")}
                visible
            />
        </Flex>
    )

    const lastImportDate = !logic.lastImportDate ? null : (
        <Flex>
            <Text
                styles={{color: "darkgray"}}
                content={translate("LastDataUpdateDoneOn", {date: moment(logic.lastImportDate).format("L")})}
            />
        </Flex>
    )

    const updateBuildingsButton = (
        <Flex column>
            <Text styles={{marginBottom: "3px"}} content={translate("MakeAnUpdate")}/>
            <Flex column gap={"gap.smaller"}>
                <Button
                    onClick={logic.handleOpenFileSelector(UpdateBuildingType.Elite)}
                    icon={<ExcelColorIcon styles={{opacity: logic.isImporting ? 0.3 : 1}} size={"large"}/>}
                    content={translate("SelectAFile")}
                    disabled={logic.isImporting}
                    loading={logic.isImporting && logic.updateType === UpdateBuildingType.Elite}
                />
                {logic.isImporting && logic.updateType === UpdateBuildingType.Elite ? updateAlert : null}
            </Flex>
        </Flex>
    )

    const updateAdvancedBuildingsButton = (
        <Flex column>
            <Text styles={{marginBottom: "3px"}} content={translate("UpdateGoogleData")}/>
            <Flex column gap={"gap.smaller"}>
                <Button
                    onClick={logic.handleOpenFileSelector(UpdateBuildingType.Google)}
                    icon={<GoogleIcon opacity={logic.isImporting ? 0.3 : 1}/>}
                    content={translate("SelectAFile")}
                    disabled={logic.isImporting}
                    loading={logic.isImporting && logic.updateType === UpdateBuildingType.Google}
                />
                {logic.isImporting && logic.updateType === UpdateBuildingType.Google ? updateAlert : null}
            </Flex>
        </Flex>
    )

    //////////////// SETTINGS GROUPS ////////////////

    const applicationSettings = (
        <Flex key={"application-settings"} column gap={"gap.medium"}>
            <Text size={"larger"} weight={"regular"} content={translate("Application")}/>
            {favoriteBrandsDropdown}
            {homePageDropdown}
        </Flex>
    )

    const buildingsSettings = (logic.hideBuildingSettings || logic.isOnMobile) ? null : (
        <Flex key={"buildings-settings"} column gap={"gap.medium"}>
            <Text size={"larger"} weight={"regular"} content={translate("Buildings")}/>
            {updateBuildingsButton}
            {updateAdvancedBuildingsButton}
            {logic.cantImport ? updateAlert : null}
            {importErrorAlert}
        </Flex>
    )

    const groups = [
        applicationSettings, buildingsSettings
    ].filter(g => !!g);

    return (
        <Flex fill column gap={"gap.medium"} className={"x-padding"}>
            {groups.map((g, index) => (
                <React.Fragment key={g?.key}>
                    {index > 0 ? <Divider/> : null}
                    {g}
                </React.Fragment>
            ))}
            {lastImportDate}
        </Flex>
    )
}