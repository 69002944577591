import {Logic} from "./Kpi.interfaces";
import "./Kpi.styles.scss";
import React from "react";
import {Flex} from "@fluentui/react-northstar";
import LocationStats from "./LocationStats/LocationStats";
import NumberKpi from "./NumberKpi/NumberKpi";
import LineChartKpi from "./LineChartKpi/LineChartKpi";
import BarChartKpi from "./BarChartKpi/BarChartKpi";
import KeywordsKpi from "./KeywordsKpi/KeywordsKpi";

export const KpiRendering = (logic: Logic) => {

    let content = null;

    switch (logic.type) {
        case "number":
            content = <NumberKpi {...logic}/>;
            break;
        case "locationStats":
            content = <LocationStats {...logic}/>;
            break;
        case "lineChart":
            content = <LineChartKpi {...logic}/>;
            break;
        case "barChart":
            content = <BarChartKpi {...logic}/>;
            break;
        case "keywords":
            content = <KeywordsKpi {...logic}/>;
            break;
        default:
            break;
    }

    return (
        <Flex fill className={"kpi"} styles={logic.styles}>
            {content}
        </Flex>
    )
}