import {Logic} from "./BuildingKpiDetails.interfaces";
import "./BuildingKpiDetails.styles.scss";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {languageObject} from "../../../translations";
import {IReview} from "../../../interfaces/IReview";
import {ReviewCard} from "../../../components";
import {Carousel} from "../../../components/Carousel/Carousel";
import {SideView} from "../../../components/SideView/SideView";
import Kpi from "../Kpi/Kpi";
import {Immutable} from "../../../interfaces/Immutable";

export const BuildingKpiDetailsRendering = (logic: Logic) => {

    const statistics = useMemo(() => (
        <Flex column gap={"gap.medium"} className={"w-100 x-padding"} styles={{paddingBottom: "10px"}}>
            <Text size={"large"} content={languageObject.translate("Statistics")}/>
            <Flex className={"flex-grid-300px"}>
                <Flex fill gap={"gap.smaller"}>
                    <Text content={languageObject.translate("ClicksCount") + " :"}/>
                    <Text weight={"bold"}
                          content={logic.kpis?.clicksCount}/>
                </Flex>
                <Flex fill gap={"gap.smaller"}>
                    <Text content={languageObject.translate("PublicReviewsCount") + " :"}/>
                    <Text weight={"bold"} content={logic.kpis?.publicReviewsCount}/>
                </Flex>
                <Flex fill gap={"gap.smaller"}>
                    <Text content={languageObject.translate("AddedToFavorites") + " :"}/>
                    <Text weight={"bold"}
                          content={logic.kpis?.favoritesCount}/>
                </Flex>
                <Flex fill gap={"gap.smaller"}>
                    <Text content={languageObject.translate("PrivateReviewsCount") + " :"}/>
                    <Text weight={"bold"} content={logic.kpis?.privateReviewsCount}/>
                </Flex>
                <Flex fill gap={"gap.smaller"}>
                    <Text content={languageObject.translate("VisitsCount") + " :"}/>
                    <Text weight={"bold"} content={logic.kpis?.visitsCount}/>
                </Flex>
            </Flex>
        </Flex>
    ), [logic.kpis]);

    const publicReviewItemsWithoutPictures = useMemo(() => (
        renderReviewItems(logic.publicReviews, false)
    ), [logic.publicReviews]);

    const publicReviewsPart = useMemo(() => publicReviewItemsWithoutPictures.length === 0 ? null : (
        <Carousel
            labelId={"PublicReviews"}
            items={publicReviewItemsWithoutPictures}
            onSeeAll={logic.handleTogglePublicReviewsSideView}
        />
    ), [publicReviewItemsWithoutPictures]);

    const publicReviewsSideView = useMemo(() => publicReviewItemsWithoutPictures.length === 0 ? null : (
        <SideView
            align={"right"}
            show={logic.showPublicReviewsSideView}
            onHide={logic.handleTogglePublicReviewsSideView}
            title={languageObject.translate("PublicReviews")}
            content={
                <Flex fill column gap={"gap.small"}>
                    {renderReviewItems(logic.publicReviews, true)}
                    <div style={{minHeight: "10px"}}/>
                </Flex>
            }
        />
    ), [logic.showPublicReviewsSideView]);

    const privateReviewItemsWithoutPictures = useMemo(() => (
        renderReviewItems(logic.privateReviews, false)
    ), [logic.privateReviews]);

    const privateReviewsPart = useMemo(() => privateReviewItemsWithoutPictures.length === 0 ? null : (
        <Carousel
            labelId={"PrivateReviews"}
            items={privateReviewItemsWithoutPictures}
            onSeeAll={logic.handleTogglePrivateReviewsSideView}
        />
    ), [privateReviewItemsWithoutPictures]);

    const privateReviewsSideView = useMemo(() => privateReviewItemsWithoutPictures.length === 0 ? null : (
        <SideView
            align={"right"}
            show={logic.showPrivateReviewsSideView}
            onHide={logic.handleTogglePrivateReviewsSideView}
            title={languageObject.translate("PrivateReviews")}
            content={
                <Flex fill column gap={"gap.small"}>
                    {privateReviewItemsWithoutPictures}
                    <div style={{minHeight: "10px"}}/>
                </Flex>
            }
        />
    ), [logic.showPrivateReviewsSideView]);

    const visitsKpi = useMemo(() => logic.visitsChartData.labels.length <= 0 ? null : (
        <Flex column gap={"gap.medium"} className={"w-100 x-padding"} styles={{paddingBottom: "10px"}}>
            <Text size={"large"} content={languageObject.translate("VisitsPerDay")}/>
            <Kpi
                type={"lineChart"}
                showSkeletons={false}
                labels={logic.visitsChartData.labels}
                values={logic.visitsChartData.values}
            />
        </Flex>
    ), [logic.visitsChartData]);

    if (!logic.loaded) return <Loader styles={{width: "100%"}}/>;
    return (
        <Flex fill column className={"building-kpis-details"}>
            <Flex fill column gap={"gap.small"} className={"building-kpis-details-content"}>
                {statistics}
                {visitsKpi}
                {publicReviewsPart}
                {privateReviewsPart}
                <Flex styles={{marginBottom: "15px"}}/>
            </Flex>
            {publicReviewsSideView}
            {privateReviewsSideView}
        </Flex>
    )
}

const renderReviewItems = (reviews: Immutable<Array<IReview>> | undefined, showPictures: boolean) => {
    return reviews?.map(r => (
        <Flex className={"w-100"} key={"review-item-" + r.id} styles={{marginBottom: "8px"}}>
            <ReviewCard review={r} showPicture={showPictures}/>
        </Flex>
    )) ?? [];
}