import {memo} from "react";
import {ComponentSlotStyle, Flex, Pill, Text} from "@fluentui/react-northstar";
import {IBadgeProps} from "./Badge.interfaces";
import "./Badge.styles.scss";

export const Badge = memo((props: IBadgeProps) => {

    const styles: ComponentSlotStyle = {};
    if (props.color) styles.backgroundColor = props.color + " !important";
    if (props.textColor) styles.color = props.textColor + " !important";

    return (
        <Flex>
            <Pill
                rectangular
                className={"no-select no-wrap badge"}
                icon={props.icon}
                content={<Text content={props.label} styles={{color: props.textColor}}/>}
                styles={styles}
            />
        </Flex>
    )
});