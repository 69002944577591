import {useNavigate} from "react-router-dom";
import {IMenuProps} from "./Menu.interfaces";
import {AppViews} from "const";
import {Flex} from "@fluentui/react-northstar";
import {AppView, IAppView} from "interfaces";
import * as React from "react";
import {useCallback, useContext, useMemo} from "react";
import {ActivityContext} from "../../services/ActivityContext/ActivityContext";
import {IActivityContext} from "../../services/ActivityContext/ActivityContext.interfaces";

const visibleViews: ReadonlyArray<AppView> = [
    AppView.AroundMe, AppView.Events, AppView.Favorites, AppView.Buildings, AppView.Dashboard, AppView.Settings
];

export const useMenu = (props: IMenuProps) => {
    const navigate = useNavigate();
    const activityContext = useContext<IActivityContext>(ActivityContext);

    const handleChangeView = useCallback((view: IAppView) => () => {
        if (view.id === AppView.Activity) activityContext.updateSawActivity();
        navigate(view.path);
        props.onChangeView(view);
    }, []);

    const filteredAppViews = useMemo((): Array<IAppView> => {
        const views = visibleViews.filter(view => !props.hiddenViews.includes(view));
        if (props.isOnMobile) {
            views.unshift(AppView.Activity);
            const dashboardViewIndex = views.indexOf(AppView.Dashboard);
            if (dashboardViewIndex >= 0) views.splice(dashboardViewIndex, 1);
        }
        const appViews = views.map(view => ({...AppViews[view]}));
        const activityItem = appViews.find(v => v.id === AppView.Activity);
        if (activityItem && activityContext.hasNewActivity) {
            activityItem.icon = (
                <Flex className={"pos-relative"}>
                    {activityItem.icon}
                    <div className={"new-activity-badge"}/>
                </Flex>
            )
        }
        return appViews;
    }, [props.hiddenViews, activityContext.hasNewActivity]);

    const userFirstName = useMemo(() => {
        return props.userName.split(" ")[0];
    }, [props.userName]);

    return {
        currentView: props.currentView,
        appViews: filteredAppViews,
        handleChangeView,
        handleClose: props.onClose,
        open: props.open,
        userFirstName,
    }
}