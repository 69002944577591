import {IActivity} from "interfaces";
import {ITeamsContext} from "../TeamsContext/TeamsContext.interfaces";
import {IUserContext} from "../UserContext";

export interface IActivityContextState {
    loaded: boolean,
    list: Array<IActivity>,
    hasNewActivity: boolean,
}

export interface IActivityContextProps {
    teamsContext: ITeamsContext,
    userContext: IUserContext,
    children: JSX.Element,
}

export interface IActivityContext extends IActivityContextState {
    updateSawActivity: () => void,
    hasNewActivity: boolean,
}

/////////////////////////////////////////////////////// REDUCER ///////////////////////////////////////////////////////

export enum ActivityContextDispatchMessageType {
    SetActivityList, SetHasNewActivity
}

type SetActivityList = {
    type: ActivityContextDispatchMessageType.SetActivityList,
    items: Array<IActivity>,
};

type SetHasNewActivity = {
    type: ActivityContextDispatchMessageType.SetHasNewActivity,
    value: boolean,
}

export type ActivityContextDispatchMessage = SetActivityList | SetHasNewActivity;
