import React from "react";
import {BuildingKpiDetailsLogic} from "./BuildingKpiDetails.logic";
import {BuildingKpiDetailsRendering} from "./BuildingKpiDetails.rendering";
import {Props} from "./BuildingKpiDetails.interfaces";

const BuildingKpiDetails = (props: Props): JSX.Element => {
    return (
        <BuildingKpiDetailsLogic {...props}>
            {logic => <BuildingKpiDetailsRendering {...logic}/>}
        </BuildingKpiDetailsLogic>
    )
}

export default BuildingKpiDetails;