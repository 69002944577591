import moment from "moment";
import {WeekDay} from "../interfaces";

const getWeekDaysOrderPerCulture = (): Array<WeekDay> => {
    const currentLocaleData = moment.localeData();
    return moment.weekdays(true).map(d => currentLocaleData.weekdaysParse(d, "dddd", false) as WeekDay);
}

const getFirstDayOfWeek = (): WeekDay => {
    return getWeekDaysOrderPerCulture()[0]!;
}

export const WeekDaysModule = {
    getWeekDaysOrderPerCulture,
    getFirstDayOfWeek,
}