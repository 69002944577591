import {instance} from "../AxiosConfig/AxiosConfig";
import {AxiosResponse} from "axios";
import {IGeoPosition} from "../../interfaces";
import {IGeocodingData} from "../../interfaces/IGeocodingData";

const getGeocodingData = (address: string): Promise<IGeocodingData | undefined> => {
    return new Promise<IGeocodingData | undefined>(resolve => {
        return instance.get("/localisation/geocode?address=" + address).then((response: AxiosResponse) => {
            return resolve(response.data);
        }).catch(_ => {
            return resolve(undefined);
        })
    })
}

const getGeoPositionFromProxy = (): Promise<IGeoPosition | undefined> => {
    return new Promise<any>(resolve => {
        return instance.get("/localisation/fromproxy").then((response: AxiosResponse) => {
            if (!response.data) return resolve(undefined);
            const result: IGeoPosition = {
                lat: response.data.latitude,
                lng: response.data.longitude
            }
            return resolve(result);
        }).catch(_ => {
            return resolve(undefined);
        })
    })
}

export const LocationApi = {
    getGeocodingData,
    getGeoPositionFromProxy,
}