import {useCallback, useContext, useEffect, useReducer, useRef} from "react";
import {
    ActivityContextDispatchMessageType,
    IActivityContext,
    IActivityContextProps
} from "./ActivityContext.interfaces";
import {initialState, reducer} from "./ActivityContext.reducer";
import {ActivitiesApi} from "apis/Activities/ActivitiesApi";
import {TeamsContext} from "../TeamsContext";
import {ITeamsContext} from "../TeamsContext/TeamsContext.interfaces";
import {IActivity} from "../../interfaces";
import {useCacheService} from "../CacheService/CacheService.hook";
import {StorageType} from "../CacheService/CacheService.interfaces";

export const useActivityContext = (props: IActivityContextProps): IActivityContext => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const cacheService = useCacheService(StorageType.Local);
    const lastSawActivityKeyRef = useRef<string>(teamsContext.userId + "_last_saw_activity_id");
    const lastSawActivityIdRef = useRef<string | undefined>(cacheService.getItem(lastSawActivityKeyRef.current) as string | undefined);
    const activitiesRef = useRef<Array<IActivity>>([]);

    useEffect(function onMount() {
        fetchUserActivities().then();
    }, []);

    const fetchUserActivities = useCallback(async (): Promise<void> => {
        const userId = props.teamsContext.userId;
        const activities = await ActivitiesApi.getByUserId(userId);
        activities.forEach(activity => activity.picture = `https://${process.env.REACT_APP_BACK_URL}${activity.picture}`);
        activitiesRef.current = activities;
        const firstActivityId = activities[0]?.id;
        const activitiesUserIds = Array.from(new Set(activities.map(a => a.userId).filter(a => a)));
        await props.userContext.fetchUsers(activitiesUserIds);
        dispatch({type: ActivityContextDispatchMessageType.SetActivityList, items: activities});
        const hasNewActivity = !!firstActivityId && firstActivityId !== lastSawActivityIdRef.current;
        dispatch({type: ActivityContextDispatchMessageType.SetHasNewActivity, value: hasNewActivity});
    }, []);

    const updateSawActivity = useCallback(() => {
        const firstActivity = activitiesRef.current.length === 0 ? null : activitiesRef.current[0];
        if (!firstActivity || lastSawActivityIdRef.current === firstActivity.id) return;
        lastSawActivityIdRef.current = firstActivity.id;
        cacheService.setItem(lastSawActivityKeyRef.current, firstActivity.id);
        dispatch({type: ActivityContextDispatchMessageType.SetHasNewActivity, value: false});
    }, []);

    return {
        ...state,
        updateSawActivity,
    }
}