import {AppView} from "interfaces";
import {useCallback, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {AppViews, Brands} from "const";
import {initialState, reducer} from "./Settings.reducer";
import {IApplicationSettings, SettingsDispatchMessageType, UpdateBuildingType} from "./Settings.interfaces";
import {DropdownItemProps} from "@fluentui/react-northstar";
import {useFilePicker} from "use-file-picker";
import {Brand} from "interfaces/Brand/Brand";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import {IUserContext, UserContext} from "../../services/UserContext";
import {UserRole} from "../../interfaces/UserRole";
import {FileContent} from "use-file-picker/dist/interfaces";
import {BuildingsApi} from "../../apis/Buildings/BuildingsApi";

export const useSettings = () => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const translate = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const updateTimeoutRef = useRef<NodeJS.Timeout>();
    const updateTypeRef = useRef<UpdateBuildingType>();

    const {isOnMobile} = teamsContext;

    useEffect(function onMount() {
        (async () => {
            const currentUserApplicationSettings = userContext.currentUser?.applicationSettings;
            if (!currentUserApplicationSettings) return;
            dispatch({
                type: SettingsDispatchMessageType.SetApplicationSettings,
                settings: currentUserApplicationSettings
            });
            const lastImportDate = await BuildingsApi.getLastEliteBuildingsUpdateDate();
            dispatch({type: SettingsDispatchMessageType.SetLastImportDate, value: lastImportDate});
            if (userContext.currentUser?.role === UserRole.SuperAdmin) {
                const canImport = await BuildingsApi.canImportingBuildings();
                dispatch({type: SettingsDispatchMessageType.SetIsImporting, value: !canImport});
            }
            dispatch({type: SettingsDispatchMessageType.SetIsLoaded, value: true});
        })();
    }, []);

    const updateUserSettings = useCallback(async (data: Partial<IApplicationSettings>) => {
        if (!!updateTimeoutRef.current) {
            clearTimeout(updateTimeoutRef.current);
            updateTimeoutRef.current = setTimeout(async () => {
                await userContext.updateSettings(data);
                updateTimeoutRef.current = undefined;
            }, 2000);
        } else {
            updateTimeoutRef.current = setTimeout(() => 0, 2000);
            await userContext.updateSettings(data);
        }
    }, []);

    const [openFileSelector, {filesContent, clear}] = useFilePicker({
        multiple: false,
        readAs: 'ArrayBuffer',
        accept: ['.xls', '.xlsx']
    });

    useEffect(function onFileSelected() {
        if (filesContent.length !== 1) return;
        const file: FileContent | undefined = filesContent[0];
        if (!file) throw new Error("File is undefined");
        const fileName = file.name;
        const fileContent: ArrayBuffer = file.content as unknown as ArrayBuffer;
        clear();
        handleFileSelection(fileName, fileContent, state.isImporting);
    }, [filesContent]);

    const handleFileSelection = useCallback(async (name: string, buffer: ArrayBuffer, isImporting: boolean) => {
        if (isImporting) return;
        dispatch({type: SettingsDispatchMessageType.SetHasImportError, value: false});
        dispatch({type: SettingsDispatchMessageType.SetIsImporting, value: true});
        let importSuccess = false;
        if (updateTypeRef.current === UpdateBuildingType.Elite)
            importSuccess = await BuildingsApi.importData(buffer);
        else if (updateTypeRef.current === UpdateBuildingType.Google)
            importSuccess = await BuildingsApi.importGoogleData(buffer);
        if (!importSuccess) {
            dispatch({type: SettingsDispatchMessageType.SetIsImporting, value: false});
            dispatch({type: SettingsDispatchMessageType.SetHasImportError, value: true});
            return;
        }
        const importInterval = setInterval(async () => {
            const canImport = await BuildingsApi.canImportingBuildings();
            if (canImport) {
                clearInterval(importInterval);
                dispatch({type: SettingsDispatchMessageType.SetIsImporting, value: false});
            }
        }, 5000);
    }, []);

    const handleChangeApplicationFavoriteBrands = useCallback(async (e, data) => {
        const items = data?.value as Array<DropdownItemProps & { value: Brand }>;
        const favoriteBrands = items.map(item => item.value);
        dispatch({type: SettingsDispatchMessageType.UpdateApplicationFavoriteBrands, favoriteBrands});
        await updateUserSettings({favoriteBrands: favoriteBrands})
    }, []);

    const handleChangeApplicationHomePage = useCallback(async (e, data) => {
        const homePage = (data?.value as DropdownItemProps & { value: AppView })?.value ?? AppView.AroundMe;
        dispatch({type: SettingsDispatchMessageType.UpdateApplicationHomePage, homePage});
        await updateUserSettings({homepage: homePage})
    }, []);

    const handleOpenFileSelector = useCallback((updateType: UpdateBuildingType) => () => {
        updateTypeRef.current = updateType;
        openFileSelector();
    }, []);

    const favoriteBrandsItems = useMemo((): Array<DropdownItemProps & { value: Brand }> => {
        return Object.values(Brands).map(brand => ({
            key: "brand-" + brand.id,
            value: brand.id,
            header: brand.formattedName,
            className: "brand-dropdown-item",
            image: brand.iconPath
        }));
    }, []);

    const homePageViewItems = useMemo((): Array<DropdownItemProps & { value: AppView }> => {
        const hiddenViews = [AppView.Settings, AppView.Activity];
        if (userContext.currentUser?.role === UserRole.Basic) hiddenViews.push(AppView.Buildings);
        const appViews = Object.values(AppViews).filter(v => !hiddenViews.includes(v.id));
        return appViews.map(appView => ({
            key: "appview-" + appView.id,
            value: appView.id,
            header: translate(appView.translationId),
        }));
    }, []);

    const hideBuildingSettings = userContext.currentUser?.role !== UserRole.SuperAdmin;

    const cantImport = state.loaded && state.isImporting && updateTypeRef.current === undefined;

    return {
        ...state,
        homePageViewItems,
        favoriteBrandsItems,
        handleChangeApplicationFavoriteBrands,
        handleChangeApplicationHomePage,
        handleOpenFileSelector,
        hideBuildingSettings,
        isOnMobile,
        updateType: updateTypeRef.current,
        cantImport,
    }
}