import React, {useCallback, useContext, useReducer} from "react";
import {IReviewFormProps, ReviewFormDispatchMessageType} from "./ReviewForm.interfaces";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import moment from "moment/moment";
import {IReview} from "../../interfaces/IReview";
import {TeamsContext} from "../../services/TeamsContext";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {initialState, reducer} from "./ReviewForm.reducer";
import {Flex, InfoIcon, Popup} from "@fluentui/react-northstar";
import languageObject from "../../translations";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useReviewForm = (props: IReviewFormProps) => {
    const translate = useTranslation();
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const {form, isValid, dataRef} = useForm({
        locale: teamsContext.fullLocale,
        fluid: true,
        readOnly: state.isSendingFeedback,
        className: "review-form",
        items: [
            {
                id: "experience-details",
                type: "title",
                value: translate("ExperienceDetails")
            },
            {
                id: "review",
                type: "textArea",
                label: translate("Review"),
                required: true,
                minHeight: "80px",
                placeholder: translate("TypeReview"),
                topRightElement: (
                    <Flex vAlign={"center"} fill>
                        <Popup
                            on={teamsContext.isOnMobile ? "click" : "hover"}
                            align={"top"}
                            offset={[teamsContext.isOnMobile ? 0 : -10, 0]}
                            pointing
                            closeOnScroll
                            position={teamsContext.isOnMobile ? "below" : "after"}
                            content={{
                                className: "legal-text-container",
                                content: languageObject.translate("ReviewLegalText"),
                            }}
                            trigger={<InfoIcon styles={{marginBottom: "3px"}} outline/>}
                        />
                    </Flex>
                )
            },
            {
                id: "picture",
                type: "imagePicker",
                label: translate("Picture"),
                initialValue: undefined,
            },
            {
                id: "date",
                type: "datePicker",
                label: translate("VisitDate"),
                required: true,
                maxDate: moment().toDate(),
                initialValue: moment().toISOString(false),
            },
            {
                id: "visitors",
                type: "input",
                label: translate("NumberVisitors"),
                required: true,
                initialValue: 1,
                inputMode: "numeric",
                clearable: false,
            },
            {
                id: "divider-1",
                type: "divider",
            },
            {
                id: "sector-manager-title",
                type: "title",
                value: translate("SectorManager")
            },
            {
                id: "sectorManagerReview",
                type: "textArea",
                label: translate("SectorManagerReview"),
                placeholder: translate("ReportPrivateReviewToSectorManager"),
                minHeight: "80px",
            }
        ]
    }, [state.isSendingFeedback]);

    const handleHideNotification = useCallback(() => {
        dispatch({type: ReviewFormDispatchMessageType.SetNotificationDialogVisibility, show: false});
        props.onSubmitDone();
    }, []);

    const handleSubmit = useCallback(async () => {
        dispatch({type: ReviewFormDispatchMessageType.SetIsSendingFeedback, value: true});
        const review: IReview = {
            id: undefined,
            buildingId: props.locationId,
            visitors: Number(dataRef.current["visitors"]),
            reviewDate: dataRef.current["date"] as string,
            review: dataRef.current["review"] as string,
            picture: dataRef.current["picture"] as string,
            sectorManagerReview: dataRef.current["sectorManagerReview"] as string,
            userAadId: teamsContext.userId,
        }
        await locationContext.publishReview(review);
        dispatch({type: ReviewFormDispatchMessageType.SetIsSendingFeedback, value: false});
        dispatch({type: ReviewFormDispatchMessageType.SetNotificationDialogVisibility, show: true});
        setTimeout(() => {
            dispatch({type: ReviewFormDispatchMessageType.SetNotificationDialogVisibility, show: false});
            setTimeout(() => {
                props.onSubmitDone();
            }, 200)
        }, 1500)
    }, []);

    return {
        ...state,
        handleSubmit,
        form,
        isFormValid: isValid,
        handleHideNotification
    }
}