import {Logic} from "./SuperAdminDashboard.interfaces";
import {Flex} from "@fluentui/react-northstar";
import React from "react";
import Kpi from "../Kpi/Kpi";
import translations, {languageObject} from "../../../translations";

export const SuperAdminDashboardRendering = (logic: Logic) => {

    const buildingsClicksKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("Buildings")}
            value={logic.buildingsClicks}
            subtitle={translations.translate(logic.buildingsClicks <= 1 ? "Click" : "Clicks")}
        />
    )

    const recommendedBuildingsClicksKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("RecommendedBuildings")}
            value={logic.recommendedBuildingsClicks}
            subtitle={translations.translate(logic.recommendedBuildingsClicks <= 1 ? "Click" : "Clicks")}
        />
    )

    const buildingsInFavoritesCountKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("BuildingsInFavorites")}
            value={logic.buildingsInFavoritesCount}
            subtitle={translations.translate(logic.buildingsInFavoritesCount <= 1 ? "Building" : "Buildings")}
        />
    )

    const createdEventsCountKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("CreatedEvents")}
            value={logic.createdEventsCount}
            subtitle={translations.translate(logic.buildingsInFavoritesCount <= 1 ? "Event" : "Events")}
        />
    )

    const listViewClicksKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("ListViewClicks")}
            value={logic.listViewClicks}
            subtitle={translations.translate(logic.listViewClicks <= 1 ? "Click" : "Clicks")}
        />
    )

    const topBuildingClicks = (
        <Kpi
            title={languageObject.translate("TopBuildingsClicks")}
            type={"barChart"}
            showSkeletons={logic.showSkeletons}
            labels={logic.topBuildingClicksChartData.labels}
            values={logic.topBuildingClicksChartData.values}
            width={2}
            onClick={logic.handleClickOnBuildingFromBarChart(logic.kpis?.topBuildingClicks)}
        />
    );

    const topRecommendedBuildingClicks = (
        <Kpi
            title={languageObject.translate("TopRecommendedBuildingsClicks")}
            type={"barChart"}
            showSkeletons={logic.showSkeletons}
            labels={logic.topRecommendedBuildingClicksChartData.labels}
            values={logic.topRecommendedBuildingClicksChartData.values}
            width={2}
            onClick={logic.handleClickOnBuildingFromBarChart(logic.kpis?.topRecommendedBuildingClicks)}
        />
    );

    const topBuildingInFavorites = (
        <Kpi
            title={languageObject.translate("TopFavoritesBuildings")}
            type={"barChart"}
            showSkeletons={logic.showSkeletons}
            labels={logic.topBuildingInFavoritesChartData.labels}
            values={logic.topBuildingInFavoritesChartData.values}
            width={2}
            onClick={logic.handleClickOnBuildingFromBarChart(logic.kpis?.topBuildingInFavorites)}
        />
    );

    const topKeywordsKpi = (
        <Kpi
            title={languageObject.translate("MostSearchedKeywords")}
            type={"keywords"}
            showSkeletons={logic.showSkeletons}
            keywords={logic.topKeywords}
        />
    );

    const connectionsKpi = (
        <Kpi
            type={"number"}
            showSkeletons={logic.showSkeletons}
            title={translations.translate("Connections")}
            value={logic.connectionsCount}
            subtitle={translations.translate(logic.connectionsCount <= 1 ? "Connection" : "Connections")}
        />
    )

    const connectionsPerDayKpi = (
        <Kpi
            title={languageObject.translate("ConnectionsPerDay")}
            type={"lineChart"}
            showSkeletons={logic.showSkeletons}
            labels={logic.connectionsChartData.labels}
            values={logic.connectionsChartData.values}
            width={2}
        />
    );

    return (
        <Flex fill className={"pos-relative"}>
            <Flex column className={"fill-absolute overflow-scroll scaled-kpis-container"}>
                <Flex className={"w-100 kpis-grid"}>
                    {connectionsKpi}
                    {connectionsPerDayKpi}
                    {buildingsClicksKpi}
                    {topBuildingClicks}
                    {recommendedBuildingsClicksKpi}
                    {topRecommendedBuildingClicks}
                    {buildingsInFavoritesCountKpi}
                    {topBuildingInFavorites}
                    {topKeywordsKpi}
                    {createdEventsCountKpi}
                    {listViewClicksKpi}
                </Flex>
                <Flex styles={{width: "100%", minHeight: "20px"}}/>
            </Flex>
        </Flex>
    )
}