import * as React from "react";
import {useContext, useEffect, useMemo, useRef} from "react";
import {ILocationMarkerPreviewProps} from "./LocationMarkerPreview.interfaces";
import {ITeamsContext} from "services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "services/TeamsContext";
import {ILocationContext} from "../../services/LocationContext/LocationContext.interfaces";
import {LocationContext} from "../../services/LocationContext/LocationContext";

export const useLocationMarkerPreview = (props: ILocationMarkerPreviewProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const locationContext = useContext<ILocationContext>(LocationContext);
    const containerRef = useRef<HTMLElement>();

    const isOnMobile = teamsContext.isOnMobile;

    useEffect(function onLocationIdChange() {
        if (isOnMobile) return;
        const element = document.body.querySelector(".location-pin-container") as HTMLElement;
        if (!element) return;
        containerRef.current = element;
        containerRef.current?.removeEventListener("mouseleave", props.onLeave);
        containerRef.current!.addEventListener("mouseleave", props.onLeave);
        return () => {
            containerRef.current?.removeEventListener("mouseleave", props.onLeave);
        }
    }, [props.locationId])

    const location = useMemo(() => {
        return locationContext.findLocation(props.locationId);
    }, [props.locationId]);

    return {
        ...props,
        location,
        isOnMobile,
    }
}