import {ArrowLeftIcon, Flex, Segment, Text} from "@fluentui/react-northstar";
import "./SideView.styles.scss";
import {ISideViewProps} from "./SideView.interfaces";
import {useContext} from "react";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const SideView = (props: ISideViewProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    const classNames = [
        (teamsContext.isOnMobile ? "on-mobile" : ""),
        "side-view",
        "side-view-" + props.align,
        (props.show ? "" : "side-view-closed")
    ];

    const header = (
        <Flex
            className={"x-padding cursor-pointer side-view-header"}
            gap={"gap.medium"}
            vAlign={"center"}
            onClick={props.onHide}>
            <ArrowLeftIcon styles={{marginTop: "2px"}}/>
            <Text size={"large"} weight={"semibold"} content={props.title}/>
        </Flex>
    )

    const content = (
        <Flex fill className={"pos-relative overflow-scroll"}>
            <Flex fill className={"fill-absolute side-view-content " + (props.noPadding ? "" : "x-padding y-padding")}>
                {props.content}
            </Flex>
        </Flex>
    )

    return (
        <Segment className={classNames.join(" ")}>
            <Flex fill className={"pos-relative overflow-scroll"}>
                <Flex fill column gap={"gap.medium"} className={"fill-absolute"}>
                    {header}
                    {content}
                </Flex>
            </Flex>
        </Segment>
    )
}