import * as React from "react";
import {memo, useCallback, useMemo} from "react";
import {Logic, LogicProps, OpenHoursRange} from "./OpenHoursForm.interfaces";
import {useReducer} from "./OpenHoursForm.reducer";
import moment from "moment";
import {WeekDaysModule} from "../../../modules/WeekDays.module";
import translations from "../../../translations";
import {WeekDay} from "../../../interfaces";
import {DropdownItemProps, DropdownProps} from "@fluentui/react-northstar";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return prevProps.initialHours === nextProps.initialHours;
}

export const OpenHoursFormLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {
    const {state, dispatch} = useReducer(props);

    const isOnMobile = props.teamsContext.isOnMobile;

    const weekDaysItems = useMemo(() => WeekDaysModule.getWeekDaysOrderPerCulture().map(day => ({
        key: day,
        value: day,
        header: translations.translate(WeekDay[day] ?? ""),
    })), []);

    const hoursItems = useMemo(() => {
        const items = getHoursItems();
        return Object.keys(items).map(hour => ({
            key: hour,
            value: items[hour]!,
            header: hour
        }))
    }, []);

    const nextDayHoursItems = useMemo(() => {
        const items = getHoursItems();
        return Object.keys(items).map(hour => ({
            key: hour,
            value: items[hour]!,
            header: hour + " (J+1)"
        }))
    }, []);

    const canAddTimeSlot = useCallback((range: OpenHoursRange) => {
        if (range.timeSlots.length === 0) return true;
        if (range.timeSlots.length > 3) return false;
        const lastTimeSlot = range.timeSlots[range.timeSlots.length - 1];
        if (!lastTimeSlot) return false;
        const startHour = lastTimeSlot.hours[0];
        const endHour = lastTimeSlot.hours[1];
        if (startHour === undefined || endHour === undefined) return false;
        const startHourIndex = hoursItems.findIndex(i => i.value === startHour);
        return endHour > startHour && startHourIndex < hoursItems.length - 1;
    }, []);

    const getFilteredTimeSlotItems = useCallback((range: OpenHoursRange, timeSlot: OpenHoursRange["timeSlots"][0]) => {
        const timeSlotIndex = range.timeSlots.findIndex(t => t.id === timeSlot.id);
        let prevTimeSlotEndHour = range.timeSlots[timeSlotIndex - 1]?.hours[1];

        let startHourItems = [...hoursItems];
        if (prevTimeSlotEndHour !== undefined)
            startHourItems = startHourItems.filter(i => i.value > prevTimeSlotEndHour!);

        let endHourItems = [...hoursItems];
        if (prevTimeSlotEndHour) {
            const hourIndex = hoursItems.findIndex(i => i.value === prevTimeSlotEndHour);
            prevTimeSlotEndHour = hoursItems[hourIndex + 1]?.value;
            if (prevTimeSlotEndHour !== undefined)
                endHourItems = endHourItems.filter(i => i.value > prevTimeSlotEndHour!);
        }
        if (timeSlot.hours[0] !== undefined) {
            endHourItems = endHourItems.filter(i => i.value > timeSlot.hours[0]!);
            const nextDayHours = nextDayHoursItems.filter(i => i.value < timeSlot.hours[0]!);
            endHourItems.push(...nextDayHours);
        }

        return {startHourItems, endHourItems}
    }, []);

    const handleToggleRangeOpenState = useCallback((rangeId: string) => () => {
        dispatch({type: "toggleRangeOpenState", rangeId});
    }, []);

    const handleChangeRangeSelectedDays = useCallback((range: OpenHoursRange) => async (event: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => {
        const items = data?.value as Array<DropdownItemProps & { value: WeekDay }>;
        const selectedDays = items.map(item => item.value);
        dispatch({type: "setRangeSelectedDays", rangeId: range.id, selectedDays: selectedDays});
    }, []);

    const handleAddTimeSlot = useCallback((rangeId: string) => () => {
        dispatch({type: "addTimeSlot", rangeId});
    }, []);

    const handleRemoveTimeSlot = useCallback((rangeId: string, timeSlotId: string) => () => {
        dispatch({type: "removeTimeSlot", rangeId, timeSlotId});
    }, []);

    const handleChangeTimeSlotHour = useCallback((rangeId: string, timeSlotId: string, isStart: boolean) => (event: React.MouseEvent | React.KeyboardEvent | null, data: DropdownProps) => {
        const hourItem = data?.value as typeof hoursItems[0] | undefined;
        if (!hourItem) return;
        const timeSlot = state.ranges.find(r => r.id === rangeId)?.timeSlots.find(t => t.id === timeSlotId);
        if (!timeSlot) return;
        const hourItemIndex = hoursItems.findIndex(i => i.key === hourItem.key);
        if (isStart) {
            if (timeSlot.hours[1] && timeSlot.hours[1] < hourItem.value) {
                const nextHour = hoursItems[hourItemIndex + 1]?.value;
                if (nextHour) dispatch({
                    type: "setTimeSlotHour",
                    rangeId,
                    timeSlotId,
                    isStart: false,
                    hour: nextHour
                }, false);
            }
        }
        dispatch({type: "setTimeSlotHour", rangeId, timeSlotId, isStart, hour: hourItem.value});
    }, []);

    return {
        ...state,
        handleToggleRangeOpenState,
        weekDaysItems,
        isOnMobile,
        handleChangeRangeSelectedDays,
        handleAddTimeSlot,
        handleRemoveTimeSlot,
        hoursItems,
        handleChangeTimeSlotHour,
        getFilteredTimeSlotItems,
        canAddTimeSlot,
        disabled: props.disabled ?? false,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

export const getHoursItems = () => {
    const items: Record<string, number> = {};
    Array.from({length: 24}).forEach((_, index) => {
        items[moment({hour: index}).format('LT')] = index;
        items[moment({hour: index, minute: 30}).format('LT')] = index + 0.5;
    });
    return items;
}