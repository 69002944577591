import {ThemeInput} from "@fluentui/react-northstar";

export const brandColors = {
    50: '#e8ebfa',
    100: "#c3ddff",
    200: "#90c1ff",
    300: "#6aa7f5",
    400: "#478ae1",
    450: "#2b75d0",
    500: "#1f6fd0",
    600: "#0a66d5",
    700: "#095cc0",
    800: "#074da1",
    900: "#063f83",
    1000: "#053269"
}

export const ThemeCustomizations: ThemeInput = {
    animations: {
        fadeinDialog: {
            keyframe: {
                '0%': {
                    opacity: 0,
                    transform: "scale(0.95)"
                },
                '100%': {
                    opacity: 1,
                    transform: "scale(1)"
                },
            },
            duration: '200ms',
        },
        fadeoutDialog: {
            keyframe: {
                '0%': {
                    opacity: 1,
                    transform: "scale(1)"
                },
                '100%': {
                    opacity: 0,
                    transform: "scale(0.95)"
                },
            },
            duration: '200ms',
        },
        fadein: {
            keyframe: {
                '0%': {
                    opacity: 0,
                },
                '100%': {
                    opacity: 1,
                },
            },
            duration: '200ms',
        },
        fadeout: {
            keyframe: {
                '0%': {
                    opacity: 1,
                },
                '100%': {
                    opacity: 0,
                },
            },
            duration: '200ms',
        },
    },
    siteVariables: {
        colors: {brand: brandColors},
        colorScheme: {
            brand: {
                foreground: brandColors[600],
                foreground1: brandColors[500],
                foreground2: brandColors[700],
                foreground3: brandColors[200],
                background: brandColors[600],
                background1: brandColors[100],
                background2: brandColors[900],
                background3: brandColors[1000],
                background4: brandColors[800],
                background5: brandColors[100],
                background6: brandColors[600],
                border1: brandColors[200],
                border2: brandColors[300],
                foregroundHover: brandColors[600],
                foregroundHover2: brandColors[200],
                borderHover: brandColors[300],
                backgroundHover: brandColors[700],
                backgroundHover1: brandColors[50],
                backgroundHover2: brandColors[100],
                backgroundHover3: brandColors[100],
                foregroundPressed: brandColors[800],
                backgroundPressed: brandColors[800],
                backgroundPressed1: brandColors[100],
                backgroundPressed2: brandColors[100],
                borderPressed: brandColors[300],
                foregroundActive: brandColors[600],
                foregroundActive1: brandColors[600],
                foregroundActive2: brandColors[200],
                backgroundActive: brandColors[600],
                backgroundActive1: brandColors[600],
                borderActive1: brandColors[200],
                borderActive2: brandColors[300],
                foregroundFocus: brandColors[600],
                foregroundFocus1: brandColors[600],
                foregroundFocus2: brandColors[700],
                foregroundFocus3: brandColors[200],
                backgroundFocus: brandColors[600],
                backgroundFocus1: brandColors[100],
                backgroundFocus2: brandColors[900],
                backgroundFocus3: brandColors[1000],
                borderFocus1: brandColors[600],
            },
        }
    }
}