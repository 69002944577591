import {EventsDispatchMessage, EventsDispatchMessageType, IEventsState} from "./Events.interfaces";

export const initialState: IEventsState = {
    searchFilter: "",
    swiperRef: null,
    selectedEventId: "",
    disableNextButton: false,
    disablePrevButton: true,
    isLoading: true,
}

export const reducer = (prevState: IEventsState, payload: EventsDispatchMessage): IEventsState => {
    const newState: IEventsState = {...prevState};
    switch (payload.type) {
        case EventsDispatchMessageType.UpdateSearchFilter:
            newState.searchFilter = payload.filter;
            return newState;
        case EventsDispatchMessageType.SetSwiperRef:
            newState.swiperRef = payload.ref;
            return newState;
        case EventsDispatchMessageType.SetSelectedEventId:
            newState.selectedEventId = payload.id;
            return newState;
        case EventsDispatchMessageType.SetNextButtonVisibility:
            newState.disableNextButton = !payload.value;
            return newState;
        case EventsDispatchMessageType.SetPrevButtonVisibility:
            newState.disablePrevButton = !payload.value;
            return newState;
        case EventsDispatchMessageType.SetIsLoading:
            newState.isLoading = payload.value;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}