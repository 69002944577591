import {
    FilterMobileDialogDispatchMessage,
    FilterMobileDialogDispatchMessageType,
    IFilterMobileDialogState
} from "./FilterMobileDialog.interfaces";

export const initialState: IFilterMobileDialogState = {
    openMenuIds: new Array<string>(),
}

export const reducer = (prevState: IFilterMobileDialogState, payload: FilterMobileDialogDispatchMessage): IFilterMobileDialogState => {
    const newState: IFilterMobileDialogState = {...prevState};
    const payloadType = payload.type;
    switch (payloadType) {
        case FilterMobileDialogDispatchMessageType.ToggleMenu:
            newState.openMenuIds = [...newState.openMenuIds];
            if (newState.openMenuIds.includes(payload.menuId))
                newState.openMenuIds = newState.openMenuIds.filter(id => id !== payload.menuId);
            else
                newState.openMenuIds.push(payload.menuId);
            return newState;
        case FilterMobileDialogDispatchMessageType.ClearOpenMenus:
            newState.openMenuIds = new Array<string>();
            return newState;
        default:
            throw new Error("Message " + FilterMobileDialogDispatchMessageType[payloadType] + " not implemented");
    }
}