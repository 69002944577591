import React, {useMemo} from "react";
import {LocationPreview} from "components";
import {MedalIcon} from "assets/icons";
import {useRecommendations} from "./Recommendations.hook";
import {IRecommendationsProps} from "./Recommendations.interfaces";
import {Carousel} from "../Carousel/Carousel";
import {Animation, Flex} from "@fluentui/react-northstar";

export const Recommendations = (props: IRecommendationsProps) => {
    const logic = useRecommendations(props);

    return useMemo(() => logic.locations.length === 0 ? null : (
        <Flex fill className={"w-100"}>
            <Carousel
                items={logic.locations.map(l => (
                    <Animation name={'fadein'} key={"location-" + l.id + logic.recommendedLocationsIds}>
                        {({classes}) => (
                            <Flex fill className={classes} styles={{paddingBottom: "7px"}}>
                                <LocationPreview
                                    isRecommendation={true}
                                    showDetails={props.showLocationDetails}
                                    key={"location-" + l.id}
                                    location={l}
                                />
                            </Flex>
                        )}
                    </Animation>
                ))}
                labelId={"Recommended"}
                icon={<MedalIcon width={20} height={20} fill={"#FFBB36"}/>}
                slidesPerView={logic.slidesPerView}
                pushMoveButtons
                collapsible
            />
        </Flex>
    ), [logic.recommendedLocationsIds, logic.slidesPerView])
}