import * as React from "react";
import {memo, useCallback, useEffect, useMemo, useRef} from "react";
import {Logic, LogicProps, Props} from "./PopupMenuButton.interfaces";
import {useReducer} from "./PopupMenuButton.reducer";
import {ComponentEventHandler, PopupProps} from "@fluentui/react-northstar";
import {MenuItemProps} from "@fluentui/react-northstar/dist/es/components/Menu/MenuItem";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return (
        prevProps.menu === nextProps.menu &&
        prevProps.isTouchScreen === nextProps.isTouchScreen &&
        prevProps.trigger === nextProps.trigger
    );
}

export const PopupMenuButtonLogic = memo((props: LogicProps): JSX.Element => {
    const logic: Logic = useLogic(props);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (props: LogicProps) => {
    const {state, dispatch} = useReducer();
    const triggerRef = useRef<HTMLDivElement | null>(null);

    useEffect(function onMount() {
        dispatch({type: "setMounted", value: true}, false);
        return () => {
            dispatch({type: "setMounted", value: false}, false);
        }
    }, []);

    useEffect(function handleCloseOnOutsideClick() {
        if (!state.open) return;
        document.body.addEventListener("click", close);
        document.body.addEventListener("touchend", close);
        document.body.addEventListener("touchmove", close);
        return () => {
            document.body.removeEventListener("click", close);
            document.body.removeEventListener("touchend", close);
            document.body.removeEventListener("touchmove", close);
        }
    }, [state.open]);

    const close = useCallback(() => {
        setTimeout(() => {
            if (!state.canClose) return;
            dispatch([{type: "close"}]);
        }, props.isTouchScreen ? 100 : 0);
    }, [props.isTouchScreen]);

    const handleOpen = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();
        dispatch([{type: "open"}]);
    }, []);

    const handleClose = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.SyntheticEvent) => {
        e?.stopPropagation();
        close();
    }, []);

    const handleToggleOpen = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch([{type: "toggleOpen"}]);
    }, []);

    const handleMenuOpenChange: ComponentEventHandler<PopupProps> = useCallback((e, data) => {
        if (e.type === "mouseenter") {
            if (state.closeTimeout !== undefined) clearTimeout(state.closeTimeout);
            dispatch({type: "setCloseTimeout", value: undefined}, false);
            return;
        }
        if (e.type !== "mouseleave") return;
        const triggerRect = triggerRef.current?.getBoundingClientRect();
        if (!triggerRect) return;
        const mouseEvent = e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>;
        const x = mouseEvent.clientX;
        const y = mouseEvent.clientY;
        const isCursorInTrigger = x >= triggerRect.left && x <= triggerRect.right && y >= triggerRect.top && y <= triggerRect.bottom;
        if (data?.open || isCursorInTrigger) return;
        handleClose(mouseEvent);
    }, []);

    const handleLeaveTrigger = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const timeoutDelayMs = 100;
        if (!e.relatedTarget || !("className" in e.relatedTarget) || !(e.relatedTarget as any).className)
            return dispatch({type: "setCloseTimeout", value: setTimeout(() => handleClose(e), timeoutDelayMs)}, false);
        const classList = (e.relatedTarget as any)?.className as string ?? "";
        if (classList.includes("ui-popup__content__content") || classList.includes("ui-menu")) return;
        return dispatch({type: "setCloseTimeout", value: setTimeout(() => handleClose(e), timeoutDelayMs)}, false);
    }, []);

    const blockPopupClosing = useCallback(() => {
        dispatch({type: "setCanClose", value: false}, false);
        setTimeout(() => dispatch({type: "setCanClose", value: true}, false), 100);
    }, []);

    const formatMenu = useCallback((menu: Props["menu"]): Props["menu"] => {
        const overrideMenu: Props["menu"] = Array.isArray(menu) ? [...menu] : [menu];
        overrideMenu.forEach((item: MenuItemProps) => {
            if (item.menu) {
                item.onClick = blockPopupClosing;
                item.menu = formatMenu(item.menu);
            } else {
                const onClick = item.onClick?.bind({});
                item.onClick = (e) => {
                    handleClose(e);
                    onClick?.(e);
                }
            }
            if (item.menu && !props.isTouchScreen) item.on = "hover";
        });
        return overrideMenu;
    }, []);

    const fillTrigger = "fill" in props.trigger && props.trigger.fill;

    const trigger = "fill" in props.trigger ? props.trigger.trigger : props.trigger;

    const menu = useMemo(() => formatMenu(props.menu), [props.menu, props.isTouchScreen]);

    const mouseLeaveDelay = props.mouseLeaveDelay ?? 300;

    const on = props.on ?? (props.isTouchScreen ? "click" : "hover");

    return {
        ...props,
        ...state,
        handleOpen,
        handleClose,
        handleToggleOpen,
        handleMenuOpenChange,
        stopPropagation,
        handleLeaveTrigger,
        triggerRef,
        fillTrigger,
        trigger,
        menu,
        mouseLeaveDelay,
        on,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
}