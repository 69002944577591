import React, {useContext, useMemo} from "react";
import {Divider, Flex, Text} from "@fluentui/react-northstar";
import {IDayColumnProps} from "./DayColumn.interfaces";
import moment from "moment";
import {EventPreview} from "../EventPreview/EventPreview";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";

export const DayColumn = (props: IDayColumnProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    const isOnMobile = teamsContext.isOnMobile;
    const dateLabel = !!props.date ? moment(props.date).format("LL") : "";

    return useMemo(() => (
        <Flex fill>
            {isOnMobile ? null : <Divider styles={{padding: 0}} vertical/>}
            {!props.events ?
                <Flex fill/>
                :
                <Flex fill column gap={"gap.medium"} className={isOnMobile ? "" : "x-padding"}>
                    <Text className={"x-padding"} weight={"bold"} size={"larger"} content={dateLabel}/>
                    <Flex fill className={"pos-relative overflow-scroll"}>
                        <Flex fill column className={"fill-absolute"}>
                            {props.events.map(event => (
                                <Flex key={"event-" + event.id} styles={{paddingBottom: "20px"}}>
                                    <EventPreview
                                        onShow={!!props.onShowEvent ? props.onShowEvent(event.id) : () => 0}
                                        key={"event-preview-" + event.id}
                                        event={event}
                                    />
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    ), [props.date, props.index, props.events])
}