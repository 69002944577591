import {IBrand} from "interfaces/Brand/IBrand";
import {Brand} from "interfaces/Brand/Brand";
import {BrandIcons, BrandPaths} from "assets/brands";

const availableBrands = Object.keys(Brand)
    .filter((key: string | number) => !isNaN(Number(key)))
    .map(key => Number(key) as Brand);

export const Brands: Record<Brand, IBrand> = {} as Record<Brand, IBrand>;

availableBrands.map(brand => {
    Brands[brand] = {
        id: brand,
        formattedName: (Brand[brand] ?? "").replace(/__/g, "'").replace(/_/g, " "),
        stringId: (Brand[brand] ?? "").replace(/[^0-9a-z]/gi, '').toLowerCase(),
        iconPath: BrandPaths[brand],
        element: BrandIcons[brand]
    }
});

