import {memo, useEffect} from "react";
import {Logic, LogicProps, ReducerData} from "./Dashboard.interfaces";
import {useReducer} from "./Dashboard.reducer";
import {IFilterItem} from "../../components/Filter/Filter.interfaces";
import {LocationModule} from "../../modules";
import {KpiRequest} from "../../interfaces/KpiRequest";
import {KpiApi} from "../../apis/Kpi/kpiApi";
import {UserRole} from "../../interfaces/UserRole";
import {ILocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {BuildingsApi} from "../../apis/Buildings/BuildingsApi";
import moment from "moment";

const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const DashboardLogic = memo((props: LogicProps): JSX.Element => {
    const reducerData: ReducerData = useReducer(props);
    const logic: Logic = useLogic(reducerData);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (reducerData: ReducerData) => {
    const {state} = reducerData;

    const userRole = reducerData.propsRef.current.userContext.currentUser?.role;

    useEffect(function onMount() {
        fetchKpisDependingOfUserRole(reducerData).then();
    }, []);

    return {
        ...state,
        handleSearchChange: handleSearchChange(reducerData),
        handleFiltersChange: handleFiltersChange(reducerData),
        handleDateRangeChange: handleDateRangeChange(reducerData),
        handleSelectBuilding: handleSelectBuilding(reducerData),
        handleSelectSuggestion: handleSelectSuggestion(reducerData),
        handleFetchSuggestions: handleFetchSuggestions(reducerData),
        handleHideBuildingDetails: handleHideBuildingDetails(reducerData),
        userRole,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSearchChange = (reducerData: ReducerData) => async (filter: string) => {
    reducerData.dispatch({type: "setSearchFilter", value: filter}, false);
}

const handleFiltersChange = (reducerData: ReducerData) => async (items: Array<IFilterItem>) => {
    reducerData.dispatch({type: "setFilterItems", value: items});
    await fetchKpisDependingOfUserRole(reducerData);
}

const handleDateRangeChange = (reducerData: ReducerData) => async (start: string, end: string) => {
    reducerData.dispatch({type: "setDateRange", value: {start, end}});
    await fetchKpisDependingOfUserRole(reducerData);
}

const handleSelectBuilding = (reducerData: ReducerData) => (id: string, name: string) => {
    reducerData.dispatch({type: "setSelectedBuilding", value: {id, name}});
}

const handleSelectSuggestion = (reducerData: ReducerData) => (sug: ILocationSuggestion) => {
    if (sug.location === undefined) return;
    reducerData.dispatch({type: "setSelectedBuilding", value: {id: sug.id, name: sug.name}});
}

const handleHideBuildingDetails = (reducerData: ReducerData) => () => {
    reducerData.dispatch({type: "setSelectedBuilding", value: undefined});
}

const handleFetchSuggestions = (reducerData: ReducerData) => async (filter: string) => {
    const isValidFilter = filter.length > 3;
    const suggestions: Array<ILocationSuggestion> = !isValidFilter ? [] : await BuildingsApi.getSuggestions({
        searchFilter: filter,
        language: reducerData.propsRef.current.teamsContext.locale,
        buildingIds: null,
        includeHiddenBuildings: true,
    });
    return suggestions.filter(s => s.location !== null);
}

const fetchKpisDependingOfUserRole = async (reducerData: ReducerData) => {
    const userRole = reducerData.propsRef.current.userContext.currentUser?.role;
    if (userRole === undefined) return;
    switch (userRole) {
        case UserRole.SuperAdmin:
            await fetchSuperAdminKpis(reducerData).then();
            break;
        case UserRole.SectorManager:
            await fetchSectorManagerKpis(reducerData).then();
            break;
        default:
            break;
    }
}

const fetchSectorManagerKpis = async (reducerData: ReducerData) => {
    const userPosition = reducerData.propsRef.current.userContext.currentUser?.geoLocation;
    if (!userPosition) return;
    reducerData.dispatch({type: "setIsFetching", value: true});
    const filters = LocationModule.getInRangeFilters(reducerData.state.filterItems);
    const request: KpiRequest = {
        inRangeRequest: {userPosition, skip: 0, take: 9999, filters},
        start: reducerData.state.dateRange.start,
        end: reducerData.state.dateRange.end,
    };
    const kpis = await KpiApi.getSectorManagerKpis(request);
    if (!kpis) return;

    reducerData.dispatch([
        {type: "setSectorManagerKpis", value: kpis},
        {type: "setIsFetching", value: false}
    ]);
}

const fetchSuperAdminKpis = async (reducerData: ReducerData) => {
    const userPosition = reducerData.propsRef.current.userContext.currentUser?.geoLocation;
    if (!userPosition) return;
    reducerData.dispatch({type: "setIsFetching", value: true});
    const filters = LocationModule.getInRangeFilters(reducerData.state.filterItems);
    const request: KpiRequest = {
        inRangeRequest: {userPosition, skip: 0, take: 9999, filters},
        start: reducerData.state.dateRange.start,
        end: reducerData.state.dateRange.end,
    };
    const kpis = await KpiApi.getSuperAdminKpis(request);
    if (!kpis) return;
    kpis.topKeywords.sort((a, b) => b.count - a.count);
    kpis.topKeywords = kpis.topKeywords.slice(0, 5);
    kpis.connections.sort((a, b) => moment(a.key).valueOf() - moment(b.key).valueOf());
    kpis.topBuildingClicks = kpis.topBuildingClicks.map(b => ({
        ...b,
        name: b.name.length <= 20 ? b.name : b.name.slice(0, 18) + "..."
    }));
    kpis.topBuildingInFavorites = kpis.topBuildingInFavorites.map(b => ({
        ...b,
        name: b.name.length <= 20 ? b.name : b.name.slice(0, 18) + "..."
    }));
    kpis.topRecommendedBuildingClicks = kpis.topRecommendedBuildingClicks.map(b => ({
        ...b,
        name: b.name.length <= 20 ? b.name : b.name.slice(0, 18) + "..."
    }));
    reducerData.dispatch([
        {type: "setSuperAdminKpis", value: kpis},
        {type: "setIsFetching", value: false}
    ]);
}