import React, {useContext, useMemo} from "react";
import {Button, ChevronEndIcon, ChevronStartIcon, Flex, SearchIcon, Text} from "@fluentui/react-northstar";
import {IToolbarProps} from "./Toolbar.interfaces";
import {useToolbar} from "./Toolbar.hook";
import {LocationViewType} from "interfaces";
import {ListIcon, MapIcon} from "assets/icons";
import {Filter} from "components/Filter/Filter";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ITeamsContext} from "../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../services/TeamsContext";
import SuggestionsDropdown from "../SuggestionsDropdown/SuggestionsDropdown";
import languageObject from "../../translations";
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector";

export const Toolbar = (props: IToolbarProps) => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);
    const translate = useTranslation();
    const logic = useToolbar(props);

    const isOnMobile = teamsContext.isOnMobile;

    const searchInput = (() => {
        if (!logic.showSearchBar) return null;
        const invertColor = !isOnMobile || (isOnMobile && !teamsContext.isLightTheme);
        return (
            <Flex fill styles={{maxWidth: isOnMobile ? "100%" : "400px"}}>
                <SuggestionsDropdown
                    placeholder={logic.searchBarPlaceholder}
                    onSelectSuggestion={logic.handleSelectSuggestion}
                    onSearchChange={logic.handleSearchChange}
                    fetchSuggestions={logic.fetchSuggestions}
                    inverted={invertColor}
                    rightIcon={<SearchIcon outline/>}
                />
            </Flex>
        )
    })();

    const locationViewToggle = useMemo(() => {
        if (!logic.showLocationViewButton) return null;
        const isListView = logic.locationView === LocationViewType.List;
        const icon = isListView ? <MapIcon className={"icon-btn"}/> : <ListIcon className={"icon-btn"}/>;
        const labelId = isListView ? "ShowMapView" : "ShowListView";
        return (
            <Flex gap={"gap.small"} styles={{marginLeft: isOnMobile ? "-4px" : "-10px"}}>
                {isOnMobile ?
                    <Button disabled={logic.disableLocationViewButton} text icon={icon} iconOnly
                            onClick={logic.handleToggleLocationView}/>
                    :
                    <Button disabled={logic.disableLocationViewButton} text icon={icon} content={translate(labelId)}
                            onClick={logic.handleToggleLocationView}/>
                }
            </Flex>
        )
    }, [logic.showLocationViewButton, logic.locationView, logic.disableLocationViewButton]);

    const filter = !logic.showFilterButton ? null : (
        <Filter
            items={logic.filterItems ?? []}
            onUpdateItems={logic.handleUpdateFilterItems}
        />
    )

    const moveButtons = useMemo(() => {
        if (!logic.showMoveButtons) return null;
        return (
            <Flex gap={"gap.smaller"} vAlign={"center"}>
                <Button text iconOnly icon={<ChevronStartIcon/>} onClick={logic.handleMoveBackward}
                        disabled={logic.disablePrevButton}/>
                <Button text iconOnly icon={<ChevronEndIcon/>} onClick={logic.handleMoveForward}
                        disabled={logic.disableNextButton}/>
            </Flex>
        )
    }, [logic.showMoveButtons, logic.disableNextButton, logic.disablePrevButton]);

    const totalCount = useMemo(() => logic.totalCount === undefined ? null : (
        <Flex className={"h-100"} vAlign={"center"}>
            <Text styles={{whiteSpace: "nowrap"}} content={
                `${logic.totalCount} ${languageObject.translate(logic.totalCount <= 1 ? "Building" : "Buildings").toLowerCase()}`
            }/>
        </Flex>
    ), [logic.totalCount]);

    const dateRangeSelector = useMemo(() => {
        if (!logic.handleDateRangeChange) return null;
        return (
            <DateRangeSelector
                dateRange={logic.dateRange}
                onDateRangeChange={logic.handleDateRangeChange}
            />
        )
    }, [logic.dateRange]);

    const desktopView = (
        <Flex styles={{width: "100%"}} gap={"gap.small"} {...(logic.align && {hAlign: logic.align})}>
            {locationViewToggle}
            {logic.showLocationViewButton ? <Flex fill/> : null}
            {totalCount}
            {dateRangeSelector}
            {filter}
            {searchInput}
            {logic.showMoveButtons ? <Flex fill/> : null}
            {moveButtons}
        </Flex>
    )

    const mobileView = (
        <Flex styles={{width: "100%"}} gap={"gap.small"} {...(logic.align && {hAlign: logic.align})}>
            {locationViewToggle}
            {searchInput}
            {filter}
            {logic.showMoveButtons ? <Flex fill/> : null}
            {moveButtons}
        </Flex>
    )

    return isOnMobile ? mobileView : desktopView;
}