import ReactDOM from 'react-dom';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import App from "./app/App";
import {AppContext} from "./services/AppContext";
import {BrowserRouter} from "react-router-dom";

const rootElement = document.getElementById('root');

const jsxElement = (
    <AppContext>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </AppContext>
)

ReactDOM.render(jsxElement, rootElement);