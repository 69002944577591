import {instance} from "apis/AxiosConfig/AxiosConfig";
import {IReview} from "../../interfaces/IReview";
import {IRawLocation} from "../../interfaces/Location/IRawLocation";
import {ILocationSuggestion, IRawLocationSuggestion} from "../../interfaces/ILocationSuggestion";
import {ILocationInternalUpdateData} from "../../services/LocationContext/LocationContext.interfaces";
import axios, {CancelTokenSource} from "axios";
import {RawInRangeRequest} from "../../interfaces/RawInRangeRequest";

const importData = (data: ArrayBuffer | null): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
        if (!data) return resolve(false);
        return instance.post("/Elitebuildings/import", data).then(() => {
            return resolve(true);
        }).catch(_ => {
            console.error("Can't import buildings data");
            return resolve(false);
        });
    });
}

const importGoogleData = (data: ArrayBuffer | null): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
        if (!data) return resolve(false);
        return instance.post("/Advancedbuildings/import", data).then(() => {
            return resolve(true);
        }).catch(_ => {
            console.error("Can't import google buildings data");
            return resolve(false);
        });
    });
}

const processFetchRequest = (url: string, data: RawInRangeRequest, handleCancelToken?: (token: CancelTokenSource) => void) => {
    return new Promise<{ count: number, buildings: Array<IRawLocation>, cancelled?: boolean } | null>(resolve => {
        const cancelToken = axios.CancelToken.source();
        if (handleCancelToken) handleCancelToken(cancelToken);
        return instance.post(url, data, {cancelToken: cancelToken.token}).then(resp => {
            return resolve(resp.data);
        }).catch(e => {
            if (axios.isCancel(e)) return resolve({count: 0, buildings: [], cancelled: true});
            console.error("Can't fetch locations from endpoint " + url);
            return resolve(null);
        });
    });
}

const getFromGeoPosition = (data: RawInRangeRequest, handleCancelToken?: (token: CancelTokenSource) => void) => {
    return processFetchRequest("/buildings/user/inrange", data, handleCancelToken);
}

const getUserFavorites = (data: RawInRangeRequest, handleCancelToken?: (token: CancelTokenSource) => void) => {
    return processFetchRequest("/buildings/user/favorites", data, handleCancelToken);
}

const getUserBuildings = (data: RawInRangeRequest, handleCancelToken?: (token: CancelTokenSource) => void) => {
    return processFetchRequest("/buildings/user/buildings", data, handleCancelToken);
}

const getIncompleteBuildings = (data: RawInRangeRequest, handleCancelToken?: (token: CancelTokenSource) => void) => {
    return processFetchRequest("/buildings/user/incomplete", data, handleCancelToken);
}

const publishReview = (review: IReview) => {
    return new Promise<IReview>(resolve => {
        return instance.post(`/BuildingReview`, review).then(resp => {
            return resolve(resp.data);
        }).catch(_ => {
            throw new Error("Can't publish review for location with id : " + review.buildingId);
        });
    });
}

const getReviews = (locationId: string): Promise<Array<IReview>> => {
    return new Promise<Array<IReview>>(resolve => {
        return instance.get(`/buildings/${locationId}/reviews`).then(resp => {
            const reviews: Array<IReview> = resp.data ?? [];
            reviews.forEach(review => review.picture = `https://${process.env.REACT_APP_BACK_URL}${review.picture}`);
            return resolve(reviews);
        }).catch(_ => {
            console.error("Can't get reviews of location : " + locationId);
            return resolve(new Array<IReview>());
        });
    });
}

const updateCustomFields = (buildingId: string, data: {
    recommendNote: string;
    notes: string;
    isRecommended: boolean;
    flagship: string | undefined;
    sectorManagerReview: string;
    pictures: Array<string>;
    isShow: boolean
}) => {
    return new Promise<void>((resolve, reject) => {
        return instance.put(`/buildings/${buildingId}/partial`, data).then(() => {
            return resolve();
        }).catch(_ => {
            return reject("Can't update partial building");
        });
    });
}

const updateInternalFields = (buildingId: string, data: ILocationInternalUpdateData) => {
    return new Promise<void>((resolve, reject) => {
        return instance.put(`/buildings/${buildingId}/InternalData`, data).then(() => {
            return resolve();
        }).catch(_ => {
            return reject("Can't update partial building");
        });
    });
}

const getSuggestions = (params: {
    searchFilter: string, language: string, buildingIds: Array<string> | null, includeHiddenBuildings: boolean
}, onlyFromGoogle?: boolean): Promise<Array<ILocationSuggestion>> => {
    return new Promise<Array<ILocationSuggestion>>(resolve => {
        return instance.post(`/buildings/search${onlyFromGoogle ? "Google" : ""}`, params).then(resp => {
            const rawItems: Array<IRawLocationSuggestion> = resp.data;
            const items = rawItems.map(formatRawLocationSuggestion).filter(i => i) as Array<ILocationSuggestion>;
            return resolve(items);
        }).catch(err => {
            console.error("Can't get suggestions");
            console.error(err)
            return resolve(new Array<ILocationSuggestion>());
        });
    });
}

const formatRawLocationSuggestion = (rawSuggestion: IRawLocationSuggestion): ILocationSuggestion | null => {
    if (!rawSuggestion.geoPosition) return null;
    return {
        id: rawSuggestion.id,
        name: rawSuggestion.name,
        location: rawSuggestion.location ?? null,
        geoPosition: !rawSuggestion.geoPosition ? null : {
            lat: rawSuggestion.geoPosition?.latitude,
            lng: rawSuggestion.geoPosition?.longitude
        },
        viewport: rawSuggestion.viewport ?? null,
        fullAddress: rawSuggestion.fullAddress,
    };
}

const canImportingBuildings = () => {
    return new Promise<boolean>(resolve => {
        return instance.get(`/import/isready`).then(resp => {
            return resolve(resp.data);
        }).catch(_ => {
            console.error("Can't check if can import buildings");
            return resolve(true);
        });
    });
}

const getLastEliteBuildingsUpdateDate = () => {
    return new Promise<string>(resolve => {
        return instance.get(`/EliteBuildings/lastdate`).then(resp => {
            const date = (resp.data ?? "") + "";
            if (date.startsWith("0")) return resolve("");
            return resolve(date);
        }).catch(_ => {
            console.error("Can't get last import buildings date");
            return resolve("");
        });
    });
}

export const BuildingsApi = {
    importData,
    importGoogleData,
    getFromGeoPosition,
    getReviews,
    publishReview,
    updateCustomFields,
    getSuggestions,
    getUserFavorites,
    getUserBuildings,
    canImportingBuildings,
    getLastEliteBuildingsUpdateDate,
    updateInternalFields,
    getIncompleteBuildings
}