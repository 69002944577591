import {IUserPinProps} from "./UserPin.interfaces";
import {Avatar, Flex} from "@fluentui/react-northstar";
import * as React from "react";
import "./UserPin.styles.scss"

export const UserPin = (props: IUserPinProps) => {
    return (
        <Flex vAlign={"center"} hAlign={"center"} className={"user-pin no-select"}>
            <div className={"radar-animation"}/>
            <div className={"img-layout"}/>
            <Avatar
                onClick={props.onClick}
                className={"cursor-pointer"}
                size={"large"}
                image={props.user?.picture}
                name={props.user?.displayName ?? ""}
            />
        </Flex>
    )
}