import {BellIcon, Button, Flex, Loader, Text, Tooltip} from "@fluentui/react-northstar";
import * as React from "react";
import {memo, useMemo} from "react";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ActivityCard} from "../ActivityCard/ActivityCard";
import {useActivityModal} from "./ActivityModal.hook";

export const ActivityModal = memo(() => {
    const logic = useActivityModal();
    const translate = useTranslation();

    const activityCards = useMemo(() => {
        if (!logic.loaded) return (
            <Loader className={"w-100"} key={"activity-loader"} styles={{height: "100px"}}/>
        )

        const noActivityContent = (
            <Flex fill hAlign={"center"} vAlign={"center"} key={"no-activity"}>
                <Text styles={{color: "darkgray"}} content={translate("NoActivity")}/>
            </Flex>
        );

        if (logic.activities.length === 0) return noActivityContent;

        const activities = logic.activities.map(activity => (
            <ActivityCard key={"activity-" + activity.id} activity={activity}/>
        ));

        if (activities.every(a => a === null)) return noActivityContent;

        return (
            <Flex key={"activity-cards"} fill column gap={"gap.medium"} className={"activities-container"}>
                {activities}
            </Flex>
        )
    }, [logic.loaded]);

    const activityButton = useMemo(() => {
        const showNewBadge = logic.hasNewActivity;
        return (
            <Flex>
                <Button
                    styles={{marginRight: "-15px", padding: "15px"}}
                    icon={
                        <Flex className={"pos-relative"}>
                            <BellIcon size={"large"} outline/>
                            {showNewBadge ? <div className={"new-activity-badge"}/> : null}
                        </Flex>
                    }
                    iconOnly
                    text
                    onMouseEnter={logic.handleMouseEnterButton}
                />
            </Flex>
        )
    }, [logic.hasNewActivity]);

    const content = useMemo(() => (
        <Flex fill column gap={"gap.medium"} styles={{padding: "8px 3px"}}>
            <Flex>
                <Text weight={"semibold"} size={"large"} content={translate("RecentActivity")}/>
            </Flex>
            <Flex styles={{
                maxHeight: "calc(100vh - 200px)",
                maxWidth: "calc(100vw - 200px)",
            }} className={"overflow-scroll"}>
                {activityCards}
            </Flex>
        </Flex>
    ), [activityCards]);

    return (
        <Tooltip
            offset={[0, 0]}
            position={"before"}
            align={"top"}
            trigger={activityButton}
            content={content}
        />
    )
}, () => true)
