import React, {memo, useMemo, useRef} from "react";
import {DateRangeType, Logic, LogicProps, Props, ReducerData, State} from "./DateRangeSelector.interfaces";
import {useReducer} from "./DateRangeSelector.reducer";
import moment from "moment";
import {DatepickerProps, PopperRefHandle} from "@fluentui/react-northstar";
import {WeekDaysModule} from "../../modules/WeekDays.module";


const keepSameLogic = (prevProps: Readonly<LogicProps>, nextProps: Readonly<LogicProps>): boolean => {
    return false;
}

export const DateRangeSelectorLogic = memo((props: LogicProps): JSX.Element => {
    const reducerData: ReducerData = useReducer(props);
    const logic: Logic = useLogic(reducerData);
    return props.children(logic);
}, keepSameLogic);

export const useLogic = (reducerData: ReducerData) => {
    const {state, propsRef} = reducerData;

    const locale = propsRef.current.teamsContext.fullLocale;

    const shortDaysNames = useMemo(() => getShortDaysNames(locale), []);
    const monthsNames = useMemo(() => getMonthsNames(locale), []);
    const firstDayOfWeek = useMemo(() => WeekDaysModule.getFirstDayOfWeek() as number, []);
    const datePickerPopperRef = useRef<PopperRefHandle | null>(null);
    const popupContentRef = useRef<HTMLDivElement | null>(null);

    const selectedDate = moment(propsRef.current.dateRange?.start).toDate();
    const selectedEndDate = moment(propsRef.current.dateRange?.end).toDate();

    const methods = useMemo(() => ({
        handleClickTrigger: handleClickTrigger(datePickerPopperRef, popupContentRef),
        handleSwitchMode: handleSwitchMode(datePickerPopperRef)(reducerData),
        handleChangeDate: handleChangeDate(reducerData),
        handleToggleSelectedCustomDate: handleToggleSelectedCustomDate(reducerData),
        handleChangeMonth: handleChangeMonth(reducerData),
    }), []);

    return {
        ...state,
        ...methods,
        reducerData,
        shortDaysNames,
        monthsNames,
        locale,
        datePickerPopperRef,
        popupContentRef,
        selectedDate,
        selectedEndDate,
        firstDayOfWeek,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleClickTrigger = (
    datePickerPopperRef: React.MutableRefObject<PopperRefHandle | null>,
    popupContentRef: React.MutableRefObject<HTMLDivElement | null>,
) => () => {
    setTimeout(() => {
        if (popupContentRef.current) popupContentRef.current!.className += " date-range-selector-picker";
        datePickerPopperRef.current?.updatePosition();
    }, 0);
}

const handleSwitchMode = (
    datePickerPopperRef: React.MutableRefObject<PopperRefHandle | null>,
) => (
    {dispatch, propsRef, state}: ReducerData) => (mode: State["selectionMode"]
) => () => {
    setTimeout(() => datePickerPopperRef.current?.updatePosition(), 0);
    dispatch({type: "setSelectionMode", value: mode});
    const {start, end} = getRangeFromDate({
        date: state.selectedDate,
        selectionMode: state.selectionMode,
        isCustomEndDateSelected: state.isCustomEndDateSelected,
        dateRange: propsRef.current.dateRange,
    })
    propsRef.current.onDateRangeChange(start, end);
}

export const handleChangeDate = (
    {state, dispatch, propsRef}: ReducerData
) => (
    _: React.SyntheticEvent, datePickerData: DatepickerProps | undefined
) => {
    const value = (datePickerData as DatepickerProps & { value: Date })?.value?.toISOString();
    dispatch({type: "setSelectedDate", value}, false);
    const {start, end} = getRangeFromDate({
        date: value,
        selectionMode: state.selectionMode,
        isCustomEndDateSelected: state.isCustomEndDateSelected,
        dateRange: propsRef.current.dateRange,
    })
    propsRef.current.onDateRangeChange(start, end);
}

const getRangeFromDate = (data: {
    date: string,
    selectionMode: State["selectionMode"],
    isCustomEndDateSelected: State["isCustomEndDateSelected"],
    dateRange: Props["dateRange"],
}) => {
    const {date, selectionMode, isCustomEndDateSelected, dateRange} = data;
    let start = "";
    let end = "";
    switch (selectionMode) {
        case DateRangeType.Day:
            start = date;
            end = moment(date).endOf("day").toISOString();
            break;
        case DateRangeType.Week:
            start = moment(date).startOf("week").toISOString();
            end = moment(date).endOf("week").toISOString();
            break;
        case DateRangeType.Month:
            start = moment(date).startOf("month").toISOString();
            end = moment(date).endOf("month").toISOString();
            break;
        case DateRangeType.Custom:
            start = dateRange?.start ?? moment().startOf("day").toISOString(false);
            end = dateRange?.end ?? moment().endOf("day").toISOString(false);
            if (isCustomEndDateSelected) {
                end = date;
                if (end <= start) start = moment(end).add(-1, "day").toISOString(false);
            } else {
                start = date;
                if (start >= end) end = moment(start).add(1, "day").toISOString(false);
            }
            break;
        default:
            break;
    }
    return {start, end};
}

const getShortDaysNames = (locale: string) => {
    const prevLocale = moment.locale();
    moment.locale(locale);
    const shortDaysNames = moment.weekdays(false).map(day => day.slice(0, 3));
    moment.locale(prevLocale);
    return shortDaysNames;
}

const getMonthsNames = (locale: string) => {
    return Array.from(Array(12).keys()).map(key => moment().locale(locale).month(key).format('MMMM'))
}

const handleToggleSelectedCustomDate = (reducerData: ReducerData) => (isEndDate: boolean) => () => {
    const {dispatch} = reducerData;
    dispatch({type: "setIsCustomEndDateSelected", value: isEndDate});
}

const handleChangeMonth = (reducerData: ReducerData) => (action: "next" | "previous") => () => {
    const {state, propsRef, dispatch} = reducerData;
    if (state.selectionMode !== DateRangeType.Month) return;
    const date = moment(state.selectedDate).add(action === "next" ? 1 : -1, "month").toISOString();
    dispatch({type: "setSelectedDate", value: date}, false);
    const {start, end} = getRangeFromDate({
        date,
        selectionMode: state.selectionMode,
        isCustomEndDateSelected: state.isCustomEndDateSelected,
        dateRange: propsRef.current.dateRange,
    })
    propsRef.current.onDateRangeChange(start, end);
}