import {AppView} from "interfaces";
import {ISettingsState, SettingsDispatchMessage, SettingsDispatchMessageType} from "./Settings.interfaces";

export const initialState: ISettingsState = {
    applicationSettings: {
        userAadId: "",
        isSuperAdmin: false,
        isAdmin: false,
        isBrandAdmin: false,
        favoriteBrands: [],
        favoriteBuildings: [],
        homepage: AppView.AroundMe,
        lastCGURead: null,
    },
    showConfirmUpdateModal: false,
    isImporting: true,
    loaded: false,
    hasImportError: false,
    lastImportDate: "",
}

export const reducer = (prevState: ISettingsState, payload: SettingsDispatchMessage): ISettingsState => {
    const newState: ISettingsState = {...prevState};
    switch (payload.type) {
        case SettingsDispatchMessageType.UpdateApplicationFavoriteBrands:
            newState.applicationSettings = {
                ...prevState.applicationSettings,
                favoriteBrands: payload.favoriteBrands,
            };
            return newState;
        case SettingsDispatchMessageType.UpdateApplicationHomePage:
            newState.applicationSettings = {
                ...prevState.applicationSettings,
                homepage: payload.homePage,
            };
            return newState;
        case SettingsDispatchMessageType.SetIsImporting:
            newState.isImporting = payload.value;
            return newState;
        case SettingsDispatchMessageType.SetApplicationSettings:
            newState.applicationSettings = payload.settings;
            return newState;
        case SettingsDispatchMessageType.SetIsLoaded:
            newState.loaded = payload.value;
            return newState;
        case SettingsDispatchMessageType.SetHasImportError:
            newState.hasImportError = payload.value;
            return newState;
        case SettingsDispatchMessageType.SetLastImportDate:
            newState.lastImportDate = payload.value;
            return newState;
        default:
            throw new Error("Invalid message type");
    }
}