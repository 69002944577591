import React, {memo, useMemo} from "react";
import {Animation, Button, CloseIcon, Dialog, Flex, Segment, Text} from "@fluentui/react-northstar";
import "./ItemDetails.styles.scss";
import {ItemDetailsProps, ItemType} from "./ItemDetails.interfaces";
import {useItemDetails} from "./ItemDetails.hook";
import {LocationDetails} from "../LocationDetails/LocationDetails";
import {EventDetails} from "../EventDetails/EventDetails";
import BuildingKpiDetails from "../../views/Dashboard/BuildingKpiDetails/BuildingKpiDetails";

const propsAreEqual = (prevProps: Readonly<ItemDetailsProps>, nextProps: Readonly<ItemDetailsProps>) => {
    return (
        prevProps.itemId === nextProps.itemId &&
        prevProps.itemType === nextProps.itemType
    )
}

export const ItemDetails = memo((props: ItemDetailsProps) => {
    const logic = useItemDetails(props);

    const itemContent = useMemo(() => {
        let content: JSX.Element | null = null;
        if (!!logic.itemId) {
            switch (logic.itemType) {
                case ItemType.Location:
                    content = <LocationDetails locationId={logic.itemId}/>;
                    break;
                case ItemType.Event:
                    content = <EventDetails eventId={logic.itemId}/>;
                    break;
                case ItemType.BuildingKpi:
                    content = (
                        <BuildingKpiDetails
                            buildingId={logic.itemId}
                            start={logic.start}
                            end={logic.end}
                        />
                    )
                    break;
                default:
                    return null;
            }
        }
        return (
            <Flex fill className={"pos-relative overflow-scroll item-details-content"}>
                <Flex className={"fill-absolute"}>
                    {content}
                </Flex>
            </Flex>
        )
    }, [logic.itemId]);

    const header = useMemo(() => (
        <Flex gap={"gap.small"} space={"between"} className={"item-details-header"} vAlign={"center"}>
            <Flex fill gap={"gap.small"} vAlign={"center"} space={"between"}>
                <Flex fill className={"pos-relative"}>
                    <Flex fill className={"fill-absolute"} vAlign={"center"}>
                        <Text truncated size={"large"} weight={"bold"} content={logic.itemName ?? logic.title ?? ""}/>
                    </Flex>
                </Flex>
                <Button text iconOnly icon={<CloseIcon/>} {...(logic.onHide && {onClick: logic.onHide})}/>
            </Flex>
        </Flex>
    ), [logic.itemName, logic.title]);

    const content = !logic.show ? null : (
        <Flex fill column>
            {header}
            {itemContent}
        </Flex>
    );

    return (
        <Animation
            {...(logic.onHide && {onExited: logic.onHide})}
            visible={logic.show}
            name={logic.show ? 'fadeinDialog' : 'fadeoutDialog'}
            mountOnEnter
            unmountOnExit
        >
            {({classes}) => logic.isOnMobile ? (
                <Segment className={"item-details-mobile-container " + classes}>
                    {content}
                </Segment>
            ) : (
                <Dialog
                    key={"item-details-dialog-" + logic.itemId}
                    styles={{
                        width: "800px",
                        maxWidth: "calc(100vw - 100px)",
                        height: "calc(100vh - 200px)",
                        maxHeight: "1000px",
                    }}
                    closeOnOutsideClick
                    {...(logic.onHide && {onCancel: logic.onHide})}
                    overlay={{className: logic.show ? "open" : "close"}}
                    className={"item-details-dialog " + classes}
                    open
                    content={content}
                    footer={null}
                />
            )
            }
        </Animation>
    )
}, propsAreEqual)