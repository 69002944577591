import {
    IGeoPosition,
    ILocation,
    ILocationHours,
    LocationImageLevel,
    LocationType,
    PriceRange,
    WeekDay
} from "interfaces";
import moment, {Moment} from "moment";
import {ILocationOpenState} from "interfaces/Location/ILocationOpenState";
import * as microsoftTeams from "@microsoft/teams-js";
import {Brand} from "../interfaces/Brand/Brand";
import languageObject from "../translations";
import {BrandModule} from "./Brand.module";
import {IRawLocation} from "../interfaces/Location/IRawLocation";
import {IFilterItem} from "../components/Filter/Filter.interfaces";
import {Immutable} from "../interfaces/Immutable";
import {InRangeFilters} from "../interfaces/InRangeFilters";

const call = async (phone: string, isOnMobile: boolean) => {
    const isMicrosoftTeamsCallSupported = microsoftTeams.call.isSupported();
    if (!isOnMobile && isMicrosoftTeamsCallSupported) {
        try {
            await microsoftTeams.call.startCall({targets: [phone]});
        } catch (e) {
            window.open("tel:" + phone);
        }
    } else {
        window.open("tel:" + phone);
    }
}

const formatPhoneNumber = (phoneNumber: string) => {
    const formattedPhoneNumber = phoneNumber.replace(/ /g, "").replace("+33", "0").replace(/^00/, "0");
    return formattedPhoneNumber.replace(/(\d\d)/g, "$1 ").trim()
}

const showItinerary = (userPosition: IGeoPosition | null | undefined, locationPosition: IGeoPosition | null | undefined) => {
    if (!userPosition) return console.error("Can't find user position");
    if (!locationPosition) return console.error("Location has no position");
    let url: string;
    url = "https://www.google.fr/maps/dir/";
    url += userPosition.lat + "," + userPosition.lng;
    url += "/";
    url += locationPosition.lat + "," + locationPosition.lng;
    window.open(url);
}

const formatHoursRangeToMoment = (range: [string, string]): [Moment, Moment] => {
    const startTime = range[0].split(":");
    const startHour = Number(startTime[0]);
    const startMinutes = Number(startTime[1]);
    if (startTime.length < 2) throw new Error("Invalid location time");
    const start = moment().startOf("day").set("hours", startHour).set("minutes", startMinutes);
    const endTime = range[1].split(":");
    const endHour = Number(endTime[0]);
    const endMinutes = Number(endTime[1]);
    if (endTime.length < 2) throw new Error("Invalid location time");
    let end = moment().startOf("day").set("hours", endHour).set("minutes", endMinutes);
    if (end < start) end = end.add(1, "day");
    return [start, end];
}

const getStateHoursRange = (openHours: ILocationHours): [Moment, Moment] | undefined => {
    const now = moment();
    const dayNumber = now.toDate().getDay() as WeekDay;
    const dayOpenedHours = openHours[dayNumber];
    if (dayOpenedHours.length === 0) return;
    const openRange = dayOpenedHours.find(range => {
        const formattedRange = formatHoursRangeToMoment(range);
        return formattedRange[0] <= now && now <= formattedRange[1];
    });
    if (!openRange) return;
    return formatHoursRangeToMoment(openRange);
}

const getNextStateHoursRange = (openHours: ILocationHours): [Moment, Moment] => {
    const now = moment();
    let dayNumber = now.toDate().getDay() as WeekDay;
    const dayOpenedHours = openHours[dayNumber];
    let openRange = dayOpenedHours.find(range => {
        const formattedRange = formatHoursRangeToMoment(range);
        return now <= formattedRange[0];
    });
    if (openRange === undefined) {
        let nextDay = now.add(1, "day");
        while (!openRange) {
            dayNumber = nextDay.toDate().getDay() as WeekDay;
            openRange = openHours[dayNumber][0];
            nextDay = nextDay.add(1, "day");
            if (nextDay === moment(now).add(7, "day")) break;
        }
        if (openRange === undefined)
            throw new Error("Can't find next open range : " + openHours);
    }
    return formatHoursRangeToMoment(openRange);
}

const getOpenStateLabel = (
    translate: (id: string, parameters?: Record<string, string>) => string,
    isOpen: boolean,
    nextStateTime: Moment | undefined
) => {
    if (!nextStateTime) return translate("Closed");
    const now = moment();
    const nextStateTimeLabel = nextStateTime.format("LT");
    if (isOpen) return translate("OpenUntil", {hour: nextStateTimeLabel});
    if (nextStateTime < now) return translate("Closed");
    return translate("OpenAt", {hour: nextStateTimeLabel});
}

const getPriceRangeLabel = (priceRange: PriceRange): string => {
    switch (priceRange) {
        case PriceRange.Low:
            return "€";
        case PriceRange.Medium:
            return "€€";
        case PriceRange.High:
            return "€€€";
        case PriceRange.Expensive:
            return "€€€€";
    }
}

const isOpen = (openHours: ILocationHours) => {
    return getStateHoursRange(openHours) !== undefined;
}

const getOpenStateData = (openHours: ILocationHours): ILocationOpenState => {
    if (Object.values(openHours).every(hours => hours.length === 0)) {
        return {
            isOpen: false,
            nextStateTime: "",
            stateLabel: getOpenStateLabel(languageObject.translate, false, undefined),
        }
    }
    let stateRange = getStateHoursRange(openHours);
    const isOpen = stateRange !== undefined;
    if (!isOpen) stateRange = getNextStateHoursRange(openHours);
    if (!stateRange) throw new Error("Can't find next state range");
    const nextStateTime = stateRange[isOpen ? 1 : 0];
    const label = getOpenStateLabel(languageObject.translate, isOpen, nextStateTime);
    return {
        isOpen,
        nextStateTime: nextStateTime.toISOString(),
        stateLabel: label,
    }
}

const getDistanceLabel = (distance: number) => {
    if (distance < 0) return "";
    if (distance < 1000) return distance + " m";
    return Math.round(distance / 100) / 10 + " km";
}

const getFilteredLocations = (
    locations: Immutable<Array<ILocation>>,
    locationFilters: Immutable<Array<IFilterItem>>,
    userFavoriteBrands: Immutable<Array<Brand>>,
): Immutable<Array<ILocation>> => {
    let filteredLocations = [...locations];
    locationFilters.forEach(filter => {
        if (!!filter.menu || filter.isChecked) {
            switch (filter.key) {
                case "types":
                    const selectedTypes = filter.menu?.filter(f => f.isChecked).map(f => f.value as LocationType);
                    if (selectedTypes?.length === 0) break;
                    filteredLocations = filteredLocations.filter(l => selectedTypes?.includes(l.type));
                    break;
                case "image-levels":
                    const selectedImageLevels = filter.menu?.filter(f => f.isChecked).map(f => f.value as LocationImageLevel);
                    if (selectedImageLevels?.length === 0) break;
                    filteredLocations = filteredLocations.filter(l => selectedImageLevels?.includes(l.imageLevel));
                    break;
                case "brands":
                    const selectedBrands = filter.menu?.filter(f => f.isChecked).map(f => f.value as Brand);
                    if (!selectedBrands || selectedBrands?.length === 0) break;
                    filteredLocations = filteredLocations.filter(l => {
                        if (l.flagship !== undefined && selectedBrands.includes(l.flagship)) return true;
                        for (const brand in selectedBrands)
                            if (l.brands.includes(Number(brand))) return true;
                        return false;
                    });
                    break;
                case "currently-open":
                    filteredLocations = filteredLocations.filter(l => l.extendedProps?.openStateData?.isOpen);
                    break;
                case "only-favorite-brands":
                    if (userFavoriteBrands.length > 0)
                        filteredLocations = filteredLocations.filter(l => !!l.brands.find(brand => userFavoriteBrands.includes(brand)));
                    else filteredLocations = [];
                    break;
                case "partner-only":
                    filteredLocations = filteredLocations.filter(l => l.isRecommended);
                    break;
                case "max-distance":
                    const inputValue = filter.input?.value;
                    if (!inputValue) break;
                    filteredLocations = filteredLocations.filter(l => l.distance <= inputValue);
                    break;
                default:
                    break;
            }
        }
    });
    return filteredLocations;
}

const formatRawLocationHours = (hours: Array<[string, string]>): Array<[string, string]> => {
    return hours.map((range) => range.map((time) => moment(time, 'HHmma').format("HH:mm")) as [string, string]);
}

const formatRawLocationPictures = (pictures: Array<string>) => {
    return pictures.map(picture => {
        // Replace picture size full HD to 800px width
        return picture.replace("=w1920-h1080", "=w800");
    })
}

const getLocationThumbnail = (pictures: Array<string>): string => {
    if (pictures.length === 0) return "";
    const thumbnailWidth = 500;
    const replaceString = `=w${thumbnailWidth}`;
    return pictures[0]!.replace("=w1920-h1080", replaceString).replace("=w800", replaceString);
}

const formatRawLocationBrands = (brands: Array<string>) => {
    return BrandModule.parseStringBrands(brands);
}

const generateGoogleMapsLink = (name: string, fullAddress: string, lat: number, lng: number) => {
    let searchQuery = [name, fullAddress].join(", ");
    searchQuery = searchQuery.replace(/ /g, "+").replace(/\++/g, "+");
    if (lat && lng) searchQuery += `/@${lat},${lng}`;
    return "https://www.google.fr/maps/search/" + searchQuery;
}

const formatRawLocation = (rawLocation: IRawLocation): Immutable<ILocation> | null => {
    const pictures = formatRawLocationPictures(rawLocation.pictures);
    const thumbnail = getLocationThumbnail(rawLocation.pictures);
    const brands = formatRawLocationBrands(rawLocation.brands);

    const location: ILocation = {
        id: rawLocation.id,
        address: {
            ...rawLocation.address,
            geoPosition: !rawLocation.address.geoPosition ? null : {
                lat: rawLocation.address.geoPosition?.latitude,
                lng: rawLocation.address.geoPosition?.longitude,
            }
        },
        pictures,
        thumbnail,
        brands: brands,
        distance: Math.round(rawLocation.distance),
        recommendNote: rawLocation.recommendNote?.trim(),
        isShow: rawLocation.isShow,
        isRecommended: rawLocation.isRecommended,
        sectorManagerReview: rawLocation.sectorManagerReview?.trim(),
        extendedProps: {},
        hours: {
            [WeekDay.Monday]: formatRawLocationHours(rawLocation.hours.monday),
            [WeekDay.Tuesday]: formatRawLocationHours(rawLocation.hours.tuesday),
            [WeekDay.Wednesday]: formatRawLocationHours(rawLocation.hours.wednesday),
            [WeekDay.Thursday]: formatRawLocationHours(rawLocation.hours.thursday),
            [WeekDay.Friday]: formatRawLocationHours(rawLocation.hours.friday),
            [WeekDay.Saturday]: formatRawLocationHours(rawLocation.hours.saturday),
            [WeekDay.Sunday]: formatRawLocationHours(rawLocation.hours.sunday),
        },
        imageLevel: rawLocation.popularity,
        name: rawLocation.name?.trim(),
        phone: rawLocation.phone?.trim(),
        priceRange: rawLocation.priceRange,
        sectorManagerId: rawLocation.sectorManagerId?.trim(),
        sectorManagerEmail: rawLocation.sectorManagerEmail?.trim(),
        type: rawLocation.location,
        googleMapsLink: generateGoogleMapsLink(
            rawLocation.name,
            rawLocation.address.fullAddress,
            rawLocation.address.geoPosition?.latitude ?? 0,
            rawLocation.address.geoPosition?.longitude ?? 0
        ),
        website: rawLocation.website?.trim(),
        updateAt: rawLocation.updateAt,
        updateBy: rawLocation.updateBy,
    }

    if (!LocationImageLevel[location.imageLevel]) location.imageLevel = LocationImageLevel.Mainstream;
    if (!!rawLocation.flagship) location.flagship = Number(rawLocation.flagship);

    location.extendedProps = {
        ...location.extendedProps,
        openStateData: LocationModule.getOpenStateData(location.hours),
    };

    return location;
}

const isLocationVisible = (location: Immutable<ILocation>): boolean => {
    return location.isShow && !!location.address.geoPosition;
}

const isLocationComplete = (location: Immutable<ILocation>): boolean => {
    return !!location.address.geoPosition && !!location.address.fullAddress;
}

const getInRangeFilters = (filters: Array<IFilterItem> | Immutable<Array<IFilterItem>>): InRangeFilters | undefined => {
    const inRangeFilters: InRangeFilters = {
        brands: [],
        currentlyOpen: false,
        favoriteBrandsOnly: false,
        imageLevels: [],
        recommendedOnly: false,
        types: [],
        withinRadius: undefined,
    };
    filters.forEach(filter => {
        if (!!filter.menu || filter.isChecked) {
            switch (filter.key) {
                case "types":
                    const selectedTypes = filter.menu?.filter(f => f.isChecked).map(f => f.value as LocationType);
                    inRangeFilters.types = selectedTypes ?? [];
                    break;
                case "image-levels":
                    const selectedImageLevels = filter.menu?.filter(f => f.isChecked).map(f => f.value as LocationImageLevel);
                    inRangeFilters.imageLevels = selectedImageLevels ?? [];
                    break;
                case "brands":
                    const selectedBrands = filter.menu?.filter(f => f.isChecked)
                        .map(f => Brand[f.value as Brand] ?? "").filter(b => b);
                    inRangeFilters.brands = selectedBrands ?? [];
                    break;
                case "currently-open":
                    inRangeFilters.currentlyOpen = true;
                    break;
                case "only-favorite-brands":
                    inRangeFilters.favoriteBrandsOnly = true;
                    break;
                case "partner-only":
                    inRangeFilters.recommendedOnly = true;
                    break;
                case "max-distance":
                    if (filter.input?.value) inRangeFilters.withinRadius = Number(filter.input!.value);
                    break;
                default:
                    break;
            }
        }
    });
    const isDefaultRequest =
        inRangeFilters.types.length === 0 &&
        inRangeFilters.imageLevels.length === 0 &&
        inRangeFilters.brands.length === 0 &&
        !inRangeFilters.currentlyOpen &&
        !inRangeFilters.recommendedOnly &&
        !inRangeFilters.favoriteBrandsOnly &&
        inRangeFilters.withinRadius === undefined;
    if (isDefaultRequest) return undefined;
    return inRangeFilters;
}

export const LocationModule = {
    getStateHoursRange,
    getPriceRangeLabel,
    isOpen,
    getOpenStateData,
    getDistanceLabel,
    getFilteredLocations,
    call,
    showItinerary,
    formatRawLocation,
    formatPhoneNumber,
    isLocationVisible,
    isLocationComplete,
    generateGoogleMapsLink,
    getInRangeFilters
}