import React, {useMemo} from "react";
import {Button, CheckmarkCircleIcon, Divider, Flex} from "@fluentui/react-northstar";
import "./ReviewForm.styles.scss";
import {IReviewFormProps} from "./ReviewForm.interfaces";
import {useReviewForm} from "./ReviewForm.hook";
import {useTranslation} from "../../services/TranslationService/TranslationService.hook";
import {ShareIcon} from "../../assets/icons";
import {NotificationDialog} from "../NotificationDialog/NotificationDialog";
import {brandColors} from "../../const/ThemeCustomizations";

export const ReviewForm = (props: IReviewFormProps) => {
    const logic = useReviewForm(props);
    const translate = useTranslation();

    const shareButton = useMemo(() => (
        <Flex className={"w-100"}>
            <Button
                fluid
                primary
                icon={<ShareIcon fill={logic.isFormValid ? "white" : "gray"}/>}
                content={translate("Share")}
                onClick={logic.handleSubmit}
                disabled={!logic.isFormValid || logic.isSendingFeedback}
                loading={logic.isSendingFeedback}
            />
        </Flex>
    ), [logic.isFormValid, logic.isSendingFeedback]);

    const notificationDialog = useMemo(() => (
        <NotificationDialog
            show={logic.showNotificationDialog}
            onHide={() => 0}
            title={translate("FeedbackShared")}
            icon={
                <CheckmarkCircleIcon
                    size={"largest"}
                    styles={{color: brandColors[500], transform: "scale(2)", padding: "30px"}}
                />
            }
        />
    ), [logic.showNotificationDialog])

    return (
        <Flex fill column>
            <Flex fill className={"pos-relative overflow-scroll"}>
                <Flex fill column gap={"gap.medium"} className={"fill-absolute"}>
                    {logic.form}
                    <Flex styles={{minHeight: "30px"}}/>
                </Flex>
            </Flex>
            <Flex column gap={"gap.small"}>
                <Divider styles={{padding: 0}}/>
                {shareButton}
            </Flex>
            {notificationDialog}
        </Flex>
    )
}