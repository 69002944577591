import React, {useContext} from "react";
import {BarChartKpiLogic} from "./BarChartKpi.logic";
import {BarChartKpiRendering} from "./BarChartKpi.rendering";
import {Props} from "./BarChartKpi.interfaces";
import {ITeamsContext} from "../../../../services/TeamsContext/TeamsContext.interfaces";
import {TeamsContext} from "../../../../services/TeamsContext";

const BarChartKpi = (props: Props): JSX.Element => {
    const teamsContext = useContext<ITeamsContext>(TeamsContext);

    return (
        <BarChartKpiLogic {...props} isLightTheme={teamsContext.isLightTheme}>
            {logic => <BarChartKpiRendering {...logic}/>}
        </BarChartKpiLogic>
    )
}

export default BarChartKpi;